import { Form } from 'antd'
import { getBlob, getStorage, ref } from 'firebase/storage'
import { useDispatch, useSelector } from 'react-redux'
import { FileUpload } from '../../../components/Forms/Inputs'
import { setStepLoading } from '../../../context/onboardingSlice'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../../../analytics/helpers'

export const UploadCSFInput = ({
    onFiscalLoaded,
    loadingInfoRetrieval,
    customAction,
    customLabel,
    customPath,
    trackLoading,
    style,
}: {
    onFiscalLoaded: (v: any) => void
    loadingInfoRetrieval?: boolean
    customAction?: (data: any) => void
    customLabel?: string
    customPath?: string
    trackLoading?: (loading: boolean) => void
    style?: React.CSSProperties | undefined
}) => {
    const dispatch = useDispatch()
    const posthog = usePostHog()
    const { team } = useSelector((state: any) => state.team)

    return (
        <Form.Item name="fiscal-file" style={style}>
            <FileUpload
                allowedFiles={['application/pdf']}
                label={customLabel || 'Seleccionar constancia de situación fiscal'}
                name="fiscal-file"
                placeholder="Pre-llena la información desde tu constancia"
                storagePath={customPath || `teams/${team.id}/files/csf`}
                firestorePath={customPath || `teams/${team.id}/files/csf`}
                previousDataKey="fiscalData"
                firestoreKey="csf"
                onFileUploaded={async (file: any) => {
                    console.log('File uploaded:', file)
                    trackLoading?.(true)
                    trackEvent({ name: 'onboarding_fiscal_from_csf', metadata: { v: 'v2' } }, posthog)
                    dispatch(setStepLoading(true))
                    const fileReference = ref(getStorage(), file.url)
                    const fileBlob = await getBlob(fileReference)

                    const formData = new FormData()
                    formData.append('constancia', fileBlob, file.name)

                    const res = await fetch(
                        `${process.env.REACT_APP_INTERNAL_API_URL}/fiscalDataAPI/v1/getFromConstancia`,
                        {
                            method: 'POST',
                            body: formData,
                        },
                    )

                    const data = await res.json()
                    if (customAction) {
                        customAction(data)
                    }
                    console.log(data)
                    trackLoading?.(false)
                    const fiscalData = data?.fiscalData || {}
                    dispatch(setStepLoading(false))
                    if (onFiscalLoaded) {
                        onFiscalLoaded(fiscalData)
                    }
                }}
            />
        </Form.Item>
    )
}
