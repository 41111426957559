import { Col, Divider, Drawer, Row, Typography } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { setData } from '../context/dataSlice'
import { X } from '@phosphor-icons/react'
import PersonalProfileForm from '../components/Forms/PersonalProfileForm'
import BillingAccountForm from '../components/Forms/BillingAccountForm'
import AutomaticInvoiceDefaultsForm from '../components/Forms/AutomatiInvoiceDefaults'
import TeamForm from '../components/Forms/TeamForm'
import CustomerPortalForm from '../components/Forms/CustomerPortalForm'
import SecurityForm from '../components/Forms/SecurityForm'
import SuscriptionForm from '../components/Forms/SuscriptionForm'
import DefaultTaxesForm from '../components/Forms/DefaultTaxesForm'
import GlobalInvoiceForm from '../components/Forms/GlobalInvoiceForm'
import AddendaForm from '../components/Forms/AddendaForm'
import InvoiceCustomMessageForm from '../components/Forms/InvoiceCustomMessageForm'
import ElementEmailTemplateForm from '../components/Forms/ElementEmailTemplateForm'
import LimitDaysToPayForm from '../components/Forms/LimitDaysToPayForm'
import PaymentsRemindersSettings from '../components/Forms/PaymentsRemindersSettings'
import GeneralSalesReceiptsForm from '../components/Forms/GeneralSalesReceiptsForm'
import ApiKeysForm from '../components/Forms/ApiKeysForm'
import WebhooksList from '../components/ComponentIndividual/WebhooksList'
import WebooksCreateForm from '../components/Forms/WebhooksCreateForm'
import SeatsTeamList from '../components/ComponentIndividual/SeatsTeamList'
import InviteNewMemberForm from '../components/Forms/InviteNewMemberForm'
import { InterfaceSettings } from '../components/Forms/InterfacePreferences'
import { removeParamFromUrl } from '../functions/UrlParams'
import CustomEmailForm from '../components/Forms/CustomEmail'
import PendingInvitesTeamList from '../components/ComponentIndividual/PendingInvitesTeamList'

const SettingsDrawer: React.FC = () => {
    // Component logic goes here
    const dispatch = useDispatch()
    const { settingsDrawerVisible } = useSelector((state: any) => state.modals)
    const { settingsDrawerConfig, testmode } = useSelector((state: any) => state.data)

    const SettingRender = () => {
        switch (settingsDrawerConfig?.type) {
            case 'personal':
                return (
                    <PersonalProfileForm
                        buttonTitle="Actualizar"
                        onUpdate={() => {
                            dispatch(
                                setData({
                                    type: 'settingsDrawerConfig',
                                    payload: null,
                                }),
                            )
                            dispatch(closeModal('settingsDrawerVisible'))
                        }}
                    />
                )

            case 'billing':
                return <BillingAccountForm onUpdate={() => {}} buttonTitle="Actualizar" />

            case 'defaults.invoice':
                return <AutomaticInvoiceDefaultsForm buttonTitle="Actualizar" />

            case 'brand':
                return (
                    <TeamForm
                        onUpdate={() => {
                            dispatch(
                                setData({
                                    type: 'settingsDrawerConfig',
                                    payload: null,
                                }),
                            )
                            dispatch(closeModal('settingsDrawerVisible'))
                        }}
                        showLogoPreview={true}
                        updateContext={true}
                        buttonTitle="Actualizar"
                    />
                )

            case 'customerPortal':
                return <CustomerPortalForm />

            case 'security':
                return <SecurityForm />

            case 'subscription':
                return <SuscriptionForm />
            case 'defaults.taxes':
                return <DefaultTaxesForm />

            case 'defaults.globalInvoice':
                return <GlobalInvoiceForm />

            case 'defaults.addenda':
                return <AddendaForm />

            case 'defaults.invoicePDFExtra':
                return <InvoiceCustomMessageForm />
            case 'defaults.invoiceMessage':
                return (
                    <ElementEmailTemplateForm
                        label="Plantilla para nuevas facturas de ingreso"
                        template="invoiceMessage"
                        emailTitle="Plantilla para nuevas facturas de ingreso"
                        emailHelper="Este correo se envía cuando se crea una nueva factura de ingreso"
                        replacers={['cliente', 'empresa', 'id', 'folio', 'fecha', 'total', 'moneda', 'servicios']}
                    />
                )

            case 'defaults.paymentsEmailsTemplate':
                return (
                    <ElementEmailTemplateForm
                        label="Plantilla para nuevos pagos"
                        template="paymentsEmailsTemplate"
                        emailTitle="Plantilla para nuevos pagos"
                        emailHelper="Este correo se envía cuando se solicita un nuevo pago a un cliente"
                        replacers={['cliente', 'empresa', 'id', 'fecha', 'total', 'moneda']}
                    />
                )

            case 'defaults.limitDaysToPay':
                return <LimitDaysToPayForm />

            case 'defaults.paymentsReminderDays':
                return <PaymentsRemindersSettings />

            case 'salesReceipts':
                return <GeneralSalesReceiptsForm />

            case 'defaults.receiptsEmailsTemplate':
                return (
                    <div>
                        <ElementEmailTemplateForm
                            label="Plantilla para nuevos recibos"
                            subjectLabel="Asunto de la plantilla para nuevos recibos"
                            template="receiptsEmailsTemplate"
                            replacers={['cliente', 'empresa', 'id', 'fecha', 'total', 'moneda', 'alias']}
                            emailTitle="Plantilla para nuevos recibos (portales de auto-facturación)"
                            emailHelper="Este correo se enviará al cliente cuando realice un pago adicional a su factura"
                        />
                    </div>
                )

            case 'apikeys':
                return <ApiKeysForm />

            case 'webhooks.list':
                return <WebhooksList />

            case 'webhooks.create':
                return <WebooksCreateForm buttonTitle="Crear webhook" />

            case 'seats':
                return <SeatsTeamList />
            case 'seats.invite':
                return <InviteNewMemberForm />
            case 'seats.pending':
                return <PendingInvitesTeamList />
            case 'interface.settings':
                return <InterfaceSettings />
            case 'emails':
                return <CustomEmailForm />
            default:
                return <div>Default</div>
        }
    }

    return (
        // JSX for the component goes here
        <Drawer
            style={{ marginTop: testmode ? '32px' : '0' }}
            width={window.innerWidth < 900 ? '100%' : '60%'}
            open={settingsDrawerVisible}
            title={null}
            closable={false}
            onClose={() => {
                dispatch(
                    setData({
                        type: 'settingsDrawerConfig',
                        payload: null,
                    }),
                )
                dispatch(closeModal('settingsDrawerVisible'))
                removeParamFromUrl('open')
            }}
        >
            {/* Content of the SettingsDrawer */}
            <Row>
                <Col xs={24}>
                    <Row justify="start">
                        <X
                            size={20}
                            onClick={() => {
                                dispatch(
                                    setData({
                                        type: 'settingsDrawerConfig',
                                        payload: null,
                                    }),
                                )
                                dispatch(closeModal('settingsDrawerVisible'))
                                removeParamFromUrl('open')
                            }}
                            className="clickable"
                        />
                    </Row>
                </Col>
                <Col xs={24} style={{ padding: '20px 15px' }}>
                    <Row>
                        <Col xs={24}>
                            <div className="d-flex flex-column">
                                <Typography.Text className="mediumparagraphbold">
                                    {settingsDrawerConfig?.title ?? '-'}
                                </Typography.Text>
                                <Typography.Text className="smallparagraph descriptions" style={{ marginTop: '5px' }}>
                                    {settingsDrawerConfig?.description ?? '-'}
                                </Typography.Text>
                            </div>
                        </Col>
                        <Divider />
                        <Col xs={24}>
                            <SettingRender />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Drawer>
    )
}

export default SettingsDrawer
