import { TeamDef } from '../../interfaces/teamDef'
import stripeLogo from '../../assets/images/stripeSquare.png'
import openpayLogo from '../../assets/images/logoopenpaySquare.jpg'
import speiLogo from '../../assets/images/speiSquare.png'
import zapierLogo from '../../assets/images/zapierSquareBgWhite.png'
import airtableLogo from '../../assets/images/airtableSquare.png'
import googleSheetsLogo from '../../assets/images/googleSheetsSquare.png'
import WHMCS from '../../assets/images/WHMCS.png'
import satLogo from '../../assets/images/logoSatSquare.png'
import hilosLogo from '../../assets/images/hilosSquare.png'
import woocomerce from '../../assets/images/woocomerce.png'
import payPalLogo from '../../assets/images/paypalSquare.png'
import ClipLogo from '../../assets/images/clip_logo.png'
import conektaLogo from '../../assets/images/conekta.png'

export const integrations = (team: TeamDef) => {
    return [
        {
            name: 'Stripe',
            logo: stripeLogo,
            completed: team?.stripe?.completed,
            description: 'Cobra a tus clientes con Stripe',
            id: 'stripe',
            clasification: 'payments',
            modalKey: 'stripeModalVisible',
            bullets: [
                { type: 'positive', description: '' },
            ]
        },
        {
            name: 'Openpay',
            logo: openpayLogo,
            completed: team?.openpay?.completed,
            description: 'Cobra a tus clientes con Openpay',
            id: 'openpay',
            clasification: 'payments',
            modalKey: 'openpayModalVisible',
            bullets: [
                { type: 'positive', description: '' },
            ]
        },
        {
            name: 'Conekta',
            logo: conektaLogo,
            completed: team?.conekta?.completed,
            description: 'Cobra a tus clientes con Conekta',
            id: 'conekta',
            clasification: 'payments',
            modalKey: 'conektaModalVisible',
            bullets: [
                { type: 'positive', description: '' },
            ]
        },
        {
            name: 'SPEI',
            logo: speiLogo,
            completed: team?.bank?.completed,
            description: 'Recibe transferencia vía SPEI',
            id: 'spei',
            clasification: 'payments',
            modalKey: 'speiModalVisible',
            bullets: [
                { type: 'positive', description: '' },
            ]
        },
        {
            name: 'Paypal',
            logo: payPalLogo,
            completed: team?.paypal?.completed,
            description: 'Recibe las transacciones cobradas con PayPal',
            id: 'paypal',
            clasification: 'payments',
            modalKey: 'paypalModalVisible',
            disabled: false,
            bullets: [
                { type: 'positive', description: 'Facturar automáticamente tus ventas de Paypal' },
                { type: 'positive', description: 'Sincroniza automáticamente tus ventas de Paypal' },
            ]
        },
        {
            name: 'Clip',
            logo: ClipLogo,
            completed: team?.clip?.completed,
            description: 'Recibe las transacciones cobradas con Clip',
            id: 'clip',
            clasification: 'payments',
            modalKey: 'clipModalVisible',
            disabled: false,
            bullets: [
                { type: 'positive', description: 'Facturar automáticamente tus ventas de Clip' },
                { type: 'positive', description: 'Sincroniza automáticamente tus ventas de Clip' },
            ]
        },
        {
            name: 'Zapier',
            logo: zapierLogo,
            completed: team?.zapier?.completed,
            description: 'Procesa pagos desde Zapier',
            id: 'zapier',
            clasification: 'payments',
            modalKey: 'zapierModalVisible',
            bullets: [
                { type: 'positive', description: '' },
            ]
        },
        {
            name: 'Woocomerce',
            logo: woocomerce,
            completed: team?.woocomerce?.completed,
            description: 'Recibe las transacciones vía Woocomerce',
            id: 'woocommerce',
            clasification: 'payments',
            modalKey: 'woocomerceModalVisible',
            bullets: [
                { type: 'positive', description: '' },
            ]
        },
        
        {
            name: 'Airtable',
            logo: airtableLogo,
            completed: team?.airtable?.completed,
            description: 'Crea solicitudes de pago desde Airtable',
            id: 'airtable',
            clasification: 'payments',
            modalKey: 'airtableModalVisible',
            bullets: [
                { type: 'positive', description: '' },
            ]
        },
        {
            name: 'Google Sheets',
            logo: googleSheetsLogo,
            completed: team?.googlesheets?.completed,
            description: 'Registra pagos desde Google Sheets',
            id: 'googlesheets',
            clasification: 'payments',
            modalKey: 'googleSheetsModalVisible',
            bullets: [
                { type: 'positive', description: '' },
            ]
        },
        {
            name: 'WHMCS',
            logo: WHMCS,
            completed: team?.whmcs?.completed,
            description: 'Conectate a WHMCS',
            id: 'whmcs',
            clasification: 'payments',
            modalKey: 'whmcsModalVisible',
            bullets: [
                { type: 'positive', description: '' },
            ]
        },
        {
            name: 'SAT',
            logo: satLogo,
            completed: team?.facturapi?.completed,
            description: 'Emite facturas desde Gigstack',
            id: 'facturapi',
            clasification: 'invoice',
            modalKey: 'satModalVisible',
            bullets: [
                { type: 'positive', description: '' },
            ]
        },
        {
            name: 'Hilos',
            logo: hilosLogo,
            completed: team?.hilos?.completed,
            description: 'Envía mensajes de whatsapp a tus clientes',
            id: 'hilos',
            clasification: 'communication',
            modalKey: 'hilosModalVisible',
            bullets: [
                { type: 'positive', description: '' },
            ]
        },
    ]
}
