import { Modal, Row, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { FileUpload } from '../components/Forms/Inputs'
import { generateCode } from '../../helpers/helperFunctions'
import { useEffect, useState } from 'react'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import { useAuth } from 'reactfire'
import moment from 'moment'

export const UploadThingsModal = () => {
    const dispatch = useDispatch()
    const auth = useAuth()

    const { uploadThingsModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)
    const { importThingsInfo, testmode } = useSelector((state: any) => state.data)
    const [fileUploaded, setFileUploaded] = useState<any>(null)
    const [loading, setLoading] = useState(false)

    const [storagePath, setStoragePath] = useState(
        `teams/${team?.id}/files/${generateCode(10, 'file')}.${importThingsInfo?.extension ?? 'xlsx'}`,
    )

    useEffect(() => {
        if (uploadThingsModalVisible) {
            setStoragePath(
                `teams/${team?.id}/files/${generateCode(10, 'file')}.${importThingsInfo?.extension ?? 'xlsx'}`,
            )
        }
        // eslint-disable-next-line
    }, [uploadThingsModalVisible])

    const handleUploadFile = async () => {
        try {
            setLoading(true)

            await setDoc(doc(getFirestore(), `imports/${generateCode(15, 'imp')}`), {
                path: storagePath,
                livemode: !testmode,
                fileType: 'application / vnd.openxmlformats - officedocument.spreadsheetml.sheet',
                owner: auth?.currentUser?.uid ?? team?.owner,
                status: 'processing',
                timestamp: moment().valueOf(),
                type: importThingsInfo?.type ?? '',
                team: team?.id,
                ...(fileUploaded ?? {}),
            })
            setLoading(false)

            message.success('Archivo subido correctamente')

            dispatch(closeModal('uploadThingsModalVisible'))
        } catch (error: any) {
            setLoading(false)
            message.error(error?.message ?? 'Ocurrio un error al subir el archivo')
        }
    }

    return (
        <Modal
            open={uploadThingsModalVisible}
            onCancel={() => {
                dispatch(closeModal('uploadThingsModalVisible'))
            }}
            title={`Importar ${importThingsInfo?.readableType}`}
            onOk={handleUploadFile}
            confirmLoading={loading}
        >
            <Typography.Text>
                Para importar {importThingsInfo?.readableType} es necesario que utilices{' '}
                {importThingsInfo?.templateUrl ? (
                    <Typography.Link href={importThingsInfo?.templateUrl} rel="noreferrer" target="_blank">
                        {' '}
                        {`\u00A0`} esta plantilla de excel
                    </Typography.Link>
                ) : (
                    ''
                )}
            </Typography.Text>
            {importThingsInfo?.description ? (
                <Typography.Text className="descriptions">{importThingsInfo?.description}</Typography.Text>
            ) : (
                ''
            )}

            <Row style={{ margin: '20px 0px' }}>
                <FileUpload
                    name="file"
                    label={`Sube el archivo de ${importThingsInfo?.readableType}`}
                    // firestoreKey="csdkey"
                    placeholder="Seleccion o arrastra un archivo"
                    storagePath={storagePath}
                    allowedFiles={['application / vnd.openxmlformats - officedocument.spreadsheetml.sheet']}
                    onFileUploaded={async (file: any) => {
                        setFileUploaded(file)
                    }}
                />
            </Row>
        </Modal>
    )
}