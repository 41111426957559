import { Button, Divider, Form, message, Modal, Row, Space, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { IntegrationOnboarding } from '../../components/Integrations/IntegrationOnboarding'
import { integrations } from '../../datasets/Integrations'
import { useEffect, useState } from 'react'
import { addParamToUrl } from '../../functions/UrlParams'
import { Robot } from '@phosphor-icons/react'
import { GetTeamHelper } from '../../context/databaseContextHelpers'
import SwitchHolder from '../../components/Forms/SwitchHolder'
import { TextInput } from '../../components/Forms/Inputs'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useAuth } from 'reactfire'
import { updateAutomaticInvoicingIntegration } from './helpers'

export const ConektaModal = () => {
    const dispatch = useDispatch()
    const auth = useAuth()
    const { conektaModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)

    const [loading, setLoading] = useState<null | string>(null)
    const integration = integrations(team).find((i) => i.id === 'conekta')
    const [step, setStep] = useState(team?.conekta?.completed ? 1 : 0)
    const [automaticInvoicing, setAutomaticInvoicing] = useState(team?.conekta?.automaticInvoicing ?? false)
    const [form] = Form.useForm()

    useEffect(() => {
        if (conektaModalVisible) {
            addParamToUrl('open', 'conekta')
        }
    }, [conektaModalVisible])

    const handleSubmit = async () => {
        try {
            setLoading('submit')

            const values = await form.validateFields()

            if(team?.conekta?.completed) {
                await GetTeamHelper(team?.id, dispatch)
                setLoading(null)
                
                dispatch(closeModal('conektaModalVisible'))
                return message.success('Configuración actualizada')
            }

            await SignedInternalAPIRequest(
                { 
                    teamId: team?.id,
                    liveKey: values.liveKey,
                    testKey: values.testKey
                },
                'conektaconnectionapp/v1/connect',
                auth.currentUser
            )

            await GetTeamHelper(team?.id, dispatch)
            setAutomaticInvoicing(true)
            message.success('Conekta conectado exitosamente')

            setLoading(null)
        } catch (error: any) {
            const errorMessage = error?.message?.includes('401') ? 'Credenciales inválidas' : error?.message
            message.error(errorMessage || 'Ocurrió un error al actualizar la configuración')
            setLoading(null)
        }
    }

    const ToRender = () => {
        switch (step) {
            case 0:
                return (
                    <IntegrationOnboarding
                        logo={integration?.logo ?? ''}
                        title="Conecta Conekta con gigstack"
                        description="Cobra y factura automáticamente con Conekta"
                        bullets={[
                            { type: 'positive', description: 'Facturar automáticamente tus ventas' },
                            { type: 'positive', description: 'Sincroniza automáticamente tus ventas' },
                        ]}
                        IntegrationFooter={
                            <Row justify="space-between" style={{ padding: '20px 24px 20px 24px' }}>
                                <Row>
                                    <Button type="primary" onClick={() => setStep(step + 1)}>
                                        Conectar
                                    </Button>
                                </Row>
                            </Row>
                        }
                    />
                )
            case 1:
                return (
                    <div style={{ padding: '20px 24px' }}>
                        <Typography.Text className="mediumparagraphbold">Conecta tu cuenta en Conekta</Typography.Text>
                        <Typography.Text className="smallparagraph descriptions">
                            Conecta tu cuenta y automatiza todas las transacciones que recibas.
                        </Typography.Text>

                        <Form form={form} layout="vertical" style={{ marginTop: '15px' }} onFinish={handleSubmit}>
                            <Divider />
                            {team?.conekta?.completed && (
                                <Space direction='vertical'>
                                    <SwitchHolder
                                        changeState={async () => {
                                            await updateAutomaticInvoicingIntegration({
                                                automaticInvoicing,
                                                dispatch, 
                                                setAutomaticInvoicing,
                                                team,
                                                integrationName: 'conekta'
                                            })
                                        }}
                                        value={automaticInvoicing}
                                        switchState={automaticInvoicing}
                                        icon={<Robot className="icon clickable" weight="regular" size={16} />}
                                        title={'Activar facturación automática de Conekta'}
                                        description="Intentaremos facturar automáticamente tus ventas de Conekta, si no es posible, crearemos un recibo de venta para que tu cliente genere su factura"
                                    />

                                    <Divider style={{ margin: '0px 0px' }} />

                                    <Row justify={'end'} style={{ marginTop: '20px' }}>
                                        <Button loading={loading === 'submit'} type="primary" htmlType="submit">
                                            Guardar
                                        </Button>
                                    </Row>
                                </Space>
                            )}
                            {!team?.conekta?.completed && (
                                <>
                                    <Typography.Text className="mediumparagraphbold">Ingresa tus credenciales</Typography.Text>
                                    <p className="smallparagraph">Puedes generar tus credenciales en el panel de administración de Conekta</p>
                                    
                                    <TextInput
                                        label="API Key Live"
                                        name="liveKey"
                                        rules={[{ required: true, message: 'Por favor ingresa tu API Key Live' }]}
                                    />
                                    <TextInput
                                        label="API Key Test"
                                        name="testKey"
                                        rules={[{ required: true, message: 'Por favor ingresa tu API Key Test' }]}
                                    />
                                    
                                    <Row justify="end" style={{ marginTop: '35px' }}>
                                        <Button loading={loading === 'submit'} type="primary" htmlType="submit">
                                            Conectar mi cuenta
                                        </Button>
                                    </Row>
                                </>
                            )}
                        </Form>
                    </div>
                )
            default:
                return <></>
        }
    }

    return (
        <Modal
            open={conektaModalVisible}
            onCancel={() => {
                dispatch(closeModal('conektaModalVisible'))
            }}
            className="integrationModal"
            title={null}
            footer={null}
            style={{ top: 55 }}
        >
            <ToRender />
        </Modal>
    )
} 
