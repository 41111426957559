import { AutoComplete, Form, Typography } from 'antd'
import { useState } from 'react'
import { CollectionType, getSearchData } from '../../search/SearchComponent'
import { useDispatch, useSelector } from 'react-redux'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { GetTeamHelper } from '../../context/databaseContextHelpers'

const formatLabel = (hit: any) => `${hit.document.unit}: ${hit.document.name}`

const setInitialValue = (
    fromForm: { unitKey: string; unitName: string },
    fromTeam: { unitKey: string; unitName: string },
) => {
    if (!fromForm.unitKey && !fromForm.unitName && !fromTeam.unitKey && !fromTeam.unitName) {
        return ''
    } else {
        return formatLabel({
            document: {
                unit: !fromForm.unitKey || fromForm.unitKey === '' ? fromTeam.unitKey : fromForm.unitKey,
                name: !fromForm.unitName || fromForm.unitName === '' ? fromTeam.unitName : fromForm.unitName,
            },
        })
    }
}
interface UnitKeySearchProps {
    form?: any
}

const UnitKeySearch: React.FC<UnitKeySearchProps> = ({ form }) => {
    const fs = getFirestore()
    const { team } = useSelector((state: any) => state.team)
    const dispatch = useDispatch()

    const fromForm = {
        unitKey: form?.getFieldValue('unit_key'),
        unitName: form?.getFieldValue('unit_name'),
    }

    const fromTeam = {
        unitKey: team?.defaults?.unit_key,
        unitName: team?.defaults?.unit_name,
    }

    const [unitKeys, setUnitKeys] = useState([])
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(setInitialValue(fromForm, fromTeam))

    const handleSearch = async (query: string) => {
        const searchParams = {
            collection: 'unit_keys' as CollectionType,
            query,
            team,
            setLoading,
        }

        const searchData: any = await getSearchData(searchParams)

        if (searchData.hits) {
            const autocompleteOptions = searchData.hits.map((hit: any) => ({
                value: formatLabel(hit),
                label: formatLabel(hit),
            }))
            setUnitKeys(autocompleteOptions)
        }
    }

    const handleSelect = async (value: string) => {
        setValue(value)
        const [unit_key, unit_name] = value.split(':').map((item: string) => item.trim())
        if (form) form.setFieldsValue({ unit_key, unit_name })
        else {
            await updateDoc(doc(fs, `teams/${team?.id}`), { defaults: { unit_key, unit_name } })
            GetTeamHelper(team?.id, dispatch)
        }
        setUnitKeys([])
    }

    return (
        <div>
            <Typography className="p-base-bold" style={{ marginBottom: '10px' }}>
                Código de la unidad:
            </Typography>
            <AutoComplete
                id="unit-key-search-auto-complete"
                style={{ width: '100%' }}
                options={loading ? [{ value: 'Cargando...' }] : unitKeys}
                placeholder="Unidad de servicio, Pieza, etc..."
                onSearch={handleSearch}
                onChange={setValue}
                onSelect={handleSelect}
                value={value}
            />
            <Form.Item name="unit_key" style={{ display: 'none' }}>
                <input value={value?.split(':')[0]?.trim()} readOnly />
            </Form.Item>
            <Form.Item name="unit_name" style={{ display: 'none' }}>
                <input value={value?.split(':')[1]?.trim()} readOnly />
            </Form.Item>
            <Typography className="p-small-regular neutral-3" style={{ marginLeft: '3px', padding: '0' }}>
                Ingresa tu búsqueda.
            </Typography>
        </div>
    )
}

export default UnitKeySearch
