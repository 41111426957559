import { Space, Row, Col, Button, Typography, Steps } from 'antd'
import { SelectInput, NumberInput } from '../Forms/Inputs'
import { PlusCircle, Trash } from '@phosphor-icons/react'
import { useEffect, useState } from 'react'
import type { FormInstance } from 'antd/lib'
import { setData } from '../../context/dataSlice'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import type { StepsProps } from 'antd'
interface Props {
    form: FormInstance
}

interface TimelineItem {
    title: string
    description: string
    status: 'process' | 'finish' | 'wait'
}

export const PaymentReminderInputs = ({ form }: Props) => {
    const [reminders, setReminders] = useState([{ type: 'default', duration: 1, periodicity: 'day', when: 'after' }])
    const { limitDaysToPay } = useSelector((state: any) => state.data)

    // Set initial form values when component mounts
    useEffect(() => {
        reminders.forEach((reminder, index) => {
            form.setFieldsValue({
                [`reminder_type_${index}`]: reminder.type,
                [`duration_${index}`]: reminder.duration,
                [`periodicity_${index}`]: reminder.periodicity,
                [`when_${index}`]: reminder.when,
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // Only run once when component mounts

    const typeOptions = [
        { label: 'Semanal', value: 'weekly' },
        { label: 'Diario', value: 'daily' },
        { label: 'Mensual', value: 'monthly' },
        { label: 'Personalizado', value: 'custom' },
        { label: 'Predeterminado', value: 'default' },
    ]

    const whenOptions = [
        { label: 'Antes', value: 'before' },
        { label: 'Después', value: 'after' },
    ]

    const periodicityOptions = [
        { label: 'Día(s)', value: 'day' },
        { label: 'Semana(s)', value: 'week' },
        { label: 'Mes(es)', value: 'month' },
    ]

    const getFilteredPeriodicityOptions = (index: number, when: string) => {
        const currentReminder = reminders[index]
        if (when !== 'before' || currentReminder.type !== 'custom') {
            return periodicityOptions
        }

        const daysAvailable = limitDaysToPay || 0

        return periodicityOptions.filter((option) => {
            switch (option.value) {
                case 'week':
                    return daysAvailable >= 7
                case 'month':
                    return daysAvailable >= 30
                case 'day':
                    return daysAvailable >= 1
                default:
                    return false
            }
        })
    }

    const getFilteredTypeOptions = (index: number) => {
        // Always allow default option for first reminder
        if (index === 0) {
            return typeOptions
        }

        const daysAvailable = limitDaysToPay || 0

        // Filter options based on available days
        return typeOptions.filter((option) => {
            switch (option.value) {
                case 'weekly':
                    return daysAvailable >= 7
                case 'monthly':
                    return daysAvailable >= 30
                case 'daily':
                    return daysAvailable >= 1
                case 'custom':
                    return true // Always allow custom
                case 'default':
                    return false // Never allow default for non-first reminders
                default:
                    return false
            }
        })
    }

    const getFilteredWhenOptions = (index: number) => {
        const maxBeforeDuration = getMaxDuration('day', 'before')

        if (maxBeforeDuration <= 0) {
            return whenOptions.filter((option) => option.value === 'after')
        }

        return whenOptions
    }

    const addReminder = () => {
        const newReminder = { type: 'custom', duration: 7, periodicity: 'day', when: 'after' }
        const newIndex = reminders.length
        setReminders([...reminders, newReminder])

        // Set form values for new reminder
        form.setFieldsValue({
            [`reminder_type_${newIndex}`]: newReminder.type,
            [`duration_${newIndex}`]: newReminder.duration,
            [`periodicity_${newIndex}`]: newReminder.periodicity,
            [`when_${newIndex}`]: newReminder.when,
        })
    }

    const removeReminder = (index: number) => {
        const updatedReminders = reminders.filter((_, i) => i !== index)
        setReminders(updatedReminders)

        // Clean up form values for removed reminder
        form.setFields([
            { name: `reminder_type_${index}`, value: undefined },
            { name: `duration_${index}`, value: undefined },
            { name: `periodicity_${index}`, value: undefined },
            { name: `when_${index}`, value: undefined },
        ])
    }

    const updateReminder = (index: number, key: keyof (typeof reminders)[0], value: string | number) => {
        const updatedReminders = reminders.map((reminder, i) =>
            i === index ? { ...reminder, [key]: value } : reminder,
        )
        setReminders(updatedReminders)

        // Update form value for the changed field
        form.setFieldValue(`${key}_${index}`, value)
    }

    const handleTypeChange = (index: number, value: string) => {
        const typeDefaults = {
            weekly: { duration: 1, periodicity: 'week' },
            daily: { duration: 1, periodicity: 'day' },
            monthly: { duration: 1, periodicity: 'month' },
            default: { duration: 1, periodicity: 'day' },
            custom: { duration: 7, periodicity: 'day' },
        }

        const type = value as keyof typeof typeDefaults

        if (type === 'custom') {
            updateReminder(index, 'type', type)
            form.setFieldsValue({
                [`reminder_type_${index}`]: type,
            })
        } else {
            console.log(typeDefaults[type])
            setReminders(
                reminders.map((reminder, i) =>
                    i === index
                        ? {
                              ...reminder,
                              type,
                              duration: typeDefaults[type].duration,
                              periodicity: typeDefaults[type].periodicity,
                          }
                        : reminder,
                ),
            )

            form.setFieldsValue({
                [`reminder_type_${index}`]: type,
                [`duration_${index}`]: typeDefaults[type].duration,
                [`periodicity_${index}`]: typeDefaults[type].periodicity,
            })
        }
    }

    const getMaxDuration = (periodicity: string, when: string) => {
        const daysToPayment = limitDaysToPay || 0
        const currentDate = moment()
        const paymentDate = moment().add(daysToPayment, 'days')

        if (when === 'before') {
            const daysAvailable = paymentDate.diff(currentDate, 'days')

            switch (periodicity) {
                case 'day':
                    return Math.max(0, Math.min(daysToPayment, daysAvailable))
                case 'week':
                    return Math.max(0, Math.floor(Math.min(daysToPayment, daysAvailable) / 7))
                case 'month':
                    return Math.max(0, Math.floor(Math.min(daysToPayment, daysAvailable) / 30))
                default:
                    return Math.max(0, Math.min(daysToPayment, daysAvailable))
            }
        }

        // For 'after' reminders, we'll allow up to 3 months from current date
        const maxAfterDays = 90
        switch (periodicity) {
            case 'day':
                return Math.min(90, maxAfterDays)
            case 'week':
                return Math.min(12, Math.floor(maxAfterDays / 7))
            case 'month':
                return Math.min(3, Math.floor(maxAfterDays / 30))
            default:
                return Math.min(90, maxAfterDays)
        }
    }

    const handlePeriodicityChange = (index: number, value: string) => {
        const currentReminder = reminders[index]
        const maxDuration = getMaxDuration(value, currentReminder.when)
        const newDuration = Math.min(currentReminder.duration, maxDuration)

        setReminders(
            reminders.map((reminder, i) =>
                i === index ? { ...reminder, periodicity: value, duration: newDuration } : reminder,
            ),
        )

        form.setFieldsValue({
            [`periodicity_${index}`]: value,
            [`duration_${index}`]: newDuration,
        })
    }

    const handleWhenChange = (index: number, value: string) => {
        const currentReminder = reminders[index]
        const maxDuration = getMaxDuration(currentReminder.periodicity, value)
        const newDuration = Math.min(currentReminder.duration, maxDuration)

        setReminders(
            reminders.map((reminder, i) =>
                i === index ? { ...reminder, when: value, duration: newDuration } : reminder,
            ),
        )

        form.setFieldsValue({
            [`when_${index}`]: value,
            [`duration_${index}`]: newDuration,
        })
    }

    const getTimelineItems = () => {
        const beforeReminders = reminders
            .filter((r) => r.when === 'before')
            .sort((a, b) => b.duration - a.duration)
            .map((reminder) => {
                let title = `${reminder.duration} día${reminder.duration !== 1 ? 's' : ''} antes`

                if (reminder.periodicity === 'week') {
                    title = `${reminder.duration} semana${reminder.duration !== 1 ? 's' : ''} antes`
                } else if (reminder.periodicity === 'month') {
                    title = `${reminder.duration} mes${reminder.duration !== 1 ? 'es' : ''} antes`
                }

                if (reminder.type === 'daily') {
                    title =
                        reminder.duration === 1
                            ? 'Cada día'
                            : `Cada ${reminder.duration} día${reminder.duration !== 1 ? 's' : ''} antes`
                } else if (reminder.type === 'weekly') {
                    title =
                        reminder.duration === 1
                            ? 'Cada semana'
                            : `Cada ${reminder.duration} semana${reminder.duration !== 1 ? 's' : ''} antes`
                } else if (reminder.type === 'monthly') {
                    title =
                        reminder.duration === 1
                            ? 'Cada mes'
                            : `Cada ${reminder.duration} mes${reminder.duration !== 1 ? 'es' : ''} antes`
                }

                return {
                    title,
                    description:
                        reminder.type === 'default'
                            ? 'Recordatorio predeterminado'
                            : reminder.type === 'custom'
                              ? 'Recordatorio antes'
                              : `Recordatorio ${reminder.type}`,
                    status: 'process' as const,
                }
            })

        const afterReminders = reminders
            .filter((r) => r.when === 'after')
            .sort((a, b) => a.duration - b.duration)
            .map((reminder) => {
                let title = `${reminder.duration} día${reminder.duration !== 1 ? 's' : ''} después`
                if (reminder.periodicity === 'week') {
                    title = `${reminder.duration} semana${reminder.duration !== 1 ? 's' : ''} después`
                } else if (reminder.periodicity === 'month') {
                    title = `${reminder.duration} mes${reminder.duration !== 1 ? 'es' : ''} después`
                }

                if (reminder.type === 'daily') {
                    title =
                        reminder.duration === 1
                            ? 'Cada día despues'
                            : `Cada ${reminder.duration} día${reminder.duration !== 1 ? 's' : ''} después`
                } else if (reminder.type === 'weekly') {
                    title =
                        reminder.duration === 1
                            ? 'Cada semana despues'
                            : `Cada ${reminder.duration} semana${reminder.duration !== 1 ? 's' : ''} después`
                } else if (reminder.type === 'monthly') {
                    title =
                        reminder.duration === 1
                            ? 'Cada mes despues'
                            : `Cada ${reminder.duration} mes${reminder.duration !== 1 ? 'es' : ''} después`
                }

                return {
                    title,
                    description:
                        reminder.type === 'default'
                            ? 'Recordatorio predeterminado'
                            : reminder.type === 'custom'
                              ? 'Recordatorio después'
                              : `Recordatorio ${reminder.type}`,
                    status: 'wait' as const,
                }
            })

        const paymentDate = moment().add(limitDaysToPay, 'days')

        return [
            ...beforeReminders,
            {
                title: paymentDate.format('DD/MM/YYYY'),
                description: 'Fecha de Pago',
                status: 'finish' as const,
            },
            ...afterReminders,
        ]
    }

    return (
        <>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Row>
                    <CustomTimeline items={getTimelineItems()} />
                </Row>
                <Row style={{ margin: '20px 0px' }}>
                    <PaymentLimit />
                </Row>
                {reminders.map((reminder, index) => (
                    <div key={index}>
                        <Row gutter={16} align="middle">
                            <Col span={10}>
                                <Typography className="p-base-bold">
                                    {index === 0 ? 'Recordatorio de pagos' : `Recordatorio de pagos ${index}`}
                                </Typography>
                            </Col>
                            <Col span={12}>
                                <Typography className="p-base-bold"> ¿Cuándo recordarle del pago?</Typography>
                            </Col>
                        </Row>
                        <Row gutter={16} align="middle">
                            <Col span={10}>
                                <SelectInput
                                    name={`reminder_type_${index}`}
                                    placeholder="Tipo de recordatorio"
                                    disabled={index === 0 && reminder.type === 'default'}
                                    value={reminder.type}
                                    options={getFilteredTypeOptions(index)}
                                    onChange={(value: any) => handleTypeChange(index, value)}
                                />
                            </Col>
                            <Col span={2}>
                                <NumberInput
                                    name={`duration_${index}`}
                                    disabled={index === 0 && reminder.type === 'default'}
                                    value={reminder.duration}
                                    min={1}
                                    max={getMaxDuration(reminder.periodicity, reminder.when)}
                                    step={1}
                                    onChange={(value: number) => updateReminder(index, 'duration', value)}
                                />
                            </Col>
                            <Col span={3}>
                                <SelectInput
                                    name={`periodicity_${index}`}
                                    disabled={index === 0 || reminder.type !== 'custom'}
                                    value={reminder.periodicity}
                                    options={getFilteredPeriodicityOptions(index, reminder.when)}
                                    onChange={(value: any) => handlePeriodicityChange(index, value)}
                                />
                            </Col>
                            <Col span={3}>
                                <SelectInput
                                    name={`when_${index}`}
                                    disabled={index === 0 && reminder.type === 'default'}
                                    value={reminder.when}
                                    options={getFilteredWhenOptions(index)}
                                    onChange={(value: any) => handleWhenChange(index, value)}
                                />
                            </Col>
                            <Col span={4}>
                                <Typography className="p-base-regular">de la fecha límite de pago</Typography>
                            </Col>
                            <Col span={2}>
                                {index > 0 && (
                                    <Trash
                                        style={{ cursor: 'pointer' }}
                                        size={20}
                                        onClick={() => removeReminder(index)}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                ))}
            </Space>

            <Button className="btn-secondary" onClick={addReminder} style={{ marginTop: '16px', marginBottom: '32px' }}>
                <PlusCircle />
                Agregar otro recordatorio
            </Button>
        </>
    )
}

const PaymentLimit = () => {
    const { team } = useSelector((state: any) => state.team)
    const { client, limitDaysToPay } = useSelector((state: any) => state.data)
    const dispatch = useDispatch()
    const [defaultOption, setDefaultOption] = useState(
        client?.defaults?.limitDaysToPay
            ? client.defaults?.limitDaysToPay
            : typeof team?.defaults?.limitDaysToPay === 'object'
              ? team?.defaults?.limitDaysToPay?.value
              : (team?.defaults?.limitDaysToPay ?? null),
    )
    useEffect(() => {
        if (client?.defaults?.limitDaysToPay) {
            setDefaultOption(client?.defaults?.limitDaysToPay)
        }

        dispatch(
            setData({
                item: 'limitDaysToPay',
                data: defaultOption,
            }),
        )
        //eslint-disable-next-line
    }, [client])

    const options = Array.from(Array(90).keys()).map((v) => ({
        value: v + 1,
        label: `${v + 1} día${v > 0 ? 's' : ''}`,
    }))

    return (
        <div style={{ width: '100%' }}>
            <SelectInput
                label="Día límite de pago"
                defaultValue={limitDaysToPay}
                options={options}
                description="Después de esta cantidad de días marcaremos el pago como atrasado y enviaremos un recordatorio a tu cliente."
                onChange={(v: any) => {
                    if (v) {
                        setDefaultOption(v)
                    }
                    dispatch(
                        setData({
                            item: 'limitDaysToPay',
                            data: v,
                        }),
                    )
                }}
                name={'limitDaysToPay'}
                placeholder={'Días para pagar'}
            />
        </div>
    )
}

const customDot: StepsProps['progressDot'] = (dot, { index, status }) => (
    <span
        style={{
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            backgroundColor: status === 'process' ? 'var(--primary-8)' : 'var(--neutral-3)',
        }}
    />
)

const CustomTimeline = ({ items }: { items: TimelineItem[] }) => {
    const paymentIndex = items.findIndex((item) => item.description === 'Fecha de Pago')

    return (
        <div style={{ width: '100%', padding: '20px 0' }}>
            <Steps
                type="default"
                current={paymentIndex}
                progressDot={customDot}
                items={items.map((item, index) => ({
                    title: (
                        <Typography.Text
                            className="p-small-bold"
                            style={{
                                color: index === paymentIndex ? 'var(--neutral-1)' : 'var(--neutral-3)',
                            }}
                        >
                            {item.title}
                        </Typography.Text>
                    ),
                    description: index === paymentIndex ? item.description : undefined,
                }))}
            />
        </div>
    )
}
