import { Empty, Row, Table, Tag, Tooltip, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { InvoicesColumns, TypeInvoicesColumnName } from '../components/Tables/Columns/InvoicesColumns'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentsColumns } from '../components/Tables/Columns/PaymentsColumns'
import { ReceiptsColumns } from '../components/Tables/Columns/ReceiptsColumns'
import { RecurringInvoicesColumns } from '../components/Tables/Columns/RecurringInvoicesColumns'
import { ClientsColumns } from '../components/Tables/Columns/ClientsColumns'
import { ServicesColumns } from '../components/Tables/Columns/ServicesColumns'
// import { ClientDef } from '../../interfaces/clientDef'
import React from 'react'
import ClientActions from '../components/ElementActions/ClientActions'
import { closeModal } from '../context/modalsSlice'
import { Envelope, User, Copy } from '@phosphor-icons/react'
import { paymentForms } from '../datasets/Fiscal'
import { returnCurrencyValue } from '../functions/helpers'
import moment from 'moment'
import i18n from '../../i18n/init'

// Utility function to highlight search terms
export const highlightText = (text: string, searchTerm: string) => {
    if (!searchTerm || !text) return text

    // Escape special characters in the search term
    const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const parts = text.toString().split(new RegExp(`(${escapedSearchTerm})`, 'gi'))

    return parts.map((part, i) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
            <mark
                key={i}
                style={{
                    backgroundColor: '#dcff79',
                    padding: 0,
                    color: 'inherit',
                    fontWeight: 'inherit',
                }}
            >
                {part}
            </mark>
        ) : (
            part
        ),
    )
}

// Types and Interfaces
type IndexName =
    | 'invoices'
    | 'payments'
    | 'receipts'
    | 'recurringEvents'
    | 'clients'
    | 'services'
    | 'product_keys'
    | 'unit_keys'

interface ColumnConfig {
    width: string
    title: string
    align?: 'left' | 'right' | 'center'
}

interface ColumnsToRender {
    [key: string]: ColumnConfig
}

interface HandleColumnsProps {
    record: any
    columnName: any
}

// Base columns configuration
const baseColumns: ColumnsToRender = {
    clientInfo: {
        width: '34%',
        title: i18n.t('Cliente'),
    },
    status: {
        width: '5%',
        title: 'Estado',
    },
    amount: {
        width: '12%',
        title: 'Monto',
    },
}

// Collection specific columns
const collectionColumns: Record<IndexName, ColumnsToRender> = {
    payments: {
        ...baseColumns,
        timestamp: {
            width: '17%',
            title: 'Fecha',
        },
        actions: {
            width: '5%',
            title: 'Acciones',
        },
    },
    receipts: {
        ...baseColumns,
        timestamp: {
            width: '17%',
            title: 'Fecha',
        },
        actions: {
            width: '5%',
            title: 'Acciones',
        },
    },
    invoices: {
        ...baseColumns,
        timestamp: {
            width: '8%',
            title: 'Fecha',
        },
        series: {
            width: '10%',
            title: 'Serie',
        },
        actions: {
            width: '5%',
            title: 'Acciones',
        },
    },
    clients: {
        name: {
            width: '25%',
            title: i18n.t('Cliente'),
        },
        // email: {
        //     width: '30%',
        //     title: 'Email',
        // },
        // rfc: {
        //     width: '15%',
        //     title: 'RFC',
        // },
        actions: {
            width: '5%',
            title: 'Acciones',
        },
    },
    recurringEvents: baseColumns,
    services: baseColumns,
    product_keys: baseColumns,
    unit_keys: baseColumns,
}

// Navigation paths configuration
const navigationPaths: Partial<Record<IndexName, (id: string) => string>> = {
    invoices: (id) => `/invoiceDetails?id=${id}`,
    payments: (id) => `/paymentDetails?id=${id}`,
    receipts: (id) => `/receiptDetails?id=${id}`,
    clients: (id) => `/clientDetails?id=${id}`,
}

export interface SearchIndexComponentProps {
    indexName: IndexName
}

export const SearchIndexComponent = ({ indexName }: SearchIndexComponentProps) => {
    const { searchResult } = useSelector((state: any) => state.search)
    const { searchConfig } = useSelector((state: any) => state.search)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    if (!searchResult?.hits) {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={`No se encontraron resultados en ${indexName}, prueba a buscar por otro término.`}
            />
        )
    }

    if (searchResult.hits.length === 0) {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={`No se encontraron resultados en ${indexName}, prueba a buscar por otro término.`}
            />
        )
    }

    const columnsToRender = indexName ? collectionColumns[indexName] : baseColumns
    const navigateTo = (id: string) => (indexName ? navigationPaths[indexName]?.(id) : undefined)

    const HandleColumns = ({ record, columnName }: HandleColumnsProps) => {
        const commonProps = {
            from: 'typesenseSearch',
            record,
            columnName,
            navigate: record?.id && navigateTo(record.id) ? () => navigate(navigateTo(record.id)!) : undefined,
        }

        // Helper function to wrap text with highlighting
        // const renderHighlightedText = (text: string) => {
        //     return highlightText(text, searchConfig.query)
        // }

        switch (indexName) {
            case 'invoices':
                return InvoicesColumns({
                    ...commonProps,
                    columnName: columnName as TypeInvoicesColumnName,
                })
            case 'payments':
                return PaymentsColumns({
                    ...commonProps,
                    columnName,
                    route: record?.id ? navigateTo(record.id) : undefined,
                })
            case 'receipts':
                return ReceiptsColumns({
                    ...commonProps,
                    columnName,
                })
            case 'recurringEvents':
                return RecurringInvoicesColumns({
                    record,
                    columnName,
                })
            case 'clients':
                return ClientsColumns({
                    ...commonProps,
                    columnName,
                })
            case 'services':
                return ServicesColumns({
                    record,
                    columnName,
                })
            default:
                return InvoicesColumns({
                    ...commonProps,
                    columnName: columnName as TypeInvoicesColumnName,
                })
        }
    }

    return (
        <Table
            size="small"
            pagination={false}
            showHeader={false}
            bordered={false}
            // scroll={{ y: 300 }}
            onRow={(record: any) => {
                if (indexName === 'clients' || indexName === 'invoices') {
                    return {
                        className: 'clickable',
                        onClick: (e) => {
                            // Stop event propagation
                            e.stopPropagation()
                            if (indexName === 'clients') {
                                navigate(`/clientDetails?id=${record.id}`, { replace: true, state: { refresh: true } })
                            }
                            if (indexName === 'invoices') {
                                navigate(`/invoiceDetails?id=${record.id}`, { replace: true, state: { refresh: true } })
                                if (window.location.pathname === '/invoiceDetails') {
                                    window.location.reload()
                                }
                            }
                            // Close the search container
                            dispatch(closeModal('searchResultContainerVisible'))
                        },
                        style: {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer',
                        },
                    }
                } else if (indexName === 'payments') {
                    return {
                        className: 'clickable',
                        onClick: (e) => {
                            e.stopPropagation()
                            // return console.log(JSON.stringify(record))

                            navigate(`/paymentDetails?id=${record.id}`, { replace: true, state: { refresh: true } })
                            dispatch(closeModal('searchResultContainerVisible'))
                        },
                        style: {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer',
                        },
                    }
                } else if (indexName === 'receipts') {
                    return {
                        className: 'clickable',
                        onClick: (e) => {
                            e.stopPropagation()
                            navigate(`/receiptDetails?id=${record.id}`, { replace: true, state: { refresh: true } })
                            dispatch(closeModal('searchResultContainerVisible'))
                        },
                        style: {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer',
                        },
                    }
                } else
                    return {
                        style: {
                            backgroundColor: '#fafafa',
                        },
                    }
            }}
            columns={
                indexName === 'clients'
                    ? [
                          {
                              dataIndex: 'legalName',
                              key: 'legalName',
                              width: '25%',
                              title: 'Nombre Fiscal',
                              render: (_, record: any) => (
                                  <div className="d-flex flex-column">
                                      <Tooltip title={'Nombre fiscal registrado'}>
                                          <p className="smallheader" style={{ whiteSpace: 'nowrap' }}>
                                              {highlightText(
                                                  record.legal_name || 'Sin nombre fiscal',
                                                  searchConfig.query,
                                              )}
                                          </p>
                                      </Tooltip>
                                      {record?.name && record?.legal_name !== record?.name && (
                                          <Tooltip title={'Nombre comercial registrado'}>
                                              <p
                                                  className="smallparagraph italic"
                                                  style={{ fontStyle: 'italic', whiteSpace: 'nowrap' }}
                                              >
                                                  <User
                                                      size={12}
                                                      //   weight="duotone"
                                                      style={{ marginRight: '5px' }}
                                                      className="icon"
                                                  />
                                                  {highlightText(
                                                      record.name || 'Sin nombre comercial',
                                                      searchConfig.query,
                                                  )}
                                              </p>
                                          </Tooltip>
                                      )}
                                      {record?.email && (
                                          <p className="smallparagraph" style={{ fontStyle: 'italic' }}>
                                              <Envelope
                                                  size={12}
                                                  //   weight="duotone"
                                                  style={{ marginRight: '5px' }}
                                                  className="icon"
                                              />{' '}
                                              {highlightText(record.email, searchConfig.query)}
                                          </p>
                                      )}
                                  </div>
                              ),
                          },
                          {
                              dataIndex: 'rfc',
                              key: 'rfc',
                              width: '30%',
                              title: 'RFC',
                              render: (_, record: any) => (
                                  <p className="smallparagraph">
                                      {highlightText(record.rfc || 'Sin RFC', searchConfig.query)}
                                  </p>
                              ),
                          },
                          {
                              dataIndex: 'company',
                              key: 'company',
                              //   width: '3%',
                              title: 'Empresa',
                              render: (_, record: any) => (
                                  <div className="d-flex flex-column" style={{ padding: '8px 0' }}>
                                      <div style={{ marginBottom: '6px', width: '100%' }}>
                                          <div
                                              style={{
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  gap: '4px',
                                                  width: '100%',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      gap: '8px',
                                                      width: '100%',
                                                      justifyContent: 'space-between',
                                                  }}
                                              >
                                                  <Tooltip title={'Nombre fiscal registrado'}>
                                                      <p
                                                          className="smallheader"
                                                          style={{
                                                              fontSize: '13px',
                                                              lineHeight: '1.4',
                                                              display: 'block',
                                                              width: '100%',
                                                              maxWidth: '100%',
                                                              overflow: 'hidden',
                                                              textOverflow: 'ellipsis',
                                                          }}
                                                      >
                                                          <div
                                                              style={{
                                                                  display: 'flex',
                                                                  alignItems: 'flex-start',
                                                                  width: '100%',
                                                                  wordBreak: 'break-word',
                                                                  whiteSpace: 'normal',
                                                              }}
                                                          >
                                                              {record.client?.legal_name || 'Sin nombre fiscal'}
                                                          </div>
                                                      </p>
                                                  </Tooltip>
                                                  {record.status && (
                                                      <Tag
                                                          color={'#f4f4f5'}
                                                          style={{
                                                              color: '#71717a',
                                                              whiteSpace: 'nowrap',
                                                              flexShrink: 0,
                                                              marginLeft: 'auto',
                                                          }}
                                                      >
                                                          {record.status === 'completed'
                                                              ? 'Completado'
                                                              : record.status === 'pending'
                                                                ? 'Pendiente'
                                                                : record.status === 'cancelled'
                                                                  ? 'Cancelado'
                                                                  : record.status}
                                                      </Tag>
                                                  )}
                                              </div>
                                              {record?.client?.name &&
                                                  record?.client?.legal_name !== record?.client?.name && (
                                                      <Tooltip title={'Nombre comercial registrado'}>
                                                          <p
                                                              className="smallparagraph italic"
                                                              style={{
                                                                  fontStyle: 'italic',
                                                                  color: '#6b7280',
                                                                  fontSize: '12px',
                                                                  display: 'flex',
                                                                  alignItems: 'center',
                                                                  gap: '4px',
                                                              }}
                                                          >
                                                              <User size={12} className="icon" />
                                                              {highlightText(
                                                                  record.client?.name || 'Sin nombre comercial',
                                                                  searchConfig.query,
                                                              )}
                                                          </p>
                                                      </Tooltip>
                                                  )}
                                          </div>
                                          <div
                                              style={{
                                                  display: 'flex',
                                                  gap: '12px',
                                                  marginTop: '4px',
                                                  flexWrap: 'wrap',
                                              }}
                                          >
                                              {record?.client?.email && (
                                                  <p
                                                      className="smallparagraph"
                                                      style={{
                                                          color: '#6b7280',
                                                          fontSize: '11px',
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          gap: '4px',
                                                      }}
                                                  >
                                                      <Envelope size={12} className="icon" />
                                                      {highlightText(record.client?.email, searchConfig.query)}
                                                  </p>
                                              )}
                                              {record?.client?.rfc && (
                                                  <p
                                                      className="smallparagraph"
                                                      style={{
                                                          color: '#6b7280',
                                                          fontSize: '11px',
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                      }}
                                                  >
                                                      RFC: {highlightText(record.client?.rfc, searchConfig.query)}
                                                  </p>
                                              )}
                                          </div>
                                      </div>
                                  </div>
                              ),
                          },
                          {
                              dataIndex: 'actions',
                              key: 'actions',
                              width: '5%',
                              title: 'Acciones',
                              render: (_, record: any) => (
                                  <ClientActions client={record} showFull={false} from={'typesenseSearch'} />
                              ),
                          },
                      ]
                    : indexName === 'invoices'
                      ? [
                            {
                                dataIndex: 'legalName',
                                key: 'legalName',
                                width: '45%',
                                title: 'Información',
                                render: (_, record: any) => (
                                    <div className="d-flex flex-column" style={{ padding: '8px 0' }}>
                                        <div style={{ marginBottom: '6px' }}>
                                            <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                                                <Tooltip title={'Nombre fiscal registrado'}>
                                                    <p
                                                        className="smallheader"
                                                        style={{
                                                            whiteSpace: 'pre-wrap',
                                                            wordBreak: 'normal',
                                                            overflowWrap: 'break-word',
                                                            lineHeight: '1.4',
                                                            display: 'block',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {highlightText(
                                                            record.client?.legal_name || 'Sin nombre fiscal',
                                                            searchConfig.query,
                                                        )}
                                                    </p>
                                                </Tooltip>
                                                {record.status && (
                                                    <Tag
                                                        color={'#f4f4f5'}
                                                        style={{
                                                            color: '#71717a',
                                                            whiteSpace: 'nowrap',
                                                            flexShrink: 0,
                                                        }}
                                                    >
                                                        {record.status === 'succeeded'
                                                            ? 'Pagado'
                                                            : record.status === 'requires_payment_method'
                                                              ? 'Pendiente'
                                                              : record.status === 'cancelled'
                                                                ? 'Cancelado'
                                                                : record.status}
                                                    </Tag>
                                                )}
                                            </div>
                                            {record?.client?.name &&
                                                record?.client?.legal_name !== record?.client?.name && (
                                                    <Tooltip title={'Nombre comercial registrado'}>
                                                        <p
                                                            className="smallparagraph italic"
                                                            style={{
                                                                fontStyle: 'italic',
                                                                whiteSpace: 'nowrap',
                                                                color: '#6b7280',
                                                            }}
                                                        >
                                                            <User
                                                                size={12}
                                                                style={{ marginRight: '5px' }}
                                                                className="icon"
                                                            />
                                                            {highlightText(
                                                                record.client?.name || 'Sin nombre comercial',
                                                                searchConfig.query,
                                                            )}
                                                        </p>
                                                    </Tooltip>
                                                )}
                                        </div>
                                        <div className="d-flex align-items-center" style={{ gap: '12px' }}>
                                            {record?.client?.email && (
                                                <p
                                                    className="smallparagraph"
                                                    style={{ color: '#6b7280', fontSize: '11px' }}
                                                >
                                                    <Envelope
                                                        size={12}
                                                        style={{ marginRight: '5px', color: '#6b7280' }}
                                                        className="icon"
                                                    />
                                                    {highlightText(record.client?.email, searchConfig.query)}
                                                </p>
                                            )}
                                            {record?.client?.rfc && (
                                                <p
                                                    className="smallparagraph"
                                                    style={{ color: '#6b7280', fontSize: '11px' }}
                                                >
                                                    RFC: {highlightText(record.client?.rfc, searchConfig.query)}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                dataIndex: 'details',
                                key: 'details',
                                width: '35%',
                                title: 'Detalles',
                                render: (_, record: any) => (
                                    <div className="d-flex flex-column" style={{ padding: '8px 0' }}>
                                        <div
                                            className="d-flex align-items-center"
                                            style={{ gap: '2px', marginBottom: '6px' }}
                                        >
                                            <Tag
                                                style={{
                                                    backgroundColor: '#f4f4f5',
                                                    color: '#71717a',
                                                    gap: '2px',
                                                }}
                                            >
                                                {highlightText(record.series, searchConfig.query)}
                                                {'-'}
                                                {highlightText(record.folio_number, searchConfig.query)}
                                            </Tag>
                                            {record.uuid && (
                                                <Tooltip title={record.uuid}>
                                                    <Tag
                                                        style={{
                                                            backgroundColor: '#f4f4f5',
                                                            color: '#71717a',
                                                            gap: '2px',
                                                        }}
                                                    >
                                                        {highlightText(record.uuid.substring(0, 8), searchConfig.query)}
                                                        ...
                                                    </Tag>
                                                </Tooltip>
                                            )}
                                            
                                            {/* Agregar los nuevos Tags para orderId e internalId */}
                                            {(record?.whmcsInvoiceId || record?.metadata?.idIntelisis || record?.metadata?.orderId) && (
                                                <Tooltip title={record.whmcsInvoiceId ? "WHMCS ID" : record?.metadata?.idIntelisis ? "ID Intelisis" : "ID de la orden"}>
                                                    <Tag
                                                        style={{
                                                            backgroundColor: '#f4f4f5',
                                                            color: '#71717a',
                                                            gap: '2px',
                                                        }}
                                                    >
                                                        {highlightText(record.whmcsInvoiceId || record?.metadata?.idIntelisis || record?.metadata?.orderId || '', searchConfig.query)}
                                                    </Tag>
                                                </Tooltip>
                                            )}
                                            
                                            {record?.metadata?.internalID || record?.metadata?.internalId && (
                                                <Tooltip title="ID Interno">
                                                    <Tag
                                                        style={{
                                                            backgroundColor: '#f4f4f5',
                                                            color: '#71717a',
                                                            gap: '2px',
                                                        }}
                                                    >
                                                        {highlightText(record.metadata.internalID || record.metadata.internalId, searchConfig.query)}
                                                    </Tag>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <div
                                            className="d-flex align-items-center"
                                            style={{ gap: '12px', whiteSpace: 'wrap' }}
                                        >
                                            <Row>
                                                {record.payment_method && (
                                                    <p
                                                        className="smallparagraph"
                                                        style={{ color: '#6b7280', fontSize: '11px' }}
                                                    >
                                                        Método: {record.payment_method}
                                                    </p>
                                                )}
                                                {record.payment_form && (
                                                    <Tooltip
                                                        title={
                                                            paymentForms.find(
                                                                (form) => form.value === record.payment_form,
                                                            )?.label
                                                        }
                                                    >
                                                        <p
                                                            className="smallparagraph"
                                                            style={{
                                                                color: '#6b7280',
                                                                fontSize: '11px',
                                                                marginLeft: '10px',
                                                                maxWidth: '120px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            Forma:{' '}
                                                            {(
                                                                paymentForms.find(
                                                                    (form) => form.value === record.payment_form,
                                                                )?.label || ''
                                                            ).substring(0, 15)}
                                                            ...
                                                        </p>
                                                    </Tooltip>
                                                )}
                                            </Row>
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                dataIndex: 'amount',
                                key: 'amount',
                                width: '20%',
                                align: 'right' as const,
                                title: 'Monto',
                                render: (_, record: any) => (
                                    <div className="d-flex flex-column align-items-end" style={{ padding: '8px 0' }}>
                                        <p
                                            className="smallheader"
                                            style={{
                                                fontSize: '14px',
                                                color: record.status === 'cancelled' ? '#dc2626' : '#000',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {returnCurrencyValue(record.total, record.currency)}
                                        </p>
                                        <div
                                            className="d-flex align-items-center"
                                            style={{ gap: '6px', marginTop: '4px' }}
                                        >
                                            <p
                                                className="smallparagraph"
                                                style={{ color: '#6b7280', fontSize: '11px' }}
                                            >
                                                {moment(record.timestamp).format('DDD MMM YYYY HH:mm')}
                                            </p>
                                        </div>
                                    </div>
                                ),
                            },
                        ]
                      : indexName === 'payments'
                        ? [
                              {
                                  dataIndex: 'legalName',
                                  key: 'legalName',
                                  width: '45%',
                                  title: 'Información',
                                  render: (_, record: any) => (
                                      <div className="d-flex flex-column" style={{ padding: '8px 0' }}>
                                          <div style={{ marginBottom: '6px' }}>
                                              <div
                                                  className="d-flex align-items-start flex-wrap"
                                                  style={{ gap: '8px' }}
                                              >
                                                  <Tooltip title={'Nombre fiscal registrado'}>
                                                      <p
                                                          className="smallheader"
                                                          style={{
                                                              whiteSpace: 'pre-wrap', // Allows text to wrap properly
                                                              wordBreak: 'break-word', // Ensures words don't break strangely
                                                              overflowWrap: 'break-word',
                                                              lineHeight: '1.4',
                                                              flex: '1 1 auto',
                                                              textOverflow: 'ellipsis',
                                                              maxWidth: '100%',
                                                          }}
                                                      >
                                                          {record.client?.legal_name || 'Sin nombre fiscal'}
                                                      </p>
                                                  </Tooltip>
                                                  {record.status && (
                                                      <Tag
                                                          color={'#f4f4f5'}
                                                          style={{
                                                              color: '#71717a',
                                                              whiteSpace: 'nowrap',
                                                              flexShrink: 0,
                                                          }}
                                                      >
                                                          {record.status === 'succeeded'
                                                              ? 'Pagado'
                                                              : record.status === 'requires_payment_method'
                                                                ? 'Pendiente'
                                                                : record.status === 'cancelled'
                                                                  ? 'Cancelado'
                                                                  : record.status}
                                                      </Tag>
                                                  )}
                                              </div>
                                              {record?.client?.name &&
                                                  record?.client?.legal_name !== record?.client?.name && (
                                                      <Tooltip title={'Nombre comercial registrado'}>
                                                          <p
                                                              className="smallparagraph italic"
                                                              style={{
                                                                  fontStyle: 'italic',
                                                                  whiteSpace: 'nowrap',
                                                                  color: '#6b7280',
                                                              }}
                                                          >
                                                              <User
                                                                  size={12}
                                                                  style={{ marginRight: '5px' }}
                                                                  className="icon"
                                                              />
                                                              {highlightText(
                                                                  record.client?.name || 'Sin nombre comercial',
                                                                  searchConfig.query,
                                                              )}
                                                          </p>
                                                      </Tooltip>
                                                  )}
                                          </div>
                                          <div className="d-flex align-items-center" style={{ gap: '12px' }}>
                                              {record?.client?.email && (
                                                  <p
                                                      className="smallparagraph"
                                                      style={{ color: '#6b7280', fontSize: '11px' }}
                                                  >
                                                      <Envelope
                                                          size={12}
                                                          style={{ marginRight: '5px', color: '#6b7280' }}
                                                          className="icon"
                                                      />
                                                      {highlightText(record.client?.email, searchConfig.query)}
                                                  </p>
                                              )}
                                              {record?.client?.rfc && (
                                                  <p
                                                      className="smallparagraph"
                                                      style={{ color: '#6b7280', fontSize: '11px' }}
                                                  >
                                                      RFC: {highlightText(record.client?.rfc, searchConfig.query)}
                                                  </p>
                                              )}
                                          </div>
                                      </div>
                                  ),
                              },
                              {
                                  dataIndex: 'details',
                                  key: 'details',
                                  width: '35%',
                                  title: 'Detalles',
                                  render: (_, record: any) => (
                                      <div className="d-flex flex-column" style={{ padding: '8px 0' }}>
                                          <div
                                              className="d-flex align-items-center"
                                              style={{ gap: '2px', marginBottom: '6px' }}
                                          >
                                              <Tag
                                                  style={{
                                                      backgroundColor: '#f4f4f5',
                                                      color: '#71717a',
                                                      gap: '2px',
                                                  }}
                                              >
                                                  {highlightText(record.id, searchConfig.query)}
                                              </Tag>
                                              
                                              {/* Agregar los nuevos Tags para orderId e internalId */}
                                              {(record.whmcsInvoiceId || record?.metadata?.idIntelisis || record?.metadata?.orderId) && (
                                                  <Tooltip title={record.whmcsInvoiceId ? "WHMCS ID" : record?.metadata?.idIntelisis ? "ID Intelisis" : "ID de la orden"}>
                                                      <Tag
                                                          style={{
                                                              backgroundColor: '#f4f4f5',
                                                              color: '#71717a',
                                                              gap: '2px',
                                                          }}
                                                      >
                                                          {highlightText(record.whmcsInvoiceId || record?.metadata?.idIntelisis || record?.metadata?.orderId || '', searchConfig.query)}
                                                      </Tag>
                                                  </Tooltip>
                                              )}
                                              
                                              {record?.metadata?.internalID || record?.metadata?.internalId && (
                                                  <Tooltip title="ID Interno">
                                                      <Tag
                                                          style={{
                                                              backgroundColor: '#f4f4f5',
                                                              color: '#71717a',
                                                              gap: '2px',
                                                          }}
                                                      >
                                                          {highlightText(record.metadata.internalID || record.metadata.internalId, searchConfig.query)}
                                                      </Tag>
                                                  </Tooltip>
                                              )}
                                          </div>
                                          <div
                                              className="d-flex align-items-center"
                                              style={{ gap: '12px', whiteSpace: 'wrap' }}
                                          >
                                              <Row>
                                                  {record.payment_form && (
                                                      <Tooltip
                                                          title={
                                                              paymentForms.find(
                                                                  (form) => form.value === record.payment_form,
                                                              )?.label
                                                          }
                                                      >
                                                          <p
                                                              className="smallparagraph"
                                                              style={{
                                                                  color: '#6b7280',
                                                                  fontSize: '11px',
                                                                  maxWidth: '120px',
                                                                  overflow: 'hidden',
                                                                  textOverflow: 'ellipsis',
                                                                  whiteSpace: 'nowrap',
                                                              }}
                                                          >
                                                              Forma:{' '}
                                                              {(
                                                                  paymentForms.find(
                                                                      (form) => form.value === record.payment_form,
                                                                  )?.label || ''
                                                              ).substring(0, 15)}
                                                              ...
                                                          </p>
                                                      </Tooltip>
                                                  )}
                                                  {record.from && (
                                                      <p
                                                          className="smallparagraph"
                                                          style={{
                                                              color: '#6b7280',
                                                              fontSize: '11px',
                                                              marginLeft: '10px',
                                                          }}
                                                      >
                                                          Origen: {record.from}
                                                      </p>
                                                  )}
                                              </Row>
                                          </div>
                                      </div>
                                  ),
                              },
                              {
                                  dataIndex: 'amount',
                                  key: 'amount',
                                  width: '20%',
                                  align: 'right' as const,
                                  title: 'Monto',
                                  render: (_, record: any) => (
                                      <div className="d-flex flex-column align-items-end" style={{ padding: '8px 0' }}>
                                          <p
                                              className="smallheader"
                                              style={{
                                                  fontSize: '14px',
                                                  color: record.status === 'cancelled' ? '#dc2626' : '#000',
                                                  textAlign: 'right',
                                              }}
                                          >
                                              {returnCurrencyValue(record.amount / 100, record.currency)}
                                          </p>
                                          <div
                                              className="d-flex align-items-center"
                                              style={{ gap: '6px', marginTop: '4px' }}
                                          >
                                              <p
                                                  className="smallparagraph"
                                                  style={{ color: '#6b7280', fontSize: '11px' }}
                                              >
                                                  {moment(record.timestamp).format('DD MMM YYYY HH:mm')}
                                              </p>
                                          </div>
                                      </div>
                                  ),
                              },
                          ]
                        : indexName === 'receipts'
                          ? [
                                {
                                    dataIndex: 'legalName',
                                    key: 'legalName',
                                    width: '45%',
                                    title: 'Información',
                                    render: (_, record: any) => (
                                        <div className="d-flex flex-column" style={{ padding: '8px 0' }}>
                                            <div style={{ marginBottom: '6px' }}>
                                                <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                                                    <Tooltip title={'Nombre fiscal registrado'}>
                                                        <p
                                                            className="smallheader"
                                                            style={{
                                                                whiteSpace: 'pre-wrap',
                                                                wordBreak: 'normal',
                                                                overflowWrap: 'break-word',
                                                                lineHeight: '1.4',
                                                                // display: 'block',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {highlightText(
                                                                record.client?.legal_name || 'Sin nombre fiscal',
                                                                searchConfig.query,
                                                            )}
                                                            {/* {record.client?.legal_name || 'Sin nombre fiscal'} */}
                                                        </p>
                                                    </Tooltip>
                                                </div>
                                                {record?.client?.name &&
                                                    record?.client?.legal_name !== record?.client?.name && (
                                                        <Tooltip title={'Nombre comercial registrado'}>
                                                            <p
                                                                className="smallparagraph italic"
                                                                style={{
                                                                    fontStyle: 'italic',
                                                                    whiteSpace: 'normal',
                                                                    wordBreak: 'break-word',
                                                                    color: '#6b7280',
                                                                }}
                                                            >
                                                                <User
                                                                    size={12}
                                                                    style={{ marginRight: '5px' }}
                                                                    className="icon"
                                                                />
                                                                {highlightText(
                                                                    record.client?.name || 'Sin nombre comercial',
                                                                    searchConfig.query,
                                                                )}
                                                            </p>
                                                        </Tooltip>
                                                    )}
                                            </div>
                                            <div className="d-flex flex-wrap" style={{ gap: '12px' }}>
                                                {record?.client?.email && (
                                                    <p
                                                        className="smallparagraph"
                                                        style={{ color: '#6b7280', fontSize: '11px' }}
                                                    >
                                                        <Envelope
                                                            size={12}
                                                            style={{ marginRight: '5px', color: '#6b7280' }}
                                                            className="icon"
                                                        />
                                                        {highlightText(record.client?.email, searchConfig.query)}
                                                    </p>
                                                )}
                                                {record?.client?.rfc && (
                                                    <p
                                                        className="smallparagraph"
                                                        style={{ color: '#6b7280', fontSize: '11px' }}
                                                    >
                                                        RFC: {highlightText(record.client?.rfc, searchConfig.query)}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    dataIndex: 'details',
                                    key: 'details',
                                    width: '35%',
                                    title: 'Detalles',
                                    render: (_, record: any) => (
                                        <div className="d-flex flex-column" style={{ padding: '8px 0' }}>
                                            {record.status && (
                                                <div className="mb-2">
                                                    <Tag
                                                        color={'#f4f4f5'}
                                                        style={{
                                                            color: '#71717a',
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                        className="mb-2"
                                                    >
                                                        {record.status === 'completed'
                                                            ? 'Completado'
                                                            : record.status === 'pending'
                                                              ? 'Pendiente'
                                                              : record.status === 'cancelled'
                                                                ? 'Cancelado'
                                                                : record.status}
                                                    </Tag>
                                                </div>
                                            )}
                                            <div
                                                className="d-flex align-items-center mt-2"
                                                style={{ gap: '2px', marginBottom: '6px' }}
                                            >
                                                <Tag
                                                    style={{
                                                        backgroundColor: '#f4f4f5',
                                                        color: '#71717a',
                                                        gap: '2px',
                                                    }}
                                                >
                                                    {highlightText(record.id, searchConfig.query)}
                                                </Tag>
                                                
                                                {/* Agregar los nuevos Tags para orderId e internalId */}
                                                {(record.whmcsInvoiceId || record?.metadata?.idIntelisis || record?.metadata?.orderId) && (
                                                    <Tooltip title={record.whmcsInvoiceId ? "WHMCS ID" : record?.metadata?.idIntelisis ? "ID Intelisis" : "ID de la orden"}>
                                                        <Tag
                                                            style={{
                                                                backgroundColor: '#f4f4f5',
                                                                color: '#71717a',
                                                                gap: '2px',
                                                            }}
                                                        >
                                                            {highlightText(record.whmcsInvoiceId || record?.metadata?.idIntelisis || record?.metadata?.orderId || '', searchConfig.query)}
                                                        </Tag>
                                                    </Tooltip>
                                                )}
                                                
                                                {record?.metadata?.internalID || record?.metadata?.internalId && (
                                                    <Tooltip title="ID Interno">
                                                        <Tag
                                                            style={{
                                                                backgroundColor: '#f4f4f5',
                                                                color: '#71717a',
                                                                gap: '2px',
                                                            }}
                                                        >
                                                            {highlightText(record.metadata.internalID || record.metadata.internalId, searchConfig.query)}
                                                        </Tag>
                                                    </Tooltip>
                                                )}
                                            </div>
                                            <div
                                                className="d-flex align-items-center"
                                                style={{ gap: '12px', whiteSpace: 'wrap' }}
                                            >
                                                <Row>
                                                    {record.validUntil && (
                                                        <p
                                                            className="smallparagraph"
                                                            style={{
                                                                color: '#6b7280',
                                                                fontSize: '11px',
                                                            }}
                                                        >
                                                            Válido hasta:{' '}
                                                            {moment(record.validUntil).format('DD MMM YYYY')}
                                                        </p>
                                                    )}
                                                    {record.status === 'pending' && record.url && (
                                                        <Tooltip title="Copiar URL">
                                                            <p
                                                                className="smallparagraph clickable"
                                                                style={{
                                                                    color: '#6b7280',
                                                                    fontSize: '11px',
                                                                    marginLeft: '10px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    navigator.clipboard.writeText(record.url)
                                                                    message.success('URL copiada al portapapeles')
                                                                }}
                                                            >
                                                                <Copy
                                                                    size={12}
                                                                    style={{ marginRight: '5px', color: '#6b7280' }}
                                                                    className="icon"
                                                                />
                                                                Copiar URL
                                                            </p>
                                                        </Tooltip>
                                                    )}
                                                </Row>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    dataIndex: 'amount',
                                    key: 'amount',
                                    width: '20%',
                                    align: 'right' as const,
                                    title: 'Monto',
                                    render: (_, record: any) => (
                                        <div
                                            className="d-flex flex-column align-items-end"
                                            style={{ padding: '8px 0' }}
                                        >
                                            <p
                                                className="smallheader"
                                                style={{
                                                    fontSize: '14px',
                                                    color: record.status === 'cancelled' ? '#dc2626' : '#000',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                {returnCurrencyValue(record.total, record.currency)}
                                            </p>
                                            <div
                                                className="d-flex align-items-center"
                                                style={{ gap: '6px', marginTop: '4px' }}
                                            >
                                                <p
                                                    className="smallparagraph"
                                                    style={{ color: '#6b7280', fontSize: '11px' }}
                                                >
                                                    {moment(record.timestamp).format('DD MMM YYYY HH:mm')}
                                                </p>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]
                          : Object.keys(columnsToRender).map((key) => ({
                                dataIndex: key,
                                key,
                                width: columnsToRender[key].width,
                                align: columnsToRender[key].align ?? 'left',
                                render: (_, record) => HandleColumns({ record, columnName: key }),
                            }))
            }
            dataSource={searchResult.hits.map((hit: any) => hit?.document)}
        />
    )
}
