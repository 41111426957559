import { Col, Drawer, FormInstance, Row, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import CreateClientForm from '../components/Clients/CreateClientForm'
import { X } from '@phosphor-icons/react'
import { setData } from '../context/dataSlice'
import { useEffect } from 'react'
import { addParamToUrl, removeParamFromUrl } from '../functions/UrlParams'
import { useTranslation } from 'react-i18next'

const CreateClientDrawer = () => {
    const dispatch = useDispatch()
    const { createClientDrawerVisible } = useSelector((state: any) => state.modals)
    const { client, testmode } = useSelector((state: any) => state.data)

    useEffect(() => {
        if (createClientDrawerVisible) {
            addParamToUrl('open', 'client')
        } else {
            dispatch(
                setData({
                    item: 'client',
                    data: null,
                }),
            )
        }

        //eslint-disable-next-line
    }, [createClientDrawerVisible])

    var receivedForm: FormInstance

    const { t } = useTranslation()

    useEffect(() => {
        if (createClientDrawerVisible && !client) {
            receivedForm?.resetFields()
        }
        // eslint-disable-next-line
    }, [createClientDrawerVisible, client])

    return (
        <Drawer
            style={{ marginTop: testmode ? '32px' : '0' }}
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        {client ? `Editar ${t('cliente')}` : `Crear nuevo ${t('cliente')}`}
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X
                                    className="icon clickable"
                                    size={20}
                                    onClick={() => {
                                        removeParamFromUrl('open')
                                        dispatch(closeModal('createClientDrawerVisible'))
                                        receivedForm.resetFields()
                                        if (client) {
                                            dispatch(
                                                setData({
                                                    element: 'client',
                                                    data: null,
                                                }),
                                            )
                                        }
                                    }}
                                />
                                <></>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            placement="right"
            closable={false}
            onClose={() => {
                removeParamFromUrl('open')
                dispatch(closeModal('createClientDrawerVisible'))
                dispatch(
                    setData({
                        element: 'client',
                        data: null,
                    }),
                )
            }}
            open={createClientDrawerVisible}
            width="100%"
            height="100%"
        >
            <Row justify="center">
                <Col xs={23} lg={18} xl={16} xxl={14} style={{ margin: 'auto' }}>
                    <CreateClientForm
                        closeElement="createClientDrawerVisible"
                        hideTitle={true}
                        passForm={(fn: FormInstance) => {
                            receivedForm = fn
                        }}
                        useClient={true}
                    />
                </Col>
            </Row>
        </Drawer>
    )
}

export default CreateClientDrawer
