import {
    ArrowClockwise,
    CloudArrowDown,
    FileArchive,
    FilePdf,
    FileText,
    FileX,
    Prohibit,
    SpinnerGap,
    Stamp,
    Trash,
    DotsThree,
    PaperPlaneTilt,
    FilePlus,
    ArrowsLeftRight,
    TextColumns,
} from '@phosphor-icons/react'
import { Button, Dropdown, MenuProps, Popconfirm, Row, Tooltip, Typography, message } from 'antd'
import { FunctionComponent, useState } from 'react'
import { MetroSpinner } from 'react-spinners-kit'
import { closeModal, openModal, setElementSelected } from '../../context/modalsSlice'
import { useDispatch } from 'react-redux'
import { LoadingDefinition } from '../../../interfaces/loadingDef'
import EmailActions from './SingleActions/EmailActions'
import { DownloadInvoice, generateCode } from '../../functions/helpers'
import { useAuth } from 'reactfire'
import { deleteDoc, doc, getDoc, getFirestore } from 'firebase/firestore'
import { setData } from '../../context/dataSlice'
import { usePermissions } from '../../customHooks/usePermissions'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import RelationPaymentComplementOption from '../Relations/RelationPaymentWithInvoiceAddComplementOption'
import { clientDataHelper } from '../../../helpers/helperFunctions'
import { CaretRight } from '@phosphor-icons/react/dist/ssr'
import { useNavigate } from 'react-router-dom'

interface InvoiceActionsProps {
    invoice: any
    enabled?: boolean | null
    hide?: boolean | null
    from?: string
}

export const INVOICE_ACTIONS_DEFINITION = {
    SEND_EMAIL: {
        action: async ({ invoice, dispatch }: { invoice: any; dispatch: any }) => {
            const invoiceClient = await clientDataHelper(invoice.clientId)
            dispatch(openModal('sendByEmailModalVisible'))
            dispatch(
                setElementSelected({
                    element: 'toEmail',
                    data: { ...invoice, elementType: 'invoices' },
                }),
            )
            dispatch(setData({ item: 'client', data: invoiceClient }))
        },
        label: 'Enviar por correo',
        icon: <PaperPlaneTilt size={18} weight="regular" />,
    },
    DOWNLOAD: {
        action: async ({
            invoice,
            dispatch,
            authUser,
            setDownloading,
        }: {
            invoice: any
            dispatch: any
            authUser: any
            setDownloading?: (v: string | null) => void
        }) => {
            DownloadInvoice({
                invoice: invoice,
                type: 'pdf',
                authUser: authUser,
                customDownloading: setDownloading,
            })
        },
        label: 'Descargar factura',
        icon: <CloudArrowDown size={18} weight="regular" />,
    },
}

const InvoiceActions: FunctionComponent<InvoiceActionsProps> = (props) => {
    const fs = getFirestore()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { canCreateInvoice } = usePermissions()

    const [loading, setLoading] = useState<LoadingDefinition[]>([])
    const [downloading, setDownloading] = useState<string | null>(null)

    const { invoice, from } = props
    const auth = useAuth()

    if (props.hide) return <></>

    const thisInvoiceHasAnyLoading = loading.find((l) => l.id === invoice?.id) !== undefined

    const AddPaymentComplement = () => {
        return <RelationPaymentComplementOption record={invoice} from={'invoiceActions'} />
    }

    const ReloadInvoiceData = async () => {
        try {
            setLoading([
                ...loading,
                {
                    action: `reload`,
                    id: invoice?.id,
                },
            ])

            await SignedInternalAPIRequest(
                {
                    type: 'update_invoice',
                    invoice: invoice,
                    invoicingIntegration: 'facturapi',
                    test: !invoice?.livemode,
                },
                'invoicing',
                auth.currentUser,
            )

            setLoading(loading.filter((l) => l.action !== 'reload'))

            message.success('Se ha actualizado la factura')
        } catch (error) {
            message.error('Ocurrió un error al intentar obtener los últimos detalles de la factura')
        }
    }

    const LoadingIcon = () => {
        return <MetroSpinner size={16} color="#686769" />
    }

    const downloadLoading = async () => {
        setLoading([
            ...loading,
            {
                action: `download`,
                id: invoice?.id,
            },
        ])
        await new Promise((resolve) => setTimeout(resolve, 5000))
        setLoading(loading.filter((l) => l.id !== invoice?.id && l.action !== `download`))
    }

    const downloadInvoiceByType = (type: string, downloadType?: string) => {
        DownloadInvoice({
            invoice: invoice,
            type,
            downloadType,
            authUser: auth.currentUser,
        })
    }

    const DownloadAction = () => {
        const downloadItems: MenuProps['items'] = [
            {
                label: (
                    <Row align="middle">
                        <FilePdf className="icon" size={18} weight="regular" />
                        <Typography.Text
                            style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '13px',
                                marginLeft: '5px',
                            }}
                        >
                            Descargar PDF
                        </Typography.Text>
                    </Row>
                ),
                onClick: () => {
                    downloadInvoiceByType('pdf')
                },
                key: '0',
            },
            {
                label: (
                    <Row align="middle">
                        <FileText className="icon" size={18} weight="regular" />
                        <Typography.Text
                            style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '13px',
                                marginLeft: '5px',
                            }}
                        >
                            Descargar XML
                        </Typography.Text>
                    </Row>
                ),
                onClick: () => {
                    downloadInvoiceByType('xml')
                },
                key: '1',
            },
            {
                type: 'divider',
            },
            {
                label: (
                    <Row align="middle">
                        <FileArchive className="icon" size={18} weight="regular" />
                        <Typography.Text
                            style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '13px',
                                marginLeft: '5px',
                            }}
                        >
                            Descargar ZIP
                        </Typography.Text>
                    </Row>
                ),
                onClick: () => {
                    downloadInvoiceByType('zip')
                },
                key: '3',
            },
            {
                type: 'divider',
            },
            {
                label: (
                    <Row align="middle">
                        <FilePdf className="icon" size={18} weight="regular" />
                        <Typography.Text
                            style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '13px',
                                marginLeft: '5px',
                            }}
                        >
                            Descargar PDF (ENG)
                        </Typography.Text>
                    </Row>
                ),
                onClick: () => {
                    downloadInvoiceByType('pdf', 'download_eng_receipt')
                },
                key: 'pdf_eng',
            },
        ]

        if (invoice.status === 'canceled' || invoice?.cancellation_receipt) {
            downloadItems.push({
                label: (
                    <Row align="middle">
                        <FileX className="icon" size={18} weight="regular" />
                        <Typography.Text
                            style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '13px',
                                marginLeft: '5px',
                            }}
                        >
                            Descargar acuse de cancelación
                        </Typography.Text>
                    </Row>
                ),
                onClick: () => {
                    DownloadInvoice({
                        invoice: { ...invoice, cancelation: true },
                        type: 'pdf',
                        customFileName: `acuse_cancelacion_${invoice.series}${invoice?.folio_number}`,
                        authUser: auth.currentUser,
                    })
                },
                key: '4',
            })
        }

        return (
            <div
                style={{
                    cursor: 'pointer',
                    marginRight: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Dropdown
                    disabled={thisInvoiceHasAnyLoading}
                    menu={{
                        items: downloadItems,
                        onClick: (e) => {
                            downloadLoading()
                        },
                    }}
                    trigger={['click']}
                >
                    {loading.find((l) => l.id === invoice?.id && l.action === `download`) ? (
                        <LoadingIcon />
                    ) : (
                        <Typography>Descargar factura</Typography>
                    )}
                </Dropdown>
            </div>
        )
    }

    const CancelationProcess = () => {
        if (invoice?.cancellation_status === 'pending') {
            return (
                <div
                    style={{
                        cursor: 'pointer',
                        marginRight: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Tooltip title={invoice.cancellationCodeText ? invoice.cancellationCodeText : ''}>
                        <Typography> En proceso de cancelación </Typography>
                    </Tooltip>
                </div>
            )
        } else return <></>
    }

    const SubstituteAction = () => {
        if (!canCreateInvoice.hasPermission && !invoice?.global) return <></>
        return (
            <div
                style={{
                    cursor: 'pointer',
                    marginLeft: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={async () => {
                    const invoiceDoc = await getDoc(doc(fs, 'invoices', invoice.id))

                    dispatch(openModal('substituteInvoiceModalVisible'))
                    dispatch(
                        setData({
                            item: 'invoice',
                            data: invoiceDoc.data(),
                        }),
                    )
                    dispatch(
                        setData({
                            item: 'client',
                            data: invoice.client,
                        }),
                    )

                    dispatch(
                        setData({
                            item: 'selectedServices',
                            data: invoice.internalItems?.map((s: any) => {
                                return {
                                    ...s,
                                    localId: generateCode(10),
                                }
                            }),
                        }),
                    )
                }}
            >
                <Typography> Sustituir factura</Typography>
            </div>
        )
    }
    const CancelAction = () => {
        if (invoice?.status !== 'canceled' && invoice?.cancellation_status !== 'pending' && canCreateInvoice.hasPermission) {
            return (
                <div
                    onClick={() => {
                        dispatch(openModal('cancelInvoiceModalVisible'))
                        dispatch(
                            setElementSelected({
                                element: 'invoice',
                                data: invoice,
                            }),
                        )
                        //TODO: CANCEL INVOICE
                    }}
                    style={{
                        cursor: 'pointer',
                        marginLeft: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography> Cancelar factura</Typography>
                </div>
            )
        } else
            return (
                <>
                    <Typography> Cancelar factura</Typography>
                </>
            )
    }

    const StampAction = () => {
        if (!invoice?.livemode && canCreateInvoice.hasPermission) {
            return (
                <div
                    style={{
                        cursor: 'pointer',
                        marginLeft: '5px',
                        marginRight: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Popconfirm
                        disabled={thisInvoiceHasAnyLoading}
                        title="Timbrar"
                        description="La factura dejará de ser modo test y tendrá validez fiscal"
                        okText="Timbrar"
                        cancelText="Cancelar"
                        onConfirm={async () => {
                            dispatch(openModal('stampInvoiceModalVisible'))
                            dispatch(
                                setElementSelected({
                                    element: 'invoice',
                                    data: invoice,
                                }),
                            )
                            dispatch(
                                setData({
                                    item: 'selectedServices',
                                    data: invoice.internalItems?.map((s: any) => {
                                        return {
                                            ...s,
                                            localId: generateCode(10),
                                        }
                                    }),
                                }),
                            )
                        }}
                    >
                        {loading.find((l) => l.id === invoice?.id && l.action === 'stamp') ? (
                            <LoadingIcon />
                        ) : (
                            <Typography> Timbrar Factura</Typography>
                        )}
                    </Popconfirm>
                </div>
            )
        } else return <></>
    }
    const DeleteAction = () => {
        if (!invoice?.livemode && canCreateInvoice.hasPermission) {
            return (
                <div
                    style={{
                        cursor: 'pointer',
                        marginLeft: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Popconfirm
                        disabled={thisInvoiceHasAnyLoading}
                        title="Eliminar recurso"
                        description="Eliminaremos esta factura en modo test"
                        okText="Eliminar"
                        cancelText="Cancelar"
                        okButtonProps={{
                            danger: true,
                            style: { marginBottom: '5px' },
                        }}
                        cancelButtonProps={{
                            type: 'primary',
                            style: { marginBottom: '5px' },
                        }}
                        onConfirm={async () => {
                            try {
                                await deleteDoc(doc(fs, 'invoices', invoice?.id))
                            } catch (error) {
                                message.error('No se pudo eliminar la factura')
                                return error
                            }
                            //WAIT 3 SECS AND REMOVE THE LOADING WITH ASYNC
                            setTimeout(() => {
                                setLoading(loading.filter((l) => l.id !== invoice?.id && l.action !== 'stamp'))
                            }, 3000)
                        }}
                    >
                        {loading.find((l) => l.id === invoice?.id && l.action === 'delete') ? (
                            <LoadingIcon />
                        ) : (
                            <Typography> Eliminar factura</Typography>
                        )}
                    </Popconfirm>
                </div>
            )
        } else
            return (
                <>
                    <Typography> Eliminar factura </Typography>
                </>
            )
    }

    const ReloadAction = () => {
        let statusSpinner = loading.some((l: any) => l.id === invoice?.id && l.action === 'reload')

        return (
            <div
                style={{
                    cursor: 'pointer',
                    marginLeft: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {statusSpinner ? (
                    <MetroSpinner size={16} color="#686769" />
                ) : (
                    <div onClick={ReloadInvoiceData}>
                        <Typography> Actualizar estado </Typography>
                    </div>
                )}
            </div>
        )
    }

    const handleAction = (action: () => void, permission: { hasPermission: boolean, reason?: string, restrictionType?: string }) => {
        if (!permission.hasPermission) {
            message.warning(permission.reason)
            return
        }
        action()
    }

    const actionMenu: MenuProps['items'] = [
        {
            label: <DownloadAction />,
            icon: <CloudArrowDown size={18} weight="regular" />,
            key: 'download_invoice',
            disabled: thisInvoiceHasAnyLoading,
        },
        {
            label: (
                <EmailActions
                    isMenu
                    action={async () => {
                        const invoiceClient = await clientDataHelper(invoice.clientId)
                        dispatch(openModal('sendByEmailModalVisible'))
                        dispatch(
                            setElementSelected({
                                element: 'toEmail',
                                data: { ...invoice, elementType: 'invoices' },
                            }),
                        )
                        dispatch(setData({ item: 'client', data: invoiceClient }))
                    }}
                    loading={loading.find((l) => l.id === invoice?.id && l.action === `email`) !== undefined}
                />
            ),
            onClick: () => INVOICE_ACTIONS_DEFINITION.SEND_EMAIL.action({ invoice, dispatch }),
            icon: <PaperPlaneTilt size={18} weight="regular" />,
            key: 'send_email',
        },
        {
            type: 'divider',
        },
        {
            label: <SubstituteAction />,
            icon: <ArrowsLeftRight size={18} weight="regular" />,
            key: 'substitute',
            onClick: () => handleAction(
                () => {
                    // lógica existente
                },
                canCreateInvoice
            ),
        },
        ...(!invoice?.livemode
            ? [
                {
                    label: <StampAction />,
                    icon: <Stamp size={18} weight="regular" />,
                    key: 'stamp',
                    disabled: !canCreateInvoice.hasPermission,
                }
              ]
            : []),

        ...((invoice.invoiceType === 'I' || invoice.type === 'I') &&
        (invoice.payment_form === '99' || invoice.payment_method === 'PPD')
            ? [
                  {
                      label: <AddPaymentComplement />,
                      icon: <FilePlus size={18} weight="regular" />,
                      key: 'add_payment_complement',
                      disabled: !canCreateInvoice.hasPermission,
                  },
              ]
            : []),

        {
            type: 'divider',
        },

        ...(invoice.cancellation_status === 'pending'
            ? [
                  {
                      label: <CancelationProcess />,
                      key: 'cancelation_process',
                      icon: <SpinnerGap size={18} weight="regular" className="icon" style={{ cursor: 'pointer' }} />,
                  },
              ]
            : [
                  {
                      label: <CancelAction />,
                      icon: <Prohibit size={18} weight="regular" />,
                      key: 'cancel',
                      disabled: !canCreateInvoice.hasPermission,
                  },
              ]),

        {
            label: <ReloadAction />,
            icon: <ArrowClockwise size={18} weight="regular" />,
            key: 'reload',
        },
        ...(!invoice?.livemode
            ? [
                  {
                      label: <DeleteAction />,
                      icon: <Trash size={18} weight="regular" />,
                      key: 'delete',
                      disabled: !canCreateInvoice.hasPermission,
                  },
              ]
            : []),
    ]

    if (from === 'successView') {
        return (
            <Row>
                <Button
                    className="btn-secondary"
                    loading={downloading === invoice?.id}
                    onClick={() =>
                        INVOICE_ACTIONS_DEFINITION.DOWNLOAD.action({
                            invoice,
                            dispatch,
                            authUser: auth.currentUser,
                            setDownloading,
                        })
                    }
                    style={{ margin: '5px' }}
                >
                    {INVOICE_ACTIONS_DEFINITION.DOWNLOAD.icon} {INVOICE_ACTIONS_DEFINITION.DOWNLOAD.label}
                </Button>
                <Button
                    className="btn-secondary"
                    onClick={() => INVOICE_ACTIONS_DEFINITION.SEND_EMAIL.action({ invoice, dispatch })}
                    style={{ margin: '5px' }}
                >
                    {INVOICE_ACTIONS_DEFINITION.SEND_EMAIL.icon} {INVOICE_ACTIONS_DEFINITION.SEND_EMAIL.label}
                </Button>
                <Button
                    className="btn-secondary"
                    onClick={() => {
                        dispatch(closeModal('invoiceModalVisible'))
                        navigate(`/invoiceDetails?id=${invoice.id}`)
                    }}
                    style={{ margin: '5px' }}
                >
                    <TextColumns size={18} /> Ver detalles
                </Button>
            </Row>
        )
    }

    return (
        <Row>
            {from === 'typesenseSearch' ? (
                <Tooltip title="Ver detalles">
                    <CaretRight
                        style={{ fontSize: '24px', cursor: 'pointer' }}
                        onClick={() => {
                            navigate(`/invoiceDetails?id=${invoice.id}`)
                            return dispatch(closeModal('searchResultContainerVisible'))
                        }}
                    />
                </Tooltip>
            ) : (
                <Dropdown menu={{ items: actionMenu }} trigger={['click']} placement="bottomRight">
                    <DotsThree id="invoice-actions-button" style={{ fontSize: '24px', cursor: 'pointer' }} />
                </Dropdown>
            )}
        </Row>
    )
}

export default InvoiceActions
