import { useSelector } from 'react-redux'
import { NumberInput, SelectInput } from '../Forms/Inputs'
import { Col, Row } from 'antd'
import { currecies } from '../../datasets/Currencies'
import { FormInstance } from 'antd/lib'

const PaymentConfig = ({ form }: { form: FormInstance }) => {
    const { exchangeRates } = useSelector((state: any) => state.data)

    const handleCurrencyChange = (value: string) => {
        const selectedCurrency = currecies.find((currency) => currency.value === value)
        if (selectedCurrency) {
            const exchangeRate = exchangeRates[selectedCurrency.value.toUpperCase()]
            form.setFieldsValue({
                currency: selectedCurrency.value,
                exchange: exchangeRate ? (1 / exchangeRate).toFixed(4) : '', // Actualizar el tipo de cambio
            })
        }
    }

    return (
        <>
            <Row gutter={[16, 16]} style={{ marginTop: '15px' }}>
                <Col xs={24} md={12}>
                    <SelectInput
                        rules={[
                            { required: true, message: `Por favor seleccion la moneda` }, // Agregar regla de validación requerida
                        ]}
                        label="Moneda"
                        name="currency"
                        options={currecies}
                        placeholder="Moneda"
                        onChange={handleCurrencyChange}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <NumberInput
                        name={'exchange'}
                        placeholder="Tipo de cambio"
                        disabled={false}
                        min={0}
                        max={100000}
                        step={0.1}
                        label="Tipo de cambio"
                    />
                </Col>
            </Row>
        </>
    )
}

export default PaymentConfig
