import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import business from './business.json'
import nonprofit from './nonprofit.json'

const resources = {
  business: {
    translation: business
  },
  nonprofit: {
    translation: nonprofit
  }
}

// Initialize i18n before any Firebase operations
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'business',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n 