import { message, notification } from 'antd'
import { saveAs } from 'file-saver'
import { FirebaseStorage, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import moment from 'moment'
import { useStorageTask } from 'reactfire'
import { InternalItem } from '../../interfaces/internalItemDef'
import { InvoiceDef } from '../../interfaces/invoicesDef'
import { TeamDef } from '../../interfaces/teamDef'
import { UserDef } from '../../interfaces/userDef'
import { colors } from '../designSystem/colors'
import { SignedInternalAPIRequest } from './APIRequest'
import { getFirestore, doc, setDoc } from 'firebase/firestore'
import { manageAutomations } from '../../helpers/helperFunctions'

export const RFCRegex = '/[A-ZÑ&]{3,4}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])(?:[A-Z]{3})/'

type UniqueTax = {
    [key: string]: {
        [key: string]: any
    }
}
const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
})
/**
 * Toma un número y devuelve una cadena con el número formateado como moneda.
 * @param value - El valor que se formateará.
 * @returns El valor de formatter.format(value).
 */
export function returnCurrencyValue(value: number, currency?: string): string {
    const format = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: currency ?? 'MXN',
    })
    return format.format(value)
}
export const InvoiceStatusReadable = (status: string) => {
    switch (status) {
        case 'valid':
            return 'Válida'
        case 'invalid':
            return 'Inválida'
        case 'canceled':
            return 'Cancelada'
        case 'requires_manual_cancellation':
            return 'Cancelación manual requerida'
        default:
            return ''
    }
}

export const SearchParamInURL = (q: string): string | null => {
    const { search } = window.location
    const params = new URLSearchParams(search)
    return params.get(q)
}

export function generateCode(length: number, prefix: string = ''): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let result = ''

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return `${prefix}${prefix ? '_' : ''}${result}`
}

export const getUniqueTaxes = (items: InternalItem[]) => {
    let uniqueTaxes: UniqueTax = {}

    for (const item of items) {
        for (const tax of item.taxes) {
            const taxId = `${tax.rate}${tax.type}${tax.factor}${tax.inclusive}${tax.withholding ?? ''}`
            let base = Number(item.total)

            if (tax.inclusive) {
                base = Number(item.total) / (1 + Number(tax.rate))
            } else {
                base = Number(item.total)
            }

            if (uniqueTaxes[taxId]) {
                uniqueTaxes[taxId].base += base
            } else {
                uniqueTaxes[taxId] = {
                    ...tax,
                    key: taxId,
                    base: base,
                }
            }
        }
    }

    const uniqueTaxesArr = []
    for (const tax of Object.values(uniqueTaxes)) {
        uniqueTaxesArr.push(tax)
    }

    return uniqueTaxesArr
}

export const getItemsAmounts = (items: InternalItem[] = [], useDiscount: boolean = false) => {
    let subtotal = 0
    let taxes = 0
    let taxesIncluded = 0
    let retentions = 0
    let canSend = true
    let feeAdded = 0
    let masterDiscount = 0
    // let haveIVA = false;

    items?.forEach((s) => {
        let times = 1
        let serviceTotal = 0
        let paymentType = s?.paymentType ? s?.paymentType.value : 'fixed'
        let discount = s.discount ?? 0
        let taxInclusiveRate = Number(s?.taxes?.find((t) => t?.inclusive)?.rate ?? 0)
        if (s?.taxes?.find((t) => t?.inclusive)) {
            discount = discount / (1 + taxInclusiveRate)
        }

        masterDiscount = masterDiscount + (discount ?? 0)

        if (!s.product_key) {
            canSend = false
        }
        if (s.quantity) {
            times = s.quantity
        }
        if (paymentType === 'hour') {
            serviceTotal = Number(s.hours) * Number(s.total) * times
            subtotal = subtotal + Number(s.hours) * Number(s.total) * times
            if (s.feeAdded) {
                feeAdded += s.feeAdded * Number(s.hours) * times
            }
        } else {
            serviceTotal = Number(s.total) * times
            subtotal = subtotal + (Number(s.total) * times) / (1 + taxInclusiveRate)
            if (s.feeAdded) {
                feeAdded += s.feeAdded * times
            }
        }
        if (s?.taxes || s?.localTaxes) {
            let nFeeAdded = useDiscount ? s.feeAdded || 0 : 0
            let d2 = s.discount ?? 0
            ;(s.taxes ?? []).concat(s?.localTaxes ?? []).forEach((t) => {
                if (t.type === 'IVA') {
                    // haveIVA = true;
                }
                if (!t.withholding) {
                    if (t.inclusive) {
                        taxesIncluded =
                            taxesIncluded +
                            (serviceTotal - (nFeeAdded + d2) - (serviceTotal - (nFeeAdded + d2)) / (Number(t.rate) + 1))
                    } else {
                        taxes = taxes + (serviceTotal - (nFeeAdded + d2)) * Number(t.rate)
                    }
                } else {
                    retentions = retentions + (serviceTotal - (nFeeAdded + d2)) * Number(t.rate)
                }
            })
        }
    })
    masterDiscount = Number((masterDiscount + feeAdded).toFixed(2))

    return {
        total: Number((subtotal + (taxesIncluded + taxes) - retentions).toFixed(2)) - masterDiscount,
        taxes: Number((taxes + taxesIncluded).toFixed(2)),
        retentions,
        feeAdded: feeAdded,
        canSend,
        feeAddedString: formatter.format(feeAdded),
        totalWithoutFeeString: formatter.format(subtotal + taxes - taxesIncluded - retentions - feeAdded),
        totalWithoutFee: Number((subtotal + taxes /*- taxesIncluded*/ - retentions - feeAdded).toFixed(2)),
        totalString: formatter.format(
            Number((subtotal + (taxesIncluded + taxes) - retentions).toFixed(2)) - masterDiscount,
        ),
        subtotalString: formatter.format(subtotal /*- taxesIncluded*/),
        subtotal: Number(
            subtotal /*- taxesIncluded*/
                .toFixed(2),
        ),
        taxesString: formatter.format(taxes + taxesIncluded),
        retentionsString: formatter.format(retentions),
        masterDiscount,
        taxesIncluded,
        masterDiscountString: formatter.format(masterDiscount),
    }
}

export const readableElementSource = (source: string) => {
    switch ((source ?? '').trim().toLowerCase()) {
        case 'gigstack':
            return 'gigstack'
        case 'manual':
            return 'Manual'
        case 'stripe':
            return 'Stripe'
        case 'openpay':
            return 'OpenPay'
        case 'api':
            return 'API'
        case 'hook':
            return 'Hook'
        case 'receipt':
            return 'Portal'
        case 'recurringevent':
            return 'Recurrencia'
        case 'substitute':
            return 'Sustitución'
        case 'global':
            return 'Global'
        case 'massiveaction':
            return 'Acción masiva'
        case 'selfinvoiceprocess':
            return 'Proceso de auto-factura'
        default:
            return source
    }
}

export const elementSourceColor = (source: string) => {
    switch ((source ?? '').trim().toLowerCase()) {
        case 'gigstack':
            return colors.purple_light
        case 'manual':
            return colors.background_darker
        case 'stripe':
            return colors.blue_light
        case 'openpay':
            return colors.yellow_light
        case 'api':
            return colors.green_light
        case 'hook':
            return colors.green_light
        case 'receipt':
            return colors.teal_light
        case 'recurringevent':
            return colors.teal_light
        case 'substitute':
            return colors.orange_light
        default:
            return colors.background_darker
    }
}

export const elementSourceTextColor = (source: string) => {
    switch ((source ?? '').trim().toLowerCase()) {
        case 'gigstack':
            return colors.purple
        case 'manual':
            return colors.text_normal
        case 'stripe':
            return colors.blue
        case 'openpay':
            return colors.yellow
        case 'api':
            return colors.green
        case 'hook':
            return colors.green
        case 'receipt':
            return colors.teal_600
        case 'recurringevent':
            return colors.teal_600
        case 'substitute':
            return colors.orange
        default:
            return colors.text_normal
    }
}

export const userRoleMessage = (role: string): string => {
    switch (role) {
        case 'admin':
            return 'Administrador'
        case 'viewer':
            return 'Visor'
        case 'editor':
            return 'Editor'
        case 'active':
            return 'Activo'
        case 'inactive':
            return 'Inactivo'
        case 'blocked':
            return 'Bloqueado'
        default:
            return ''
    }
}

export const returnColorByUserRole = (role: string): string => {
    switch (role) {
        case 'admin':
            return '#DEF7E7'
        case 'viewer':
            return '#FFECE1'
        case 'editor':
            return '#E7F3FA'
        case 'active':
            return '#DEF7E7'
        case 'inactive':
            return '#E7EAEE'
        case 'blocked':
            return '#FEE9E9'
        default:
            return ''
    }
}

export const returnTextColorByUserRole = (role: string): string => {
    switch (role) {
        case 'admin':
            return '#0AB649'
        case 'viewer':
            return '#FB7A30'
        case 'editor':
            return '#0B9DF8'
        case 'active':
            return '#0AB649'
        case 'inactive':
            return '#556071'
        case 'blocked':
            return '#F15B5B'
        default:
            return ''
    }
}

export const ReadableFirebaseErrorMessage = (error: any): string => {
    if (!error) {
        return ''
    }

    if (error.code) {
        switch (error.code) {
            case 'auth/user-not-found':
                return 'Usuario no encontrado, asegurate de usar el correo electrónico con el que te registraste en gigstack.'
            case 'auth/wrong-password':
                return 'Contraseña incorrecta'
            case 'auth/user-disabled':
                return 'Usuario deshabilitado, por favor contacta al equipo de soporte.'
            case 'auth/invalid-email':
                return 'Correo electrónico inválido, asegurate de usar el correo electrónico con el que te registraste en gigstack.'
            case 'auth/email-already-in-use':
                return 'Correo electrónico ya en uso, por favor usa uno diferente o inicia sesión con el correo electrónico con el que te registraste en gigstack.'
            case 'auth/weak-password':
                return 'Contraseña débil, la contraseña debe tener al menos 6 caracteres.'
            case 'auth/requires-recent-login':
                return 'Se requiere un inicio de sesión reciente, por favor inicia sesión nuevamente.'
            default:
                return error.message ?? 'Error desconocido'
        }
    }

    return ''
}

//[601, 603, 605, 606, 607, 608, 610, 611, 612, 614, 615, 616, 620, 621, 622, 623, 624, 625, 626]
/* Creating an array of objects that will be used to populate the dropdown menu. */
export const taxRegimes = [
    { value: '601', label: 'Ley General de Personas Morales' },
    { value: '603', label: 'Personas Morales con Fines no Lucrativos' },
    { value: '605', label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
    { value: '606', label: 'Arrendamiento' },
    { value: '607', label: 'Régimen de Enajenación o Adquisición de Bienes' },
    { value: '608', label: 'Demás ingresos' },
    { value: '609', label: 'Consolidación' },
    {
        value: '610',
        label: 'Residentes en el Extranjero sin Establecimiento Permanente en México',
    },
    // { value: "610", label: "Residentes en el Extranjero" },
    { value: '611', label: 'Ingresos por Dividendos(socios y accionistas)' },
    {
        value: '612',
        label: 'Personas Físicas con Actividades Empresariales y Profesionales',
    },
    { value: '614', label: 'Ingresos por intereses' },
    { value: '615', label: 'Régimen de los ingresos por obtención de premios' },
    { value: '616', label: 'Sin obligaciones fiscales' },
    {
        value: '620',
        label: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
    },
    { value: '621', label: 'Régimen de Incorporación Fiscal' },
    { value: '622', label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
    { value: '623', label: 'Opcional para Grupos de Sociedades' },
    { value: '624', label: 'Coordinados' },
    {
        value: '625',
        label: 'Régimen de las Actividades Empresariales con ingresos a través de plataformas tecnológicas',
    },
    { value: '626', label: 'Régimen simplificado de confianza' },
    { value: '628', label: 'Hidrocarburos' },
    {
        value: '629',
        label: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
    },
    { value: '630', label: 'Enajenación de acciones en bolsa de valores' },
]
/**
 * It takes a value and returns the tax regime object that has that value
 * @param value - The value of the tax regime.
 * @returns The taxRegime object that matches the value passed in.
 */

export const findTaxRegime = (value: string) => {
    let taxRegime = taxRegimes.find((taxRegime) => taxRegime.value === value)
    return taxRegime
}

/* Creating an array of objects that will be used to populate the invoiceUsage dropdown. */
export const invoiceUsage = [
    {
        label: 'Adquisición De Mercancías',
        value: 'G01',
    },
    {
        label: 'Devoluciones Descuentos Bonificaciones',
        value: 'G02',
    },
    {
        label: 'Gastos En General',
        value: 'G03',
    },
    {
        label: 'Construcciones',
        value: 'I01',
    },
    {
        label: 'Mobiliario Y Equipo De Oficina',
        value: 'I02',
    },
    {
        label: 'Equipo de Transporte',
        value: 'I03',
    },
    {
        label: 'Equipo de Cómputo y Accesorios',
        value: 'I04',
    },
    {
        label: 'Dados, troqueles, moldes, matrices y herramental.',
        value: 'I05',
    },
    {
        label: 'Comunicaciones Telefónicas',
        value: 'I06',
    },
    {
        label: 'Comunicaciones Satelitales',
        value: 'I07',
    },
    {
        label: 'Otra Maquinaria',
        value: 'I08',
    },
    {
        label: 'Honorarios médicos, dentales y gastos hospitalarios.',
        value: 'D01',
    },
    {
        label: 'Gastos Médicos Por Incapacidad',
        value: 'D02',
    },
    {
        label: 'Gastos Funerales',
        value: 'D03',
    },
    {
        label: 'Donativos',
        value: 'D04',
    },
    {
        label: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
        value: 'D05',
    },
    {
        label: 'Aportaciones voluntarias al SAR.',
        value: 'D06',
    },
    {
        label: 'Prima Seguros Gastos Médicos',
        value: 'D07',
    },
    {
        label: 'Gastos de transportación escolar obligatoria',
        value: 'D08',
    },
    {
        label: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
        value: 'D09',
    },
    {
        label: 'Pagos por servicios educativos (colegiaturas)',
        value: 'D10',
    },
    {
        label: 'Sin efectos fiscales',
        value: 'S01',
    },
    {
        value: 'CP01',
        label: 'Pagos',
    },
    {
        label: 'Nómina',
        value: 'CN01',
    },
]
/**
 * It takes a value and returns the usage object that has that value
 * @param value - The value of the usage you want to find.
 * @returns The usage object that matches the value passed in.
 */
export const findUsage = (value: string) => {
    let usage = invoiceUsage.find((usage) => usage.value === value)
    return usage
}

/* Creating an array of objects. */
// export const paymentForms = [
//     { value: "01", label: "Efectivo" },
//     { value: "02", label: "Cheque nominativo" },
//     { value: "03", label: "Transferencia electrónica de fondos" },
//     { value: "04", label: "Tarjeta de crédito" },
//     { value: "05", label: "Monedero electrónico" },
//     { value: "06", label: "Dinero electrónico" },
//     { value: "08", label: "Vales de despensa" },
//     { value: "12", label: "Dación en pago" },
//     { value: "13", label: "Pago por subrogación" },
//     { value: "14", label: "Pago por consignación" },
//     { value: "15", label: "Condonación" },
//     { value: "17", label: "Compensación" },
//     { value: "23", label: "Novación" },
//     { value: "24", label: "Confusión" },
//     { value: "25", label: "Remisión de deuda" },
//     { value: "26", label: "Prescripción o caducidad" },
//     { value: "27", label: "A satisfacción del acreedor" },
//     { value: "28", label: "Tarjeta de débito" },
//     { value: "29", label: "Tarjeta de servicios" },
//     { value: "99", label: "Por definir" },
// ]
// export const paymentForms: { value: any; label: any }[] = [
//     { value: "01", label: "Efectivo" },
//     { value: "02", label: "Cheque nominativo" },
//     { value: "03", label: "Transferencia electrónica de fondos" },
//     { value: "04", label: "Tarjeta de crédito" },
//     { value: "05", label: "Monedero electrónico" },
//     { value: "06", label: "Dinero electrónico" },
//     { value: "08", label: "Vales de despensa" },
//     { value: "12", label: "Dación en pago" },
//     { value: "13", label: "Pago por subrogación" },
//     { value: "14", label: "Pago por consignación" },
//     { value: "15", label: "Condonación" },
//     { value: "17", label: "Compensación" },
//     { value: "23", label: "Novación" },
//     { value: "24", label: "Confusión" },
//     { value: "25", label: "Remisión de deuda" },
//     { value: "26", label: "Prescripción o caducidad" },
//     { value: "27", label: "A satisfacción del acreedor" },
//     { value: "28", label: "Tarjeta de débito" },
//     { value: "29", label: "Tarjeta de servicios" },
//     { value: "31", label: "Intermediario pagos" },
//     { value: "99", label: "Por definir" },
// ];

/* An array of objects that contains the value and label for the payment form. */

/* Creating an array of objects. */
export const relationOptions = [
    {
        value: '01',
        label: 'Nota de crédito de los documentos relacionados',
    },
    {
        value: '02',
        label: 'Nota de débito de los documentos relacionados',
    },
    {
        value: '03',
        label: 'Devolución de mercancía sobre facturas o traslados previos',
    },
    {
        value: '04',
        label: 'Sustitución de los CFDI previos',
    },
    {
        value: '05',
        label: 'Traslados de mercancias facturados previamente',
    },
    {
        value: '06',
        label: 'Factura generada por los traslados previos',
    },
    {
        value: '07',
        label: 'CFDI por aplicación de anticipo',
    },
    {
        value: '08',
        label: 'Factura generada por pagos en parcialidades',
    },
    {
        value: '09',
        label: 'Factura generada por pagos diferidos',
    },
]

/**
 * Elimina todas las propiedades indefinidas de un objeto.
 * @param obj - El objeto del que se eliminarán los valores indefinidos.
 * @returns El objeto con las claves indefinidas eliminadas.
 */
export const RemoveUndefined = (obj: Record<string, any>) => {
    Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key])
    return obj
}

/**
 * Retorna un objeto con el tipo de método de pago, la tarifa, la tarifa A y un arreglo de tarifas.
 * @param user - El objeto de usuario.
 * @param team - El objeto de equipo.
 * @returns Un objeto con información sobre las tarifas.
 */
export const FeesAmounts = (user: any, team: any = {}) => {
    const fees: any[] = []
    let type = ''
    let fee = 0
    let feeA = 0

    if (team?.conekta?.completed) {
        fees.push({ type: 'conekta', fee: 0, feeA: 12.5 })
        type = 'conekta'
        fee = 0
        feeA = 12.5
    }

    if (team?.stripe?.completed) {
        fees.push({ type: 'stripe', fee: 3.6, feeA: 3 })
        type = 'stripe'
        fee = 3.6
        feeA = 3
    }

    if (team?.paypal?.completed) {
        fees.push({ type: 'paypal', fee: 3.95, feeA: 4 })
        type = 'paypal'
        fee = 3.95
        feeA = 4
    }

    return {
        type,
        fee,
        feeA,
        fees,
    }
}

/**
 * Verifica si el usuario tiene una cuenta de Stripe y si la cuenta de Stripe no tiene requisitos vencidos,
 * luego retorna true.
 * @param user - El objeto de usuario.
 * @param team - El objeto de equipo.
 * @returns Un valor booleano.
 */
export const UserHasStripe = (user: any, team: any) => {
    let hasStripe = false

    if (team?.stripe?.completed) {
        hasStripe = true
    }

    return hasStripe
}

/**
 * Abre una nueva ventana con la URL "whatsapp://send?text=" y el texto proporcionado.
 * @param text - El texto que se compartirá.
 * @returns Un objeto de ventana.
 */
export const ShareViaWhatsapp = (text: string) => {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=300,left=10,top=10`
    return window.open(`whatsapp://send?text=${text}`, '_blank', params)
}

/**
 * Descarga una factura desde el almacenamiento de Firebase y la guarda en la computadora del usuario.
 */
export const DownloadInvoice = async ({
    invoice,
    type = 'pdf',
    authUser,
    customDownloading,
    downloadType,
    customFileName,
}: any) => {
    try {
        if (customDownloading) {
            customDownloading(invoice?.id)
        }
        const invoicereq = await SignedInternalAPIRequest(
            {
                invoicingIntegration: 'facturapi',
                type: 'download_invoice',
                test: !invoice?.livemode,
                downloadType: type,
                id: invoice?.id,
                invoiceId: invoice?.id,
                invoiceType: invoice?.invoiceType ?? 'I',
                cancelation: invoice?.cancelation ?? null,
                downloadLang: downloadType === 'download_eng_receipt' ? 'eng' : 'esp',
            },
            'invoicing',
            authUser,
        )

        let xhr = new XMLHttpRequest()

        if (invoice?.cancelation || downloadType === 'download_eng_receipt') {
            if (invoicereq.pdf) {
                const prepB64 = `data:application/pdf;base64,${invoicereq.pdf}`
                const binaryData = atob(prepB64.replace(/^[^,]+,/, ''))
                const blob = new Blob([new Uint8Array(binaryData.length).map((_, i) => binaryData.charCodeAt(i))], {
                    type: 'application/pdf',
                })

                const url = URL.createObjectURL(blob)

                const a = document.createElement('a')
                a.href = url
                const fileName = customFileName
                    ? customFileName
                    : `${invoice.series + invoice.folio_number || invoice.uuid}.pdf`
                a.download = fileName

                a.click()
                URL.revokeObjectURL(url)
            }
            return
        }
        const url = await getDownloadURL(ref(getStorage(), invoicereq.url))

        xhr.responseType = 'blob'
        xhr.onload = function (event) {
            let blob = xhr.response
            const fileName = `${invoice.series + invoice.folio_number || invoice.uuid}.${type}`
            saveAs(blob, fileName)
        }
        xhr.open('GET', url)
        xhr.send()
        xhr.addEventListener('progress', (e) => {
            let percent_complete = (e.loaded / e.total) * 100
            if (percent_complete >= 100) {
            }
        })
    } catch (error: string | any) {
        console.log('ERROR: ', error)
        message.error(error.message, 15)
    }
    if (customDownloading) {
        customDownloading(null)
    }
}

/**
 * Descarga un recibo de cancelación desde la factura y lo guarda en la computadora del usuario.
 */
export const DownloadCanceledReceipt = async ({ invoice, setdownloading, authUser }: any) => {
    setdownloading(invoice.id)

    try {
        const xml = invoice?.cancellation_receipt
        const blob = new Blob([xml], { type: 'text/plain;charset=utf-8' })
        saveAs(blob, `Acuse-${invoice.uuid}.xml`)
        setdownloading(null)
    } catch (error) {
        message.error('Error al descargar el acuse de cancelación')
        setdownloading(null)
    }
}

/**
 * Toma una factura y crea una nueva factura duplicada con una nueva fecha y un nuevo ID en modo en vivo.
 */
export const DuplicateInvoice = async ({ invoice, currentUser }: { invoice: InvoiceDef; currentUser: any }) => {
    try {
        const ninv = {
            ...invoice,
            type: 'create_invoice',
            date: moment().toISOString(),
            signPastInovice: true,
            signPastInvoice: true,
            items: invoice?.internalItems,
            invoicingIntegration: 'facturapi',
            test: false,
            payment_form: invoice.payment_form,
            series: invoice.series || 'gigstack',
            use: invoice.use,
            client: invoice.client,
            customer: invoice.client,
            relation: invoice.relation ?? null,
        }

        if (invoice.relation?.length <= 0 || invoice.relation == null) {
            delete ninv['relation']
        }

        const response = await SignedInternalAPIRequest(ninv, 'invoicing', currentUser)

        if (response.error) {
            throw new Error(response.error)
        }

        notification.success({
            message: 'Factura timbrada',
            description: 'La factura pasó exitosamente por el proceso de timbrado',
        })
    } catch (error: string | any) {
        message.error(error.message)
    }
}

/**
 * Maneja el estado del correo electrónico y devuelve un objeto con un mensaje y un color según el estado.
 * @param status - El estado del correo electrónico.
 * @returns Un objeto con un mensaje y un color.
 */
export const HandleEmailStatus = (status: string) => {
    switch (status) {
        case 'dropped':
            return {
                message: 'Fallido',
                color: 'red',
            }

        case 'sent':
            return {
                message: 'Enviado',
                color: 'green',
            }

        case 'delivered':
            return {
                message: 'Entregado',
                color: 'green',
            }

        case 'processed':
            return {
                message: 'Procesado',
                color: 'blue',
            }

        case 'open':
            return {
                message: 'Abierto',
                color: 'green',
            }

        default:
            return {
                message: status || 'Enviado',
                color: 'blue',
            }
    }
}

/**
 * Devuelve un objeto con un booleano y una matriz de cadenas. El booleano es true si todos los productos
 * tienen una clave de producto, y false si al menos uno de ellos no la tiene. La matriz de cadenas contiene los
 * nombres de los productos que no tienen una clave de producto.
 * @param products - Matriz de productos.
 */
export const ProductsAvailableForSat = (products: any[]) => {
    let available = true
    let messages: string[] = []

    if (products.length <= 0) {
        return { available: false, messages: [] }
    }

    products.forEach((product) => {
        if (!product.product_key) {
            available = false
            messages.push(`El producto ${product.name} no tiene clave de producto`)
        }
    })

    return { available, messages }
}

/**
 * Si el producto no tiene una clave de producto, devuelve false, de lo contrario devuelve true.
 * @param product - El objeto de producto.
 * @returns Un valor booleano.
 */
export const ProductAvailableForSAT = (product: any) => {
    return !!product.product_key
}

/**
 * Si el cliente tiene RFC, nombre legal, código postal y sistema tributario, devuelve true, de lo contrario
 * devuelve false.
 * @param client - El objeto de cliente.
 * @returns Un valor booleano.
 */
export const ClientReadyForInvoice = (client: any) => {
    return !!client?.rfc && !!client?.legal_name && !!client?.address?.zip && !!client?.tax_system
}

/**
 * Devuelve un objeto con dos propiedades: percentComplete y status.
 * @returns Un objeto con dos propiedades: percentComplete y status.
 */
// export function UploadProgress({ uploadTask, storageRef }: any) {
//     const { status, data: uploadProgress } = useStorageTask(uploadTask, storageRef);

//     let percentComplete;

//     if (status === 'loading') {
//         percentComplete = 0;
//     } else {
//         const { bytesTransferred, totalBytes } = uploadProgress;
//         percentComplete = Math.round(100 * (bytesTransferred / totalBytes));
//     }

//     return { percentComplete, status };
// }
export function UploadProgress({ uploadTask, storageRef }: any) {
    const { status, data: uploadProgress } = useStorageTask(uploadTask, storageRef)

    let percentComplete

    if (status === 'loading') {
        percentComplete = 0
    } else if (
        uploadProgress &&
        typeof uploadProgress === 'object' &&
        'bytesTransferred' in uploadProgress &&
        'totalBytes' in uploadProgress
    ) {
        const { bytesTransferred, totalBytes } = uploadProgress as {
            bytesTransferred: number
            totalBytes: number
        }
        percentComplete = Math.round(100 * (bytesTransferred / totalBytes))
    } else {
        percentComplete = 0
    }

    return { percentComplete, status }
}

/**
 * Descarga un archivo desde una URL y lo guarda en la computadora del usuario.
 */
export const DownloadFile = async ({ url, name, setdownloading = () => {}, type }: any) => {
    setdownloading(url)

    try {
        let xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = function (event) {
            let blob = xhr.response
            saveAs(blob, `${name}.${type}`)
        }
        xhr.open('GET', url)
        xhr.send()
        xhr.addEventListener('progress', (e) => {
            let percent_complete = (e.loaded / e.total) * 100
            if (percent_complete >= 100) {
                setdownloading(null)
            }
        })
    } catch (error) {
        setdownloading(null)
    }
}

/**
 * Toma una matriz de objetos y devuelve una nueva matriz de objetos, donde cada objeto es único según
 * el valor de una clave especificada.
 * @param array - La matriz de objetos de la que deseas eliminar duplicados.
 * @param [identifierKey=id] - La clave que deseas utilizar para identificar el objeto.
 * @returns Una función que toma una matriz y una clave de identificación y devuelve una nueva matriz sin duplicados.
 */
export const RemoveDuplicatesFromObjectArray = (array: any[], identifierKey: string = 'id') => {
    let uniqueArray: any[] = []

    array.forEach((a) => {
        if (!uniqueArray.find((u) => u[identifierKey] === a[identifierKey])) {
            uniqueArray.push(a)
        }
    })

    return uniqueArray
}

/**
 * Toma una cadena como argumento y devuelve un objeto con una propiedad de texto y una propiedad de color.
 * @param type - El tipo de factura.
 * @returns Un objeto con las propiedades de texto y color.
 */
export const InvoiceType = (type: string) => {
    switch (type) {
        case 'P':
            return { text: 'Pago', color: '#DEF7E7', textColor: '#0AB649' }
        case 'I':
            return { text: 'Ingreso', color: '#FEE9E9', textColor: '#F15B5B' }
        case 'E':
            return { text: 'Egreso', color: '#FEE9E9', textColor: '#F15B5B' }
        case 'N':
            return { text: 'Nómina', color: '#FEE9E9', textColor: '#F15B5B' }
        case 'T':
            return { text: 'Traslado', color: '#FEE9E9', textColor: '#F15B5B' }
        default:
            return { text: 'Factura', color: '#0B9DF8' }
    }
}

/**
 * Toma una cadena y devuelve un objeto con la misma cadena como valor y etiqueta.
 * @param periodicity - La periodicidad de la suscripción.
 * @returns Un objeto con el valor y la etiqueta de la periodicidad.
 */
export const TranslatePeriodicityToObject = (periodicity: string) => {
    switch (periodicity) {
        case 'day':
            return { value: 'day', label: 'Día' }
        case 'week':
            return { value: 'week', label: 'Semana' }
        case 'fortnight':
            return { value: 'fortnight', label: 'Quincenal' }
        case 'month':
            return { value: 'month', label: 'Mes' }
        case 'two_months':
            return { value: 'two_months', label: '2 meses' }
        default:
            return { value: 'month', label: 'Mes' }
    }
}

/**
 * Toma una cadena y devuelve una matriz de todos los números que están dentro de llaves dobles.
 * @param text - El texto del que se van a extraer las letiables.
 */
// export const ExtractletiablesFromText = (text: string) => {
//     let letiables = [];
//     let regex = /{{(\d+)}}/g;
//     let match;
//     while (match = regex.exec(text)) {
//         letiables.push(match[1]);
//     }
//     return letiables;
// }

/**
 * Toma un número y lo redondea.
 * @params number - Número a redondear.
 * @returns numberRounded
 */
export const roundAmount = (number: number) => {
    return Math.round(number)
}

const paymentObj = {
    canceled_at: null,
    review: null,
    invoices: ['3kxj5FTO9Z7C'],
    on_behalf_of: null,
    manualSuccess: true,
    exchangeRate: 1,
    receipt_email: '{{email}}',
    isManual: false,
    automatic_payment_methods: null,
    lastViewed: 1673487601092,
    source: null,
    clientID: '1SwuEPWzLD',
    succeededTimestamp: 1673488133913,
    paidIn: 'bank',
    customer: {
        zip: '{{ zip }}',
        legal_name: '{{ legal_name }}',
        tax_system: {
            label: 'Personas Físicas con Actividades Empresariales y Profesionales',
            value: '612',
        },
        name: '{{ name }}',
        from: '{{ gigstack }}',
        phoneNumbers: [
            {
                value: '{{ phone }}',
            },
        ],
        address: {
            address: '{{ address }}',
            zip: '{{ zip }}',
            country: '{{ country }}',
        },
        RFCvalidated: true,
        phone: '{{ phone }}',
        owner: '{{ owner }}',
        company: '{{ company }}',
        emailAddresses: [
            {
                value: '{{ email }}',
            },
        ],
        efos: {
            data: {
                mensaje: 'La consulta para el rfc especificado no devolvió resultados',
                detalles: [],
            },
            is_valid: true,
        },
        rfc: 'XAXX010101000',
        names: [
            {
                displayName: '{{ displayName }}',
            },
        ],
        email: '{{ email }}',
        id: '1SwuEPWzLD',
        country: {
            label: '{{ country }}',
            code: 'MX',
            value: '{{ country }}',
        },
        timestamp: 1673330844947,
    },
    charges: null,
    statement_descriptor: '',
    application: null,
    exchange_rate: 1,
    payment_intent: null,
    metadata: {
        internalID: '{{ internalID }}',
        items: 1,
        owner: '{{ owner }}',
    },
    amount_capturable: 0,
    clientVoucher:
        'https://firebasestorage.googleapis.com/v0/b/gigstackpro.appspot.com/o/paymentVouchers%2FjgsGYBDw1xbyigs.jpeg?alt=media&token=419c97c4-8a00-4152-9465-234de163b615',
    items: [
        {
            id: 'mE9JyqKaby',
            product_key: '81111504',
            quantity: 1,
            description: 'Creación de una landing page responsive ',
            taxes: [
                {
                    factor: 'Tasa',
                    rate: '0.16',
                    type: 'IVA',
                    withholding: false,
                },
            ],
            hours: null,
            owner: '{{ owner }}',
            paymentType: {
                label: 'Precio fijo',
                value: 'fixed',
            },
            total: '3',
            name: 'Desarrollo de Landing Page',
            timestamp: 1673331152792,
        },
    ],
    capture_method: 'automatic',
    created: 1673487553593,
    v: 2,
    payment_method_types: [],
    id: '{{ id }}',
    application_fee_amount: null,
    discount: 0,
    client: {
        testInvoicesCreated: 1,
        name: '{{ name }}',
        email: '{{ email }}',
        zip: '91380',
        id: '1SwuEPWzLD',
        emailAddresses: [
            {
                value: '{{ email }}',
            },
        ],
        names: [
            {
                displayName: '{{ name }}',
            },
        ],
        timestamp: 1673330844947,
        rfc: 'XAXX010101000',
        phoneNumbers: [
            {
                value: '{{ phone }}',
            },
        ],
        tax_system: {
            label: 'Personas Físicas con Actividades Empresariales y Profesionales',
            value: '612',
        },
        phone: '2281495452',
        legal_name: '{{ name }}',
        from: '{{ company }}',
        owner: '{{ owner }}',
        country: {
            value: '{{ country }}',
            code: 'MX',
            label: '{{ country }}',
        },
        paymentsCreated: 1,
        efos: {
            data: {
                mensaje: 'La consulta para el rfc especificado no devolvió resultados',
                detalles: [],
            },
            is_valid: true,
        },
        address: {
            address: '{{ address }}',
            country: '{{ country }}',
            zip: '{{ zip }}',
        },
        RFCvalidated: true,
        company: '{{ company }}',
    },
    proposals: null,
    description: '',
    payment_method: null,
    confirmation_method: '',
    shipping: null,
    processor: '',
    last_payment_error: null,
    review_created_at: 1673488082149,
    voucherMeta: {
        extension: 'jpeg',
        path: 'paymentVouchers/jgsGYBDw1xbyigs.jpeg',
    },
    owner: 'DiMlHSc5blTQAvednxNb9yBqkYS2',
    sms: false,
    shortURL: 'https://gigstack.xyz/jgsGYBDw1xbyigs',
    timestamp: 1673487553593,
    custom_method_types: [
        {
            logo: 'https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/+bank.png',
            id: 'bank',
            details: 'Pago con transferencia desde tu cuenta bancaria',
            name: 'Transferencia bancaria',
            manualConfirmation: true,
        },
    ],
    currency: 'mxn',
    shortUrl: 'https://gigstack.xyz/jgsGYBDw1xbyigs',
    next_action: null,
    amount_received: 0,
    hasStripe: false,
    exchangeRateDate: 'Wed, 11 Jan 2023 00:00:01 +0000',
    cancellation_reason: null,
    livemode: true,
    review_status: 'succeeded',
    object: 'payment',
    invoicesCount: 0,
    viewed: 1,
    status: 'succeeded',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXF1ZXN0ZXIiOiJEaU1sSFNjNWJsVFFBdmVkbnhOYjl5QnFrWVMyIiwicGF5bWVudCI6Impnc0dZQkR3MXhieWlncyIsImlhdCI6MTY3MzQ4NzU2Mn0.AVJYR13_nDPcwvy6p10ciyCrfkydYJ72UirMfB1OUK8',
    bank: {
        holder_name: '{{ holder_name }}',
        clabe: '{{ clabe }}',
        country: '{{ country }}',
        help: 'Enviar captura de la transferencia o comprobante de pago',
        formatOk: true,
        account: '20011164435',
        tag: '{{ bank }}',
        multiple: true,
        voucherRequired: true,
        ok: true,
        code: {
            bank: '014',
            city: '905',
        },
        bank: 'Banco Santander',
        checksum: 6,
        message: 'Valid',
        total: 2,
        city: 'Ciudad Industrial Framboyan, Veracruz MX-VER',
    },
    amount: 5348,
    internalStatus: 'viewed',
    fid: '{{ fid }}',
    transfer_group: null,
}
/**
 * Genera un array de pagos de prueba.
 * @param n - Número de pagos a generar.
 * @returns Un array de pagos de prueba.
 */
export const generateTestPayments = (n: any) => {
    let paymentsArray = []
    for (let i = 0; i <= n; i++) {
        paymentsArray.push({
            ...paymentObj,
            id: `${i}`,
            customer: {
                ...paymentObj.customer,
                name: `${paymentObj.customer.name}-${i}`,
            },
            client: { ...paymentObj, name: `${paymentObj.client.name}-${i}` },
        })
    }
    return paymentsArray
}

/**
 * Crea un objeto de pago interno.
 * @param options - Las opciones del pago interno.
 * @returns Un objeto de pago interno.
 */
export const InternalPayment = ({
    amount = 0,
    status = 'pending',
    internalStatus = 'pending',
    internalItems = [],
    custom_method_types = [],
    exchange = 1,
    currency = 'MXN',
    livemode = false,
    paidId = 'manual',
    id = '',
    fid = '',
    v = 2,
    from = 'manual',
    processor = '',
    relatedTo = '',
    invoices = null,
    payments = null,
    client = {
        id: '',
    },
    discount = 0,
    payment = {},
    user = {
        uid: '',
    },
    automations = {
        type: '',
        id: '',
    },
    shortURL = null,
    shortUrl = null,
    token = null,
    team = '',
    billingAccount = '',
    hasStripe = null,
    timestamp = moment().valueOf(),
    created = moment().valueOf(),
    succeededTimestamp = moment().valueOf(),
    payment_form = '99',
    binnacle = '',
}) => {
    return {
        ...payment,
        owner: user.uid,
        amount: Number(amount),
        status,
        internalStatus,
        internalItems,
        items: internalItems,
        custom_method_types,
        exchange,
        currency,
        paidId,
        id,
        fid,
        v,
        from,
        payment_form,
        processor,
        relatedTo,
        invoices,
        payments,
        team,
        billingAccount,
        client,
        clientId: client?.id ?? null,
        discount: typeof discount === 'object' ? 0 : discount,
        timestamp,
        created,
        shortURL,
        shortUrl,
        token,
        hasStripe,
        succeededTimestamp,
        binnacle,
    }
}

interface IHandleMetadataInput {
    key: string
    value: any
}

export const HandleMetadataInput = ({ metadataValue }: { metadataValue: IHandleMetadataInput[] }) => {
    //remove all undefined values inside the metadataValue array

    metadataValue.filter(({ key, value }) => {
        return key !== '' && value !== '' && value !== undefined
    })

    let prepared = metadataValue?.filter(({ key, value }) => {
        return key !== '' && value !== ''
    })
    const metadata = prepared.reduce(
        (acc, { key, value }) => {
            acc[key] = value
            return acc
        },
        {} as { [key: string]: any },
    )

    return { metadata }
}

export const returnReadableOwnerInfo = ({ resource, team, user }: { resource: any; team: TeamDef; user: UserDef }) => {
    let ownerId = resource?.owner || null

    const teamMember = team.members.find((member) => member.id === ownerId)

    if (user.uid === ownerId) {
        return {
            owner: ownerId,
            email: user.email,
            name: user.name || null,
        }
    }

    return {
        owner: ownerId || '',
        email: teamMember?.email ?? 'Usuario no encontrado',
        name: null,
    }
}

export const preserveQueryParams = (
    url: string,
    currentSearchParams: URLSearchParams,
    excludeParams: string[] = [],
): string => {
    const isAbsolute = url.startsWith('http://') || url.startsWith('https://')
    const newUrl = isAbsolute ? new URL(url) : new URL(url, window.location.origin)

    currentSearchParams.forEach((value, key) => {
        if (!excludeParams.includes(key)) {
            newUrl.searchParams.set(key, value)
        }
    })

    return isAbsolute ? newUrl.toString() : `${newUrl.pathname}${newUrl.search}`
}

export async function uploadLogoToStorage(
    file: File | null,
    storage: FirebaseStorage,
    route: string,
): Promise<string | undefined> {
    if (!file) {
        return
    } else {
        try {
            // Create a reference to the location where we want to upload the file
            const storageRef = ref(storage, route)

            // Upload the file to Firebase Storage
            const snapshot = await uploadBytes(storageRef, file)
            console.log('Uploaded a blob or file!')

            // Get the download URL
            const downloadURL = await getDownloadURL(snapshot.ref)
            console.log('File available at', downloadURL)

            return downloadURL
        } catch (error) {
            console.error('Error uploading file: ', error)
            throw error
        }
    }
}

interface DemoService {
    id: string
    name: string
    description: string
    total: number
    product_key: string
    quantity: number
    taxes: Array<{
        rate: number
        type: string
        factor: string
        withholding: boolean
    }>
    discount: number
    unit_key: string
    unit_name: string
    paymentType: {
        label: 'Precio fijo'
        value: 'fixed' | 'hour'
    }
}

interface DemoClient {
    id: string
    name: string
    email: string
    legal_name: string
    rfc: string
    tax_system: {
        value: string
        label: string
    }
    address: {
        zip: string
        country: string
        address: string
    }
}

interface RegisterDemoPaymentParams {
    demoService?: DemoService
    demoClient?: DemoClient
    user: any
    team: any
    automations?: boolean
    paid?: boolean
    clientExtras?: {
        email?: string
        name?: string
        lastName?: string
        rfc?: string
        taxSystem?: string
    }
}

const DEFAULT_DEMO_SERVICE: DemoService = {
    id: generateCode(10, 'service'),
    name: 'Servicio de Consultoría',
    description: 'Servicio profesional de consultoría empresarial',
    total: 1000,
    product_key: '81111504',
    quantity: 1,
    taxes: [
        {
            rate: 0.16,
            type: 'IVA',
            factor: 'Tasa',
            withholding: false,
        },
    ],
    discount: 0,
    unit_key: 'E48',
    unit_name: 'Unidad de servicio',
    paymentType: {
        label: 'Precio fijo',
        value: 'fixed',
    },
}

const DEFAULT_DEMO_CLIENT: DemoClient = {
    id: generateCode(10, 'client'),
    name: 'Cliente Demo',
    email: 'demo@cliente.com',
    legal_name: 'Cliente Demo SA de CV',
    rfc: 'XAXX010101000',
    tax_system: {
        value: '601',
        label: 'General de Ley Personas Morales',
    },
    address: {
        zip: '06500',
        country: 'México',
        address: 'Av. Reforma 222, Col. Juárez',
    },
}

export const registerDemoPayment = async ({
    demoService = DEFAULT_DEMO_SERVICE,
    demoClient = DEFAULT_DEMO_CLIENT,
    user,
    team,
    automations = false,
    paid = false,
    clientExtras = {},
}: RegisterDemoPaymentParams) => {
    const id = generateCode(10, 'payment')

    // Create demo values
    const values = {
        paymentForm: '03', // Transferencia electrónica
        currency: 'MXN',
        exchange: 1,
        timestamp: moment().valueOf(),
        test: true,
    }

    const selectedServices = [
        {
            ...demoService,
            owner: user?.uid,
            timestamp: moment().valueOf(),
        },
    ]

    const client = {
        ...demoClient,
        owner: user?.uid,
        timestamp: moment().valueOf(),
        ...(clientExtras ?? {}),
    }

    const payment: any = {
        amount: getItemsAmounts(selectedServices, true).total * 100,
        items: selectedServices,
        status: 'requires_payment_method',
        succeededTimestamp: moment().valueOf(),
        internalStatus: 'requires_payment_method',
        internalItems: selectedServices,
        custom_method_types: [],
        payment_form: values.paymentForm,
        client: client ?? null,
        clientId: client?.id ?? null,
        clientID: client?.id ?? null,
        payment_method: 'PUE',
        exchange: Number(values.exchange),
        currency: values.currency,
        paidId: values.paymentForm === '03' ? 'bank' : values.paymentForm,
        id: id,
        fid: id,
        v: 2,
        from: 'manual',
        owner: user?.uid,
        processor: 'manual',
        relatedTo: '',
        invoices: null,
        payments: null,
        discount: 0,
        team: team.id,
        billingAccount: team.billingAccount,
        timestamp: moment(values.timestamp).valueOf(),
        created: moment().valueOf(),
        livemode: !values.test,
        binnacle: `Demo payment created by ${user?.email} ${user?.uid}`,
    }

    if (clientExtras?.email) {
        payment.emails = [clientExtras.email]
    }

    if (automations) {
        payment.automations = manageAutomations({ toCreate: 'invoice', from: 'payments' })
    }

    if (paid) {
        payment.status = 'succeeded'
        payment.internalStatus = 'succeeded'
    }

    // Remove undefined values
    Object.keys(payment).forEach((key) => payment[key] === undefined && delete payment[key])

    try {
        const db = getFirestore()
        await setDoc(doc(db, 'payments', id), {
            ...payment,
            ...{
                status: 'succeeded',
                internalStatus: 'succeeded',
            },
        })

        return { success: true, paymentId: id, demoData: { service: demoService, client: demoClient, payment } }
    } catch (error: any) {
        console.error('Error registering demo payment:', error)
        throw new Error(error.message ?? 'Error registering demo payment')
    }
}

interface DemoInvoiceParams {
    user: any
    team: any
    demoService?: DemoService
    demoClient?: DemoClient
    automations?: boolean
    clientExtras?: {
        email?: string
        name?: string
        lastName?: string
        rfc?: string
        taxSystem?: string
        address?: {
            zip?: string
        }
    }
}

export const registerDemoInvoice = async ({
    user,
    team,
    demoService = DEFAULT_DEMO_SERVICE,
    demoClient = DEFAULT_DEMO_CLIENT,
    automations = false,
    clientExtras = {},
}: DemoInvoiceParams) => {
    const id = generateCode(10, 'invoice')

    const selectedServices = [
        {
            ...demoService,
            owner: user?.uid,
            timestamp: moment().valueOf(),
        },
    ]

    const client = {
        ...demoClient,
        owner: user?.uid,
        timestamp: moment().valueOf(),
    }

    const invoice: any = {
        id,
        fid: id,
        type: 'create_invoice',
        invoiceType: 'I',
        series: team?.invoice_serie ?? 'G',
        folio_number: team?.invoice_folio ?? 1,
        date: moment().toISOString(),
        payment_form: '03', // Transferencia electrónica
        payment_method: 'PUE',
        use: 'G03',
        currency: 'MXN',
        exchange: 1,
        items: selectedServices,
        internalItems: selectedServices,
        client,
        clientId: client?.id,
        clientID: client?.id,
        owner: user?.uid,
        team: team.id,
        billingAccount: team.billingAccount,
        amount: getItemsAmounts(selectedServices, true).total * 100,
        from: 'manual',
        created: moment().valueOf(),
        timestamp: moment().valueOf(),
        livemode: false,
        test: true,
        status: 'requires_validation',
        internalStatus: 'requires_validation',
        binnacle: `Demo invoice created by ${user?.email} ${user?.name}`,
        v: 2,
        invoicingIntegration: 'facturapi',
    }
    if (clientExtras?.email) {
        invoice.emails = [clientExtras.email]
    }

    if (automations) {
        invoice.automations = manageAutomations({ toCreate: 'payment', from: 'invoices' })
    }

    // Remove undefined values
    Object.keys(invoice).forEach((key) => invoice[key] === undefined && delete invoice[key])

    try {
        const response = await SignedInternalAPIRequest(invoice, 'invoicing', user, {}, 'POST', false, false)

        if (response.error) {
            throw new Error(response.error)
        }

        return {
            ...response,
            success: true,
            invoiceId: response.id,
            uuid: response.uuid,
            demoData: {
                service: demoService,
                client: demoClient,
                invoice: response,
            },
        }
    } catch (error: any) {
        console.error('Error registering demo invoice:', error)
        throw new Error(error.message ?? 'Error registering demo invoice')
    }
}

interface DemoReceiptParams {
    user: any
    team: any
    demoService?: DemoService
    demoClient?: DemoClient
    automations?: boolean
    clientExtras?: {
        email?: string
        name?: string
        lastName?: string
        rfc?: string
        taxSystem?: string
        address?: {
            zip?: string
        }
    }
}

export const registerDemoReceipt = async ({
    user,
    team,
    demoService = DEFAULT_DEMO_SERVICE,
    demoClient = DEFAULT_DEMO_CLIENT,
    automations = false,
    clientExtras = {},
}: DemoReceiptParams) => {
    const id = generateCode(10, 'receipt')

    const selectedServices = [
        {
            ...demoService,
            owner: user?.uid,
            timestamp: moment().valueOf(),
        },
    ]

    const client = {
        ...demoClient,
        owner: user?.uid,
        timestamp: moment().valueOf(),
        ...(clientExtras ?? {}),
    }

    const receipt: any = {
        id,
        fid: id,
        type: 'create_receipt',
        receiptType: 'R',
        series: team?.receipt_serie ?? 'R',
        folio_number: team?.receipt_folio ?? 1,
        date: moment().toISOString(),
        payment_form: '03', // Transferencia electrónica
        payment_method: 'PUE',
        currency: 'MXN',
        exchange: 1,
        items: selectedServices,
        internalItems: selectedServices,
        client,
        clientId: client?.id,
        clientID: client?.id,
        owner: user?.uid,
        team: team.id,
        billingAccount: team.billingAccount,
        amount: getItemsAmounts(selectedServices, true).total * 100,
        from: 'manual',
        created: moment().valueOf(),
        timestamp: moment().valueOf(),
        livemode: false,
        test: true,
        status: 'requires_validation',
        internalStatus: 'requires_validation',
        binnacle: `Demo receipt created by ${user?.email} ${user?.name}`,
        v: 2,
        invoicingIntegration: 'facturapi',
    }

    if (clientExtras?.email) {
        receipt.emails = [clientExtras.email]
    }

    if (automations) {
        receipt.automations = manageAutomations({ toCreate: 'payment', from: 'invoices' })
    }

    // Remove undefined values
    Object.keys(receipt).forEach((key) => receipt[key] === undefined && delete receipt[key])
    console.log(receipt)

    try {
        const response = await SignedInternalAPIRequest(receipt, 'receiptsApp/v1/create', user)

        if (response.error) {
            throw new Error(response.error)
        }

        console.log(response)

        return {
            ...response,
            ...(response.receipt ?? {}),
            success: true,
            receiptId: response.receipt.id,
            url: response.receipt.url,
            demoData: {
                service: demoService,
                client: demoClient,
                receipt: response.receipt,
                url: response.receipt.url,
            },
        }
    } catch (error: any) {
        console.error('Error registering demo receipt:', error)
        throw new Error(error.message ?? 'Error registering demo receipt')
    }
}
