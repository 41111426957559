import { createSlice } from '@reduxjs/toolkit'
import { Payment, paymentSet } from '../../interfaces/paymentsDef'
import moment from 'moment'

interface SettingsDrawerConfig {
    type: string
    title: string
    description: string
}

interface SettingsDrawerConfig {
    type: string
    title: string
    description: string
}

interface SettingsDrawerConfig {
    type: string
    title: string
    description: string
}

interface DownloadInfoTypes {
    type: string
    readableType: string
    clientId?: string
}

interface ItemsTablePaginationTypes {
    page: number
    pageSize: number
}

interface DataState {
    clients: any[]
    services: any[]
    payment: Payment
    selectedServices: any[]
    selectedPayments: any[]
    insights: any[]
    exchangeRates: {}
    client: any
    selectedClients: any[]
    items: any[]
    service: any
    taxes: any[]
    addAutomations: any[]
    limitDaysToPay?: any
    custom_method_types: any[]
    automatePayment?: boolean
    payment_method: any
    invoice: any
    payment_form?: any
    currency?: string
    exchage?: any
    periodicity?: string
    livemode?: boolean
    temporality?: string
    invoiceComplement: any
    selectedRelatedDocument: any
    search: any
    templateEmails: any[]
    recurringInvoiceData: any
    settingsDrawerConfig?: SettingsDrawerConfig | null
    selectedReceipts: any[]
    testmode: boolean
    clientsLoaded: boolean
    servicesLoaded: boolean
    relations: any
    relationInfo: any
    relationsData: any[]
    itemsTablePagination: ItemsTablePaginationTypes
    preloadedWebhookData: any
    massiveInvoiceStatus: any[]
    preloadedFailedInvoiceData: any
    anchorLocation?: string
    tourSelected?: string | null

    downloadInfo?: DownloadInfoTypes | null
    clientsToMerge: any[]
    importThingsInfo: any

    tab?: string | null
    subtab?: string | null

    searchResult?: any
    period?: { start: number; end: number }
    fiscalInformationOnb?: any
    defaultsOnb?: any
}

const initialState: DataState = {
    clients: [],
    services: [],
    payment: paymentSet({}),
    selectedServices: [],
    selectedPayments: [],
    insights: [],
    exchangeRates: {},
    client: {},
    selectedClients: [],
    service: null,
    taxes: [],
    addAutomations: [],
    items: [],
    limitDaysToPay: null,
    custom_method_types: [],
    automatePayment: false,
    payment_method: null,
    invoice: null,
    payment_form: null,
    currency: '',
    exchage: null,
    periodicity: '',
    livemode: false,
    temporality: '',
    invoiceComplement: null,
    selectedRelatedDocument: null,
    search: null,
    templateEmails: [],
    recurringInvoiceData: null,
    settingsDrawerConfig: null,
    selectedReceipts: [],
    testmode: false,
    clientsLoaded: false,
    servicesLoaded: false,
    relations: {},
    relationInfo: {},
    relationsData: [],
    clientsToMerge: [],
    importThingsInfo: null,
    searchResult: null,
    preloadedWebhookData: null,
    massiveInvoiceStatus: [],
    preloadedFailedInvoiceData: null,
    anchorLocation: '',
    tourSelected: null,

    downloadInfo: null,

    tab: null,
    subtab: null,
    itemsTablePagination: {
        page: 1,
        pageSize: 10,
    },
    period: { start: moment().startOf('month').valueOf(), end: moment().endOf('month').valueOf() },
    fiscalInformationOnb: {},
    defaultsOnb: {},
}

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setData: (state: any, action) => {
            state[action.payload.item] = action.payload.data
        },
        pushNewData: (state: any, action) => {
            state[action.payload.item].push(action.payload.data)
        },
        deleteData: (state: any, action) => {
            state[action.payload.item] = state[action.payload.item].filter((item: any) => item.id !== action.payload.id)
        },
        updateData: (state: any, action) => {
            state[action.payload.item] = state[action.payload.item].map((item: any) => {
                if (item.id === action.payload.data.id) {
                    return action.payload.data
                }
                return item
            })
        },
        setElementSelected: (state: any, action) => {
            state[action.payload.item] = action.payload.data
        },
    },
})

export const { setData, pushNewData, deleteData, updateData, setElementSelected } = dataSlice.actions

export default dataSlice.reducer
