import { LightbulbFilament, SmileySad, Sparkle, StarFour, Link, ArrowRight } from '@phosphor-icons/react'
import { Alert, Col, Form, FormInstance, message, Row, Space, Tag, Typography, Button } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import { useAuth } from 'reactfire'
import { CSDForm } from '../../../components/Forms/Integrations/SatIntegration'
import { setStepLoading } from '../../../context/onboardingSlice'
import { setTeam } from '../../../context/teamsSlice'
import { SignedInternalAPIRequest } from '../../../functions/APIRequest'
import StepLayout from '../StepsLayout'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../../../analytics/helpers'
import { getFunctions, httpsCallable } from 'firebase/functions'

interface SatConnectionStepProps {
    form: FormInstance
    onStepCompleted: () => void
    renderButtons: () => React.ReactNode
}

const SatConnectionStep = ({ form, onStepCompleted, renderButtons }: SatConnectionStepProps) => {
    const dispatch = useDispatch()
    const auth = useAuth()
    const posthog = usePostHog()

    const { team } = useSelector((state: any) => state.team)
    const [error, setError] = useState<string | null>(null)
    const [errorCount, setErrorCount] = useState(0)
    const [isGeneratingLink, setIsGeneratingLink] = useState(false)
    const [linkGenerated, setLinkGenerated] = useState(false)

    useEffect(() => {
        trackEvent({ name: 'onboarding_sat_section_viewed', metadata: { v: 'v2' } }, posthog)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFinish = async (values: any) => {
        try {
            dispatch(setStepLoading(true))
            setError(null)

            const resp = await SignedInternalAPIRequest(
                {
                    password: values.password,
                    rfc: team.rfc,
                    invoicingIntegration: 'facturapi',
                    type: 'connect',
                    typeCsd: 'update',
                },
                'invoicing',
                auth.currentUser,
            )

            if (resp) {
                const latestTeam = await getDoc(doc(getFirestore(), 'teams', team.id))
                const teamData = latestTeam.data()

                trackEvent({ name: 'sat_connection', metadata: { from: 'onboarding', v: 'v2' } }, posthog)

                dispatch(setTeam(teamData))
                onStepCompleted()
                dispatch(setStepLoading(false))
            } else {
                console.log('resp', resp)

                throw new Error(resp.message || 'Error al conectar con el SAT')
            }
            onStepCompleted()
            dispatch(setStepLoading(false))
        } catch (error: any) {
            console.log('🚀 ~ handleFinish ~ error:', error)

            trackEvent({ name: 'sat_connection_errors', metadata: { from: 'onboarding', v: 'v2' } }, posthog)
            dispatch(setStepLoading(false))
            setErrorCount((prev) => {
                const newCount = prev + 1
                if (newCount === 2) {
                    const chatwootWidget = (window as any).$chatwoot
                    if (chatwootWidget) {
                        chatwootWidget.toggle('open')
                    }
                }
                return newCount
            })

            setError(error.message || 'Hubo un problema al conectar con el SAT. Por favor, intenta de nuevo.')
            console.error('Error al conectar con el SAT:', error)
            message.error(error.message || 'Hubo un problema al conectar con el SAT. Por favor, intenta de nuevo.')
            console.error(error.message)
        }
    }

    const openChatWidget = () => {
        const chatwootWidget = (window as any).$chatwoot
        if (chatwootWidget) {
            chatwootWidget.toggle()
        }
    }

    const generateSecureLink = async () => {
        setIsGeneratingLink(true)
        try {
            const generateOnboardingUrl = httpsCallable(getFunctions(), 'generateOnboardingUrl')
            const result = await generateOnboardingUrl({ teamId: team.id }) as any
            
            await navigator.clipboard.writeText(result.data.url)
            setLinkGenerated(true)
            message.success('Enlace copiado al portapapeles')
        } catch (error) {
            message.error('Error al generar el enlace')
            console.error(error)
        } finally {
            setIsGeneratingLink(false)
        }
    }

    const skipSatConnection = async () => {
        try {
            dispatch(setStepLoading(true))
            
            await updateDoc(doc(getFirestore(), 'teams', team.id), {
                'facturapi.skip_onboarding_connection': true
            })
            
            dispatch(setTeam({
                ...team,
                facturapi: {
                    ...(team.facturapi || {}),
                    skip_onboarding_connection: true
                }
            }))
            
            onStepCompleted()
            dispatch(setStepLoading(false))
            
            trackEvent({ name: 'sat_connection_skipped', metadata: { from: 'onboarding' } }, posthog)
        } catch (error) {
            console.error('Error al saltar la configuración:', error)
            message.error('Hubo un problema al continuar. Por favor intenta nuevamente.')
            dispatch(setStepLoading(false))
        }
    }

    const satForm = (
        <Form
            form={form}
            onFinish={handleFinish}
            layout="vertical"
            style={{ width: '100%' }}
            onFinishFailed={(errorInfo) => {
                console.log('Form validation failed:', errorInfo)
                message.error('Por favor, corrige los errores en el formulario antes de continuar.')
            }}
        >
            <Row justify="center" gutter={[0, 0]} style={{ marginBottom: '24px' }}>
                <Col span={24} className="d-flex flex-column">
                    <Typography.Title level={3} className="fade-in-animation" style={{ margin: 0 }}>
                        <Sparkle
                            size={20}
                            weight="fill"
                            color={'#044737'}
                            className="primary-color"
                            style={{ marginRight: '5px' }}
                        />
                        Agreguemos tus sellos del Certificado Digital
                    </Typography.Title>
                    <Typography.Text type="secondary" className="fade-in-animation" style={{ marginTop: '8px' }}>
                        Los sellos del Certificado Digital nos permiten emitir las facturas electrónicas
                    </Typography.Text>
                </Col>
            </Row>
            <CSDForm error={null} />

            <div 
                style={{
                    padding: '24px',
                    background: 'var(--neutral-7)',
                    borderRadius: '12px',
                    border: '1px solid var(--neutral-4)'
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Typography.Title level={5} className="h5-bold neutral-1" style={{ margin: '0px' }}>
                            ¿Prefieres que alguien más suba los sellos?
                        </Typography.Title>
                        <Typography.Text className="p-base-regular neutral-2">
                            Genera un enlace seguro y compártelo con tu contador o un compañero para que puedan subir los sellos.
                        </Typography.Text>
                    </Col>
                    <Col span={24}>
                        <Row justify="space-between" align="middle">
                            <Button
                                type="default"
                                icon={<Link size={16} weight="regular" />}
                                onClick={generateSecureLink}
                                className="neutral-button"
                                loading={isGeneratingLink}
                            >
                                {isGeneratingLink ? 'Generando enlace...' : 'Generar enlace seguro'}
                            </Button>
                        </Row>
                    </Col>
                    {linkGenerated && <Col span={24}>
                        <Row justify="space-between" align="middle">
                            <Button
                                className="btn-link"
                                onClick={skipSatConnection}
                            >
                                Continuar, configuraré los sellos más tarde
                                <ArrowRight size={16} weight="regular" />
                            </Button>
                        </Row>
                    </Col>}
                </Row>
            </div>

            {error && (
                <Alert
                    message={<Typography.Text className="p-base-bold red-6">Ocurró un error</Typography.Text>}
                    description={
                        <Space direction="vertical">
                            <Typography.Text className="p-base-regular red-6">{error}</Typography.Text>
                            {errorCount >= 2 && (
                                <p className="p-base-regular">
                                    Si necesitas ayuda, puedes{' '}
                                    <span
                                        className="p-base-bold"
                                        onClick={openChatWidget}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        escribirnos por el chat
                                    </span>{' '}
                                    o contactarnos a{' '}
                                    <a className="primary-1" href="mailto:soporte@gigstack.io">
                                        soporte@gigstack.io
                                    </a>
                                </p>
                            )}
                        </Space>
                    }
                    type="error"
                    showIcon
                    icon={<SmileySad color="var(--red-6)" />}
                    closable
                    onClose={() => setError(null)}
                    style={{
                        marginBottom: '24px',
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        border: '1px solid var(--red-6)',
                    }}
                />
            )}
            {renderButtons()}
        </Form>
    )

    const supportElement = (
        <Row justify="center">
            <Col sm={18} md={18} lg={16}>
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <div>
                        <Typography className="h5-bold neutral-1" style={{ marginBottom: 8 }}>
                            ¿Qué son los Sellos de Certificado Digital?
                        </Typography>
                        <Typography className="p-base-regular neutral-2">
                            Un Certificado de Sello Digital (CSD) es un certificado digital específico para emitir
                            facturas electrónicas (CFDI) y otros documentos fiscales, expedido por el SAT.
                        </Typography>
                    </div>
                    <Row align="middle">
                        <LightbulbFilament size={18} style={{ marginRight: '10px' }} />
                        <Typography
                            className="p-base-bold neutral–2"
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => {
                                window.open(
                                    'https://helpcenter.gigstack.pro/es/articles/6902738-genera-en-el-sat-tu-csd-certificado-de-sello-digital',
                                    'WindowName',
                                    'noopener',
                                )
                            }}
                        >
                            Recuerda que el CSD no es igual que la FIEL
                        </Typography>
                    </Row>
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <Typography className="h5-bold neutral-1" style={{ marginBottom: 8 }}>
                            Beneficios
                        </Typography>
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Tag
                                className="rounded-xl"
                                style={{ border: 'none', backgroundColor: 'var(--green-11)', padding: '0px 15px' }}
                            >
                                <StarFour
                                    weight="fill"
                                    size={16}
                                    color="var(--primary-1)"
                                    style={{ marginRight: '5px' }}
                                />
                                <Typography className="p-large-bold primary-1">Conexión segura</Typography>
                            </Tag>
                            <Tag
                                className="rounded-xl"
                                style={{ border: 'none', backgroundColor: 'var(--primary-1)', padding: '0px 15px' }}
                            >
                                <StarFour
                                    weight="fill"
                                    size={16}
                                    color="var(--green-11)"
                                    style={{ marginRight: '5px' }}
                                />
                                <Typography className="p-large-bold green-11">Validación directa con el SAT</Typography>
                            </Tag>
                            <Tag
                                className="rounded-xl"
                                style={{ border: 'none', backgroundColor: 'var(--primary-8)', padding: '0px 15px' }}
                            >
                                <StarFour
                                    weight="fill"
                                    size={16}
                                    color="var(--neutral-2)"
                                    style={{ marginRight: '5px' }}
                                />
                                <Typography className="p-large-bold neutral-2">Integridad de los datos</Typography>
                            </Tag>
                        </Space>
                    </Space>
                </Space>
            </Col>
        </Row>
    )

    return <StepLayout form={satForm} supportElement={supportElement} />
}

export default SatConnectionStep
