import {
    collection,
    getCountFromServer,
    getDocs,
    getFirestore,
    limit,
    orderBy,
    query,
    startAfter,
    where,
} from 'firebase/firestore'
import { PaymentLink } from '../../../interfaces/paymentsDef'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { SearchParamInURL } from '../../functions/helpers'
import { useEffect, useState } from 'react'
import { useFirestoreCollectionData } from 'reactfire'
import { PaymentLinksColumnsObject } from './ColumnsSettlers'
import { Table } from 'antd'

const PaymentLinksTable = ({
    max: incomingMax = 10,
    search,
    startPeriod,
    endPeriod,
}: {
    max: number
    search?: any
    startPeriod?: number | null
    endPeriod?: number | null
}) => {
    const navigate = useNavigate()
    const { testmode } = useSelector((state: any) => state.data)
    const source = 'paymentLinks'
    let def: PaymentLink
    let defSet = (data: any) => {
        return data as PaymentLink
    }

    //IMPORTS
    const fs = getFirestore()

    // HOOKS
    // const navigate = useNavigate()

    //CONTEXTS
    const { team } = useSelector((state: any) => state.team)

    const ps = SearchParamInURL('ps') ?? 10
    const p = SearchParamInURL('page') ?? 1

    let max = incomingMax

    if (p !== 1) {
        max = Number(ps) * Number(p)
    }

    //STATES
    const [newLoading, setNewLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize, setPageSize] = useState(ps ?? 10)
    const [currentPage, setCurrentPage] = useState(p ?? 1)
    const [items, setItems] = useState<(typeof def)[]>([])
    const [activatePages /*setActivatePages*/] = useState(true)
    // const searchInput = useRef<InputRef>(null)

    // let resetTrigger = false;

    // type DataIndex = keyof typeof def

    // const handleSearch = (
    //     selectedKeys: string[],
    //     confirm: (param?: FilterConfirmProps) => void,
    //     dataIndex: DataIndex
    // ) => {
    //     setActivatePages(resetTrigger || !selectedKeys[0] ? true : false)
    //     confirm()

    //     resetTrigger = false;
    // }

    // const handleReset = (clearFilters: () => void) => {
    //     resetTrigger = true
    //     // setSearchText('')
    //     clearFilters()
    // }

    //VARIABLES
    let initialQuery =
        startPeriod && endPeriod
            ? query(
                  collection(fs, source),
                  where('team', '==', team?.id ?? ''),
                  where('timestamp', '>=', startPeriod),
                  where('timestamp', '<=', endPeriod),
                  orderBy('timestamp', 'desc'),
                  limit(max),
              )
            : query(
                  collection(fs, source),
                  where('team', '==', team?.id ?? ''),
                  orderBy('timestamp', 'desc'),
                  where('livemode', '==', !testmode),
                  limit(max),
              )
    let totalCountQuery =
        startPeriod && endPeriod
            ? query(
                  collection(fs, source),
                  where('team', '==', team?.id ?? ''),
                  where('timestamp', '>=', startPeriod),
                  where('timestamp', '<=', endPeriod),
                  orderBy('timestamp', 'desc'),
                  where('livemode', '==', !testmode),
              )
            : query(
                  collection(fs, source),
                  where('team', '==', team?.id ?? ''),
                  where('livemode', '==', !testmode),
                  orderBy('timestamp', 'desc'),
              )

    //DATA
    const { status: initialLoadStatus, data } = useFirestoreCollectionData(initialQuery, {
        idField: 'fid',
    })

    //FUNCTIONS
    const getInitialCount = async () => {
        setTotalCount((await getCountFromServer(totalCountQuery)).data().count)
    }

    const getNewData = async ({ lastItem: newItem, ps, p }: { lastItem: number; ps: number; p: number }) => {
        if (ps * p < items.length) return
        setNewLoading(true)
        const newDataQuery =
            startPeriod && endPeriod
                ? query(
                      collection(fs, source),
                      where('team', '==', team?.id ?? ''),
                      where('timestamp', '>=', startPeriod),
                      where('timestamp', '<=', endPeriod),
                      orderBy('timestamp', 'desc'),
                      limit(ps * p - items.length + 1),
                      where('livemode', '==', !testmode),
                      startAfter(newItem),
                  )
                : query(
                      collection(fs, source),
                      where('team', '==', team?.id ?? ''),
                      orderBy('timestamp', 'desc'),
                      limit(ps * p - items.length + 1),
                      where('livemode', '==', !testmode),
                      startAfter(newItem),
                  )
        let newItems = await getDocs(newDataQuery)
        setItems([...items, ...(newItems.docs.map((c) => defSet(c.data())) ?? [])])
        setNewLoading(false)
    }

    //EFFECTS
    useEffect(() => {
        getInitialCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, testmode, team?.id])

    return (
        <>
            <Table
                scroll={{ x: 1500 }}
                rowKey={(rec: any) => rec?.id ?? 'as'}
                loading={initialLoadStatus === 'loading' || newLoading}
                pagination={
                    activatePages
                        ? {
                              total: totalCount,
                              defaultPageSize: Number(pageSize),
                              defaultCurrent: Number(currentPage),
                              pageSize: Number(pageSize),
                              current: Number(currentPage),
                              onChange(p, ps) {
                                  setPageSize(ps)
                                  setCurrentPage(p)
                                  let allItems = [...data, ...items]
                                  getNewData({
                                      lastItem: allItems[allItems.length - 1]?.timestamp,
                                      ps,
                                      p,
                                  })
                                  const path = `/payments?page=${p}&ps=${ps}`
                                  navigate(path)
                              },
                          }
                        : {
                              defaultPageSize: Number(pageSize),
                              pageSize: Number(pageSize),
                          }
                }
                columns={PaymentLinksColumnsObject({
                    customKeysToShow: null,
                    navigate: (p: string) => navigate(p),
                }).map((c) => {
                    let column = c
                    if (c.dataIndex === 'status') {
                        column = {
                            ...column,
                            filterMode: 'tree',
                            filterSearch: true,
                            filters: [
                                {
                                    text: 'Activo',
                                    value: 'active',
                                },
                                {
                                    text: 'Inactivo',
                                    value: 'inactive',
                                },
                            ],
                            onFilter: (value: string, record: any) => record.status.includes(value),
                        }
                    }
                    return column
                })}
                dataSource={[...(data ?? [])?.map((d) => defSet(d)), ...(items ?? []).map((d) => defSet(d))]}
            />
        </>
    )
}

export default PaymentLinksTable
