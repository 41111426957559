import { PlusOutlined } from '@ant-design/icons'
import {
    CaretUpDown,
    CheckCircle,
    Eye,
    EyeClosed,
    MagnifyingGlass,
    Plus,
    TrashSimple,
    Upload as UploadIcon,
    UploadSimple,
    User,
    WarningCircle,
    XCircle,
} from '@phosphor-icons/react'
import {
    AutoComplete,
    Button,
    Checkbox,
    Col,
    ColorPicker,
    DatePicker,
    Form,
    Image,
    Input,
    InputNumber,
    message,
    Modal,
    Popover,
    Row,
    Select,
    Tag,
    Typography,
    Upload,
} from 'antd'
import { Color } from 'antd/es/color-picker'
import type { RcFile, UploadProps } from 'antd/es/upload'
import type { UploadFile } from 'antd/es/upload/interface'
import { deleteDoc, doc, getFirestore, setDoc } from 'firebase/firestore'
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { ReactElement, ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { MetroSpinner } from 'react-spinners-kit'
import { useStorage } from 'reactfire'
import { generateCode } from '../../../helpers/helperFunctions'
import { tagsColors } from './TagsColors'

export const SearchAutocomplete = ({
    id,
    value,
    notFoundContent,
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    styleProps,
    onChange,
    options,
    onSelect,
    isLoading,
    onClear,
    popovercontent,
}: {
    id?: string
    name: string
    label?: string | null
    placeholder?: string | undefined
    description?: string
    disabled?: boolean
    rules?: any
    styleProps?: object
    onChange: (value: string) => void
    options: any
    notFoundContent?: ReactElement
    onSelect: (v: any) => void
    value?: any
    isLoading?: boolean
    onClear?: () => void
    popovercontent?: ReactElement
}) => {
    const LoadingIcon = () => {
        return <MetroSpinner size={16} color="#686769" />
    }
    return (
        <Form.Item name={name} label={label} help={description} rules={rules} style={styleProps ?? {}}>
            <AutoComplete
                id={id}
                onClear={onClear}
                allowClear={true}
                defaultValue={value}
                menuItemSelectedIcon={<MagnifyingGlass weight="regular" />}
                notFoundContent={notFoundContent ?? <></>}
                style={{ width: '100%' }}
                onSearch={onChange}
                disabled={disabled}
                options={options}
                onSelect={(value, option) => onSelect(option?.key)}
                //CHANGE THE REACT COMPONENT FOR RESULTS
            >
                <Input
                    prefix={
                        isLoading ? (
                            <LoadingIcon />
                        ) : (
                            <Popover content={popovercontent}>
                                <User size={13} weight="regular" />
                            </Popover>
                        )
                    }
                    placeholder={placeholder}
                />
            </AutoComplete>
        </Form.Item>
    )
}

export const SearchInput = ({
    onChangeCapture,
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    styleProps,
    onChange,
}: {
    onChangeCapture?: any
    name: string
    label?: string | null
    placeholder?: string | undefined
    description?: string
    disabled?: boolean
    rules?: any
    styleProps?: object
    onChange: Function
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules} style={styleProps ?? {}}>
            <Input
                prefix={<MagnifyingGlass weight="regular" className="icon" />}
                onChangeCapture={onChangeCapture}
                disabled={disabled}
                placeholder={placeholder}
                onChange={(v) => onChange(v)}
            />
        </Form.Item>
    )
}

export const PasswordInput = ({
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    prefix,
    suffix,
}: {
    name: string
    label?: string | null
    placeholder?: string | undefined
    description?: string
    disabled?: boolean
    rules?: any
    prefix?: ReactNode
    suffix?: ReactNode
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules} className="ph-no-capture">
            <Input.Password
                className="ph-no-capture"
                suffix={suffix}
                prefix={prefix}
                placeholder={placeholder}
                disabled={disabled}
                iconRender={(visible) =>
                    visible ? (
                        <Eye
                            weight="regular"
                            size={16}
                            style={{
                                cursor: 'pointer',
                                color: 'var(--neutral-1)',
                            }}
                        />
                    ) : (
                        <EyeClosed
                            weight="regular"
                            size={16}
                            style={{
                                cursor: 'pointer',
                                color: 'var(--neutral-1)',
                            }}
                        />
                    )
                }
            />
        </Form.Item>
    )
}

export const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }
    const color = tagsColors[0]
    //random color from colors

    return (
        <Tag
            color={color.bg}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3, color: color.text, marginBottom: '3px' }}
        >
            {label}
        </Tag>
    )
}

interface CustomTagRenderProps {
    label: string
    closable: boolean
    onClose: (event: React.MouseEvent) => void
    color: {
        bg: string
        text: string
    }
}

export const customTagRender = (props: CustomTagRenderProps) => {
    const { label, closable, onClose, color } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }

    return (
        <Tag
            color={color.bg}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3, color: color.text, marginBottom: '3px' }}
        >
            <Typography.Text className="smallparagraphbold">{label}</Typography.Text>
        </Tag>
    )
}

export const ChipsSelect = ({
    id,
    name,
    label,
    placeholder,
    change,
    options,
    description,
    disabled,
    rules,
    empty,
    customTag,
}: {
    id?: string
    name: string
    label?: string | null
    placeholder?: string | undefined
    change: any
    options: any
    description?: string
    disabled?: boolean
    rules?: any
    empty?: ReactNode
    customTag?: (props: CustomTagProps) => ReactElement
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules}>
            <Select
                id={id}
                disabled={disabled}
                mode="tags"
                allowClear={true}
                notFoundContent={empty ?? <></>}
                tagRender={customTag ?? tagRender}
                // tagRender={tagRender}
                //SET CUSTOM COLORS TO tagsColors

                style={{ width: '100%' }}
                placeholder={placeholder}
                onChange={change}
                options={options}
            />
        </Form.Item>
    )
}

export const FileUpload = ({
    name,
    placeholder,
    label,
    icon,
    storagePath,
    firestorePath,
    showUploadList = true,
    disabled,
    firestoreKey,
    firestorePreviousData,
    previousDataKey,
    onURLUploaded,
    onFileUploaded,
    allowedFiles,
    rules,
    hardKey,
    incomingFiles,
    inputType = 'default',
    description,
}: {
    name: string
    placeholder?: string
    label?: string
    icon?: ReactElement
    storagePath: string
    firestorePath?: string
    showUploadList?: boolean
    disabled?: boolean
    firestoreKey?: string
    firestorePreviousData?: any
    previousDataKey?: string
    onURLUploaded?: (url: string) => void
    onFileUploaded?: (file: any) => void
    allowedFiles?: string[]
    rules?: any[]
    hardKey?: string
    incomingFiles?: any[]
    inputType?: 'default' | 'box'
    description?: string
}) => {
    const { team } = useSelector((state: any) => state.team)
    const [files, setFiles] = useState<any>([...(incomingFiles ?? [])])
    const storage = useStorage()
    const [elementSuccess, setElementSuccess] = useState(false)
    const [currentProgress, setCurrentProgress] = useState(0)

    const [messageApi, contextHolder] = message.useMessage()

    const onChange = ({ file, onSuccess, onError, onProgress }: any) => {
        const fileName = file.name
        const id = hardKey ?? generateCode(10, 'file')
        var composedFile = {
            ...file,
            name: fileName,
            id: id,
            storageRef: storagePath ?? `teams/${team.id}/files/` + id,
        }
        setFiles([...files, composedFile])

        console.log('UPLOADING TO', storagePath ?? `teams/${team.id}/files/` + id)

        const newRef = ref(storage, storagePath ?? `teams/${team.id}/files/` + id)
        // setRef(newRef);

        const uploadTask = uploadBytesResumable(newRef, file)

        uploadTask.on(
            'state_changed',
            (snapshot: any) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                onProgress({ percent: progress })
                setCurrentProgress(progress)
                setFiles((files: any) => {
                    return files.map((file: any) => {
                        if (file.uid === composedFile.uid) {
                            return {
                                ...file,
                                status: 'uploading',
                                percent: progress,
                            }
                        }
                        return file
                    })
                })
            },
            (error: any) => {
                console.log(error)

                messageApi.open({
                    type: 'error',
                    content: `Ocurrió un error al subir el archivo: ${error?.message}`,
                    className: 'custom-class',
                    duration: 3,
                    style: {
                        marginTop: '5vh',
                    },
                })
                setFiles((files: any) => {
                    return files.map((file: any) => {
                        if (file.uid === composedFile.uid) {
                            return {
                                ...file,
                                status: 'error',
                            }
                        }
                        return file
                    })
                })
                onError(error)
                setElementSuccess(false)
            },
            () => {
                onSuccess(null, file)
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    var composedDoc = { ...file }
                    if (firestorePath) {
                        composedDoc = {
                            ...(firestorePreviousData ?? {}),
                            [firestoreKey ?? 'url']: url,
                        }
                        if (previousDataKey) {
                            composedDoc[previousDataKey] = {
                                ...(firestorePreviousData ?? {})[previousDataKey],
                                [firestoreKey ?? 'url']: url,
                            }
                        }
                        const finalPath = firestoreKey ? `${firestorePath}` : `${firestorePath}/${id}`
                        console.log('SETTING ON DB TO', finalPath)

                        setDoc(doc(getFirestore(), finalPath), composedDoc, { merge: true })
                        if (onURLUploaded) {
                            onURLUploaded(url)
                        }
                    }
                    if (onFileUploaded) {
                        onFileUploaded({ ...composedFile, url: url })
                    }
                    setFiles((files: any) => {
                        return files.map((file: any) => {
                            if (file.uid === composedFile.uid) {
                                return {
                                    ...file,
                                    status: 'done',
                                    percent: 100,
                                    url: url,
                                }
                            }
                            return file
                        })
                    })
                    setElementSuccess(true)
                })
            },
        )
    }

    var placeHolderFiledName = files.length > 0 ? files[0].name : placeholder
    return (
        <div style={{ maxWidth: '100%', cursor: 'pointer' }}>
            <Form.Item
                name={name}
                label={label}
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                rules={rules}
                help={description}
            >
                {contextHolder}
                <Upload
                    style={{ width: '100%', height: '100%' }}
                    customRequest={onChange}
                    className="customUpload"
                    accept={allowedFiles?.join(',')}
                    disabled={disabled}
                    fileList={files}
                    beforeUpload={(file, fileList) => {
                        if (files.length > 0) {
                            messageApi.open({
                                type: 'warning',
                                content: `No puedes seleccionar más de un archivo.`,
                                className: 'custom-class',
                                duration: 3,
                                style: {
                                    marginTop: '5vh',
                                },
                            })

                            return false
                        }

                        return true
                    }}
                    multiple={false}
                    listType="text"
                    showUploadList={showUploadList}
                    onRemove={async (file) => {
                        if (files.length === 0) return
                        if (!files[0]?.storageRef) return
                        try {
                            await deleteObject(ref(storage, files[0]?.storageRef))
                            await deleteDoc(doc(getFirestore(), firestorePath ?? ''))
                        } catch (error: any) {}
                        setFiles([])
                    }}
                >
                    {inputType === 'default' && (
                        <Row
                            align="middle"
                            style={{
                                width: '100%',
                                height: '100%',
                                minHeight: '40px',
                                border: '1px solid var(--Neutral-Extended-Borders, #D8DAE5)',
                                borderRadius: '5px',
                            }}
                        >
                            <Typography.Text
                                className="p-base-regular"
                                style={{ paddingLeft: '5px', flex: 1, color: 'lightgray' }}
                            >
                                {placeHolderFiledName}
                            </Typography.Text>

                            <Row
                                align="middle"
                                className="rounded-md"
                                style={{
                                    borderLeft: '0px solid var(--Neutral-Extended-Borders, #D8DAE5)',
                                    borderTopLeftRadius: '3px',
                                    borderBottomLeftRadius: '3px',
                                    backgroundColor: 'var(--neutral-5)',
                                    height: '100%',
                                    minHeight: '40px',
                                    padding: '0px 5px',
                                }}
                            >
                                <Typography.Text className="p-base-semi-bold neutral-1" style={{ paddingRight: '5px' }}>
                                    {files.length > 0 ? 'Seleccionado' : 'Subir'}
                                </Typography.Text>
                                {currentProgress > 0 && currentProgress < 100 ? (
                                    <Typography.Text className="p-base-bold neutral-1">
                                        {currentProgress}%
                                    </Typography.Text>
                                ) : files.length > 0 ? (
                                    elementSuccess ? (
                                        <CheckCircle className="icon" />
                                    ) : (
                                        <WarningCircle className="icon" />
                                    )
                                ) : (
                                    <UploadSimple className="icon" />
                                )}
                            </Row>
                        </Row>
                    )}

                    {inputType === 'box' && (
                        <div
                            className="d-flex flex-column d-center clickable"
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'var(--neutral-5)',
                                borderRadius: '5px',
                                padding: '50px',
                            }}
                        >
                            <UploadIcon />
                            <Typography.Text>{placeHolderFiledName}</Typography.Text>
                        </div>
                    )}
                </Upload>
            </Form.Item>
        </div>
    )
}

export function LogoUploader({
    file,
    setFile,
    imageUrl,
    setImageUrl,
    disabled,
}: {
    file: File | null
    setFile: (file: File | null) => void
    imageUrl: string | null
    setImageUrl: (url: string | null) => void
    disabled?: boolean
}) {
    const handleChange = (info: any) => {
        const file = info.file.originFileObj
        setFile(file)
        getBase64(file, (url: string) => {
            setImageUrl(url)
        })
    }

    const getBase64 = (img: File, callback: (url: string) => void) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result as string))
        reader.readAsDataURL(img)
    }

    const beforeUpload = (file: File) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
            message.error('Solamente puedes subir imágenes en formato jpeg o png')
        }
        return isJpgOrPng
    }

    const handleDelete = () => {
        setImageUrl(null)
        setFile(null)
    }

    const uploadButton = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100px',
                height: '100px',
            }}
        >
            <PlusOutlined />
            <div className="p-small-regular" style={{ marginTop: '8px' }}>
                Cargar
            </div>
        </div>
    )

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
            <div style={{ position: 'relative' }}>
                <Upload
                    name="avatar"
                    listType="picture-circle"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    disabled={disabled}
                    style={{ width: '100px', height: '100px' }}
                >
                    {imageUrl ? (
                        <Image
                            src={imageUrl}
                            alt="logo"
                            width={100}
                            height={100}
                            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
                            preview={{
                                mask: <div className="white p-xsmall-regular">Vista previa</div>,
                            }}
                        />
                    ) : (
                        uploadButton
                    )}
                </Upload>
                {imageUrl && (
                    <button
                        onClick={handleDelete}
                        aria-label="Delete image"
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: '4px',
                            borderRadius: '9999px',
                            border: 'none',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <TrashSimple size={15} />
                    </button>
                )}
            </div>
        </div>
    )
}

export const TextAreaInput = ({
    id,
    name,
    ref,
    label,
    placeholder,
    description,
    disabled,
    rules,
    propsStyle,
    minRows,
    maxRows,
    maxLength,
    showCount,
    onFocus,
    defaultValue,
}: {
    id?: string
    name: string
    ref?: React.RefObject<any>
    label?: string | null
    placeholder?: string | undefined
    description?: string
    disabled?: boolean
    rules?: any
    propsStyle?: React.CSSProperties
    minRows?: number
    maxRows?: number
    maxLength?: number
    showCount?: boolean
    onFocus?: any
    defaultValue?: string
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules} style={propsStyle ? propsStyle : {}}>
            <Input.TextArea
                id={id}
                onFocus={onFocus ? onFocus : () => {}}
                showCount={showCount ?? false}
                ref={ref}
                disabled={disabled}
                maxLength={maxLength ?? 1000000} //lines
                autoSize={{ minRows: minRows ?? 3, maxRows: maxRows ?? 6 }}
                placeholder={placeholder}
                defaultValue={defaultValue}
            />
        </Form.Item>
    )
}

export const TextInput = ({
    id,
    initialValue,
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    onChange,
    prefix,
    suffix,
    onInput,
    readOnly,
    defaultValue,
    customStyle,
    onBlur,
    type,
}: {
    id?: string
    initialValue?: any
    name: string
    label?: string | null
    placeholder?: string | undefined
    description?: string
    disabled?: boolean
    rules?: any
    onChange?: any
    prefix?: ReactNode
    suffix?: ReactNode
    onInput?: any
    readOnly?: boolean
    defaultValue?: any
    customStyle?: React.CSSProperties
    onBlur?: any
    type?: string
}) => {
    return (
        <Form.Item
            initialValue={initialValue}
            name={name}
            label={label}
            help={description}
            rules={rules}
            style={
                customStyle
                    ? customStyle
                    : {
                          marginBottom: description ? '20px' : '10px',
                      }
            }
        >
            <Input
                id={id}
                type={type ?? 'text'}
                onBlur={onBlur ? onBlur : () => {}}
                defaultValue={defaultValue}
                readOnly={readOnly}
                prefix={prefix}
                suffix={suffix}
                disabled={disabled}
                onInput={onInput ? onInput : () => {}}
                onChange={onChange ? onChange : () => {}}
                placeholder={placeholder}
            />
        </Form.Item>
    )
}

export const ColorInput = ({
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    showText = true,
    onChange,
}: {
    name: string
    label?: string | null
    placeholder?: string | undefined
    description?: string
    disabled?: boolean
    rules?: any
    showText?: boolean
    onChange?: (value: Color, css: string) => void
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules}>
            <ColorPicker disabled={disabled} showText={showText} onChange={onChange} />
        </Form.Item>
    )
}
interface UploadLogoProps {
    logo?: string // Prop para el logo
}

export const UploadLogo: React.FC<UploadLogoProps> = ({ logo }) => {
    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result as string)
            reader.onerror = (error) => reject(error)
        })
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [fileList, setFileList] = useState<UploadFile[]>([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: logo,
        },
    ])

    const handleCancel = () => setPreviewOpen(false)

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile)
        }

        setPreviewImage(file.url || (file.preview as string))
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    }

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList)

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )
    return (
        <>
            <Upload listType="picture-card" fileList={fileList} onPreview={handlePreview} onChange={handleChange}>
                {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    )
}

export const SelectInput = ({
    id,
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    showText,
    options,
    onChange,
    defaultValue,
    mode,
    customStyle,
    initialValues,
    optionRender,
    labelRender,
    dropdownRender,
    value,
    onBlur,
    autoFocus,
}: {
    id?: string
    name: string
    label?: string
    placeholder?: string
    description?: string
    disabled?: boolean
    rules?: any[]
    showText?: boolean
    options?: any[]
    onChange?: any
    defaultValue?: any
    mode?: 'multiple' | 'tags' | undefined
    customStyle?: React.CSSProperties
    initialValues?: Object
    optionRender?: any
    labelRender?: any
    dropdownRender?: any
    value?: any
    onBlur?: any
    autoFocus?: boolean
}) => {
    return (
        <Form.Item
            name={name}
            label={label}
            help={description}
            rules={rules}
            style={customStyle}
            initialValue={initialValues}
        >
            <Select
                id={id}
                autoFocus={autoFocus}
                onBlur={onBlur ? onBlur : () => {}}
                value={value}
                suffixIcon={<CaretUpDown size={16} className="neutral-1" style={{ paddingLeft: '5px' }} />}
                options={options}
                mode={mode}
                disabled={disabled}
                style={{ marginBottom: description ? '5px' : '0px', paddingRight: '5px' }}
                placeholder={placeholder}
                showSearch
                filterOption={(input: string, option?: { label: string; value: string }) =>
                    (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())
                }
                onChange={onChange ? onChange : () => {}}
                defaultValue={defaultValue}
                optionRender={optionRender ?? undefined}
                labelRender={labelRender ?? undefined}
                dropdownRender={dropdownRender ?? undefined}
            />
        </Form.Item>
    )
}

export const CheckBoxInput = ({
    id,
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    showText,
    content,
    checked,
    onChange,
    propsStyle,
}: {
    id?: string
    name: string
    label?: string
    placeholder?: string
    description?: string
    disabled?: boolean
    rules?: any[]
    showText?: boolean
    content?: ReactElement
    checked?: boolean
    onChange?: any
    propsStyle?: React.CSSProperties
}) => {
    return (
        <Form.Item
            id={id}
            name={name}
            label={label}
            valuePropName="checked"
            help={description}
            rules={rules}
            style={propsStyle}
        >
            <Checkbox onChange={onChange}>{content}</Checkbox>
        </Form.Item>
    )
}

export const NumberInput = ({
    id,
    initialValue,
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    showText,
    min,
    max,
    step,
    onChange,
    propsStyle,
    defaultValue,
    onBlur,
    value,
}: {
    id?: string
    initialValue?: any
    name: string
    label?: string
    placeholder?: string
    description?: string
    disabled?: boolean
    rules?: any[]
    showText?: boolean
    min: number
    max: number
    step: number
    onChange?: Function
    onBlur?: Function
    propsStyle?: React.CSSProperties
    defaultValue?: any
    value?: any
}) => {
    return (
        <Form.Item
            initialValue={initialValue}
            name={name}
            label={label}
            help={description}
            rules={rules}
            style={propsStyle}
        >
            <InputNumber
                id={id}
                value={value}
                style={{ width: '100%', height: '40px' }}
                min={min}
                max={max}
                step={step}
                placeholder={placeholder}
                onChange={onChange ? (v) => onChange(v) : undefined}
                defaultValue={defaultValue}
                disabled={disabled}
                changeOnWheel={false}
                onBlur={onBlur ? (v) => onBlur(v) : undefined}
            />
        </Form.Item>
    )
}

export const MetadataListInput = ({
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    showText,
    customAddButton,
    initialValue,
    onChange,
}: {
    name: string
    label?: string
    placeholder?: string
    description?: string
    disabled?: boolean
    rules?: any[]
    showText?: boolean
    customAddButton?: ReactElement
    initialValue?: any[]
    onChange?: (v: any) => void
}) => {
    return (
        <Form.List name={name} initialValue={initialValue}>
            {(fields: any[] | undefined = initialValue, { add, remove }) => (
                <div className="d-flex flex-column" style={{ width: '100%', padding: '0px 5px' }}>
                    {label && <label>{label}</label>}
                    {fields && fields.length > 0 && (
                        <Row>
                            <Col xs={24} lg={12}>
                                <label>Key</label>
                            </Col>
                            <Col xs={24} lg={12}>
                                <label>Valor</label>
                            </Col>
                        </Row>
                    )}
                    {fields?.map(({ key, name, ...restField }) => (
                        <Row
                            key={key}
                            style={{ width: '100%', padding: '0px 0px', marginTop: '5px' }}
                            align="middle"
                            justify="space-between"
                            gutter={{ xs: 10 }}
                        >
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingrese la llave o elimine el campo en metadata',
                                        },
                                    ]}
                                    noStyle
                                    name={[name, 'key']}
                                >
                                    <Input placeholder="Llave" disabled={disabled} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={11}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingrese el valor o elimine el campo en metadata',
                                        },
                                    ]}
                                    noStyle
                                    name={[name, 'value']}
                                >
                                    <Input placeholder="Valor" disabled={disabled} />
                                </Form.Item>
                            </Col>
                            <XCircle
                                style={{ marginLeft: '10px' }}
                                weight="regular"
                                className="icon clickable"
                                onClick={() => {
                                    remove(name)
                                    if (onChange) onChange(name)
                                }}
                            />
                        </Row>
                    ))}
                    <Button
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '15px',
                            marginBottom: '15px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#474D66',
                        }}
                        icon={<Plus size={15} />}
                        onClick={() => {
                            add()
                            onChange && onChange(fields)
                        }}
                    >
                        Agregar {fields && fields.length > 0 ? 'otro' : 'un'} valor
                    </Button>
                </div>
            )}
        </Form.List>
    )
}

// Dates

export const DateInput = ({
    id,
    name,
    label,
    placeholder,
    description,
    disabled,
    rules,
    showText,
    onChange,
    showTime,
    propsStyle,
    defaultValue,
    disabledDate,
    disabledTime,
}: {
    id?: string
    name: string
    label?: string
    placeholder?: string
    description?: string
    disabled?: boolean
    rules?: any[]
    showText?: boolean
    onChange?: Function
    propsStyle?: React.CSSProperties
    defaultValue?: any
    disabledDate?: any
    showTime?: boolean
    disabledTime?: any
}) => {
    return (
        <Form.Item name={name} label={label} help={description} rules={rules} style={propsStyle}>
            {/* <InputNumber style={{ width: '100%' }} placeholder={placeholder} onChange={onChange ? (v) => onChange(v) : undefined}
            defaultValue={defaultValue} disabled={disabled} /> */}
            <DatePicker
                id={id}
                style={{ width: '100%' }}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                defaultValue={defaultValue}
                showTime={showTime}
                format={showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'}
            />
        </Form.Item>
    )
}
