import { Button, Row, Typography } from 'antd'
import { Warning } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'

interface LimitReachedBannerProps {
    limit: number
    current: number
    testMode?: boolean
}

export default function LimitReachedBanner({ limit, current, testMode = false }: LimitReachedBannerProps) {
    const navigate = useNavigate()

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                top: testMode ? '32px' : 0,
                minHeight: '32px',
                width: '100%',
                backgroundColor: 'var(--yellow-2)',
                padding: '2px 16px',
                zIndex: 1999, // Un nivel menos que el TestModeBanner
            }}
        >
            <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                <Row align="middle">
                    <Warning size={16} weight="fill" className="yellow-8" style={{ marginRight: '8px' }} />
                    <Typography.Text className="p-small-bold yellow-8">
                        Has alcanzado el límite de {limit} clientes, las automatizaciones podrían pausarse en cualquier momento.
                    </Typography.Text>
                </Row>
                <Row align="middle" style={{ gap: '8px' }}>
                    <Typography.Text className="p-small-regular yellow-8">
                        Tienes {current} clientes registrados
                    </Typography.Text>
                    <Button 
                        type="primary"
                        size="small"
                        style={{
                            marginRight: '24px',
                        }}
                        onClick={() => navigate('/memberships')}
                    >
                        Aumenta tu límite
                    </Button>
                </Row>
            </Row>
        </div>
    )
} 