import { useSelector } from 'react-redux'

export interface PermissionResponse {
    hasPermission: boolean
    reason?: string
    restrictionType?: 'PLAN' | 'USER_PERMISSION' | 'LIMIT'
}

export interface PermissionsDef {
    canCreateInvoice: PermissionResponse
    canCreateReceipt: PermissionResponse
    canCreatePayment: PermissionResponse
    canCreateService: PermissionResponse
    canCreateClient: PermissionResponse
    canCreateCustomerPortal: PermissionResponse
    canCreateVendor: PermissionResponse
    canViewPayments: PermissionResponse
    canViewInvoices: PermissionResponse
    canViewReceipts: PermissionResponse
    canViewServices: PermissionResponse
    canViewClients: PermissionResponse
    canViewVendors: PermissionResponse
    canCreateTeam: PermissionResponse
    canCreateWebhooks: PermissionResponse
    canCreateApiKeys: PermissionResponse
    canInstallApps: PermissionResponse
    isTeamAdmin: PermissionResponse
    isBillingAccountAdmin: PermissionResponse
}

const createDefaultPermission = (
    hasPermission: boolean = false,
    reason?: string,
    restrictionType?: 'PLAN' | 'USER_PERMISSION' | 'LIMIT',
): PermissionResponse => ({
    hasPermission,
    reason,
    restrictionType,
})

export const usePermissions = () => {
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { clients } = useSelector((state: any) => state.data)

    const permissions: PermissionsDef = {
        canCreateInvoice: createDefaultPermission(),
        canCreateReceipt: createDefaultPermission(),
        canCreatePayment: createDefaultPermission(),
        canCreateService: createDefaultPermission(),
        canCreateClient: createDefaultPermission(),
        canCreateCustomerPortal: createDefaultPermission(),
        canCreateVendor: createDefaultPermission(),
        canViewPayments: createDefaultPermission(),
        canViewInvoices: createDefaultPermission(),
        canViewReceipts: createDefaultPermission(),
        canViewServices: createDefaultPermission(),
        canViewClients: createDefaultPermission(),
        canViewVendors: createDefaultPermission(),
        canCreateTeam: createDefaultPermission(),
        canCreateWebhooks: createDefaultPermission(),
        canCreateApiKeys: createDefaultPermission(),
        canInstallApps: createDefaultPermission(),
        isTeamAdmin: createDefaultPermission(),
        isBillingAccountAdmin: createDefaultPermission(),
    }

    const member = team?.members?.find((m: any) => m.id === user?.uid)

    if (!member) {
        return Object.keys(permissions).reduce(
            (acc, key) => ({
                ...acc,
                [key]: createDefaultPermission(false, 'No eres miembro del equipo', 'USER_PERMISSION'),
            }),
            permissions,
        )
    }

    const isV2Plan = billingAccount?.plan?.pricingVersion === 'v2'
    const planFeatures = isV2Plan ? billingAccount?.plan?.features : {}

    const checkNumericLimit = (current: number, limit: string | number): PermissionResponse => {
        if (limit === 'unlimited') return createDefaultPermission(true)
        const numericLimit = Number(limit)
        return createDefaultPermission(
            current < numericLimit,
            current >= numericLimit
                ? `Has alcanzado el límite máximo de ${limit} registros, por favor actualiza tu plan`
                : undefined,
            'LIMIT',
        )
    }

    const clientsLimit = isV2Plan
        ? checkNumericLimit(clients?.length || 0, planFeatures?.maxClients || 'unlimited')
        : createDefaultPermission(true)

    const countActiveIntegrations = (team: any): number => {
        const integrationKeys = [
            'stripe',
            'paypal',
            'clip',
            'openpay',
            'zapier',
            'airtable',
            'shopify',
            'whcms',
        ]

        return integrationKeys.reduce((count, key) => {
            return count + (team?.[key]?.completed ? 1 : 0)
        }, 0)
    }

    const integrationsLimit = isV2Plan
        ? planFeatures?.maxIntegrations === 0
            ? createDefaultPermission(false, 'Tu plan no incluye integraciones, por favor actualiza tu plan', 'LIMIT')
            : checkNumericLimit(countActiveIntegrations(team), planFeatures?.maxIntegrations || 'unlimited')
        : createDefaultPermission(true)

    if (member.rol === 'admin' || member.rol === 'editor') {
        const basePermissions = {
            canCreateInvoice: true,
            canCreateReceipt: true,
            canCreatePayment: true,
            canCreateService: true,
            canViewPayments: true,
            canViewInvoices: true,
            canViewReceipts: true,
            canViewServices: true,
            isTeamAdmin: true,
        }

        Object.entries(basePermissions).forEach(([key, value]) => {
            permissions[key as keyof PermissionsDef] = createDefaultPermission(value)
        })

        if (isV2Plan) {
            if (!planFeatures?.apiAndWebhooks) {
                permissions.canCreateWebhooks = createDefaultPermission(
                    false,
                    'El plan no incluye API y webhooks',
                    'PLAN',
                )
                permissions.canCreateApiKeys = createDefaultPermission(
                    false,
                    'El plan no incluye API y webhooks',
                    'PLAN',
                )
            } else {
                permissions.canCreateWebhooks = createDefaultPermission(true)
                permissions.canCreateApiKeys = createDefaultPermission(true)
            }

            permissions.canInstallApps = integrationsLimit

            permissions.canCreateClient = clientsLimit.hasPermission
                ? createDefaultPermission(true)
                : createDefaultPermission(false, clientsLimit.reason, 'LIMIT')
            if (planFeatures?.customerPortal === false) {
                permissions.canCreateCustomerPortal = createDefaultPermission(
                    false,
                    'El plan no incluye el portal de clientes',
                )
            } else {
                permissions.canCreateCustomerPortal = createDefaultPermission(true)
            }

            if (planFeatures?.supplierMode === false) {
                permissions.canCreateVendor = createDefaultPermission(false, 'El plan no incluye el modo de proveedor')
                permissions.canViewVendors = createDefaultPermission(false, 'El plan no incluye el modo de proveedor')
            } else {
                permissions.canCreateVendor = createDefaultPermission(true)
                permissions.canViewVendors = createDefaultPermission(true)
            }
        } else {
            permissions.canCreateClient = createDefaultPermission(true)
            permissions.canCreateCustomerPortal = createDefaultPermission(true)
            permissions.canInstallApps = createDefaultPermission(true)
            permissions.canCreateApiKeys = createDefaultPermission(true)
            permissions.canCreateWebhooks = createDefaultPermission(true)
        }
    }

    switch (member.invoices) {
        case 'editor':
            permissions.canCreateInvoice = createDefaultPermission(true)
            permissions.canViewInvoices = createDefaultPermission(true)
            break
        case 'viewer':
            permissions.canCreateInvoice = createDefaultPermission(false, 'No tienes permisos para crear facturas')
            permissions.canViewInvoices = createDefaultPermission(true)
            break
        default:
            permissions.canCreateInvoice = createDefaultPermission(false, 'No tienes permisos para crear facturas')
            permissions.canViewInvoices = createDefaultPermission(false, 'No tienes permisos para crear facturas')
    }

    switch (member.payments) {
        case 'editor':
            permissions.canCreatePayment = createDefaultPermission(true)
            permissions.canViewPayments = createDefaultPermission(true)
            break
        case 'viewer':
            permissions.canCreatePayment = createDefaultPermission(false, 'No tienes permisos para crear pagos')
            permissions.canViewPayments = createDefaultPermission(true)
            break
        default:
            permissions.canCreatePayment = createDefaultPermission(false, 'No tienes permisos para crear pagos')
            permissions.canViewPayments = createDefaultPermission(false, 'No tienes permisos para crear pagos')
    }

    switch (member.receipts) {
        case 'editor':
            permissions.canCreateReceipt = createDefaultPermission(true)
            permissions.canViewReceipts = createDefaultPermission(true)
            break
        case 'viewer':
            permissions.canCreateReceipt = createDefaultPermission(true, 'No tienes permisos para crear recibos')
            permissions.canViewReceipts = createDefaultPermission(true)
            break
        default:
            permissions.canCreateReceipt = createDefaultPermission(true, 'No tienes permisos para crear recibos')
            permissions.canViewReceipts = createDefaultPermission(true, 'No tienes permisos para crear recibos')
    }

    switch (member.services) {
        case 'editor':
            permissions.canCreateService = createDefaultPermission(true)
            permissions.canViewServices = createDefaultPermission(true)
            break
        case 'viewer':
            permissions.canCreateService = createDefaultPermission(false, 'No tienes permisos para crear servicios')
            permissions.canViewServices = createDefaultPermission(true)
            break
        default:
            permissions.canCreateService = createDefaultPermission(false, 'No tienes permisos para crear servicios')
            permissions.canViewServices = createDefaultPermission(false, 'No tienes permisos para crear servicios')
    }

    if (billingAccount?.admins.includes(user?.uid)) {
        permissions.canCreateTeam = createDefaultPermission(true)
        permissions.isBillingAccountAdmin = createDefaultPermission(true)
    }

    return permissions
}
