import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Row, Select, Space, Popover, Checkbox, Skeleton, Typography, Tag } from 'antd'
import type { InputRef } from 'antd'
import { MagnifyingGlass, FunnelSimple, CaretUpDown, Lightbulb, Command, ArrowFatUp } from '@phosphor-icons/react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { SearchIndexComponent } from './IndexComponent'
import { setSearchResult, setSearchConfig, clearSearch } from '../context/searchSlice'
import { TeamDef } from '../../interfaces/teamDef'
import { usePostHog } from 'posthog-js/react'
import { closeModal, openModal } from '../context/modalsSlice'
import { trackEvent } from '../analytics/helpers'
import { useTranslation } from 'react-i18next'

interface SearchProps {
    indexName?: string
    teamId?: string
    type?: string
}

export type CollectionType = 'invoices' | 'clients' | 'payments' | 'receipts' | 'product_keys' | 'unit_keys'

const searchCollections: { value: CollectionType; label: string }[] = [
    { value: 'invoices', label: 'Facturas' },
    { value: 'clients', label: 'Clientes' },
    { value: 'payments', label: 'Pagos' },
    { value: 'receipts', label: 'Recibos' },
]

const collectionFields: Record<CollectionType, { value: string; label: string }[]> = {
    invoices: [
        { value: 'uuid', label: 'UUID' },
        { value: 'objectId', label: 'ID del objeto' },
        { value: 'metadata.orderId', label: 'ID de la orden' },
        { value: 'client.name', label: 'Nombre' },
        { value: 'client.email', label: 'Email' },
        { value: 'client.legal_name', label: 'Nombre legal' },
        { value: 'client.rfc', label: 'RFC' },
        { value: 'folio_number', label: 'Número de folio' },
        { value: 'series', label: 'Serie' },
        { value: 'metadata.orderId,metadata.idIntelisis', label: 'ID de la orden' },
        { value: 'metadata.internalID,metadata.internalID', label: 'ID interno' },
    ],
    receipts: [
        { value: 'client.name', label: 'Nombre del cliente' },
        { value: 'client.email', label: 'Email' },
        { value: 'client.legal_name', label: 'Nombre legal' },
        { value: 'client.rfc', label: 'RFC' },
        { value: 'objectId', label: 'ID del objeto' },
        { value: 'metadata.orderId,metadata.idIntelisis', label: 'ID de la orden' },
        { value: 'metadata.internalID,metadata.internalID', label: 'ID interno' },
    ],
    clients: [
        { value: 'name', label: 'Nombre' },
        { value: 'email', label: 'Email' },
        { value: 'rfc', label: 'RFC' },
        { value: 'legal_name', label: 'Nombre legal' },
        { value: 'company', label: 'Compañía' },
    ],
    payments: [
        { value: 'fid', label: 'ID' },
        { value: 'client.name', label: 'Nombre del cliente' },
        { value: 'client.email', label: 'Email' },
        { value: 'whmcsInvoiceId', label: 'ID de WHMCS' },
        { value: 'client.legal_name', label: 'Nombre legal' },
        { value: 'client.rfc', label: 'RFC' },
        { value: 'charge', label: 'ID del cargo' },
        { value: 'metadata.orderId,metadata.idIntelisis', label: 'ID de la orden' },
        { value: 'metadata.internalID,metadata.internalID', label: 'ID interno' },
    ],
    product_keys: [
        { value: 'code', label: 'Código' },
        { value: 'description', label: 'Descripción' },
        { value: 'type', label: 'Tipo' },
        { value: 'division', label: 'División' },
        { value: 'group', label: 'Grupo' },
    ],
    unit_keys: [
        { value: 'unit', label: 'Unidad' },
        { value: 'name', label: 'Nombre' },
    ],
}

export const getSearchData = async (params: {
    collection: CollectionType
    query: string
    fields?: string[]
    testmode?: boolean
    team: TeamDef
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const { collection, query, fields, testmode, team, setLoading } = params
    setLoading(true)
    const searchFunction = httpsCallable(getFunctions(), 'searchapp')
    const response = await searchFunction({
        query,
        collection,
        fields,
        apiKey: team?.typesense?.keys?.live_key,
        testmode,
    })
    setLoading(false)
    return response.data
}

// Update keyboard shortcut constants
const KEYBOARD_SHORTCUTS = {
    FOCUS_SEARCH: 'f',
    CLEAR_SEARCH: 'Escape',
    TOGGLE_FILTERS_UP: 'ArrowUp',
    TOGGLE_FILTERS_DOWN: 'ArrowDown',
    INVOICES: 'I',
    CLIENTS: 'C',
    PAYMENTS: 'P',
    RECEIPTS: 'R',
} as const

const ComponentSearch: React.FC<SearchProps> = () => {
    const dispatch = useDispatch()
    const posthog = usePostHog()
    const { t } = useTranslation()

    const { user } = useSelector((state: any) => state.user)
    const { team } = useSelector((state: any) => state.team)
    const { testmode } = useSelector((state: any) => state.data)
    const { searchResultContainerVisible } = useSelector((state: any) => state.modals)

    const [query, setQuery] = useState('')
    const [activeCollection, setActiveCollection] = useState<CollectionType>('clients')
    const [selectedFields, setSelectedFields] = useState<string[]>(
        collectionFields['clients'].map((field) => field.value),
    )
    const [loading, setLoading] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [isSearchFocused, setIsSearchFocused] = useState(false)

    const searchContainerRef = useRef<HTMLDivElement>(null)
    const searchInputRef = useRef<InputRef>(null)
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    // Initialize collection based on URL
    useEffect(() => {
        const currentPath = window.location.pathname
        let initialCollection: CollectionType = 'clients' // default

        if (currentPath.includes('payments')) {
            initialCollection = 'payments'
        } else if (currentPath.includes('receipts')) {
            initialCollection = 'receipts'
        } else if (currentPath.includes('invoices')) {
            initialCollection = 'invoices'
        }

        setActiveCollection(initialCollection)
        setSelectedFields(collectionFields[initialCollection].map((field) => field.value))
    }, [])

    // Existing team/user effect
    useEffect(() => {
        if (!activeCollection) {
            setActiveCollection('clients')
            setSelectedFields(collectionFields['clients'].map((field) => field.value))
        }
    }, [user, team, activeCollection])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
                dispatch(closeModal('searchResultContainerVisible'))
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [dispatch])

    const handleSearch = useCallback(
        async (searchQuery: string) => {
            if (searchQuery.length < 1) return

            // If no fields are selected, select all fields for the active collection
            if (selectedFields.length === 0) {
                setSelectedFields(collectionFields[activeCollection].map((field) => field.value))
            }

            dispatch(openModal('searchResultContainerVisible'))
            setLoading(true)
            try {
                const data = await getSearchData({
                    query: searchQuery,
                    collection: activeCollection,
                    fields:
                        selectedFields.length > 0
                            ? selectedFields
                            : collectionFields[activeCollection].map((field) => field.value),
                    testmode,
                    team,
                    setLoading,
                })
                trackEvent(
                    {
                        name: 'search_performed',
                        metadata: { query: searchQuery, collection: activeCollection, fields: selectedFields },
                    },
                    posthog,
                )
                dispatch(setSearchResult(data))
                dispatch(
                    setSearchConfig({
                        type: activeCollection,
                        query: searchQuery,
                        fields: selectedFields,
                    }),
                )
            } catch (error) {
                console.error('Search error:', error)
            } finally {
                setLoading(false)
            }
        },
        [activeCollection, selectedFields, testmode, team, dispatch, posthog],
    )

    const debouncedSearch = useCallback(
        (searchQuery: string) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            timeoutRef.current = setTimeout(() => {
                handleSearch(searchQuery).catch(console.error)
            }, 300)
        },
        [handleSearch],
    )

    useEffect(() => {
        if (query) {
            debouncedSearch(query)
        }
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [query, debouncedSearch])

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === '') {
                dispatch(clearSearch())
                dispatch(closeModal('searchResultContainerVisible'))
            }
            setQuery(e.target.value)
        },
        [dispatch],
    )

    const handleCollectionChange = useCallback(
        async (value: CollectionType) => {
            setActiveCollection(value)
            // When changing collection, always select all fields for the new collection
            const allFields = collectionFields[value].map((field) => field.value)
            setSelectedFields(allFields)
            if (query) await handleSearch(query)
        },
        [query, handleSearch],
    )

    const handleFieldsChange = useCallback(
        async (checkedValues: string[]) => {
            setSelectedFields(checkedValues)
            if (query) await handleSearch(query)
        },
        [query, handleSearch],
    )

    const filterCount = selectedFields.length

    const handleSelectAllFields = useCallback(() => {
        const allFields = collectionFields[activeCollection].map((field) => field.value)
        setSelectedFields(allFields)
        if (query) handleSearch(query)
    }, [activeCollection, query, handleSearch])

    const handleDeselectAllFields = useCallback(() => {
        setSelectedFields([])
        if (query) handleSearch(query)
    }, [query, handleSearch])

    const filterContent = (
        <div style={{ maxWidth: '300px' }}>
            <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                <Button size="small" onClick={handleSelectAllFields}>
                    Seleccionar todo
                </Button>
                <Button size="small" onClick={handleDeselectAllFields}>
                    Deseleccionar todo
                </Button>
            </div>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <Checkbox.Group
                    className="vertical-checkbox-group"
                    options={collectionFields[activeCollection]}
                    value={selectedFields}
                    onChange={handleFieldsChange}
                />
            </div>
        </div>
    )

    // Update keyboard shortcut handler
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
            const modifierKey = isMac ? event.metaKey : event.ctrlKey

            // Special handling for filter toggle - allow it even in input
            if (
                modifierKey &&
                (event.key === KEYBOARD_SHORTCUTS.TOGGLE_FILTERS_UP ||
                    event.key === KEYBOARD_SHORTCUTS.TOGGLE_FILTERS_DOWN)
            ) {
                event.preventDefault()
                setIsFilterOpen((prev) => !prev)
                return
            }

            // Special handling for focus search
            if (modifierKey && event.key.toLowerCase() === KEYBOARD_SHORTCUTS.FOCUS_SEARCH) {
                event.preventDefault()
                searchInputRef.current?.focus()
                return
            }

            // Special handling for collection switching
            if (modifierKey && event.shiftKey) {
                const key = event.key.toUpperCase()
                const collectionMap: Record<string, CollectionType> = {
                    [KEYBOARD_SHORTCUTS.INVOICES]: 'invoices',
                    [KEYBOARD_SHORTCUTS.CLIENTS]: 'clients',
                    [KEYBOARD_SHORTCUTS.PAYMENTS]: 'payments',
                    [KEYBOARD_SHORTCUTS.RECEIPTS]: 'receipts',
                }

                const newCollection = collectionMap[key]
                if (newCollection) {
                    event.preventDefault()
                    handleCollectionChange(newCollection)
                    return
                }
            }

            // For other shortcuts, ignore if user is typing in an input or textarea
            if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
                return
            }

            // Handle other keyboard shortcuts
            switch (event.key.toLowerCase()) {
                case KEYBOARD_SHORTCUTS.CLEAR_SEARCH:
                    if (query || searchResultContainerVisible) {
                        event.preventDefault()
                        setQuery('')
                        dispatch(clearSearch())
                        dispatch(closeModal('searchResultContainerVisible'))
                    }
                    break
            }
        }

        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [query, searchResultContainerVisible, dispatch, handleSearch, handleCollectionChange])

    return (
        <div className="main-search--container" ref={searchContainerRef}>
            <Space direction="vertical" style={{ width: '100%', marginTop: '15px' }} size="small">
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        border: '1px solid var(--neutral-4)',
                        borderRadius: '6px',
                    }}
                >
                    <Select
                        id="search-selector-collection"
                        value={activeCollection}
                        style={{
                            width: '100px',
                            borderRadius: 0,
                            borderRight: 'none',
                        }}
                        onChange={handleCollectionChange}
                        options={searchCollections}
                        suffixIcon={<CaretUpDown size={16} />}
                        variant="borderless"
                        title="⌘/Ctrl + I/P/R/C para cambiar colección"
                    />
                    <Popover
                        content={filterContent}
                        title="Seleccionar campos para buscar"
                        trigger="click"
                        placement="bottom"
                        open={isFilterOpen}
                        onOpenChange={setIsFilterOpen}
                    >
                        <Button
                            id="search-selector-filter"
                            style={{
                                height: '40px',
                                width: '40px',
                                borderRadius: 0,
                                border: 'none',
                                borderRight: '1px solid var(--neutral-4)',
                                borderLeft: '1px solid var(--neutral-4)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 0,
                            }}
                            title="Presiona ⌘/Ctrl + ↑/↓ para abrir/cerrar filtros"
                        >
                            <FunnelSimple size={16} />
                            {filterCount > 0 && (
                                <sup
                                    style={{
                                        position: 'absolute',
                                        top: '2px',
                                        right: '2px',
                                        backgroundColor: 'var(--neutral-1)',
                                        color: 'white',
                                        borderRadius: '50%',
                                        padding: '0 4px',
                                        fontSize: '10px',
                                    }}
                                >
                                    {filterCount}
                                </sup>
                            )}
                        </Button>
                    </Popover>
                    <Input.Search
                        id="search-input"
                        ref={searchInputRef}
                        variant="borderless"
                        placeholder="Buscar... (Presiona ⌘/Ctrl + F para enfocar)"
                        style={{ flex: 1 }}
                        value={query}
                        onChange={handleInputChange}
                        onSearch={handleSearch}
                        onFocus={() => setIsSearchFocused(true)}
                        onBlur={() => setIsSearchFocused(false)}
                        allowClear
                        enterButton={
                            <Button type="primary">
                                <MagnifyingGlass size={16} />
                            </Button>
                        }
                    />
                </div>

                {isSearchFocused && (
                    <div className="d-flex flex-column" style={{ position: 'relative' }}>
                        {!query ? (
                            <div
                                className="searchResultsContainer d-flex flex-column"
                                style={{
                                    borderBottomLeftRadius: '5px',
                                    borderBottomRightRadius: '5px',
                                    padding: '10px 10px',
                                }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    return
                                }}
                            >
                                <Row style={{ padding: '10px 0px' }} align="middle">
                                    <Lightbulb size={13} type="duotone" style={{ color: '#9193A1' }} />
                                    <Typography.Text
                                        className="smallparagraph"
                                        style={{ padding: 0, marginLeft: '7px', color: '#9193A1' }}
                                    >
                                        TIPS PARA EL BUSCADOR
                                    </Typography.Text>
                                </Row>
                                <Row style={{ marginBottom: '5px', marginTop: '10px' }}>
                                    <Tag>
                                        <Command size={12} />
                                        <Typography.Text className="smallparagraph" style={{ marginRight: '5px' }}>
                                            +
                                        </Typography.Text>
                                        <ArrowFatUp size={12} />
                                    </Tag>
                                    <Typography.Text className="smallparagraph" style={{ marginRight: '5px' }}>
                                        +
                                    </Typography.Text>
                                    <Tag style={{ marginRight: '5px' }}>
                                        <Typography.Text className="smallparagraph">F</Typography.Text>
                                    </Tag>
                                    <Typography.Text className="smallparagraph">
                                        para enfocar el buscador (⌘/Ctrl)
                                    </Typography.Text>
                                </Row>
                                <Row style={{ marginBottom: '5px' }}>
                                    <Tag>
                                        <Command size={12} />
                                        <Typography.Text className="smallparagraph" style={{ marginRight: '5px' }}>
                                            +
                                        </Typography.Text>
                                        <ArrowFatUp size={12} />
                                    </Tag>
                                    <Typography.Text className="smallparagraph" style={{ marginRight: '5px' }}>
                                        +
                                    </Typography.Text>
                                    <Tag style={{ marginRight: '5px' }}>
                                        <Typography.Text className="smallparagraph">I</Typography.Text>
                                    </Tag>
                                    <Typography.Text className="smallparagraph">
                                        para cambiar la busqueda a facturas (⌘/Ctrl + Shift + I).
                                    </Typography.Text>
                                </Row>
                                <Row style={{ marginBottom: '5px' }}>
                                    <Tag>
                                        <Command size={12} />
                                        <Typography.Text className="smallparagraph" style={{ marginRight: '5px' }}>
                                            +
                                        </Typography.Text>
                                        <ArrowFatUp size={12} />
                                    </Tag>
                                    <Typography.Text className="smallparagraph" style={{ marginRight: '5px' }}>
                                        +
                                    </Typography.Text>
                                    <Tag style={{ marginRight: '5px' }}>
                                        <Typography.Text className="smallparagraph">P</Typography.Text>
                                    </Tag>
                                    <Typography.Text className="smallparagraph">
                                        para cambiar la busqueda a pagos (⌘/Ctrl + Shift + P).
                                    </Typography.Text>
                                </Row>
                                <Row style={{ marginBottom: '5px' }}>
                                    <Tag>
                                        <Command size={12} />
                                        <Typography.Text className="smallparagraph" style={{ marginRight: '5px' }}>
                                            +
                                        </Typography.Text>
                                        <ArrowFatUp size={12} />
                                    </Tag>
                                    <Typography.Text className="smallparagraph" style={{ marginRight: '5px' }}>
                                        +
                                    </Typography.Text>
                                    <Tag style={{ marginRight: '5px' }}>
                                        <Typography.Text className="smallparagraph">R</Typography.Text>
                                    </Tag>
                                    <Typography.Text className="smallparagraph">
                                        para cambiar la busqueda a recibos (⌘/Ctrl + Shift + R).
                                    </Typography.Text>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <p className="smallparagraph">
                                        Selecciona una colección para buscar, entre{' '}
                                        <span
                                            style={{ fontWeight: activeCollection === 'clients' ? 'bold' : 'normal' }}
                                        >
                                            {t('clientes')}
                                        </span>
                                        ,{' '}
                                        <span
                                            style={{ fontWeight: activeCollection === 'invoices' ? 'bold' : 'normal' }}
                                        >
                                            facturas
                                        </span>
                                        ,{' '}
                                        <span
                                            style={{ fontWeight: activeCollection === 'receipts' ? 'bold' : 'normal' }}
                                        >
                                            recibos
                                        </span>{' '}
                                        y{' '}
                                        <span
                                            style={{ fontWeight: activeCollection === 'payments' ? 'bold' : 'normal' }}
                                        >
                                            pagos
                                        </span>{' '}
                                        para obtener resultados más precisos.
                                    </p>
                                </Row>
                            </div>
                        ) : (
                            <div className="searchResultsContainer">
                                {loading ? (
                                    <Row align="middle" justify="center" style={{ margin: '15px' }}>
                                        <Skeleton active title={false} paragraph={{ rows: 1 }} />
                                        <Skeleton active title={false} paragraph={{ rows: 1 }} />
                                        <Skeleton active title={false} paragraph={{ rows: 1 }} />
                                    </Row>
                                ) : (
                                    <SearchIndexComponent indexName={activeCollection} />
                                )}
                            </div>
                        )}
                    </div>
                )}

                {searchResultContainerVisible && !isSearchFocused && (
                    <div className="d-flex flex-column" style={{ position: 'relative' }}>
                        <div
                            className="searchResultsContainer"
                            onClick={(e) => {
                                // Prevent click from bubbling up
                                e.stopPropagation()
                            }}
                        >
                            {loading ? (
                                <Row align="middle" justify="center" style={{ margin: '15px' }}>
                                    <Skeleton active title={false} paragraph={{ rows: 1 }} />
                                    <Skeleton active title={false} paragraph={{ rows: 1 }} />
                                    <Skeleton active title={false} paragraph={{ rows: 1 }} />
                                </Row>
                            ) : (
                                <SearchIndexComponent indexName={activeCollection} />
                            )}
                        </div>
                    </div>
                )}
            </Space>
        </div>
    )
}

export default ComponentSearch
