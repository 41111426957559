import { Dropdown, Menu, Popconfirm, Row, Tag, Tooltip, Typography, message } from 'antd'
import { useDispatch } from 'react-redux'
import { setData } from '../../context/dataSlice'
import { openModal } from '../../context/modalsSlice'
import { usePermissions } from '../../customHooks/usePermissions'
import { getItemsAmounts } from '../../functions/helpers'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { PlusCircle } from '@phosphor-icons/react'
import { useState } from 'react'
import dayjs from 'dayjs'

export const handleRelationsTitle = (elementType: string) => {
    switch (elementType) {
        case 'invoices':
            return 'Relaciones de la factura'
        case 'payments':
            return 'Relaciones del pago'
        case 'receipts':
            return 'Relaciones del recibo'
        case 'recurringInvoices':
            return 'Relaciones de la factura recurrente'
        case 'recurringPayments':
            return 'Relaciones del pago recurrente'
        default:
            return 'Relaciones'
    }
}

export const ElementRelations = ({
    record,
    elementType,
    hideAdd,
    incomingMenu,
}: {
    record: any
    elementType: 'payments' | 'invoices' | 'receipts' | 'recurringInvoices' | 'recurringPayments'
    hideAdd?: boolean
    incomingMenu?: any
}) => {
    const dispatch = useDispatch()

    const receipts = (record.receipts ?? []).length
    const payments =
        elementType === 'recurringPayments' ? (record.relations ?? []).length : (record.payments ?? []).length
    const invoices =
        elementType === 'recurringInvoices' ? (record.relations ?? []).length : (record.invoices ?? []).length


    const handleOpenRelations = ({ record }: { record: any }) => {
        const title = handleRelationsTitle(elementType)

        dispatch(
            setData({
                item: 'relationInfo',
                data: {
                    title,
                    sourceType: elementType,
                    item: record,
                },
            }),
        )
        dispatch(openModal('relationsDrawerVisible'))
    }
    const { canCreatePayment, canCreateInvoice } = usePermissions()

    const [loading, setLoading] = useState<string[]>([])
    const menu = (
        <Menu>
            <Menu.Item
                key="1"
                onClick={() => {
                    if (!canCreatePayment.hasPermission || !canCreateInvoice.hasPermission)
                        return message.warning('No tienes permisos para crear pagos o facturas')

                    dispatch(
                        setData({
                            item: 'client',
                            data: record.client,
                        }),
                    )
                    dispatch(
                        setData({
                            item: 'selectedServices',
                            data: record.internalItems,
                        }),
                    )
                    dispatch(
                        setData({
                            item: 'payment',
                            data: {
                                amount: getItemsAmounts(record?.items).total * 100,
                                items: record?.internalItems,
                                currency: record?.currency ?? 'MXN',
                                exchange: record?.exchange ?? 1,
                                [elementType]: [record?.id],
                            },
                        }),
                    )

                    dispatch(openModal('paymentModalVisible'))
                }}
            >
                Solicitar pago
            </Menu.Item>

            <Popconfirm
                title="Continuar a los detalles de registro de pago"
                description="Registra y asocia un pago"
                onConfirm={async () => {
                    setLoading([...loading, `registerPayment${record?.id}`])
                    let items = record?.internalItems

                    if (record?.invoiceType === 'P' && record?.invoices?.length > 0) {
                        let invoice = await getDoc(doc(getFirestore(), 'invoices', record?.invoices[0]))
                        console.log('invoice', invoice.data())

                        items = invoice.data()?.internalItems
                        dispatch(
                            setData({
                                item: 'payment',
                                data: {
                                    currency: invoice.data()?.currency,
                                    exchange: invoice.data()?.exchange,
                                    timestamp: dayjs(record.timestamp),
                                    paymentForm: invoice.data()?.payment_form,
                                    [elementType]: [record?.id].push(...(record[elementType] ?? [])),
                                },
                            }),
                        )
                    }

                    dispatch(setData({ item: 'client', data: record.client }))
                    dispatch(setData({ item: 'selectedServices', data: items }))

                    // dispatch(setData({
                    //     item: 'payment',
                    //     data: record?.internalItems
                    // }))
                    dispatch(openModal('registerPaymentModalVisible'))
                    setLoading(loading.filter((l) => l !== `registerPayment${record?.id}`))
                }}
                okButtonProps={{
                    loading: loading.includes(`registerPayment${record?.id}`),
                }}
                onCancel={() => {}}
                okText="Registrar"
                cancelText="Cancelar"
            >
                <Menu.Item key="3">Registrar Pago</Menu.Item>
            </Popconfirm>
            {elementType !== 'receipts' && (
                <Menu.Item
                    key="2"
                    onClick={() => {
                        if (!canCreatePayment.hasPermission || !canCreateInvoice.hasPermission)
                            return message.warning('No tienes permisos para crear pagos o facturas')

                        dispatch(
                            setData({
                                item: 'relating',
                                data: {
                                    element: record,
                                    type: elementType,
                                    collection: elementType,
                                },
                            }),
                        )
                        dispatch(
                            setData({
                                item: 'search',
                                data: {
                                    type: elementType,
                                    query: '',
                                },
                            }),
                        )
                    }}
                >
                    Asociar Recurso
                </Menu.Item>
            )}
        </Menu>
    )

    return (
        <Row align="middle" wrap style={{ width: 'auto' }} justify="center">
            {payments > 0 && (
                <Tooltip title="Pagos relacionados">
                    <Tag
                        color="#EDEFF5"
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onClick={() => {
                            handleOpenRelations({ record })
                        }}
                    >
                        <Typography.Text className="smallparagraphbold neutral">
                            {payments + ' Pago' + (payments > 1 ? 's' : '')}
                        </Typography.Text>
                    </Tag>
                </Tooltip>
            )}
            {(invoices > 0 || record?.invoice) && (
                <Tooltip title="Facturas relacionadas">
                    <Tag
                        color="#EDEFF5"
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onClick={() => {
                            handleOpenRelations({ record })
                        }}
                    >
                        <Typography.Text className="smallparagraphbold neutral">
                            {invoices + ' Factura' + (invoices > 1 ? 's' : '')}
                        </Typography.Text>
                    </Tag>
                </Tooltip>
            )}
            {receipts > 0 && (
                <Tooltip title="Recibos relacionados">
                    <Tag
                        color="#EDEFF5"
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onClick={() => {
                            handleOpenRelations({ record })
                        }}
                    >
                        <Typography.Text className="smallparagraphbold neutral">
                            {receipts + ' Recibo' + (receipts > 1 ? 's' : '')}
                        </Typography.Text>
                    </Tag>
                </Tooltip>
            )}
            {!hideAdd &&
                (incomingMenu ? (
                    incomingMenu
                ) : (
                    <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
                        <PlusCircle size={20} className="icon" style={{ marginLeft: '5px' }} />
                    </Dropdown>
                ))}
        </Row>
    )
}
