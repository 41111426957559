import { LoadingOutlined } from '@ant-design/icons'
import { Globe, Trash, Sparkle } from '@phosphor-icons/react'
import { Alert, Avatar, Button, Card, Col, Form, message, Row, Space, Typography } from 'antd'
import { Image } from 'antd/lib'
import { FormInstance } from 'antd/lib/form'
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useWatch } from 'antd/es/form/Form'
import { getAuth } from 'firebase/auth'
import { ColorInput, FileUpload, TextInput } from '../../../components/Forms/Inputs'
import { setStepLoading } from '../../../context/onboardingSlice'
import { setTeam } from '../../../context/teamsSlice'
import { setUser } from '../../../context/userSlice'
import useLogoAndColors from '../../../customHooks/useLogoAndColors'
import CountrySelect from '../CountrySelect'
import StepLayout from '../StepsLayout'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../../../analytics/helpers'
import moment from 'moment'
import { motion } from 'framer-motion'

interface ComercialInformationStepProps {
    form: FormInstance
    onStepCompleted: () => void
    onValuesUpdate?: (data: any) => void
    renderButtons: () => React.ReactNode
    showLogoPreview?: boolean
    updateContext?: boolean
}

const ComericalInformationStep = ({
    form,
    onStepCompleted,
    onValuesUpdate,
    showLogoPreview = false,
    renderButtons,
}: ComercialInformationStepProps) => {
    const auth = getAuth()
    const dispatch = useDispatch()
    const posthog = usePostHog()
    const { fetchLogoAndColors, loading: logoLoading, logo: logofromapi, colors, removeLogo } = useLogoAndColors()
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const [error, setError] = useState<string | null>(null)
    const [logo, setLogo] = useState(team?.brand?.logo ?? '')

    const [primaryColor, setPrimaryColor] = useState<string | null>(team?.brand?.primaryColor ?? '#000000')

    const alias = useWatch('alias', form)

    useEffect(() => {
        trackEvent({ name: 'onboarding_brand_section_viewed', metadata: { v: 'v2' } }, posthog)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const loadTeamData = async () => {
            try {
                dispatch(setStepLoading(true))
                if (!logofromapi) {
                    await fetchLogoAndColors(team?.social?.website ?? auth?.currentUser?.email?.split('@')[1])
                }

                form.setFieldsValue({
                    website: team?.social?.website ?? `https://${auth?.currentUser?.email?.split('@')[1]}`,
                    alias: team?.brand?.alias,
                    country: team?.address?.country || 'MEX',
                    primaryColor: team?.brand?.primaryColor ?? '#000000',
                    secondaryColor: team?.brand?.secondaryColor ?? '#FFFFFF',
                })
            } catch (err) {
                console.error('Error loading team data:', err)
                setError('No se pudo cargar la información del equipo. Por favor, intenta de nuevo.')
            } finally {
                dispatch(setStepLoading(false))
            }
        }

        loadTeamData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, form, team, user])

    useEffect(() => {
        if (colors) {
            setPrimaryColor(colors?.[0])

            form.setFieldsValue({
                primaryColor: colors?.[0],
                secondaryColor: colors?.[1],
                logo: logofromapi,
            })
            setLogo(logofromapi)
        }
    }, [colors, form, logofromapi])

    const handleFinish = async (values: any) => {
        try {
            dispatch(setStepLoading(true))
            form.validateFields()

            const primaryColor =
                typeof values?.primaryColor === 'string'
                    ? values?.primaryColor
                    : `${values?.primaryColor?.toHex() ?? ''}`
            let secondaryColor =
                typeof values?.secondaryColor === 'string'
                    ? values?.secondaryColor
                    : `#${values?.secondaryColor?.toHex() ?? ''}`

            const isValidColor = (color: string) => {
                return /^#[0-9A-Fa-f]{6}$/.test(color)
            }

            const userId = auth?.currentUser?.uid ?? ''

            const brandObj = {
                primaryColor:
                    primaryColor && isValidColor(primaryColor)
                        ? primaryColor
                        : (team?.brand?.primaryColor ?? '#000000'),
                secondaryColor:
                    secondaryColor && isValidColor(secondaryColor)
                        ? secondaryColor
                        : (team?.brand?.secondaryColor ?? '#FFFFFF'),
                logo: logo || values.logo || '',
                alias: values.alias ?? team?.brand?.alias ?? '',
            }

            const teamDef = {
                brand: brandObj,
                id: team?.id ?? '',
                social: { website: values?.website ?? team?.social?.website ?? '' },
                supportEmail: values?.supportEmail ?? team?.supportEmail ?? '',
                address: {
                    country: values?.country,
                    ...(team?.address || {}),
                },
            }

            dispatch(setStepLoading(true))

            try {
                await updateDoc(doc(getFirestore(), 'teams', team?.id), { ...teamDef })
                dispatch(setTeam(teamDef))

                if (billingAccount.pendingTeamConfiguration === team.id) {
                    await updateDoc(doc(getFirestore(), 'billingAccounts', billingAccount.id), {
                        pendingTeamConfiguration: null,
                    })
                }

                await updateDoc(doc(getFirestore(), 'users', auth?.currentUser?.uid ?? ''), {
                    lastTeamViewed: teamDef.id,
                    lastBillingAccountViewed: billingAccount.id,
                    teams: [teamDef.id],
                    billingAccounts: [billingAccount.id],
                })

                const userdb = await getDoc(doc(getFirestore(), 'users', userId))
                const teamdb = await getDoc(doc(getFirestore(), 'teams', teamDef.id))

                dispatch(setUser(userdb.data()))
                dispatch(setTeam(teamdb.data()))

                dispatch(setStepLoading(false))

                onStepCompleted()
            } catch (e) {
                console.error('Error updating team:', e)
                dispatch(setStepLoading(false))
                message.error('Ocurrió un error al actualizar la información')
            }
        } catch (err) {
            console.error('Error submitting form:', err)
            setError('Hubo un problema al guardar los datos. Por favor, intenta de nuevo.')
            message.error('Hubo un problema al guardar los datos. Por favor, intenta de nuevo.')
        }
    }

    const ComercialInformationForm = (
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <Row justify="center" gutter={[0, 0]} style={{ marginBottom: '24px' }}>
                <Col span={24} className="d-flex flex-column">
                    <Typography.Title level={3} className="fade-in-animation" style={{ margin: 0 }}>
                        <Sparkle
                            size={20}
                            weight="fill"
                            color={'#044737'}
                            className="primary-color"
                            style={{ marginRight: '5px' }}
                        />
                        Configuremos tu marca{' '}
                    </Typography.Title>
                    <Typography.Text type="secondary" className="fade-in-animation" style={{ marginTop: '8px' }}>
                        Personaliza cómo tus clientes verán tu marca en documentos y portales
                    </Typography.Text>
                </Col>
            </Row>
            {error && (
                <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                >
                    <Alert
                        message="Error"
                        description={error}
                        type="error"
                        showIcon
                        closable
                        onClose={() => setError(null)}
                        style={{ marginBottom: '24px' }}
                        className="rounded-xl"
                    />
                </motion.div>
            )}
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                style={{ width: '100%' }}
                onFinishFailed={(errorInfo) => {
                    console.log('Form validation failed:', errorInfo)
                    message.error('Por favor, corrige los errores en el formulario antes de continuar.')
                }}
            >
                <Row gutter={[32, { xs: 12, lg: 12 }]}>
                    <Col xs={24} lg={24}>
                        <motion.div
                            whileHover={{ scale: 1.01 }}
                            transition={{ duration: 0.2 }}
                            className="shadow-sm hover:shadow-md transition-all duration-200 rounded-lg"
                        >
                            <TextInput
                                onChange={(e: any) => {
                                    let value = e.target.value
                                    if (value && !value.match(/^https?:\/\//)) {
                                        value = 'https://' + value
                                        form.setFieldsValue({ website: value })
                                    }
                                }}
                                onBlur={async () => {
                                    let value = form.getFieldValue('website')
                                    if (value) {
                                        if (!value.match(/^https?:\/\//)) {
                                            value = 'https://' + value
                                            form.setFieldsValue({ website: value })
                                        }
                                        await fetchLogoAndColors(value)
                                    }
                                }}
                                prefix={logoLoading ? <LoadingOutlined /> : <Globe size={16} />}
                                name="website"
                                label="Sitio Web"
                                placeholder="https://www.tusitio.com"
                                rules={[
                                    { required: false },
                                    { type: 'url', message: 'Por favor ingresa una URL válida' },
                                    {
                                        validator: (_: any, value: string) => {
                                            if (value && !value.match(/^https?:\/\//)) {
                                                return Promise.reject('La URL debe comenzar con http:// o https://')
                                            }
                                            return Promise.resolve()
                                        },
                                    },
                                ]}
                            />
                        </motion.div>
                    </Col>
                    <Col xs={24} lg={24}>
                        <motion.div
                            whileHover={{ scale: 1.01 }}
                            transition={{ duration: 0.2 }}
                            className="shadow-sm hover:shadow-md transition-all duration-200 rounded-lg"
                        >
                            <TextInput
                                name="alias"
                                label="Nombre Comercial"
                                placeholder="Escribe tu Nombre comercial"
                                rules={[
                                    { required: true, message: 'Por favor ingresa el nombre comercial' },
                                    { max: 100, message: 'El nombre comercial no puede exceder los 100 caracteres' },
                                ]}
                            />
                        </motion.div>
                    </Col>
                    {logofromapi ? (
                        <Col xs={24}>
                            <Row justify="space-between" align="middle">
                                <Row>
                                    <Avatar src={logofromapi} size={20} />
                                    <Typography.Text
                                        style={{
                                            color: 'var(--neutral-3)',
                                            paddingLeft: '3px',
                                        }}
                                    >
                                        Logo obtenido desde sitio
                                    </Typography.Text>
                                </Row>
                                <Button icon={<Trash size={16} />} onClick={removeLogo} />
                            </Row>
                        </Col>
                    ) : (
                        <Col xs={24}>
                            <Row align="middle">
                                {showLogoPreview && (
                                    <Image
                                        src={logo}
                                        height={50}
                                        style={{ objectFit: 'contain', marginRight: '10px' }}
                                    />
                                )}
                                <div style={{ flex: 1 }}>
                                    <FileUpload
                                        showUploadList={true}
                                        allowedFiles={['image/png', 'image/jpeg', 'image/jpg']}
                                        placeholder="Sube el logo de la empresa"
                                        label="Logo"
                                        name="logo"
                                        description="Medida recomendada: 200x200px"
                                        storagePath={`teams/${team.id}/files/logo`}
                                        firestorePath={`teams/${team.id}/files/logo`}
                                        previousDataKey="brand"
                                        firestoreKey="logo"
                                        firestorePreviousData={team?.brand}
                                        onURLUploaded={(url: string) => {
                                            setLogo(url)
                                            if (onValuesUpdate) {
                                                onValuesUpdate({ logo: url })
                                            }
                                        }}
                                    />
                                </div>
                            </Row>
                        </Col>
                    )}

                    <Col xs={24} lg={24}>
                        <motion.div
                            whileHover={{ scale: 1.01 }}
                            transition={{ duration: 0.2 }}
                            className="shadow-sm hover:shadow-md transition-all duration-200 rounded-lg"
                        >
                            <CountrySelect
                                name="country"
                                label="País de operación"
                                placeholder="Selecciona tu país de operación"
                                rules={[{ required: true, message: 'Por favor selecciona tu país' }]}
                            />
                        </motion.div>
                    </Col>

                    <Col xs={24} md={12}>
                        <div className="colorOverride">
                            <motion.div
                                whileHover={{ scale: 1.01 }}
                                transition={{ duration: 0.2 }}
                                className="shadow-sm hover:shadow-md transition-all duration-200 rounded-lg"
                            >
                                <ColorInput
                                    onChange={(value) => {
                                        setPrimaryColor(value.toHexString())
                                    }}
                                    label="Color Primario"
                                    name="primaryColor"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Este campo es requerido',
                                        },
                                    ]}
                                />
                            </motion.div>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className="colorOverride">
                            <motion.div
                                whileHover={{ scale: 1.01 }}
                                transition={{ duration: 0.2 }}
                                className="shadow-sm hover:shadow-md transition-all duration-200 rounded-lg"
                            >
                                <ColorInput
                                    label="Color Secundario"
                                    name="secondaryColor"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Este campo es requerido',
                                        },
                                    ]}
                                />
                            </motion.div>
                        </div>
                    </Col>
                </Row>
                {renderButtons()}
            </Form>
        </motion.div>
    )

    const ComecialInformationPreview = (
        <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
        >
            <Row justify="center">
                <Col style={{ width: '100%' }}>
                    <Space direction="vertical" size="large" style={{ width: '100%' }}>
                        <div style={{ width: '90%', margin: 'auto' }}>
                            <Typography.Title level={5} className="fade-in-animation" style={{ marginBottom: 8 }}>
                                Vista Previa de tu marca
                            </Typography.Title>
                            <Typography.Text type="secondary" className="fade-in-animation">
                                Así verán tus clientes tu marca en documentos y portales:
                            </Typography.Text>
                        </div>

                        <motion.div
                            whileHover={{ scale: 1.02 }}
                            transition={{ duration: 0.2 }}
                            style={{ width: '100%' }}
                        >
                            <Card
                                className="rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
                                style={{
                                    width: '90%',
                                    maxWidth: '90%',
                                    margin: 'auto',
                                    background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
                                }}
                            >
                                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                    <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.2 }}>
                                        <Avatar
                                            size={64}
                                            src={logo || team?.brand?.logo}
                                            icon={!(logo || team?.brand?.logo) && <Image />}
                                            style={{ border: '2px solid #f0f0f0' }}
                                        />
                                    </motion.div>
                                    <Typography.Title level={4} style={{ margin: 0 }}>
                                        {alias || team?.brand?.comercialName || 'Nombre de tu marca'}
                                    </Typography.Title>
                                    <Typography.Text type="secondary" style={{ fontSize: '14px' }}>
                                        Hola {user?.name?.trim() ?? 'cliente'}, ya puedes generar la factura de la
                                        compra que realizaste el {moment().format('D [de] MMMM [del] YYYY')}, si tienes
                                        alguna duda puedes contactarnos.
                                    </Typography.Text>
                                    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                                        <Button
                                            className="btn shadow-sm hover:shadow-md transition-all duration-200"
                                            style={{
                                                width: '100%',
                                                backgroundColor: `${primaryColor}`,
                                                color: 'white',
                                                height: '40px',
                                                borderRadius: '8px',
                                                border: 'none',
                                            }}
                                        >
                                            Realizar mi factura
                                        </Button>
                                    </motion.div>
                                </Space>
                            </Card>
                        </motion.div>
                    </Space>
                </Col>
            </Row>
        </motion.div>
    )

    return <StepLayout form={ComercialInformationForm} supportElement={ComecialInformationPreview} />
}

export default ComericalInformationStep
