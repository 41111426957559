import { Route, Routes } from 'react-router-dom'
import { SuspenseWithPerf } from 'reactfire'
import Loader from '../appStates/LoadingView'
import AuthWrapper from '../auth/AuthWrapper'
import OnboardingRoutes from '../auth/routes/OnboardingRoutes'
import PublicRoutes from '../auth/routes/PublicRoutes'
import PricingCardDeck from '../components/ComponentIndividual/PricingCardDeck'
import MainLayout from '../Layouts/MainLayout'
import ConfirmOnboardingStep from '../new-onboarding/onboarding/steps/ConfirmOnboardingStep'
import { OAuthView } from '../pages/OAuthView'
import { useSelector } from 'react-redux'
import { SupportWidget } from '@disruptive-learning/pulsar/ui'
import { useEffect, useState, useMemo } from 'react'

const environment: 'production' | 'staging' | 'local' = (process.env.REACT_APP_ENVIRONMENT ?? 'production') as
    | 'production'
    | 'staging'
    | 'local'

const MainRoutes = () => {
    const { user } = useSelector((state: any) => state.user)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { team } = useSelector((state: any) => state.team)
    const [isDataReady, setIsDataReady] = useState(false)

    const userData = useMemo(
        () => ({
            uid: user?.uid,
            chatwoot_identifier: user?.chatwoot_identifier,
        }),
        [user?.uid, user?.chatwoot_identifier],
    )

    useEffect(() => {
        const hasUser = !!userData.uid
        const hasChatwootIdentifier = !!userData.chatwoot_identifier
        setIsDataReady(hasUser && hasChatwootIdentifier)
    }, [userData])

    // SUPOORT OBJECTS
    const supportUser = useMemo(
        () => ({
            uid: user.uid,
            name: user.name,
            email: user.email,
            company_name: team.brand?.alias,
        }),
        [user.uid, user.name, user.email, team.brand?.alias],
    )

    const supportCustomAttributes = useMemo(
        () => ({
            team_id: team?.id,
            billing_id: billingAccount?.id,
            plan: billingAccount?.plan?.name,
        }),
        [team?.id, billingAccount?.id, billingAccount?.plan?.name],
    )

    return (
        <SuspenseWithPerf traceId={'firebase-user-wait'} fallback={<Loader loading={true} />}>
            {isDataReady && environment !== 'local' && (
                <SupportWidget
                    user={supportUser}
                    chatwoot_identifier={user?.chatwoot_identifier}
                    customAttributes={supportCustomAttributes}
                    websiteToken={process.env.REACT_APP_CHATWOOT_WEBSITE_TOKEN!}
                    hmacSecret={process.env.REACT_APP_CHATWOOT_HMAC_SECRET!}
                />
            )}

            <AuthWrapper publicRoutes={<PublicRoutes />} onboardingRoutes={<OnboardingRoutes />}>
                <>
                    <Routes>
                        <Route path="/:tab" element={<MainLayout element="home" />} />
                        <Route
                            path="/onboarding/finished"
                            element={<ConfirmOnboardingStep type="onboarding-finished" />}
                        />
                        <Route path="*" element={<MainLayout element="404" />} />
                        <Route path="/oauth" element={<OAuthView />} />
                        <Route path="/memberships" element={<PricingCardDeck />} />
                    </Routes>
                </>
            </AuthWrapper>
        </SuspenseWithPerf>
    )
}

export default MainRoutes
