import { CaretDoubleRight, Question } from '@phosphor-icons/react'
import { Button, Col, Form, Row, Tour, TourProps, Typography, message, notification } from 'antd'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { setTeam } from '../../context/teamsSlice'
import { RemoveUndefined, invoiceUsage } from '../../functions/helpers'
import ErrorsInFormComponent from './ErrorsInFormComponent'
import { CheckBoxInput, SelectInput, TextInput } from './Inputs'
import ProductKeySearch from '../ComponentIndividual/ProductKeySearch'
import UnitKeySearch from '../ComponentIndividual/UnitKeySearch'

const AutomaticInvoiceDefaultsForm: React.FC<any> = ({ buttonTitle, onUpdate }) => {
    const [notificationApi, contextHolder] = notification.useNotification()
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [errors, setErrors] = useState<any>([])
    const [loading, setLoading] = useState(false)

    const { team } = useSelector((state: any) => state.team)

    const { defaults } = team

    /*TOUR */
    const productKeyRef = useRef(null)
    const unitKeyRef = useRef(null)
    const unitNameRef = useRef(null)
    const usageRef = useRef(null)
    const [openTour, setOpenTour] = useState<boolean>(false)

    const steps: TourProps['steps'] = [
        {
            title: 'Código del producto',
            description: (
                <Typography.Text className="smallparagraph descriptions">
                    El código del producto, es el valor que utilizamos ante el SAT para identificar el producto o
                    servicio que estamos vendiendo, puedes encontrar la lista en
                    https://www.sat.gob.mx/consultas/53693/catalogo-de-productos-y-servicios
                </Typography.Text>
            ),
            target: () => productKeyRef.current,

            nextButtonProps: {
                children: 'Siguiente',
            },
            prevButtonProps: {
                children: 'Atrás',
            },
        },
        {
            title: 'Nombre de la unidad',
            description: (
                <Typography.Text className="smallparagraph descriptions">
                    El nombre de la unidad, está relacionado a como medimos en cantidad, por ejemplo si vendemos algo
                    líquido el nombre de nuestra unidad sería "Litros" puedes consultar el catálogo del SAT en
                    http://pys.sat.gob.mx/PyS/catUnidades.aspx
                </Typography.Text>
            ),
            target: () => unitNameRef.current,
            nextButtonProps: {
                children: 'Siguiente',
            },
            prevButtonProps: {
                children: 'Atrás',
            },
        },

        {
            title: 'Código de la unidad',
            description: (
                <Typography.Text className="smallparagraph descriptions">
                    Ante el SAT, cada unidad tiene un código específico, puedes consultar los códigos en
                    http://pys.sat.gob.mx/PyS/catUnidades.aspx
                </Typography.Text>
            ),
            target: () => unitKeyRef.current,
            nextButtonProps: {
                children: 'Siguiente',
            },
            prevButtonProps: {
                children: 'Atrás',
            },
        },
        {
            title: 'Uso predefinido',
            description: (
                <Typography.Text className="smallparagraph descriptions">
                    Aunque no todos los regimenes fiscales pueden utilizar todos los tipos, nosotros recomendamos tener
                    uno predefinido para las facturas. El más común es "Gastos en general" o su código "G03".
                </Typography.Text>
            ),
            target: () => usageRef.current,
            nextButtonProps: {
                children: 'Finalizar',
            },
            prevButtonProps: {
                children: 'Atrás',
            },
        },
    ]

    const onFinish = async (values: any) => {
        try {
            setLoading(true)
            const db = doc(getFirestore(), 'teams', team.id)

            console.log(values)
            let defaultsToUpdate = {
                ...defaults,
                completed: true,
                ...values
            }

            defaultsToUpdate = RemoveUndefined(defaultsToUpdate)
            await updateDoc(db, { defaults: defaultsToUpdate })
            dispatch(setTeam({ ...team, defaults: defaultsToUpdate }))
            message.success('Información actualizada correctamente')
            if (onUpdate) {
                onUpdate({ ...team, defaults: defaultsToUpdate })
            }
            dispatch(closeModal('settingsDrawerVisible'))
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            notificationApi.error({
                message: 'Error al actualizar la información',
                description: error?.message ?? 'Desconocido',
            })
        }
    }

    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            onError={(e: any) => {
                setErrors(e.errorFields)
            }}
            onErrorCapture={(e: any) => {
                setErrors(e.errorFields)
            }}
            form={form}
            initialValues={{ ...defaults }}
        >
            {contextHolder}
            <Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />
            <Row gutter={{ xs: 12, lg: 12 }}>
                {/* <Col xs={24} lg={24}>
                    <Divider />
                </Col> */}
                <Col xs={24}>
                    <Row justify="end">
                        <Button
                            shape="circle"
                            onClick={() => setOpenTour(true)}
                            size="small"
                            icon={<Question weight="regular" className="icon primary" />}
                        />
                    </Row>
                </Col>
                <Col xs={24} ref={productKeyRef}>
                    {/* <TextInput
                        label="Código de producto"
                        placeholder="01010101"
                        name="product_key"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo es requerido',
                            },
                        ]}
                    /> */}
                    <ProductKeySearch />
                </Col>
                <Col xs={24} lg={12} ref={unitNameRef}>
                    <UnitKeySearch form={form} />
                </Col>
                {/* <Col xs={24} style={{ margin: '0px 0px 10px 0px' }}>
                    <Typography.Text className="smallparagraph descriptions">
                        Puedes revisar el nombre de la unidad y código que corresponde en :
                        http://pys.sat.gob.mx/PyS/catUnidades.aspx
                    </Typography.Text>
                </Col> */}
                <Col xs={24} ref={usageRef}>
                    <SelectInput
                        label="Uso de la factura"
                        placeholder="Gastos en general"
                        name="usage"
                        rules={[
                            {
                                required: true,
                                message: 'Este campo es requerido',
                            },
                        ]}
                        options={invoiceUsage}
                    />
                </Col>

                <Col xs={24} lg={12}>
                    <TextInput
                        id="invoice-defaults-custom-payment-description-input"
                        label="Nombre de los productos / servicios"
                        placeholder="Servicio de diseño gráfico"
                        name="customPaymentDescription"
                        description="Utilizaremos esta descripción cuando la información de los productos dentro de un pago no lo tengan"
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <TextInput
                        id="invoice-defaults-sku-input"
                        label="SKU predeterminado"
                        placeholder="Clave interna"
                        name="sku"
                        description="Utilizaremos este SKU cuando la información del pago no lo tenga."
                    />
                </Col>

                <Col xs={24} style={{ marginTop: '25px' }}>
                    <SelectInput
                        id="invoice-defaults-allowed-use-on-self-invoice-select"
                        mode="multiple"
                        label="Permitir estas opciones de uso para facturar"
                        placeholder="Permitir todas las opciones"
                        name="allowedUseOnSelfInvoice"
                        description="Solo  mostraremos estas opciones a tu cliente para el uso de su factura en el portal de auto factura."
                        options={invoiceUsage}
                    />
                </Col>

                {/* <Col xs={24} style={{ marginTop: '25px' }}>
                    <SelectInput
                        mode="multiple"
                        label="Ignorar automatización en estas monedas"
                        placeholder="Automatizando todas las monedas"
                        name="avoidAutomationsOnCurrencies"
                        description="Ignoraremos las automatizaciones en estas monedas"
                        options={currecies}
                    />
                </Col> */}

                <Col xs={24} style={{ marginTop: '25px' }}>
                    <CheckBoxInput
                        id="invoice-defaults-use-foreign-public-in-usd-checkbox"
                        name="useForeignPublicInUSD"
                        label="Utilizar público en general extranjero"
                        content={
                            <Typography.Text className="smallparagraph descriptions">
                                En caso de recibir un pago en moneda diferente a MXN utilizaremos el cliente Público en
                                general extranjero.
                            </Typography.Text>
                        }
                    />
                </Col>

                <Col xs={24} style={{ marginTop: '25px' }}>
                    <CheckBoxInput
                        name="automatePaymentComplementForPPDInvoices"
                        label="Automatizar complementos de pago para facturas PPD"
                        content={
                            <Typography.Text className="p-small-regular neutral-3">
                                Al activar esta opción, se generarán automáticamente los complementos de pago cuando se registre un pago para una factura con método de pago PPD.
                            </Typography.Text>
                        }
                    />
                </Col>

                <Col xs={24}>
                    <ErrorsInFormComponent title={''} form={form} formErrors={errors} />
                </Col>

                <Col xs={24} style={{ marginTop: '20px' }}>
                    <Row justify="end">
                        <Button id="invoice-defaults-update-button" loading={loading} type="primary" htmlType="submit">
                            {buttonTitle ?? 'Actualizar información'}{' '}
                            <CaretDoubleRight className="" style={{ marginLeft: '5px' }} />
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export const InovocieDefaultsForm = () => {
    return (
        <Row gutter={24}>
            <Col xs={24}>
                <ProductKeySearch />
            </Col>

            <Col xs={24} lg={12}>
                <UnitKeySearch />
            </Col>
            <Col xs={24} style={{ margin: '0px 0px 10px 0px' }}>
                <Typography className="p-small-regular neutral-3">
                    Son códigos generado por el SAT que debes emplear para tu emisión de facturas según la actividad
                    realizada.{' '}
                    <a target="_blank" href="http://pys.sat.gob.mx/PyS/catUnidades.aspx" rel="noreferrer">
                        Búscalos aquí
                    </a>
                </Typography>
            </Col>
            <Col xs={24}>
                <SelectInput
                    id="invoice-defaults-usage-select"
                    label="Uso de la factura"
                    placeholder="Gastos en general"
                    name="usage"
                    rules={[
                        {
                            required: true,
                            message: 'Este campo es requerido',
                        },
                    ]}
                    options={invoiceUsage}
                />
            </Col>

            <Col xs={24}>
                <TextInput
                    label="Nombre de los productos / servicios"
                    placeholder="Servicio de diseño gráfico"
                    name="customPaymentDescription"
                />
            </Col>
            <Col xs={24} style={{ margin: '0px 0px 10px 0px' }}>
                <Typography.Text className="p-small-regular neutral-3">
                    Utilizaremos esta descripción cuando la información de los productos dentro de un pago no lo tengan
                </Typography.Text>
            </Col>
            <Col xs={24}>
                <TextInput label="SKU predeterminado" placeholder="Clave interna" name="sku" />
            </Col>
            <Col xs={24} style={{ margin: '0px 0px 10px 0px' }}>
                <Typography.Text className="p-small-regular neutral-3">
                    Utilizaremos este SKU cuando la información del pago no lo tenga.
                </Typography.Text>
            </Col>
        </Row>
    )
}

export const InvoiceDefaultsOptions = () => {
    return (
        <Row gutter={24}>
            <Col xs={24} style={{ marginTop: '25px' }}>
                <SelectInput
                    id="invoice-defaults-allowed-use-on-self-invoice-select"
                    mode="multiple"
                    label="Permitir estas opciones de uso para facturar"
                    placeholder="Permitir todas las opciones"
                    name="allowedUseOnSelfInvoice"
                    description="Solo  mostraremos estas opciones a tu cliente para el uso de su factura en el portal de auto factura."
                    options={invoiceUsage}
                />
            </Col>

            {/* <Col xs={24} style={{ marginTop: '25px' }}>
                <SelectInput
                    mode="multiple"
                    label="Ignorar automatización en estas monedas"
                    placeholder="Automatizando todas las monedas"
                    name="avoidAutomationsOnCurrencies"
                    description="Ignoraremos las automatizaciones en estas monedas"
                    options={currecies}
                />
            </Col> */}

            <Col xs={24} style={{ marginTop: '25px' }}>
                <CheckBoxInput
                    name="useForeignPublicInUSD"
                    label="Utilizar público en general extranjero"
                    content={
                        <Typography.Text className="p-small-regular neutral-3">
                            En caso de recibir un pago en moneda diferente a MXN utilizaremos el cliente Público en
                            general extranjero.
                        </Typography.Text>
                    }
                />
            </Col>

            <Col xs={24} style={{ marginTop: '25px' }}>
                <CheckBoxInput
                    id="invoice-defaults-automate-payment-complement-for-ppd-invoices-checkbox"
                    name="automatePaymentComplementForPPDInvoices"
                    label="Automatizar complementos de pago para facturas PPD"
                    content={
                        <Typography.Text className="p-small-regular neutral-3">
                            Al activar esta opción, se generarán automáticamente los complementos de pago cuando se registre un pago para una factura con método de pago PPD.
                        </Typography.Text>
                    }
                />
            </Col>
        </Row>
    )
}

export default AutomaticInvoiceDefaultsForm
