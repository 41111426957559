import { Button, Divider, Form, Modal, Row, Spin, Tabs, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { integrations } from '../../datasets/Integrations'
import {
    IntegrationOnbHeader,
    IntegrationOnboarding,
    IntegrationSucceededView,
} from '../../components/Integrations/IntegrationOnboarding'
import { ArrowLeft, Question } from '@phosphor-icons/react'
import { useEffect, useState } from 'react'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useAuth } from 'reactfire'
import { GetTeamHelper } from '../../context/databaseContextHelpers'
import {
    CSDForm,
    EditFiscalInformationForm,
    FiscalAddressForm,
    FiscalInformationForm,
    SATDefaults,
    UpdateCSD,
} from '../../components/Forms/Integrations/SatIntegration'
import ErrorsInFormComponent from '../../components/Forms/ErrorsInFormComponent'
import { addParamToUrl } from '../../functions/UrlParams'
import { usePostHog } from 'posthog-js/react'
import moment from 'moment'
import Link from 'antd/es/typography/Link'
import { trackEvent } from '../../analytics/helpers'

const FormLoader = () => {
    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 2,
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Spin />
        </div>
    )
}

const ManageSATIntegration = ({
    setTabSelected,
    error,
    tabSelected,
    state,
}: {
    setTabSelected: (tab: string) => void
    error: string | null
    tabSelected: string
    state: any
}) => {
    const { subtab } = useSelector((state: any) => state.data)
    const satIntegrationItems = [
        {
            key: 'invoicesDefaults',
            label: 'Emisión de facturas',
            children: <SATDefaults />,
        },
        {
            key: 'fiscalInformation',
            label: 'Información fiscal',
            children: <EditFiscalInformationForm />,
            disabled: false,
        },
        {
            key: 'updateCsd',
            label: 'Actualizar CSD',
            children: <UpdateCSD error={'' /** TODO Add error */} />,
            disabled: false,
        },
    ]

    return (
        <div style={{ padding: '20px 24px', position: 'relative' }}>
            {state.initialDataLoading && <FormLoader />}
            <div className="d-flex flex-column" style={{ marginBottom: '15px' }}>
                <Typography.Text className="mediumparagraphbold">Configura tu integración con el SAT</Typography.Text>
                <Typography.Text className="smallparagraph descriptions" style={{ marginTop: '5px' }}>
                    Actualiza el folio, serie y la información fiscal de tu cuenta
                </Typography.Text>
            </div>
            <Tabs
                items={satIntegrationItems}
                onTabClick={(s: string) => {
                    setTabSelected(s)
                    addParamToUrl('subtab', s)
                }}
                defaultActiveKey={subtab}
                activeKey={tabSelected}
            />

            {error && <ErrorsInFormComponent title="Error al actualizar tu información" errorMessage={error} />}
        </div>
    )
}

const FiscalInformation = () => {
    const { team } = useSelector((state: any) => state.team)
    const satIntegration = integrations(team).find((i) => i.id === 'facturapi')

    return (
        <div style={{ padding: '20px 24px' }}>
            <IntegrationOnbHeader
                logo={satIntegration?.logo ?? ''}
                title="Información fiscal"
                description={
                    <Typography.Text className="mediumparagraph">
                        Tal cual se muestra en tu {'\u00A0'}{' '}
                        <Typography.Link
                            underline
                            href="https://www.sat.gob.mx/aplicacion/53027/genera-tu-constancia-de-situacion-fiscal"
                            target="_blank"
                        >
                            constancia de situación fiscal
                        </Typography.Link>
                    </Typography.Text>
                }
                justify="start"
                width={24}
            />
            <Divider style={{ margin: '15px 0px' }} />

            <FiscalInformationForm />
        </div>
    )
}

const FiscalAddress = () => {
    const { team } = useSelector((state: any) => state.team)
    const satIntegration = integrations(team).find((i) => i.id === 'facturapi')

    return (
        <div style={{ padding: '20px 24px' }}>
            <IntegrationOnbHeader
                logo={satIntegration?.logo ?? ''}
                title="Dirección fiscal"
                description={
                    <Typography.Text className="mediumparagraph">
                        Tal cual se muestra en tu {'\u00A0'}{' '}
                        <Typography.Link
                            underline
                            href="https://www.sat.gob.mx/aplicacion/53027/genera-tu-constancia-de-situacion-fiscal"
                            target="_blank"
                        >
                            constancia de situación fiscal
                        </Typography.Link>
                    </Typography.Text>
                }
                justify="start"
                width={24}
            />

            <Divider style={{ margin: '15px 0px' }} />
            <FiscalAddressForm />
        </div>
    )
}

const UploadCSDConnection = ({ error }: { error: string | null }) => {
    const { team } = useSelector((state: any) => state.team)
    const satIntegration = integrations(team).find((i) => i.id === 'facturapi')

    return (
        <div style={{ padding: '20px 24px' }}>
            <IntegrationOnbHeader
                logo={satIntegration?.logo ?? ''}
                title=" Información para facturar: Sube tus sellos (CSD)"
                description={
                    <p className="mediumparagraph descriptions" style={{ margin: 0 }}>
                        Si tienes dudas de como generarlos visita:{' '}
                        <a
                            href="https://helpcenter.gigstack.pro/es/articles/6902738-genera-en-el-sat-tu-csd-certificado-de-sello-digital"
                            rel="noreferrer"
                            target="_blank"
                        >
                            {' '}
                            ¿Cómo obtengo mis sellos digitales?
                        </a>
                    </p>
                }
                justify="start"
                width={24}
            />
            <Divider style={{ margin: '15px 0px' }} />

            <CSDForm error={error} />
        </div>
    )
}

export const SatModal = () => {
    const posthog = usePostHog()
    const dispatch = useDispatch()
    const fs = getFirestore()
    const auth = useAuth()
    const [form] = Form.useForm()

    const { satModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const [state, setState] = useState({
        step: 1,
        error: null,
        loading: false,
        tabSelected: 'invoicesDefaults',
        initialDataLoading: true,
    })

    const satIntegration = integrations(team).find((i) => i.id === 'facturapi')

    const setFormInitialValues = async () => {
        let ftpiConfig: any = {}
        let ftpiFiscalData: any = {}

        try {
            const ftpiData = await SignedInternalAPIRequest(
                {},
                'facturapiAppHandlerInfo/data',
                auth.currentUser,
                {},
                'GET',
            )
            ftpiConfig = ftpiData.series
            ftpiFiscalData = ftpiData.legal
        } catch (error) {
            console.log('Error getting FTPi data', error)
        }

        const invoiceSerie = team?.invoice_serie || 'F'
        const invoiceSeriePayments = team?.invoice_serie_payments || 'P'
        const invoiceSerieEgress = team?.invoice_serie_egress || 'NC'

        const ftpiInvoiceSeries = ftpiConfig?.find((f: any) => f.series === invoiceSerie)
        const ftpiInvoiceSeriesPayments = ftpiConfig?.find((f: any) => f.series === invoiceSeriePayments)
        const ftpiInvoiceSeriesEgress = ftpiConfig?.find((f: any) => f.series === invoiceSerieEgress)
        const { address } = ftpiFiscalData

        form.setFieldsValue({
            taxSystem: ftpiFiscalData.tax_system ?? team?.tax_system ?? billingAccount?.tax_system ?? billingAccount?.taxSystem ?? '601',
            rfc: ftpiFiscalData.tax_id ?? team?.rfc ?? '',
            legalName: ftpiFiscalData?.legal_name ?? team?.legal_name ?? '',
            street: address?.street ?? team?.address?.street ?? billingAccount?.address?.street,
            exterior: address?.exterior ?? team?.address?.exterior ?? billingAccount?.address?.exterior,
            interior: address?.interior ?? team?.address?.interior_number ?? billingAccount?.address?.interior_number,
            neighborhood: address?.neighborhood ?? team?.address?.neighborhood ?? billingAccount?.address?.neighborhood,
            zip: address?.zip ?? team?.address?.zip ?? billingAccount?.address?.zip,
            city: address?.city ?? team?.address?.city ?? billingAccount?.address?.city,
            state: address?.state ?? team?.address?.state ?? billingAccount?.address?.state,

            invoice_folio: ftpiInvoiceSeries?.next_folio ?? 1,
            invoice_folio_test: ftpiInvoiceSeries?.next_folio_test ?? 1,
            invoice_folio_payments: ftpiInvoiceSeriesPayments?.next_folio ?? 1,
            invoice_folio_egress: ftpiInvoiceSeriesEgress?.next_folio ?? 1,

            invoice_serie: ftpiInvoiceSeries?.series || '',
            invoice_serie_payments: ftpiInvoiceSeriesPayments?.series || '',
            invoice_serie_egress: ftpiInvoiceSeriesEgress?.series || '',
        })
        addParamToUrl('open', 'sat')

        setState(() => ({ ...state, initialDataLoading: false }))
    }

    useEffect(() => {
        if (satModalVisible) {
            try {
                setFormInitialValues()
            } catch (error: any) {
                message.error('Ocurrió un error al cargar la información ', error?.message ?? '')
            }
        }

        if (!satModalVisible) {
            // removeParamFromUrl('open')
            // removeParamFromUrl('subtab')
            // setState(() => ({ ...state, step: 1, error: null, loading: false, tabSelected: 'invoicesDefaults' }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [satModalVisible])

    const ToRender = () => {
        if (!team?.facturapi?.completed || (team?.facturapi?.completed && state.step === 4)) {
            console.log('Step', state.step)
            switch (state.step) {
                case 0:
                    return (
                        <IntegrationOnboarding
                            logo={satIntegration?.logo ?? ''}
                            title="Conecta el SAT con Gigstack"
                            description="Emite facturas válidas en México"
                            bullets={[
                                { type: 'positive', description: 'Factura automáticamente' },
                                {
                                    type: 'positive',
                                    description: 'Crea complementos de pago y notas de crédito',
                                },
                                { type: 'positive', description: 'Crea facturas recurrentes' },
                            ]}
                            IntegrationFooter={<></>}
                        />
                    )
                case 1:
                    return <FiscalInformation />
                case 2:
                    return <FiscalAddress />
                case 3:
                    return <UploadCSDConnection error={state.error} />
                case 4:
                    return (
                        <IntegrationSucceededView
                            logo={satIntegration?.logo ?? ''}
                            title="¡Conexión éxitosa!"
                            description="Tu integración con el SAT está completa, ahora tu cuenta está lista para emitir facturas"
                        />
                    )
                default:
                    break
            }
        }

        return (
            <ManageSATIntegration
                state={state}
                setTabSelected={(tab) => setState({ ...state, tabSelected: tab })}
                tabSelected={state.tabSelected}
                error={state.error}
            />
        )
    }

    const handleFtpiConnection = async (values: any) => {
        if (team?.facturapi?.completed && state.step !== 4) {
            setState(() => ({ ...state, loading: true }))
            switch (state.tabSelected) {
                case 'invoicesDefaults':
                    try {
                        await SignedInternalAPIRequest(
                            {
                                folio_number: values.invoice_folio ?? 1,
                                folio_number_test: values.invoice_folio_test || 1,
                                folio_number_payments: values.invoice_folio_payments ?? 1,
                                folio_number_egress: values.invoice_folio_egress ?? 1,
                                teamId: team?.id,
                                serie: values.invoice_serie ?? team.invoice_serie ?? 'F',
                                serie_payments: values.invoice_serie_payments,
                                serie_egress: values.invoice_serie_egress,
                                billingAccount: billingAccount?.id,
                            },
                            'facturapiAppHandlerInfo/update',
                            auth.currentUser,
                            {} as any,
                            'POST',
                        )

                        await updateDoc(doc(fs, `teams/${team?.id}`), {
                            invoice_folio: values.invoice_folio ?? team.invoice_folio,
                            invoice_folio_test: values.invoice_folio_test ?? team.invoice_folio_test ?? 1,
                            invoice_folio_payments: values.invoice_folio_payments ?? team.invoice_folio_payments,
                            invoice_folio_egress: values.invoice_folio_egress ?? team.invoice_folio_egress,
                            invoice_serie: values.invoice_serie ?? team.invoice_serie,
                            invoice_serie_payments: values.invoice_serie_payments ?? team.invoice_serie_payments,
                            invoice_serie_egress: values.invoice_serie_egress ?? team.invoice_serie_egress,
                        })
                        trackEvent({ name: 'update_defaults' }, posthog)
                    } catch (error: any) {
                        setState(() => ({ ...state, error: error.message, loading: false }))
                        message.error(error?.message ?? 'Ocurrió un error al actualizar la información')
                        return
                    }
                    break
                case 'fiscalInformation':
                    try {
                        trackEvent({ name: 'update_fiscal' }, posthog)
                        await SignedInternalAPIRequest(
                            {
                                address: {
                                    street: values.street,
                                    exterior: values.exterior ?? '',
                                    interior: values.interior ?? '',
                                    neighborhood: values.neighborhood,
                                    city: values.city,
                                    state: values.state,
                                    zip: values.zip,
                                },
                                tax_system: values.taxSystem,
                                legal_name: values.legalName,
                                type: 'update_fiscal_data',
                            },
                            'invoicing',
                            auth.currentUser,
                        )

                        await updateDoc(doc(fs, `teams/${team?.id}`), {
                            tax_system: values.taxSystem,
                            taxSystem: values.taxSystem,
                            rfc: (values.rfc ?? '').toUpperCase(),
                            legal_name: (values.legalName ?? '').toUpperCase(),
                            legalName: (values.legalName ?? '').toUpperCase(),
                            address: {
                                street: values.street,
                                exterior: values.exterior ?? '',
                                interior: values.interior ?? '',
                                neighborhood: values.neighborhood,
                                city: values.city,
                                state: values.state,
                                municipality: values.municipality,
                                zip: values.zip,
                            },
                        })
                    } catch (error: any) {
                        setState(() => ({ ...state, error: error.message, loading: false }))
                        message.error(error?.message ?? 'Ocurrió un error al actualizar la información fiscal')
                        return
                    }
                    break
                case 'updateCsd':
                    try {
                        trackEvent({ name: 'update_csd' }, posthog)
                        const resp: any = await SignedInternalAPIRequest(
                            {
                                password: values.password,
                                rfc: team.rfc,
                                invoicingIntegration: 'facturapi',
                                type: 'connect',
                                typeCsd: 'update',
                            },
                            'invoicing',
                            auth.currentUser,
                        )
                        message.success(resp?.message ?? '¡Listo! Tus sellos están actualizados')
                    } catch (error: any) {
                        setState(() => ({ ...state, error: error.message, loading: false }))
                        message.error(error?.message ?? 'Ocurrió un error al actualizar los sellos')
                        return
                    }
                    break
            }
            GetTeamHelper(team?.id, dispatch)
            setState(() => ({ ...state, loading: false, error: null }))
            dispatch(closeModal('satModalVisible'))
            return
        }

        try {
            switch (state.step) {
                case 0:
                    setState(() => ({ ...state, step: state.step + 1 }))
                    trackEvent(
                        { name: 'sat_connection', metadata: { step: 1, date: moment().format('DD/MM/YYYY HH:mm') } },
                        posthog,
                    )
                    break
                case 1:
                    trackEvent(
                        { name: 'sat_connection', metadata: { step: 2, date: moment().format('DD/MM/YYYY HH:mm') } },
                        posthog,
                    )
                    setState(() => ({ ...state, loading: true }))
                    await updateDoc(doc(fs, `teams/${team?.id}`), {
                        tax_system: values.taxSystem,
                        taxSystem: values.taxSystem,
                        rfc: (values.rfc ?? '').toUpperCase(),
                        legal_name: (values.legalName ?? '').toUpperCase(),
                        legalName: (values.legalName ?? '').toUpperCase(),
                    })
                    setState(() => ({ ...state, loading: false, step: state.step + 1 }))
                    break
                case 2:
                    setState(() => ({ ...state, loading: true }))
                    trackEvent(
                        { name: 'sat_connection', metadata: { step: 3, date: moment().format('DD/MM/YYYY HH:mm') } },
                        posthog,
                    )
                    await updateDoc(doc(fs, `teams/${team?.id}`), {
                        address: {
                            street: values.street,
                            exterior: values.exterior ?? '',
                            interior: values.interior ?? '',
                            neighborhood: values.neighborhood,
                            zip: values.zip,
                            city: values.city,
                            state: values.state,
                        },
                    })
                    setState(() => ({ ...state, loading: false, step: state.step + 1 }))
                    break
                case 3:
                    setState(() => ({ ...state, loading: true, error: null }))
                    trackEvent(
                        { name: 'sat_connection', metadata: { step: 4, date: moment().format('DD/MM/YYYY HH:mm') } },
                        posthog,
                    )
                    await SignedInternalAPIRequest(
                        {
                            password: values.password,
                            rfc: team.rfc,
                            invoicingIntegration: 'facturapi',
                            type: 'connect',
                        },
                        'invoicing',
                        auth.currentUser,
                    )
                    setState(() => ({ ...state, loading: false, step: state.step + 1 }))
                    GetTeamHelper(team?.id, dispatch)
                    break
                case 4:
                    trackEvent(
                        { name: 'sat_connection', metadata: { step: 5, date: moment().format('DD/MM/YYYY HH:mm') } },
                        posthog,
                    )
                    break
            }
        } catch (error: any) {
            trackEvent({ name: 'sat_connection_errors', metadata: { error: error.message } }, posthog)
            setState(() => ({ ...state, loading: false, error: error.message }))
        }

        return
    }

    const handleButtonText = () => {
        if (team?.facturapi?.completed && state.step !== 4) {
            return 'Guardar'
        }
        if (state.step !== 4) {
            return 'Siguiente'
        }
        return 'Cerrar'
    }

    return (
        <Modal
            open={satModalVisible}
            onCancel={() => {
                setState(() => ({ ...state, step: 0 }))
                dispatch(closeModal('satModalVisible'))
            }}
            className="integrationModal"
            title={null}
            footer={null}
        >
            <Form layout="vertical" form={form} onFinish={handleFtpiConnection} disabled={state.initialDataLoading}>
                <ToRender />

                <div style={{ padding: '0px 24px 20px 24px' }}>
                    <Row justify={state.step !== 4 ? 'space-between' : 'center'} align="middle">
                        <Row>
                            {state.step !== 4 && (
                                <Link
                                    href="https://chatwoot.gigstack.io/hc/gigstack-help-center/articles/conecta-tu-csd-certificado-de-sello-digital-para-facturar-con-gigstack"
                                    target="_blank"
                                >
                                    <Button icon={<Question className="icon clickable" weight="regular" size={16} />}>
                                        <Typography.Text className="mediumparagraphbold">
                                            ¿Necesitas ayuda?
                                        </Typography.Text>
                                    </Button>
                                </Link>
                            )}
                        </Row>
                        <Row>
                            {state.step !== 0 && state.step !== 4 && (
                                <Button
                                    type="text"
                                    style={{ marginRight: '5px' }}
                                    icon={<ArrowLeft className="icon" size={18} weight="regular" />}
                                    onClick={() => {
                                        setState(() => ({ ...state, step: state.step - 1 }))
                                    }}
                                >
                                    Atrás
                                </Button>
                            )}
                            <Button
                                type="primary"
                                onClick={async () => {
                                    form.submit()
                                    // setState(() => ({ ...state, step: state.step + 1 }))
                                }}
                                loading={state.loading}
                            >
                                {handleButtonText()}
                            </Button>
                        </Row>
                    </Row>
                </div>
            </Form>
        </Modal>
    )
}
