import React, { useEffect, useState } from 'react'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useSelector } from 'react-redux'
import { useAuth } from 'reactfire'
import { Button, Col, Row, Table, Tag, Typography, message, Tooltip } from 'antd'
import { MetroSpinner } from 'react-spinners-kit'
import { PaperPlaneTilt } from '@phosphor-icons/react'
import { generateCode } from '../../functions/helpers'
import { usePermissions } from '../../customHooks/usePermissions'
import moment from 'moment'

const PendingInvitesTeamList: React.FC = () => {
    const permission = usePermissions()

    const auth = useAuth()
    const { team } = useSelector((state: any) => state.team)
    const [resendLoading, setResendLoading] = useState<any[]>([])
    const [invitesState, setInvitesState] = useState({
        loaded: false,
        invites: [] as any[],
        teamId: team?.id,
        failed: false,
        error: null,
    })

    const HandleResendInvite = async (invite: any) => {
        try {
            setResendLoading([...resendLoading, invite.id])

            await SignedInternalAPIRequest(
                {},
                `handleSeats/v1/invites/${invite.id}/resend`,
                auth.currentUser,
                {},
                'POST',
            )

            message.success('Invitación reenviada correctamente')
            setResendLoading(resendLoading.filter((l: any) => l !== invite.id))
        } catch (error: any) {
            console.log('*** error', error)
            message.error('Error al reenviar la invitación')
            setResendLoading(resendLoading.filter((l: any) => l !== invite.id))
        }
    }

    const getInvites = async () => {
        try {
            if (!team?.id) return

            setInvitesState({
                loaded: false,
                invites: [],
                teamId: team?.id,
                failed: false,
                error: null,
            })
            
            const rawInvites = await SignedInternalAPIRequest(
                {},
                `handleSeats/v1/teams/${team.id}/invites`,
                auth.currentUser,
                {},
                'GET',
            )

            setInvitesState({
                loaded: true,
                invites: rawInvites,
                teamId: team.id,
                failed: false,
                error: null,
            })
        } catch (error: any) {
            setInvitesState({
                loaded: true,
                invites: [],
                teamId: team.id,
                failed: true,
                error: error?.message ?? '',
            })
        }
    }

    useEffect(() => {
        getInvites()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formatRol = (rol: string) => {
        switch (rol) {
            case 'admin':
                return 'Administrador'
            case 'editor':
                return 'Editor'
            case 'viewer':
                return 'Visor'
            default:
                return rol
        }
    }

    const formatTimestamp = (timestamp: number) => {
        return moment(timestamp).format('DD/MM/YYYY HH:mm')
    }

    const isExpired = (expiringDate: number) => {
        return moment().valueOf() > expiringDate;
    }

    const columns: any = [
        {
            title: 'Correo',
            dataIndex: 'email',
            key: generateCode(5),
            fixed: 'left',
            width: '30%',
            sorter: (a: any, b: any) => (a?.email).localeCompare(b?.email),
            render: (text: string) => <Typography.Text>{text}</Typography.Text>,
        },
        {
            title: 'Rol',
            dataIndex: 'rol',
            key: generateCode(5),
            width: '15%',
            sorter: (a: any, b: any) => (a?.rol).localeCompare(b?.rol),
            render: (text: string) => <Typography.Text>{formatRol(text)}</Typography.Text>,
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: generateCode(5),
            width: '15%',
            sorter: (a: any, b: any) => (a?.status).localeCompare(b?.status),
            render: (text: string, record: any) => {
                let color = ''
                let statusText = ''
                
                if (text === 'pending' && isExpired(record.expiring)) {
                    color = 'red'
                    statusText = 'Expirada'
                } else {
                    switch (text) {
                        case 'pending':
                            color = 'orange'
                            statusText = 'Pendiente'
                            break
                        case 'accepted':
                            color = 'green'
                            statusText = 'Aceptada'
                            break
                        case 'expired':
                            color = 'red'
                            statusText = 'Expirada'
                            break
                        default:
                            color = 'default'
                            statusText = text
                    }
                }
                
                return (
                    <Tag color={color} style={{ borderRadius: '5px' }}>
                        {statusText}
                    </Tag>
                )
            },
        },
        {
            title: 'Fecha de creación',
            dataIndex: 'timestamp',
            key: generateCode(5),
            width: '20%',
            sorter: (a: any, b: any) => a.timestamp - b.timestamp,
            render: (text: number) => <Typography.Text>{formatTimestamp(text)}</Typography.Text>,
        },
        {
            title: 'Expira',
            dataIndex: 'expiring',
            key: generateCode(5),
            width: '20%',
            sorter: (a: any, b: any) => a.expiring - b.expiring,
            render: (text: number) => <Typography.Text>{formatTimestamp(text)}</Typography.Text>,
        },
        {
            title: '',
            dataIndex: 'actions',
            key: generateCode(5),
            width: '10%',
            render: (_: any, record: any) => {
                if (!permission.isTeamAdmin) return null
                
                const expired = isExpired(record.expiring);
                const canResend = record.status === 'pending' && !expired;

                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Tooltip title={expired ? "Invitación expirada" : "Reenviar invitación"}>
                            <Button 
                                type="link"
                                icon={<PaperPlaneTilt className={canResend ? 'clickableIcon icon' : ''} size={15} />}
                                loading={resendLoading.includes(record.id)}
                                onClick={() => HandleResendInvite(record)}
                                disabled={!canResend}
                            />
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    return (
        <Row>
            <Col xs={24}>
                <label>Invitaciones pendientes</label>
                <div style={{ marginTop: '20px' }}>
                    {invitesState.loaded ? (
                        <Table
                            dataSource={invitesState?.invites}
                            columns={columns}
                            pagination={false}
                            scroll={{ x: 1000 }}
                            locale={{ emptyText: 'No hay invitaciones pendientes' }}
                        />
                    ) : (
                        <MetroSpinner size={30} color="#686769" loading={true} />
                    )}
                </div>
            </Col>
        </Row>
    )
}

export default PendingInvitesTeamList 