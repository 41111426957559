import { Button, DatePicker, Form, message, Space, Typography } from 'antd'
import ReceiptsTable from '../components/Tables/ReceiptsTable'
import DynamicTabs from '../components/Menu/Tabs'
import { CalendarBlank, DownloadSimple, Gear } from '@phosphor-icons/react'
import TablesContainer from '../components/Tables/TablesContainer'
import { useDispatch } from 'react-redux'
import { openModal } from '../context/modalsSlice'
import dayjs from 'dayjs'
import moment from 'moment'
import { useState } from 'react'
import { usePermissions } from '../customHooks/usePermissions'
import { setData } from '../context/dataSlice'

const ReceiptsPage: React.FC = () => {
    const { canCreateReceipt } = usePermissions()

    // const { selectedReceipts } = useSelector((state: any) => state.data)
    // const navigate = useNavigate();
    const [periods, setPeriods] = useState<any>({
        start: null,
        end: null,
    })

    const dispatch = useDispatch()
    const openDrawer = () => {
        if (!canCreateReceipt.hasPermission) {
            message.warning(canCreateReceipt.reason ?? 'No tienes permisos para crear recibos')
            return
        }
        dispatch(openModal('receiptModalVisible'))
    }

    const TableContent = () => {
        return (
            <TablesContainer
                title="Recibos de venta"
                description="Recibos que tu cliente podrá auto-facturar en caso de requerirlo."
                actions={
                    <Space
                        wrap
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'end',
                            alignItems: 'center',
                        }}
                    >
                        <Form
                            initialValues={{
                                dates: periods.start ? [dayjs(periods.start), dayjs(periods.end)] : [],
                            }}
                        >
                            <Form.Item name="dates" style={{ margin: 0, padding: '4px 0', marginLeft: '5px' }}>
                                <DatePicker.RangePicker
                                    style={{ maxWidth: '250px' }}
                                    suffixIcon={<CalendarBlank size={17} weight="regular" className="icon" />}
                                    format="DD MMM YYYY"
                                    onChange={(a: any) => {
                                        if (!a)
                                            return setPeriods({
                                                start: null,
                                                end: null,
                                            })
                                        else
                                            setPeriods({
                                                start: moment(a[0].valueOf()).startOf('day').valueOf(),
                                                end: moment(a[1].valueOf()).endOf('day').valueOf(),
                                            })
                                    }}
                                    presets={[
                                        {
                                            label: `${moment().startOf('month').format('D')} al ${moment().endOf('month').format('D MMMM YYYY')}`,
                                            value: [dayjs().startOf('month'), dayjs().endOf('month')],
                                        },
                                        {
                                            label: `${moment().add(-1, 'month').startOf('month').format('D')} al ${moment().add(-1, 'month').endOf('month').format('D MMMM YYYY')}`,
                                            value: [
                                                dayjs().add(-1, 'month').startOf('month'),
                                                dayjs().add(-1, 'month').endOf('month'),
                                            ],
                                        },
                                        {
                                            label: '60 días',
                                            value: [dayjs().add(-60, 'days').startOf('day'), dayjs().endOf('day')],
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Form>
                        <Button
                            className="btn-dashed"
                            disabled={!canCreateReceipt.hasPermission}
                            icon={<Gear size={18} className="iconColor" weight="regular" />}
                        ></Button>
                        <Button
                            className="btn-secondary"
                            icon={<DownloadSimple className="iconColor" size={18} weight="regular" />}
                            onClick={() => {
                                if (!canCreateReceipt.hasPermission) {
                                    message.warning(
                                        canCreateReceipt.reason ?? 'No tienes permisos para descargar recibos',
                                    )
                                    return
                                }
                                dispatch(openModal('downloadsModalVisible'))
                                dispatch(
                                    setData({
                                        item: 'downloadInfo',
                                        data: { type: 'receipts', readableType: 'recibos' },
                                    }),
                                )
                            }}
                        >
                            Descargar
                        </Button>
                        <Button className="btn-primary" onClick={openDrawer}>
                            Crear
                        </Button>
                    </Space>
                }
                children={<ReceiptsTable max={10} startPeriod={periods.start} endPeriod={periods.end} />}
            />
        )
    }

    const tabsConfig = [
        {
            key: '1',
            title: 'Recibos',
            content: <TableContent />,
        },
        {
            key: '2',
            title: 'Acciones/Insights',
            content: <>Próximamente</>,
        },
    ]
    const handleTabChange = (activeKey: string) => {}

    return (
        <>
            <Typography.Text className="h2-bold neutral-1" style={{ marginBottom: '15px' }}>
                Recibos
            </Typography.Text>

            <DynamicTabs tabsConfig={tabsConfig} onChange={handleTabChange} />
        </>
    )
}
export default ReceiptsPage
