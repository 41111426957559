import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkCsdExpiration } from '../../helpers/helperFunctions'
import { Stamp } from '@phosphor-icons/react/dist/ssr'
import { openModal } from '../context/modalsSlice'
import { useNavigate } from 'react-router-dom'
import { setData } from '../context/dataSlice'
import { addParamToUrl } from '../functions/UrlParams'

export interface NoticeCard {
    id: string
    title: string
    description: string
    icon: () => JSX.Element
    expirationDate?: number
}

export function useNoticeCards() {
    // We can add more notice cards here
    const cardCatalog = [
        {
            id: 'csdToExpire',
            title: 'Tus sellos (CSD) están por vencer',
            description: 'Tus sellos vencerán el . Para evitar problemas con tu facturación, actualízalos ahora',
            icon: () => <Stamp size={30} color="var(--primary-1)" />,
            expirationDate: 0,
        },
        {
            id: 'csdExpired',
            title: 'Tus sellos (CSD) están vencidos',
            description: 'Tus sellos vencieron el . Para evitar problemas con tu facturación, actualízalos ahora',
            icon: () => <Stamp size={30} color="var(--primary-1)" />,
            expirationDate: 0,
        },
        {
            id: 'updateSat',
            title: 'Actualiza tus sellos (CSD)',
            description: 'Estamos dando mantenimiento a todas las conexiones con el SAT, por favor reingresa tus sellos',
            icon: () => <Stamp size={30} color="var(--primary-1)" />
        }
    ]
    const [noticeCards, setNoticeCards] = useState<NoticeCard[]>([])
    const [needsCsdNotice, setNeedsCsdNotice] = useState<{ expired: boolean, expirationDate: number } | null>(null)
    const [expiresAt, setExpiresAt] = useState<number | null>(null)

    const { team } = useSelector((state: any) => state.team)

    // More notice cards can be added here
    useEffect(() => {
        const expires_at = team?.facturapi?.certificate?.expires_at || team?.facturapi?.legal?.certificate?.expires_at
        const needs_csd_notice = expires_at ? checkCsdExpiration(expires_at) : null
        setExpiresAt(expires_at)
        setNeedsCsdNotice(needs_csd_notice || null)
    }, [team])

    useEffect(() => {
        if (!expiresAt) setNoticeCards([])

        const needs_sat_update = !team?.facturapi?.pwd && team?.facturapi?.completed

        if (needs_sat_update && !needsCsdNotice) {
            let updateSatCard = cardCatalog.find((card) => card.id === 'updateSat')!
            setNoticeCards((prev) => {
                if (!prev.some((card) => card.id === updateSatCard.id)) {
                    return [...prev, updateSatCard]
                }
                return prev
            })
        } else if (!needs_sat_update) {
            setNoticeCards((prev) => prev.filter((card) => card.id !== 'updateSat'))
        }

        if (needsCsdNotice && !needsCsdNotice.expired) {
            let csdToExpireCard = cardCatalog.find((card) => card.id === 'csdToExpire')!
            csdToExpireCard.expirationDate = needsCsdNotice.expirationDate
            setNoticeCards((prev) => {
                if (!prev.some((card) => card.id === csdToExpireCard.id)) {
                    return [...prev, csdToExpireCard]
                }
                return prev
            })
        } else if (needsCsdNotice && needsCsdNotice.expired) {
            let csdExpiredCard = cardCatalog.find((card) => card.id === 'csdExpired')!
            csdExpiredCard.expirationDate = needsCsdNotice.expirationDate
            setNoticeCards((prev) => {
                if (!prev.some((card) => card.id === csdExpiredCard.id)) {
                    return [...prev, csdExpiredCard]
                }
                return prev
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team, needsCsdNotice])

    return {
        noticeCards,
    }
}

export interface OnboardingStepCard {
    id: string
    description: string
    isCompleted: boolean
    action: () => void
}

export function useMissingOnboardingStepsCards() {

    const dispatch = useDispatch()
    const navigate  = useNavigate()
    
    const { team } = useSelector((state: any) => state.team)

    const cardCatalog = [
        {
            id: 'fiscalData',
            description: 'Configura tu información fiscal.',
            isCompleted: false,
            action: () => dispatch(openModal('satModalVisible'))
        },
        {
            id: 'satConnection',
            description: 'Conéctate al SAT.',
            isCompleted: false,
            action: () => dispatch(openModal('satModalVisible'))
        },
        {
            id: 'defaults',
            description: 'Configura los datos de tu facturación automática.',
            isCompleted: false,
            action: () => {
                dispatch(
                    setData({
                        item: 'settingsDrawerConfig',
                        data: {
                            type: 'defaults.invoice',
                            title: 'Configura las facturas automáticas',
                            description:
                                'La información predeterminada de tus facturas para las automatizaciones',
                        },
                    }),
                )
                dispatch(openModal('settingsDrawerVisible'))
                addParamToUrl('open', 'settings_automatic_invoices')
            }
        },
        {
            id: 'team',
            description: 'Configura tu marca e información comercial.',
            isCompleted: false,
            action: () => {
                dispatch(
                    setData({
                        item: 'settingsDrawerConfig',
                        data: {
                            type: 'brand',
                            title: 'Información de la marca',
                            description:
                                'Edita el logo, colores y comunicación pública para tus clientes.',
                        },
                    }),
                )
                dispatch(openModal('settingsDrawerVisible'))
                addParamToUrl('open', 'settings_brand')
            }
        },
        {
            id: 'integrations',
            description: 'Conecta una fuente de ingresos.',
            isCompleted: false,
            action: () => navigate('/integrations')
        },
    ]

    const [missingOnboardingSteps, setMissingOnboardingSteps] = useState<OnboardingStepCard[]>(cardCatalog)
    const [loading, setLoading] = useState(true)

    const hasIntegrations =
        team?.stripe?.completed ||
        team?.openpay?.completed ||
        team?.bank?.completed ||
        team?.zapier?.completed ||
        team?.airtable?.completed ||
        team?.googlesheets?.completed ||
        team?.whmcs?.completed ||
        team?.woocomerce?.completed ||
        team?.paypal?.completed ||
        team?.clip?.completed

    const needsFiscalData = (!team?.legal_name && !team?.legalName) || !team?.address?.zip || !team?.rfc

    const updateOnboardingSteps = () => {
        if (team?.facturapi?.completed) {
            setMissingOnboardingSteps((prev) =>
                prev.map((card) => (card.id === 'satConnection' ? { ...card, isCompleted: true } : card)),
            )
        } 
        if (team.brand?.alias && team.brand?.primaryColor && team.supportEmail) {
            setMissingOnboardingSteps((prev) =>
                prev.map((card) => (card.id === 'team' ? { ...card, isCompleted: true } : card)),
            )
        }
        if (!needsFiscalData) {
            setMissingOnboardingSteps((prev) =>
                prev.map((card) => (card.id === 'fiscalData' ? { ...card, isCompleted: true } : card)),
            )
        }
        if (team.defaults?.completed) {
            setMissingOnboardingSteps((prev) =>
                prev.map((card) => (card.id === 'defaults' ? { ...card, isCompleted: true } : card)),
            )
        }
        if (hasIntegrations) {
            setMissingOnboardingSteps((prev) =>
                prev.map((card) => (card.id === 'integrations' ? { ...card, isCompleted: true } : card)),
            )
        }
    }

    const sortOnboardingSteps = () => {
        setMissingOnboardingSteps((prev) => {
            const sortedSteps = prev.sort((a, b) => {
                if (a.isCompleted && !b.isCompleted) return 1
                if (!a.isCompleted && b.isCompleted) return -1
                return 0
            })
            return sortedSteps
        })
    }

    useEffect(() => {
        if (!team) return
        setTimeout(() => setLoading(false), 750)
        updateOnboardingSteps()
        sortOnboardingSteps()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team])

    const showOnboardingCards = missingOnboardingSteps.some((card) => !card.isCompleted)

    return { missingOnboardingSteps, showOnboardingCards, loading }
}
