import {
    Atom,
    CheckCircle,
    Receipt,
    CreditCard,
    UserCircle,
    ArrowRight,
    Invoice,
    PaperPlaneTilt,
    Link,
    Download,
} from '@phosphor-icons/react'
import { Col, FormInstance, Row, Typography, Card, Radio, Form, Button, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import StepLayout from '../StepsLayout'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../../context/dataSlice'
import { DownloadInvoice, registerDemoInvoice, registerDemoReceipt } from '../../../functions/helpers'
import { useAuth } from 'reactfire'
import { TextInput } from '../../../components/Forms/Inputs'
import { InvoiceDef } from '../../../../interfaces/invoicesDef'
import { ReceiptDef } from '../../../../interfaces/receiptsDef'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import moment from 'moment'
// import { ONBOARDING_STEP } from '../../../context/onboardingSlice'
import { setTeam } from '../../../context/teamsSlice'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../../../analytics/helpers'

interface TimeToValueStepProps {
    onStepCompleted: () => void
    form: FormInstance
    renderButtons: () => React.ReactNode
}

export const TimeToValueStep: React.FC<TimeToValueStepProps> = ({ onStepCompleted, form, renderButtons }) => {
    const dispatch = useDispatch()
    const auth = useAuth()
    const posthog = usePostHog()
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const [currentStep, setCurrentStep] = useState(0)
    const [isCompleted, setIsCompleted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [invoice, setInvoice] = useState<InvoiceDef | null>(null)
    const [portal, setPortal] = useState<ReceiptDef | null>(null)
    const [element, setElement] = useState('portal')

    const steps = [
        {
            icon: <CreditCard size={24} weight="fill" />,
            title: 'Recibes un pago',
            description: 'Un cliente realiza un pago a tu empresa',
            color: '#4096ff',
        },
        {
            icon: <Receipt size={24} weight="fill" />,
            title: 'Portal de auto-facturación',
            description: 'gigstack envía un portal personalizado',
            color: '#722ed1',
        },
        {
            icon: <UserCircle size={24} weight="fill" />,
            title: 'Cliente completa información',
            description: 'El cliente proporciona sus datos fiscales',
            color: '#13c2c2',
        },
        {
            icon: <Receipt size={24} weight="fill" />,
            title: 'Factura enviada',
            description: 'La factura se genera y envía automáticamente',
            color: '#52c41a',
        },
        {
            icon: <CheckCircle size={24} weight="fill" />,
            title: 'Conciliación completa',
            description: 'Tu empresa está completamente conciliada',
            color: '#389e0d',
        },
    ]

    useEffect(() => {
        let timer: NodeJS.Timeout

        const advanceStep = () => {
            setCurrentStep((prev) => {
                const nextStep = prev + 1
                if (nextStep >= steps.length) {
                    setIsCompleted(true)
                    return prev
                }
                return nextStep
            })
        }

        const restartAnimation = () => {
            setCurrentStep(0)
            setIsCompleted(false)
        }

        if (!isCompleted) {
            timer = setInterval(advanceStep, 2000)
        } else {
            timer = setTimeout(restartAnimation, 15000)
        }

        return () => {
            if (timer) {
                clearInterval(timer)
                clearTimeout(timer)
            }
        }
    }, [isCompleted, steps.length])

    useEffect(() => {
        dispatch(
            setData({
                item: 'fiscalInformationOnb',
                data: {
                    legal_name: team.legal_name,
                    rfc: team.rfc,
                    tax_system: team.tax_system,
                    address: team.address,
                    addressStr: `${team.address.street} #${team.address.exterior}, ${team.address.neighborhood}, ${team.address.city}, ${team.address.zip} ${team.address.state}`,
                },
            }),
        )

        trackEvent({ name: 'onboarding_time_to_value_viewed', metadata: { v: 'v2' } }, posthog)

        // eslint-disable-next-line
    }, [])

    const RenderTTV = (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Typography.Title level={3} className="fade-in-animation" style={{ margin: 0 }}>
                    <Atom
                        size={20}
                        weight="fill"
                        color={'#044737'}
                        className="primary-color"
                        style={{ marginRight: '5px' }}
                        onClick={async () => {
                            try {
                                // await registerDemoPayment({
                                //     user: auth.currentUser,
                                //     team,
                                //     automations: true,
                                //     clientExtras: {
                                //         email: 'santiago@gigstack.io',
                                //     },
                                //     paid: true,
                                // })
                                const invoice = await registerDemoInvoice({
                                    user: auth.currentUser,
                                    team,
                                    automations: true,
                                    clientExtras: {
                                        email: 'santiago@gigstack.io',
                                    },
                                })
                                await DownloadInvoice({
                                    invoice: invoice,
                                    type: 'pdf',
                                    downloadType: 'pdf',
                                    authUser: auth.currentUser,
                                })
                            } catch (error) {
                                console.log(error)
                            }
                        }}
                    />
                    Ahora prueba la magia que ofrece gigstack
                </Typography.Title>
                <Typography.Text
                    type="secondary"
                    className="fade-in-animation"
                    style={{ marginTop: '8px', display: 'block' }}
                >
                    Descubre cómo gigstack automatiza tu proceso de facturación
                </Typography.Text>
                <Typography.Text
                    className="smallparagraphbold "
                    style={{ marginTop: '25px', marginBottom: '10px', color: 'var(--neutral-10)' }}
                >
                    Envía una prueba
                </Typography.Text>
                <div className="d-flex flex-column">
                    <Radio.Group value={element}>
                        <div
                            className="d-flex flex-column clickable"
                            style={{ borderRadius: '8px', border: '1px solid #E4E4E7', padding: '10px' }}
                        >
                            <Row align="top" justify="space-between" onClick={() => setElement('portal')}>
                                <Atom size={16} color="#343330" />
                                <div className="d-flex flex-column" style={{ flex: 1, marginLeft: '10px' }}>
                                    <Typography.Text
                                        className="smallparagraphbold"
                                        style={{ color: 'var(--neutral-1)' }}
                                    >
                                        Portal de auto-factura
                                    </Typography.Text>
                                    <Typography.Text className="smallparagraph">
                                        Crea un portal para que tu cliente agregue la información fiscal
                                    </Typography.Text>
                                </div>
                                <Radio
                                    value="portal"
                                    checked={element === 'portal'}
                                    onChange={() => setElement('portal')}
                                ></Radio>{' '}
                            </Row>
                        </div>
                        <div
                            className="d-flex flex-column clickable"
                            style={{
                                borderRadius: '8px',
                                border: '1px solid #E4E4E7',
                                padding: '10px',
                                marginTop: '10px',
                            }}
                            onClick={() => setElement('invoice')}
                        >
                            <Row align="top" justify="space-between">
                                <Invoice size={16} color="#343330" />
                                <div className="d-flex flex-column" style={{ flex: 1, marginLeft: '10px' }}>
                                    <Typography.Text
                                        className="smallparagraphbold"
                                        style={{ color: 'var(--neutral-1)' }}
                                    >
                                        Factura electrónica
                                    </Typography.Text>
                                    <Typography.Text className="smallparagraph">
                                        Las facturas que reciben tus clientes se verán de la siguiente forma
                                    </Typography.Text>
                                </div>
                                <Radio
                                    value="invoice"
                                    checked={element === 'invoice'}
                                    onChange={() => setElement('invoice')}
                                ></Radio>{' '}
                            </Row>
                        </div>
                    </Radio.Group>
                    <Typography.Text
                        className="smallparagraphbold "
                        style={{ marginTop: '20px', color: 'var(--neutral-10)', marginBottom: '10px' }}
                    >
                        Enviar por correo
                    </Typography.Text>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={async () => {
                            console.log('something')
                            if (!invoice && !portal) {
                                trackEvent(
                                    { name: 'onboarding_time_to_value_dismissed', metadata: { v: 'v2' } },
                                    posthog,
                                )
                            } else {
                                trackEvent(
                                    { name: 'onboarding_time_to_value_next_step', metadata: { v: 'v2' } },
                                    posthog,
                                )
                            }
                            try {
                                await updateDoc(doc(getFirestore(), 'teams', team.id), {
                                    timeToValueViewed: moment().valueOf(),
                                })
                                dispatch(setTeam({ ...team, timeToValueViewed: moment().valueOf() }))
                                onStepCompleted()
                            } catch (error) {
                                console.log(error)
                            }
                        }}
                    >
                        <Row align="middle">
                            <TextInput
                                disabled={isLoading}
                                name="demoemail"
                                label=""
                                customStyle={{ flex: 1, margin: 0 }}
                                placeholder=""
                                defaultValue={user?.email}
                                type="email"
                            />
                            <Button
                                ghost
                                // className="btn-primary"
                                onClick={async () => {
                                    setIsLoading(true)
                                    setInvoice(null)
                                    setPortal(null)
                                    try {
                                        if (element === 'portal') {
                                            trackEvent(
                                                { name: 'onboarding_time_to_value_receipt', metadata: { v: 'v2' } },
                                                posthog,
                                            )
                                            const receipt = await registerDemoReceipt({
                                                user: auth.currentUser,
                                                team,
                                                // paid: true,
                                                automations: true,
                                                clientExtras: {
                                                    email: form.getFieldValue('demoemail'),
                                                },
                                            })
                                            setPortal(receipt)
                                            // await new Promise((resolve) => setTimeout(resolve, 2500))
                                            // const paymentDB = await getDoc(
                                            //     doc(getFirestore(), 'payments', payment.paymentId),
                                            // )
                                            // const receipt = paymentDB.data()?.receipts[0] ?? ''
                                            // if (receipt) {
                                            //     const receiptDB = await getDoc(doc(getFirestore(), 'receipts', receipt))
                                            //     setPortal(receiptDB.data() as ReceiptDef)
                                            // }
                                            notification.success({
                                                icon: <PaperPlaneTilt size={16} />,
                                                message: (
                                                    <Typography.Text className="smallparagraphbold">
                                                        Portal de auto-factura registrado correctamente
                                                    </Typography.Text>
                                                ),
                                                description: (
                                                    <Typography.Text className="smallparagraph">
                                                        Revisa tu correo para ver el portal de auto-factura
                                                    </Typography.Text>
                                                ),
                                            })
                                        } else {
                                            trackEvent(
                                                { name: 'onboarding_time_to_value_invoice', metadata: { v: 'v2' } },
                                                posthog,
                                            )
                                            const invoice = await registerDemoInvoice({
                                                user: auth.currentUser,
                                                team,
                                                automations: true,
                                                clientExtras: {
                                                    email: form.getFieldValue('demoemail'),
                                                },
                                            })
                                            await new Promise((resolve) => setTimeout(resolve, 1000))
                                            notification.success({
                                                icon: <PaperPlaneTilt size={16} />,
                                                message: (
                                                    <Typography.Text className="smallparagraphbold">
                                                        Factura registrada correctamente
                                                    </Typography.Text>
                                                ),
                                                description: (
                                                    <Typography.Text className="smallparagraph">
                                                        La factura se ha registrado correctamente, revisa tu correo para
                                                        ver la factura electrónica
                                                    </Typography.Text>
                                                ),
                                            })
                                            setInvoice(invoice)
                                            await DownloadInvoice({
                                                invoice: invoice,
                                                type: 'pdf',
                                                downloadType: 'pdf',
                                                authUser: auth.currentUser,
                                            })
                                        }
                                    } catch (error) {
                                        console.log(error)
                                    }
                                    setIsLoading(false)
                                }}
                                type="primary"
                                htmlType="button"
                                style={{
                                    margin: '0px',
                                    height: '40px',
                                    marginBottom: '5px',
                                    marginLeft: '10px',
                                    backgroundColor: 'var(--neutral-5)',
                                    border: '1px solid var(--neutral-5)',
                                }}
                                loading={isLoading}
                            >
                                Enviar
                                <PaperPlaneTilt size={16} />
                            </Button>
                        </Row>
                        {portal && (
                            <div
                                style={{
                                    backgroundColor: 'var(--primary-8)',
                                    padding: '12px 16px',
                                    borderRadius: '8px',
                                    marginTop: '16px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => window.open(portal.url, '_blank')}
                            >
                                <div className="d-flex flex-column  justify-content-between">
                                    <Typography.Text strong style={{ color: 'var(--primary-1)' }}>
                                        ¡Dale un vistazo al portal!
                                    </Typography.Text>
                                </div>
                                <Row align="middle">
                                    <Typography.Text style={{ color: 'var(--primary-1)', fontSize: '10px', flex: 1 }}>
                                        {portal.url}
                                    </Typography.Text>
                                    <Link size={16} style={{ color: 'var(--primary-1)' }} />
                                </Row>
                            </div>
                        )}
                        {invoice && (
                            <div
                                style={{
                                    backgroundColor: 'var(--primary-8)',
                                    padding: '12px 16px',
                                    borderRadius: '8px',
                                    marginTop: '16px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    DownloadInvoice({
                                        invoice: invoice,
                                        type: 'pdf',
                                        downloadType: 'pdf',
                                        authUser: auth.currentUser,
                                    })
                                }}
                            >
                                <div className="d-flex flex-column  justify-content-between">
                                    <Typography.Text strong style={{ color: 'var(--primary-1)' }}>
                                        ¡Descarga la factura electrónica!
                                    </Typography.Text>
                                </div>
                                <Row align="middle">
                                    <Typography.Text style={{ color: 'var(--primary-1)', fontSize: '10px', flex: 1 }}>
                                        {invoice.uuid}
                                    </Typography.Text>
                                    <Download size={16} style={{ color: 'var(--primary-1)' }} />
                                </Row>
                            </div>
                        )}
                        {!isLoading ? renderButtons() : null}
                    </Form>
                </div>
            </Col>
        </Row>
    )

    const GigstackExperiencePreview = (
        <div style={{ width: '100%', padding: '20px' }}>
            <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                {steps.map((step, index) => (
                    <Card
                        key={index}
                        style={{
                            marginBottom: '8px',
                            transform: `translateY(${index <= currentStep ? '0' : '-20px'})`,
                            opacity: index <= currentStep ? 1 : 0,
                            transition: 'all 0.5s ease-in-out',
                            borderLeft: `4px solid ${step.color}`,
                            backgroundColor: index === currentStep ? '#ffffff' : '#fafafa',
                            boxShadow: index === currentStep ? '0 2px 8px rgba(0,0,0,0.15)' : 'none',
                        }}
                        bodyStyle={{ padding: '12px' }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ color: step.color, marginRight: '12px' }}>{step.icon}</div>
                            <div style={{ flex: 1 }}>
                                <Typography.Text
                                    strong
                                    style={{
                                        opacity:
                                            index === currentStep || (isCompleted && index === steps.length - 1)
                                                ? 1
                                                : 0.7,
                                    }}
                                >
                                    {step.title}
                                </Typography.Text>
                                <Typography.Text
                                    type="secondary"
                                    style={{
                                        display: 'block',
                                        fontSize: '12px',
                                        opacity:
                                            index === currentStep || (isCompleted && index === steps.length - 1)
                                                ? 1
                                                : 0.7,
                                    }}
                                >
                                    {step.description}
                                </Typography.Text>
                            </div>
                            {index < steps.length - 1 && index === currentStep && !isCompleted && (
                                <ArrowRight
                                    size={20}
                                    style={{
                                        color: step.color,
                                        animation: 'pulse 1s infinite',
                                    }}
                                />
                            )}
                            {isCompleted && index === steps.length - 1 && (
                                <CheckCircle
                                    size={20}
                                    weight="fill"
                                    style={{
                                        color: step.color,
                                        animation: 'fadeIn 0.5s ease-in-out',
                                    }}
                                />
                            )}
                        </div>
                    </Card>
                ))}
            </div>
            <style>
                {`
                    @keyframes pulse {
                        0% { transform: scale(1); }
                        50% { transform: scale(1.2); }
                        100% { transform: scale(1); }
                    }
                    @keyframes fadeIn {
                        from { opacity: 0; }
                        to { opacity: 1; }
                    }
                `}
            </style>
        </div>
    )

    return <StepLayout form={RenderTTV} supportElement={GigstackExperiencePreview} />
}

export default TimeToValueStep
