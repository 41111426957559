import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

interface LanguageProviderProps {
    children: React.ReactNode
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
    const { i18n } = useTranslation()
    const { team } = useSelector((state: any) => state.team)

    useEffect(() => {
        if (team?.defaults) {
            const profile = team.defaults.isNonprofit ? 'nonprofit' : 'business'
            i18n.changeLanguage(profile)
        }
    }, [team?.defaults, i18n])

    return <>{children}</>
} 