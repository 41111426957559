import { useEffect } from 'react'
import { getItemsAmounts } from '../../functions/helpers'
import { useState } from 'react'
import { InternalItem } from '../../../interfaces/internalItemDef'
import { Alert, Tooltip } from 'antd'
import { StageSpinner } from 'react-spinners-kit'
import { Question } from '@phosphor-icons/react'

const taxTypes = [
    { key: 'iva_16', type: 'IVA', rate: '0.16', inclusive: true },
    { key: 'iva_no_16', type: 'IVA', rate: '0.16', inclusive: false },
    { key: 'isr_30', type: 'ISR', rate: '0.30', inclusive: true },
    { key: 'isr_no_30', type: 'ISR', rate: '0.30', inclusive: false },
]

export function createTaxObject(v: any, taxType: string) {
    // If it's a standard tax
    if (taxType !== 'customTax') {
        const selectedTax = taxTypes.find((tax) => tax.key === taxType)
        if (selectedTax && v[taxType]) {
            return [
                {
                    type: selectedTax.type,
                    rate: selectedTax.rate,
                    factor: 'Tasa',
                    inclusive: selectedTax.inclusive,
                    withholding: false,
                },
            ]
        }
    }

    // If it's a custom tax
    if (taxType === 'customTax' && v.customTax) {
        return [
            {
                type: v.type,
                rate: v.rate,
                factor: v.factor,
                inclusive: v.inclusive,
                withholding: v.withholding,
            },
        ]
    }

    return []
}

function useTaxValidation(formInfo: any, taxType: string) {
    const [taxValidation, setTaxValidation] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const itemTotal = 100

    useEffect(() => {
        if (!formInfo || !taxType) return
        
        setLoading(true)
        setTimeout(() => {
            const latestTaxes = createTaxObject(formInfo, taxType)
            console.log('latestTaxes for', taxType, latestTaxes)

            const validationItem: InternalItem[] = [
                {
                    description: 'Test',
                    quantity: 1,
                    unit_key: 'H87',
                    unit_name: 'Pieza',
                    taxes: latestTaxes,
                    owner: '',
                    discount: 0,
                    product_key: '01010101',
                    id: 'service_?',
                    paymentType: {
                        label: 'Precio fijo',
                        value: 'fixed',
                    },
                    total: itemTotal,
                    timestamp: 0,
                    name: 'Prueba',
                },
            ]

            const validationResult = getItemsAmounts(validationItem)
            setTaxValidation(validationResult)
            setLoading(false)
        }, 700)
    }, [formInfo, taxType])

    return { taxValidation, loading, itemTotal }
}

function ValidateTaxesComponent(taxFormValues: any) {
    const { formInfo, taxType } = taxFormValues
    const { taxValidation, loading, itemTotal } = useTaxValidation(formInfo, taxType)

    return (
        <div className="d-flex align-items-center justify-content-center">
            {loading ? (
                <StageSpinner size={12} color="#000000" loading={loading} />
            ) : taxValidation.total && taxValidation.total !== itemTotal ? (
                <Tooltip
                    className="p-xsmall-regular"
                    title={`Por ejemplo: un pago con un artículo de $${itemTotal} no podría ser importado, ya que su total de calculado sería $${taxValidation.total}`}
                >
                    <Alert
                        style={{ cursor: 'pointer' }}
                        className="p-xsmall-regular"
                        message={
                            <div className="d-flex flex-row align-items-center" style={{ gap: '5px' }}>
                                <Question size={16} color='var(--red-5)' />
                                <span className="p-xsmall-regular">Error en la validación de impuestos</span>
                            </div>
                        }
                        type="error"
                    />
                </Tooltip>
            ) : (
                <></>
            )}
        </div>
    )
}

export default ValidateTaxesComponent
