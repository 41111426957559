import { Row, Switch, Typography } from 'antd'
import IconHolder from '../IconHolder'
import { ChatTeardrop } from '@phosphor-icons/react'

const SwitchHolder = ({
    switchState = false,
    title = '',
    description = '',
    icon = <ChatTeardrop weight="regular" style={{ fontSize: '18px' }} />,
    changeState = () => {},
    disabled = false,
    value,
    loading = false,
}: {
    switchState?: boolean
    changeState?: Function
    title: string
    description?: string
    icon?: JSX.Element, 
    disabled?: boolean, 
    value?: boolean
    loading?: boolean
}) => {
    return (
        <Row
            justify="space-between"
            align="middle"
            style={{ backgroundColor: '#F9F9FA', padding: '10px', borderRadius: '10px' }}
        >
            <div className="d-flex flex-row" style={{ flex: 1 }}>
                <IconHolder icon={icon} width="40px" height="40px" />
                <div className="d-flex flex-column" style={{ margin: '0px 10px' }}>
                    <Typography.Title level={5} style={{ margin: 0, padding: 0, fontSize: '13px' }}>
                        {title}
                    </Typography.Title>
                    <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                        {description}
                    </Typography.Text>
                </div>
            </div>
            <Switch 
                defaultChecked={switchState} 
                onChange={(value) => changeState(value)}
                disabled={disabled || loading}
                loading={loading}
                {...(value !== undefined ? { checked: value } : {})}
            />
        </Row>
    )
}

export default SwitchHolder
