import { Modal } from 'antd'
import { useNavigate } from 'react-router-dom'

interface UpgradePlanModalProps {
    isOpen: boolean
    onClose: () => void
    title?: string
    content?: string
}

export const UpgradePlanModal = ({
    isOpen,
    onClose,
    title = '¡Actualiza tu plan!',
    content = 'Necesitas actualizar tu plan para acceder a esta funcionalidad.'
}: UpgradePlanModalProps) => {
    const navigate = useNavigate()

    return (
        <Modal
            title={title}
            open={isOpen}
            onCancel={onClose}
            okText="Ver planes"
            cancelText="Cancelar"

            okButtonProps={{
                className: 'btn-primary'
            }}
            cancelButtonProps={{
                className: 'btn-secondary'
            }}
            onOk={() => {
                onClose()
                navigate('/memberships')
            }}
        >   
            <div className="flex flex-col items-center justify-center gap-4">
                {content}
            </div>
        </Modal>
    )
} 