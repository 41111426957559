import { Rate, Space, Input, Button } from 'antd'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { doc, getFirestore, setDoc, getDocs, query, collection, limit, where, orderBy } from 'firebase/firestore'
import { CheckCircle, Spinner } from '@phosphor-icons/react'
import { Typography, message } from 'antd'
import { generateCode } from '../../functions/helpers'
import moment from 'moment'

export default function FeatureRating({ resource }: { resource: string }) {
    const { user } = useSelector((state: any) => state.user)
    const { team } = useSelector((state: any) => state.team)

    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [rating, setRating] = useState<number | null>(null)
    const [showRating, setShowRating] = useState(true)
    const [showComments, setShowComments] = useState(false)
    const [comments, setComments] = useState('')

    const translateResource = () => {
        switch (resource) {
            case 'invoice':
                return 'facturas'
            case 'payment':
                return 'pagos'
            default:
                return 'esta funcionalidad'
        }
    }

    useEffect(() => {
        // Check if the user has already rated this resource in the last month
        const checkPreviousRating = async () => {
            if (!user?.uid) return

            try {
                const ratingQuery = await getDocs(
                    query(
                        collection(getFirestore(), 'ratings'),
                        where('owner', '==', user.uid),
                        where('resourceType', '==', resource),
                        where('team', '==', team?.id),
                        orderBy('timestamp', 'desc'),
                        limit(1),
                    ),
                )

                const ratingData = ratingQuery.docs[0].data()
                if (ratingData) {
                    const lastRatingDate = ratingData.timestamp

                    if (lastRatingDate) {
                        const lastRatingMoment = moment(lastRatingDate)
                        const oneMonthAgo = moment().subtract(1, 'month')

                        if (lastRatingMoment.isAfter(oneMonthAgo)) {
                            setShowRating(false)
                        }
                    }
                }
            } catch (error) {
                console.error('Error checking previous rating:', error)
            }
        }

        checkPreviousRating()
    }, [user, resource, team])

    const handleRatingChange = async (value: number) => {
        if (!value) return

        setRating(value)

        // Show comments input if rating is 3.5 or less
        if (value <= 3.5) {
            setShowComments(true)
            return // Don't submit yet, wait for comments
        } else {
            submitRating(value)
        }
    }

    const submitRating = async (value: number, userComments: string = '') => {
        setLoading(true)

        try {
            // Create a unique ID for this rating
            const ratingId = generateCode(10, 'rtng')

            // Save the rating to Firestore
            await setDoc(
                doc(getFirestore(), 'ratings', ratingId),
                {
                    owner: user.uid,
                    team: team?.id,
                    resourceType: resource,
                    rating: value,
                    comments: userComments,
                    timestamp: moment().valueOf(),
                },
                { merge: true },
            )

            setSubmitted(true)
        } catch (error) {
            console.error('Error saving rating:', error)
            message.error('No se pudo guardar tu valoración. Inténtalo de nuevo.')
            setRating(null)
        } finally {
            setLoading(false)
        }
    }

    const handleCommentsSubmit = () => {
        if (rating !== null) {
            submitRating(rating, comments)
        }
    }

    // If user has already rated in the last month, don't show the rating component
    if (!showRating) {
        return null
    }

    if (submitted) {
        return (
            <Space direction="horizontal" align="center" style={{ width: '100%' }}>
                <CheckCircle size={24} weight="fill" color="#52c41a" />
                <Typography className="p-base-regular">¡Gracias por tu valoración!</Typography>
            </Space>
        )
    }

    return (
        <Space direction="vertical" align="center" style={{ width: '100%' }}>
            <Typography.Text style={{ display: 'block', marginBottom: '8px' }}>
                ¿Cómo calificarías tu experiencia {resource ? `con ${translateResource()}` : ''}?
            </Typography.Text>
            {loading && <Spinner size={24} weight="regular" />}

            {!loading && (
                <>
                    <Rate
                        allowHalf
                        allowClear={false}
                        value={rating || undefined}
                        onChange={handleRatingChange}
                        style={{ fontSize: '24px' }}
                    />

                    {showComments && (
                        <div style={{ marginTop: '16px', width: '100%', maxWidth: '400px' }}>
                            <Typography.Text
                                style={{
                                    display: 'block',
                                    marginBottom: '12px',
                                    color: '#595959',
                                    fontSize: '14px',
                                }}
                            >
                                ¿Cómo podríamos mejorar?
                            </Typography.Text>

                            <Input.TextArea
                                name="comments"
                                defaultValue={comments}
                                onChange={(e) => setComments(e.target.value)}
                                placeholder="Tus comentarios nos ayudan a mejorar"
                                style={{
                                    marginBottom: '16px',
                                    borderRadius: '8px',
                                    resize: 'none',
                                    boxShadow: 'none',
                                    border: '1px solid var(--neutral-4)',
                                    transition: 'all 0.3s ease',
                                }}
                            />

                            <Button className="btn-primary" style={{ width: '100%' }} onClick={handleCommentsSubmit}>
                                Enviar
                            </Button>
                        </div>
                    )}
                </>
            )}
        </Space>
    )
}
