import { Button, Col, Form, Modal, Row, Typography, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, openModal } from '../context/modalsSlice'
import { pushNewData, setData } from '../context/dataSlice'
import { CaretDoubleRight, Plus, X } from '@phosphor-icons/react'
import TaxesTable from '../components/Tables/TaxesTable'
import { CheckBoxInput, NumberInput, TextInput } from '../components/Forms/Inputs'
import { taxElementSet } from '../../interfaces/invoicesDef'
import ErrorsInFormComponent from '../components/Forms/ErrorsInFormComponent'
import { InternalItem, internalItemSet } from '../../interfaces/internalItemDef'
import { generateCode } from '../functions/helpers'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import { addParamToUrl, removeParamFromUrl } from '../functions/UrlParams'
import ProductKeySearch from '../components/ComponentIndividual/ProductKeySearch'
import UnitKeySearch from '../components/ComponentIndividual/UnitKeySearch'

const CreateServiceModal: React.FC = () => {
    const dispatch = useDispatch()

    const { createServiceVisible } = useSelector((state: any) => state.modals)
    const { service, taxes, selectedServices, testmode } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { user } = useSelector((state: any) => state.user)
    const [form] = Form.useForm()

    const [loading, setLoading] = useState<string[]>([])

    // const [initLoading, setInitLoading] = useState(true)

    const [errors, setErrors] = useState<any[]>([])

    const [useSKu, setUseSKu] = useState(false)

    const submitRef = React.useRef<any>()

    useEffect(() => {
        if (!service) {
            form.resetFields()
            // setInitLoading(false)
        } else if (service && createServiceVisible) {
            // form.resetFields();
            // form.setFieldsValue({
            //     ...service,
            //     use_sku: service?.sku ? true : false
            // })
            // setInitLoading(false)
        }

        if (createServiceVisible) {
            addParamToUrl('open', 'service')
        }
    }, [createServiceVisible, service, form])

    const Cancel = () => {
        form.resetFields()
        removeParamFromUrl('open')
        dispatch(
            setData({
                item: 'service',
                data: null,
            }),
        )
        dispatch(
            setData({
                item: 'taxes',
                data: [],
            }),
        )
        // setInitLoading(true)
        dispatch(closeModal('createServiceVisible'))
    }

    const Validate = async () => {
        try {
            setErrors([])
            await form.validateFields()
        } catch (error: any) {
            if (error?.errorFields?.length > 0) {
                setErrors(error.errorFields)
                message.error('Por favor revisa los errores en el formulario')
            }
        }
    }

    const Create = async () => {
        try {
            setErrors([])
            await form.validateFields()

            const product: InternalItem = internalItemSet({
                quantity: service?.quantity ?? 1,
                taxes: taxes,
                timestamp: service?.timestamp ?? new Date().getTime(),
                id: service?.id ?? generateCode(10, 'service'),
                owner: user.uid,
                billingAccount: billingAccount.id,
                team: team.id,
                ...form.getFieldsValue(),
            })

            setLoading(['submit'])
            const productDoc = doc(getFirestore(), 'services', product.id)
            if (service && service?.id) {
                await setDoc(productDoc, { ...product }, { merge: true })
                dispatch(
                    pushNewData({
                        item: 'services',
                        data: product,
                    }),
                )
            } else {
                await setDoc(productDoc, { ...product })
                dispatch(
                    pushNewData({
                        item: 'services',
                        data: product,
                    }),
                )
            }

            dispatch(
                setData({
                    item: 'taxes',
                    data: [],
                }),
            )
            dispatch(
                setData({
                    item: 'service',
                    data: null,
                }),
            )

            if (selectedServices?.length > 0) {
                // dispatch(updateData({
                //     item: 'selectedServices',
                //     data: product
                // }))

                let newSelectedServices = []

                if (service && service?.index !== undefined) {
                    // change the product in selectedServices in specific index
                    newSelectedServices = selectedServices?.map((s: any, index: number) =>
                        index === service?.index ? product : s,
                    )
                } else {
                    newSelectedServices = selectedServices?.map((s: any) => (s.id === product.id ? product : s))
                }

                dispatch(
                    setData({
                        item: 'selectedServices',
                        data: newSelectedServices,
                    }),
                )
            }
            form.resetFields()
            dispatch(closeModal('createServiceVisible'))
            setLoading(loading.filter((l) => l !== 'submit'))
            // setInitLoading(true)
        } catch (error: any) {
            console.log('ERROR: ', error)
            if (error?.errorFields?.length > 0) {
                message.error(error?.message ?? 'Ocurrió un error desconocido')
                setErrors(error.errorFields)
            }
            setLoading(loading.filter((l) => l !== 'submit'))
        }
    }

    return (
        <Modal
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        {service ? 'Editar producto / servicio' : 'Crear nuevo producto / servicio'}
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X className="icon clickable" size={20} onClick={Cancel} />
                                <></>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            closeIcon={<></>}
            width={window.innerWidth > 768 ? '60%' : '90%'}
            style={{ top: testmode ? 40 : 15 }}
            open={createServiceVisible}
            onCancel={Cancel}
            footer={null}
            zIndex={1001}
            className="relevant-modal"
        >
            {
                /*initLoading ? <>Cargando... </> : */ <Form
                    layout="vertical"
                    form={form}
                    initialValues={{
                        ...service,
                        use_sku: service?.sku ? true : false,
                    }}
                    style={{ maxHeight: '80vh', overflow: 'auto', marginTop: '20px' }}
                    // onSubmitCapture={Create}
                    onFinish={Create}
                    onSubmitCapture={Validate}
                >
                    <Row>
                        <Col xs={24}>
                            <TextInput
                                id="service-name-input"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingresa el nombre del producto / servicio',
                                    },
                                ]}
                                name={'name'}
                                label="Nombre del producto / servicio"
                                placeholder="Servicios de ___"
                            />
                        </Col>
                        <Col xs={24}>
                            <NumberInput
                                id="service-price-input"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor añade el precio unitario',
                                    },
                                ]}
                                name={'total'}
                                label="Precio unitario"
                                placeholder="100"
                                min={0}
                                max={100000000}
                                step={0.01}
                            />
                        </Col>
                        <Col xs={24}>
                            <CheckBoxInput
                                name="use_sku"
                                onChange={(v: any) => {
                                    setUseSKu(v.target.checked)
                                }}
                                content={<Typography.Text>Utilizar SKU</Typography.Text>}
                                description=""
                            />
                            {useSKu && (
                                <TextInput
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingresa el SKU o desactiva la opción de "Utilizar SKU"',
                                        },
                                    ]}
                                    name={'sku'}
                                    label="SKU"
                                    description="Identificador interno"
                                    placeholder="_____"
                                />
                            )}
                        </Col>
                    </Row>

                    <Col
                        xs={24}
                        style={{
                            padding: '10px',
                            borderRadius: '8px',
                            backgroundColor: '#fafbfd',
                        }}
                        className="d-flex flex-column"
                    >
                        <Row justify="space-between" align="middle" style={{ marginBottom: '10px' }}>
                            <Typography.Text className="smallheader">Valores utilizados para el SAT</Typography.Text>
                            <Button
                                id="service-use-default-values-button"
                                onClick={() => {
                                    form.setFieldsValue({
                                        product_key: team?.defaults?.product_key ?? '01010101',
                                        unit_key: team?.defaults?.unit_key ?? 'E48',
                                        unit_name: team?.defaults?.unit_name ?? 'Unidad de servicio',
                                    })

                                    dispatch(
                                        setData({
                                            item: 'taxes',
                                            data: (team?.defaults?.defaultTaxes ?? []).map((tax: any) =>
                                                taxElementSet(tax),
                                            ),
                                        }),
                                    )
                                }}
                            >
                                Utilizar pre-definidos
                            </Button>
                        </Row>
                        <ProductKeySearch />
                        <UnitKeySearch form={form} />
                        <TextInput
                            rules={[]}
                            name={'property_tax_account'}
                            label="Cuenta para el impuesto predial"
                            description=""
                            placeholder="Cuenta predial"
                        />

                        <Row justify="space-between" align="middle" style={{ marginBottom: '15px' }}>
                            <label>Impuestos</label>
                            <Button
                                id="service-add-tax-button"
                                htmlType="button"
                                onClick={() => dispatch(openModal('addTaxModalVisible'))}
                                icon={<Plus size={15} className="" />}
                            >
                                Añadir impuesto
                            </Button>
                        </Row>
                        <TaxesTable
                            customDelete={(record: any) => {
                                dispatch(
                                    setData({
                                        item: 'taxes',
                                        data: taxes
                                            .map((t: any) => taxElementSet(t))
                                            .filter((t: any) => t.id !== record.id),
                                    }),
                                )

                                dispatch(
                                    setData({
                                        item: 'service',
                                        data: {
                                            ...service,
                                            taxes: taxes
                                                .map((t: any) => taxElementSet(t))
                                                .filter((t: any) => t.id !== record.id),
                                        },
                                    }),
                                )
                            }}
                        />
                    </Col>

                    <Col xs={24}>
                        <ErrorsInFormComponent
                            formErrors={errors}
                            title={`Errores al ${service ? 'actualizar' : 'crear'}`}
                            form={form}
                        />
                    </Col>

                    <Row justify="end" style={{ marginTop: '15px' }}>
                        <Button
                            id="service-create-button"
                            ref={submitRef}
                            loading={loading.includes('submit')}
                            htmlType="submit"
                            type="primary"
                        >
                            {service ? 'Guardar cambios' : 'Crear producto / servicio'}
                            <CaretDoubleRight size={15} style={{ marginLeft: '5px' }} className="" weight="regular" />
                        </Button>
                    </Row>
                </Form>
            }
        </Modal>
    )
}

export default CreateServiceModal
