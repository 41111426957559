import React, { useState, useEffect } from 'react'
import { Menu } from 'antd'
import TeamHeader from '../UI/TeamHeader'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Cardholder,
    CurrencyDollar,
    Coins,
    HandCoins,
    BagSimple,
    CirclesFour,
    Gear,
    User,
    House,
    UserSquare,
    NewspaperClipping,
    Article,
    Users,
    GitBranch,
    Lightning,
} from '@phosphor-icons/react'
import { useSelector } from 'react-redux'
import { teamCompleteGettingStarted } from '../../../helpers/helperFunctions'
import i18n from '../../../i18n/init'

interface MenuItem {
    key: string
    icon: JSX.Element
    label: string
}

const SiderMenu: React.FC = () => {
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState<boolean>(false)
    let { tab } = useParams()
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { team } = useSelector((state: any) => state.team)

    const gettingStartedFinish = teamCompleteGettingStarted(team)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768)
        }
        handleResize()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const Home: MenuItem[] = [
        {
            key: 'home',
            icon: <House weight="regular" style={{ color: '#BCC0D3', width: '16px', height: '14px' }} />,
            label: 'Inicio',
        },
        {
            key: 'gettingStarted',
            icon: <Lightning weight="regular" style={{ color: '#BCC0D3', width: '16px', height: '14px' }} />,
            label: 'Getting Started',
        },
    ]

    const Invoicing: MenuItem[] = [
        {
            key: 'invoices',
            icon: <NewspaperClipping weight="regular" style={{ color: '#BCC0D3', width: '16px', height: '14px' }} />,
            label: 'Facturación',
        },
        {
            key: 'receipts',
            icon: <Article weight="regular" style={{ color: '#BCC0D3', width: '16px', height: '14px' }} />,
            label: 'Recibos de venta',
        },
    ]

    const Payments: MenuItem[] = [
        {
            key: 'payments',
            icon: <Coins weight="regular" style={{ color: '#BCC0D3', width: '16px', height: '14px' }} />,
            label: 'Pagos',
        },
        // {
        //     key: 'collections',
        //     icon: <HandCoins weight="regular" style={{ color: '#BCC0D3', width: '16px', height: '14px' }} />,
        //     label: 'Cobranza',
        // },
    ]

    const Manejo: MenuItem[] = [
        {
            key: 'products',
            icon: <CirclesFour weight="regular" className="neutral-2" style={{ width: '16px', height: '14px' }} />,
            label: 'Productos',
        },
        {
            key: 'clients',
            icon: <Users weight="regular" className="neutral-2" style={{ width: '16px', height: '14px' }} />,
            label: i18n.t('Clientes'),
        },
        {
            key: 'vendors',
            icon: <UserSquare weight="regular" className="neutral-2" style={{ width: '16px', height: '14px' }} />,
            label: 'Proveedores',
        },
    ]
    const Settings: MenuItem[] = [
        {
            key: 'integrations',
            icon: <GitBranch weight="regular" className="neutral-2" style={{ width: '16px', height: '14px' }} />,
            label: 'Integraciones',
        },
        {
            key: 'settings',
            icon: <Gear weight="regular" className="neutral-2" style={{ width: '16px', height: '14px' }} />,
            label: 'Configuracion',
        },
    ]

    return (
        <>
            {!isMobile && (
                <div
                    className="d-flex flex-column"
                    style={{ width: '100%', height: '100vh', borderRight: '1px solid var(--neutral-4)' }}
                >
                    <TeamHeader />
                    <div style={{ padding: '5px', backgroundColor: 'white', height: '100%' }}>
                        <Menu
                            style={{
                                border: '0px solid',
                                flex: 1,
                                overflow: 'auto',
                                height: '100%',
                                width: '100%',
                                backgroundColor: 'white',
                            }}
                            theme="light"
                            mode="vertical"
                            activeKey={tab}
                            defaultValue={tab}
                            inlineCollapsed={false}
                            onClick={(v) => {
                                navigate(`/${v.key}`)
                            }}
                        >
                            {Home.map((item) => {
                                if (item.key === 'gettingStarted' && gettingStartedFinish.isComplete) return null

                                return (
                                    <Menu.Item key={item.key} className="p-base-regular neutral-1" icon={item.icon} id={`sidebar-${item.key}`}>
                                        {item.label}
                                    </Menu.Item>
                                )
                            })}

                            <h1 className="p-base-bold neutral-1" style={{ marginLeft: '10px', marginTop: '30px' }}>
                                Facturación
                            </h1>
                            {Invoicing.map((item) => (
                                <Menu.Item key={item.key} className="p-base-regular " icon={item.icon}>
                                    {item.label}
                                </Menu.Item>
                            ))}

                            <h1 className="p-base-bold neutral-1" style={{ marginLeft: '10px', marginTop: '30px' }}>
                                Pagos
                            </h1>

                            {Payments.map((item) => (
                                <Menu.Item key={item.key} className="p-base-regular " icon={item.icon}>
                                    {item.label}
                                </Menu.Item>
                            ))}
                            <h1 className="p-base-bold neutral-1" style={{ marginLeft: '10px', marginTop: '30px' }}>
                                Manejo
                            </h1>
                            {Manejo.map((item) => {
                                if (item.key === 'vendors' && (!billingAccount?.isMarketplace || !team?.isMasterTeam))
                                    return null
                                return (
                                    <Menu.Item key={item.key} className="p-base-regular " icon={item.icon}>
                                        {item.label}
                                    </Menu.Item>
                                )
                            })}

                            <h1 className="p-base-bold" style={{ marginLeft: '10px', marginTop: '30px' }}>
                                Configuración
                            </h1>
                            {Settings.map((item) => (
                                <Menu.Item key={item.key} className="p-base-regular " icon={item.icon}>
                                    {item.label}
                                </Menu.Item>
                            ))}
                        </Menu>
                    </div>
                </div>
            )}

            {isMobile && (
                <div
                    className="d-flex flex-column"
                    style={{ width: '100%', backgroundColor: 'white', height: '100vh' }}
                >
                    <div style={{ width: '100%' }}>
                        <TeamHeader mobile={true} />
                    </div>
                    <div
                        className="mobile-menu"
                        style={{
                            width: window.innerWidth < 780 ? '70px' : '',
                            overflow: 'auto',
                            marginTop: '10px',
                            flex: 1,
                        }}
                    >
                        <Menu
                            defaultValue={tab}
                            activeKey={tab}
                            onClick={(v) => {
                                navigate(`/${v.key}`)
                            }}
                        >
                            <Menu.Item
                                key="home"
                                icon={<House weight="regular" style={{ color: '#BCC0D3', fontSize: '15px' }} />}
                            />
                            <Menu.Item
                                key="invoices"
                                icon={
                                    <CurrencyDollar weight="regular" style={{ color: '#BCC0D3', fontSize: '15px' }} />
                                }
                            />
                            <Menu.Item
                                key="receipts"
                                icon={<Cardholder weight="regular" style={{ color: '#BCC0D3', fontSize: '15px' }} />}
                            />
                            <Menu.Item
                                key="payments"
                                icon={<Coins weight="regular" style={{ color: '#BCC0D3', fontSize: '15px' }} />}
                            />
                            <Menu.Item
                                key="collections"
                                icon={<HandCoins weight="regular" style={{ color: '#BCC0D3', fontSize: '15px' }} />}
                            />
                            <Menu.Item
                                key="products"
                                icon={<BagSimple weight="regular" style={{ color: '#BCC0D3', fontSize: '15px' }} />}
                            />
                            <Menu.Item
                                key="clients"
                                icon={<User weight="regular" style={{ color: '#BCC0D3', fontSize: '15px' }} />}
                            />
                            <Menu.Item
                                key="vendors"
                                icon={<UserSquare weight="regular" style={{ color: '#BCC0D3', fontSize: '15px' }} />}
                            />
                            <Menu.Item
                                key="integrations"
                                icon={<CirclesFour weight="regular" style={{ color: '#BCC0D3', fontSize: '15px' }} />}
                            />
                            <Menu.Item
                                key="settings"
                                icon={<Gear weight="regular" style={{ color: '#BCC0D3', fontSize: '15px' }} />}
                            />
                        </Menu>
                    </div>
                </div>
            )}
        </>
    )
}

export default SiderMenu
