import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    searchResult: null,
    searchConfig: {
        type: null,
        query: '',
        fields: [],
    },
}

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchResult: (state, action) => {
            state.searchResult = action.payload
        },
        setSearchConfig: (state, action) => {
            state.searchConfig = action.payload
        },
        clearSearch: (state) => {
            state.searchResult = null
            state.searchConfig = initialState.searchConfig
        },
    },
})

export const { setSearchResult, setSearchConfig, clearSearch } = searchSlice.actions

export default searchSlice.reducer
