import { GetClientHelper, GetServicesByIdsHelper } from '../context/databaseContextHelpers'
import { setData } from '../context/dataSlice'
import { openModal } from '../context/modalsSlice'
import { modalsFromUrl } from './modalsParams'

type availableUrlParams =
    | 'open'
    | 'edit'
    | 'clientId'
    | 'services'
    | 'id'
    | 'invoiceType'
    | 'tab'
    | 'subtab'
    | 'subscription'
    | 'tour'

export const handleUrlParams = (dispatch: any) => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)

    const open = searchParams.get('open')
    const tab = searchParams.get('tab')
    const subtab = searchParams.get('subtab')
    const clientId = searchParams.get('clientId')
    const services = searchParams.get('services')
    const invoiceType = searchParams.get('invoiceType')

    if (open) {
        modalsFromUrl.find((modal) => modal.type === open)?.modal &&
            dispatch(openModal(modalsFromUrl.find((modal) => modal.type === open)?.modal))

        if (open.includes('settings_')) {
            dispatch(setData(modalsFromUrl.find((modal) => modal.type === open)?.data))
        }
    }

    if (tab) {
        dispatch(
            setData({
                item: 'tab',
                data: tab,
            }),
        )
    }

    if (subtab) {
        dispatch(
            setData({
                item: 'subtab',
                data: subtab,
            }),
        )
    }

    if (invoiceType) {
        dispatch(
            setData({
                item: 'invoice',
                data: {
                    type: invoiceType,
                    invoiceType: invoiceType,
                    step: 1,
                },
            }),
        )
    }

    if (clientId) {
        GetClientHelper(dispatch, clientId)
    }

    if (services && services?.split(',')?.length > 0) {
        GetServicesByIdsHelper(dispatch, services.split(',') || [])
    }
}

export const addParamToUrl = (param: availableUrlParams, value: string) => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    searchParams.set(param, value)
    url.search = searchParams.toString()
    window.history.pushState({}, '', url.toString())
}

export const removeParamFromUrl = (param: availableUrlParams) => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    searchParams.delete(param)
    url.search = searchParams.toString()
    window.history.pushState({}, '', url.toString())
}
