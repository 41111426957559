import React, { useState, useEffect } from 'react'
import { Row, Typography, Switch, Divider, Alert, Space } from 'antd'
import { CreditCard, CaretDown, CaretUp, Info } from '@phosphor-icons/react'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../context/dataSlice'
import PaymentsMethods from './PaymentsMetods'

const PaymentOptionsSwitch: React.FC = () => {
    const dispatch = useDispatch()
    const { custom_method_types, automatePayment } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    
    const [expanded, setExpanded] = useState(false)
    
    const hasPaymentMethods = team?.stripe?.completed || team?.bank?.completed
    
    useEffect(() => {
        if (custom_method_types && custom_method_types.length > 0) {
            dispatch(setData({
                item: 'automatePayment',
                data: true
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [custom_method_types])
    
    const handleSwitchChange = (checked: boolean) => {
        dispatch(setData({
            item: 'automatePayment',
            data: checked
        }))
        
        setExpanded(checked)
        
        if (!checked) {
            dispatch(setData({
                item: 'custom_method_types',
                data: []
            }))
        }
    }
    
    return (
        <div id="payment-options-switch" className="payment-options-container" style={{ marginBottom: '16px' }}>
            <Row align="middle" justify="space-between" 
                style={{ 
                    padding: '12px 16px',
                    background: 'var(--neutral-11)',
                    borderRadius: '8px',
                    border: '1px solid var(--neutral-4)'
                }}
            >
                <Space size={12}>
                    <CreditCard size={20} weight="regular" className="neutral-2" />
                    <div>
                        <Typography.Text className="p-base-bold neutral-1">
                            Solicitar un pago
                        </Typography.Text>
                        <div>
                            <Typography.Text className="p-small-regular neutral-3">
                                Puedes solicitar un pago a tus clientes mediante un enlace de pago
                            </Typography.Text>
                        </div>
                    </div>
                </Space>
                
                <div className="switch-container">
                    <Switch 
                        disabled={!hasPaymentMethods}
                        checked={automatePayment}
                        onChange={handleSwitchChange}
                    />
                </div>
            </Row>
            
            {!hasPaymentMethods && (
                <Alert
                    type="info"
                    showIcon
                    style={{ marginTop: '8px' }}
                    message={
                        <Typography.Text className="p-small-regular">
                            Para ofrecer opciones de pago, primero configura tus métodos en Configuración → Integraciones
                        </Typography.Text>
                    }
                />
            )}
            
            {automatePayment && (
                <div className="payment-methods-container"
                    style={{
                        padding: '16px',
                        marginTop: '8px',
                        background: 'white',
                        borderRadius: '8px',
                        border: '1px solid var(--neutral-4)'
                    }}
                >
                    <Row justify="space-between" align="middle" 
                        style={{ marginBottom: '16px', cursor: 'pointer' }}
                        onClick={() => setExpanded(!expanded)}
                    >
                        <Typography.Text className="p-base-bold neutral-1">
                            Métodos de pago disponibles
                        </Typography.Text>
                        
                        {expanded ? 
                            <CaretUp size={16} weight="bold" className="neutral-3" /> : 
                            <CaretDown size={16} weight="bold" className="neutral-3" />
                        }
                    </Row>
                    
                    {expanded && (
                        <>
                            <Divider style={{ margin: '0 0 16px 0' }} />
                            <PaymentsMethods hideTitle={true} />
                            
                            {custom_method_types && custom_method_types.length > 0 && (
                                <Alert
                                    type="info"
                                    icon={<Info size={16} />}
                                    style={{ marginTop: '16px' }}
                                    message={
                                        <Typography.Text className="p-small-regular">
                                            Tu cliente recibirá un enlace de pago con los métodos de pago seleccionados en la solicitud
                                        </Typography.Text>
                                    }
                                />
                            )}
                            
                            {custom_method_types.length === 0 && automatePayment && (
                                <Alert
                                    type="warning"
                                    icon={<Info size={16} />}
                                    style={{ marginTop: '16px' }}
                                    message={
                                        <Typography.Text className="p-small-regular">
                                            Debes seleccionar al menos un método de pago
                                        </Typography.Text>
                                    }
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default PaymentOptionsSwitch 