import { Button, Col, Input, InputRef, Modal, Row, Space, Table, Typography, message, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, setElementSelected } from '../context/modalsSlice'
import { ClientsColumns } from '../components/Tables/Columns/ClientsColumns'
import { MetroSpinner } from 'react-spinners-kit'
import { useEffect, useRef, useState } from 'react'
import { GetClients } from '../context/databaseContextHelpers'
import { ClientDef, clientSet } from '../../interfaces/clientDef'
import { FilterConfirmProps, ColumnType } from 'antd/lib/table/interface'
import { CaretDoubleRight, MagnifyingGlass, X } from '@phosphor-icons/react'
import { setData } from '../context/dataSlice'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'

const AssignClientModal = () => {
    const dispatch = useDispatch()
    const { assignClientModalVisible } = useSelector((state: any) => state.modals)
    //access to the element invoice in state modals
    const { assignTo, clients, clientsLoaded } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)

    const [selected, setSelected] = useState<ClientDef[] | any[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (assignClientModalVisible && clients.length <= 0) {
            GetClients(dispatch, team.id, clientsLoaded)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignClientModalVisible])

    const [, /*searchText*/ setSearchText] = useState('')
    const [, /*searchedColumn*/ setSearchedColumn] = useState('')
    const searchInput = useRef<InputRef>(null)

    type DataIndex = keyof ClientDef

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters: () => void) => {
        clearFilters()
        setSearchText('')
    }

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<ClientDef> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Typography.Text
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys as string[], confirm, dataIndex)
                        }}
                        className="smallparagraph descriptions clickable"
                    >
                        Restablecer
                    </Typography.Text>

                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<MagnifyingGlass />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>

                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        <X className="icon" />
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => {
            return (
                <MagnifyingGlass
                    style={{
                        color: filtered ? '#8666FF' : '#50566B',
                    }}
                    size={16}
                />
            )
        },
        onFilter: (value: any, record: ClientDef) =>
            (record[dataIndex] ?? '')
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text: any, record: any) =>
            ClientsColumns({
                record,
                columnName: 'name',
            }),
    })

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: ClientDef[]) => {
            // dispatch(setData({
            //     item: 'selectedClients',
            //     data: selectedRows
            // }))
            //SAVE SELECTED ON STATE TAKING IN MIND THE SEARCH AND FILTER
            // setSelectedRows(selectedRowKeys)

            setSelected(selectedRowKeys)
        },

        getCheckboxProps: (record: ClientDef) => ({
            disabled: !record.id, // Column configuration not to be checked
            name: record.id,
        }),
    }

    const AssignButton = () => {
        return (
            <Button
                loading={loading}
                disabled={selected.length <= 0}
                type="primary"
                onClick={async (e) => {
                    if (!assignTo || !assignTo?.collection || !assignTo?.id) {
                        message.error('No se puede asignar un cliente a este elemento')
                        return
                    }
                    const path = doc(getFirestore(), assignTo?.collection ?? '', assignTo?.id ?? '')
                    const clientSelected = clients.find((client: ClientDef) => client.id === selected[0])
                    if (!clientSelected) return
                    setLoading(true)
                    try {
                        await updateDoc(path, {
                            clientId: selected[0],
                            clientID: selected[0],
                            client: clientSet(clientSelected),
                        })
                        setLoading(false)
                        dispatch(closeModal('assignClientModalVisible'))
                        dispatch(
                            setData({
                                item: 'toAssing',
                                data: null,
                            }),
                        )
                        setSelected([])
                    } catch (error: any) {
                        setLoading(false)
                        notification.error({
                            message: 'Ocurrió un error al asignar el cliente',
                            description: error?.message ?? 'Error desconocido',
                        })
                    }

                    //     clientId: selected[0]

                    // })
                }}
            >
                Asignar
                <CaretDoubleRight size={15} style={{ marginLeft: '5px' }} className="" weight="regular" />
            </Button>
        )
    }

    return (
        <Modal
            style={{
                top: 5,
            }}
            width={'60%'}
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        Asignar cliente
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X
                                    className="icon clickable"
                                    size={20}
                                    onClick={() => {
                                        dispatch(closeModal('assignClientModalVisible'))
                                        dispatch(
                                            setData({
                                                item: 'toAssing',
                                                data: null,
                                            }),
                                        )
                                        setSelected([])
                                    }}
                                />
                                <></>
                                <AssignButton />
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            closable={false}
            closeIcon={<></>}
            open={assignClientModalVisible}
            onCancel={() => {
                dispatch(closeModal('assignClientModalVisible'))
                dispatch(
                    setElementSelected({
                        element: 'assignTo',
                        data: null,
                    }),
                )
            }}
            footer={null}
        >
            <div className="d-flex flex-column">
                {clients.length <= 0 ? (
                    <Row justify="center" style={{ marginTop: '25px' }}>
                        <MetroSpinner size={20} color="#8666FF" />
                    </Row>
                ) : (
                    <Table
                        scroll={{ x: 900 }}
                        style={{
                            marginTop: '25px',
                        }}
                        rowKey={(record: ClientDef) => record.id}
                        rowSelection={{
                            type: 'radio',
                            selectedRowKeys: [...selected],
                            ...rowSelection,
                        }}
                        dataSource={clients}
                        columns={[
                            {
                                title: 'Nombre',
                                dataIndex: 'name',
                                key: 'name',
                                ...getColumnSearchProps('name'),
                                fixed: 'left',
                                width: '20%',
                                render: (text: any, record: any) =>
                                    ClientsColumns({
                                        record,
                                        columnName: 'name',
                                    }),
                            },
                            {
                                title: 'Email',
                                dataIndex: 'email',
                                key: 'email',
                                width: '25%',
                                ...getColumnSearchProps('email'),
                                render: (text: any, record: any) =>
                                    ClientsColumns({
                                        record,
                                        columnName: 'email',
                                    }),
                            },
                            {
                                title: 'RFC',
                                dataIndex: 'rfc',
                                key: 'rfc',
                                ...getColumnSearchProps('rfc'),
                                render: (text: any, record: any) =>
                                    ClientsColumns({
                                        record,
                                        columnName: 'rfc',
                                    }),
                            },
                            {
                                title: 'Empresa',
                                dataIndex: 'company',
                                key: 'company',
                                ...getColumnSearchProps('company'),
                                render: (text: any, record: any) =>
                                    ClientsColumns({
                                        record,
                                        columnName: 'company',
                                    }),
                            },
                        ]}
                    />
                )}

                <Row justify="end">
                    <AssignButton />
                </Row>
            </div>
        </Modal>
    )
}

export default AssignClientModal
