import { useNavigate } from 'react-router-dom'
import {
    DownloadFile,
    DownloadInvoice,
    HandleEmailStatus,
    SearchParamInURL,
    generateCode,
    invoiceUsage,
    readableElementSource,
    returnCurrencyValue,
    taxRegimes,
} from '../functions/helpers'
import {
    Alert,
    Avatar,
    Button,
    Col,
    Divider,
    Form,
    Modal,
    Row,
    Table,
    Tag,
    Timeline,
    Skeleton,
    Typography,
    message,
    Tabs,
    Tooltip,
} from 'antd'
import { useAuth, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire'
import { arrayUnion, collection, doc, getDoc, getFirestore, query, updateDoc, where } from 'firebase/firestore'
import { paymentSet } from '../../interfaces/paymentsDef'
import PaymentsActions from '../components/ElementActions/PaymentsActions'
import moment from 'moment'
import { PaymentStatusTag, ReceiptStatusTag } from '../components/UI/ElementStatus'
import ProductsServicesTotals from '../components/ComponentIndividual/ProductsServicesTotals'
import {
    ArrowLeft,
    BookOpen,
    CalendarBlank,
    CalendarCheck,
    CursorClick,
    Copy,
    CreditCard,
    Download,
    Envelope,
    Eye,
    FilePdf,
    Flag,
    GitFork,
    HandCoins,
    IdentificationBadge,
    Info,
    MapTrifold,
    Path,
    StarFour,
    TagChevron,
    X,
    Link,
    CheckCircle,
    Prohibit,
    CaretRight,
    Question,
} from '@phosphor-icons/react'
import { Suspense, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SelectInput, TextAreaInput } from '../components/Forms/Inputs'
import { paymentForms } from '../datasets/Fiscal'
import { LogsRelatedToElement } from '../components/Logs/LogsRelatedToElement'
import { DetailItem } from './ReceiptsViewDetail'
import { MetroSpinner } from 'react-spinners-kit'
import InvoiceActions from '../components/ElementActions/InvoiceActions'
import ReceiptActions from '../components/ElementActions/ReceiptsActions'

const MarkAsPaidModal = ({ open, close, data }: { open: boolean; close: Function; data: any }) => {
    const { user } = useSelector((state: any) => state.user)
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    return (
        <Modal
            open={open}
            closable={false}
            style={{ top: 5 }}
            onCancel={() => close()}
            title={
                <div style={{ position: 'relative', paddingBottom: '0px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        Completar pago
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X className="icon clickable" size={20} onClick={() => close()} />
                                <></>
                                <Button type="primary" onClick={() => form.submit()}>
                                    Completar
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            footer={null}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={async () => {
                    setLoading(true)
                    try {
                        await updateDoc(doc(getFirestore(), 'payments', data?.fid), {
                            status: 'succeeded',
                            payment_form: form.getFieldValue('payment_form'),
                            notes: (data?.notes ?? '') + ' ' + form.getFieldValue('notes'),
                            logs: arrayUnion({
                                timestamp: moment().valueOf(),
                                message: 'Pago completado',
                                user: user.uid,
                            }),
                        })
                        setLoading(false)
                        form.resetFields()
                        close()
                    } catch (error) {
                        setLoading(false)
                    }
                }}
            >
                <Row justify="center">
                    <Col xs={24}>
                        <Row justify="start" align="middle" style={{ marginBottom: '15px', marginTop: '25px' }}>
                            <Typography.Text className="smallheader descriptions">
                                ¿Estás seguro que deseas marcar como pagado este pago?
                            </Typography.Text>
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <SelectInput
                                    options={paymentForms}
                                    label="Forma de pago"
                                    rules={[{ required: true, message: 'Selecciona una forma de pago' }]}
                                    placeholder="Forma de pago"
                                    name="payment_form"
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Col xs={24}>
                                <TextAreaInput name="notes" label="Notas" placeholder="Agrega notas adicionales" />
                            </Col>
                        </Row>
                        <Row justify="space-between" align="middle">
                            <div></div>
                            <Button loading={loading} type="primary" onClick={() => form.submit()}>
                                Completar
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const PaymentDetailsView = () => {
    const auth = useAuth()
    const { team } = useSelector((state: any) => state.team)
    const [clientData, setClientData] = useState<any>(null)
    const [downloading, setDownloading] = useState<any>([])
    // const [invoiceRelated, setInvoiceRelated] = useState<any>(null)
    const [receiptsRelated, setReceiptsRelated] = useState<any>([])
    const [invoicesRelated, setInvoicesRelated] = useState<any>([])

    const [completePayment, setCompletePayment] = useState(false)
    // const { team } = useSelector((state: any) => state.team)
    // const { user } = useSelector((state: any) => state.user)
    const { anchorLocation } = useSelector((state: any) => state.data)
    // const dispatch = useDispatch()
    const navigate = useNavigate()
    const fs = getFirestore()
    const id = SearchParamInURL('id')

    if (!id) {
        message.warning('No se encontró el id del pago')
        navigate('payments')
    }

    const paymentRef = doc(fs, 'payments', id ?? '')
    const {
        status,
        data,
        error: paymentError,
    } = useFirestoreDocData(paymentRef, {
        suspense: true,
    })
    const [cronogram, setCronogram] = useState<any>([
        {
            id: 'emails_loading',
            timestamp: moment().valueOf(),
            dot: <MetroSpinner size={18} color="#8666FF" />,
            children: (
                <div className="d-flex flex-column">
                    <Typography.Text className="smallparagraphbold">
                        Correos
                        <Typography.Text className="smallparagraphbold primary">enviados</Typography.Text>
                    </Typography.Text>
                    <Typography.Text className="smallparagraph">
                        {moment(data?.timestamp).format('DD MMM YY HH:mm')}
                    </Typography.Text>
                </div>
            ),
        },
    ])
    const {
        status: emailStatus,
        data: emailsData,
        error: emailsError,
    } = useFirestoreCollectionData(
        query(collection(fs, 'emails'), where('related', '==', `payments/${id}`), where('team', '==', team.id)),
        {
            suspense: true,
        },
    )
    useEffect(() => {
        const updateCronogram = async () => {
            const allCronogramItems = new Map()
            console.log('GETTING DATA')
            console.log(data.invoices)
            console.log(data?.invoices?.length)

            // Add creation event
            try {
                allCronogramItems.set('creation', {
                    id: 'creation',
                    timestamp: data?.timestamp,
                    dot: <HandCoins size={18} weight="regular" className="icon" />,
                    children: (
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallparagraphbold">
                                Pago desde
                                <Typography.Text className="smallparagraphbold primary">
                                    {'\u00A0' + readableElementSource(data?.from ?? 'manual')}
                                </Typography.Text>
                            </Typography.Text>
                            <Typography.Text className="smallparagraph">
                                {moment(data?.timestamp).format('DD MMM YY HH:mm')}
                            </Typography.Text>
                        </div>
                    ),
                })
            } catch (error) {
                console.log(error)
            }

            // Add completion event if payment succeeded
            try {
                if (data?.status === 'succeeded' && data?.succeededTimestamp) {
                    allCronogramItems.set('completion', {
                        id: 'completion',
                        timestamp: data.succeededTimestamp,
                        dot: <Flag size={18} weight="regular" className="icon" />,
                        children: (
                            <div className="d-flex flex-column">
                                <Typography.Text className="smallparagraphbold">
                                    Pago completado
                                    <Typography.Text className="smallparagraphbold primary">
                                        {'\u00A0'}exitosamente
                                    </Typography.Text>
                                </Typography.Text>
                                <Typography.Text className="smallparagraph">
                                    {moment(data.succeededTimestamp).format('DD MMM YY HH:mm')}
                                </Typography.Text>
                            </div>
                        ),
                    })
                }
            } catch (error) {
                console.log(error)
            }

            // Add email events if available
            try {
                if (emailStatus === 'success' && emailsData) {
                    emailsData.forEach((email) => {
                        allCronogramItems.set(`email_${email.id}`, {
                            id: `email_${email.id}`,
                            timestamp: email.timestamp,
                            dot: <Envelope size={18} className="icon" />,
                            children: (
                                <div className="d-flex flex-column">
                                    <Typography.Text className="smallparagraphbold">
                                        Enviado a
                                        <Typography.Text className="smallparagraphbold primary">
                                            {'\u00A0' + email.to.join(', ')}
                                        </Typography.Text>
                                    </Typography.Text>
                                    <Typography.Text className="smallparagraph">
                                        {moment(email.timestamp).format('DD MMM YY HH:mm')}
                                    </Typography.Text>
                                </div>
                            ),
                        })
                    })
                }
            } catch (error) {
                console.log(error)
            }

            // Handle receipts
            try {
                if (data?.receipts && data?.receipts.length > 0) {
                    const uniqueReceipts = new Map()

                    for (const pi of data.receipts) {
                        const paymentRef = doc(fs, 'receipts', pi as string)
                        const receiptDocSnapshot = await getDoc(paymentRef)

                        if (receiptDocSnapshot.exists()) {
                            const receiptData = receiptDocSnapshot.data()
                            uniqueReceipts.set(pi, { ...receiptData, id: pi })

                            // Add receipt creation event
                            allCronogramItems.set(`receipt_${pi}`, {
                                id: `receipt_${pi}`,
                                timestamp: receiptData?.timestamp,
                                dot: <CreditCard size={18} weight="regular" className="icon" />,
                                children: (
                                    <Tooltip
                                        title={`El portal se generó por la siguiente razón: ${receiptData?.invoiceErrorMessage}`}
                                    >
                                        <div className="d-flex flex-column">
                                            <Typography.Text
                                                className="smallparagraphbold"
                                                onClick={() => {
                                                    console.log(receiptData)
                                                }}
                                            >
                                                Portal de auto-facturación
                                                <Typography.Text
                                                    className="smallparagraphbold primary"
                                                    style={{ marginLeft: '5px' }}
                                                >
                                                    creado
                                                </Typography.Text>
                                            </Typography.Text>
                                            <Typography.Text className="smallparagraph">
                                                {moment(receiptData?.timestamp).format('DD MMM YY HH:mm')}
                                            </Typography.Text>
                                        </div>
                                    </Tooltip>
                                ),
                            })

                            // Add receipt completion event if completed
                            if (receiptData?.status === 'completed') {
                                allCronogramItems.set(`receipt_${pi}_completed`, {
                                    id: `receipt_${pi}_completed`,
                                    timestamp: receiptData?.completedTimestamp,
                                    dot: <CheckCircle size={18} weight="regular" className="icon" />,
                                    children: (
                                        <div className="d-flex flex-column">
                                            <Typography.Text className="smallparagraphbold">
                                                Auto-factura
                                                <Typography.Text
                                                    className="smallparagraphbold primary"
                                                    style={{ marginLeft: '5px' }}
                                                >
                                                    completado
                                                </Typography.Text>
                                            </Typography.Text>
                                            <Typography.Text className="smallparagraph">
                                                {moment(receiptData?.completedTimestamp).format('DD MMM YY HH:mm')}
                                            </Typography.Text>
                                        </div>
                                    ),
                                })
                            }
                            // if ((receiptData?.emailID ?? []).length > 0) {
                            //     console.log('setting emails')

                            //     for (const emailsReceipt of receiptData?.emailID) {
                            //         allCronogramItems.set(`receiptWithEmail_${emailsReceipt}`, {
                            //             id: `receiptWithEmail_${emailsReceipt}`,
                            //             timestamp: moment(receiptData?.lastEmail).add(1, 'minutes').valueOf(),
                            //             dot: <Envelope size={18} className="icon" />,
                            //             children: (
                            //                 <div className="d-flex flex-column">
                            //                     <Typography.Text className="smallparagraphbold">
                            //                         Portal de auto-facturación enviado por correo
                            //                     </Typography.Text>
                            //                     <Typography.Text className="smallparagraph">
                            //                         {(receiptData.emails ?? [])?.join(', ') ??
                            //                             receiptData?.client?.email}
                            //                     </Typography.Text>
                            //                 </div>
                            //             ),
                            //         })
                            //     }
                            // }
                        }
                    }

                    // Update receiptsRelated state with unique receipts
                    setReceiptsRelated(Array.from(uniqueReceipts.values()))
                }
            } catch (error) {
                console.log(error)
            }

            // Handle invoices

            try {
                if (data?.invoices && data?.invoices.length > 0) {
                    console.log('has invoices')

                    const uniqueInvoices = new Map()
                    setInvoicesRelated(uniqueInvoices)
                    for (const ii of data.invoices) {
                        const invoiceRef = doc(fs, 'invoices', ii as string)
                        const invoiceDocSnapshot = await getDoc(invoiceRef)

                        if (invoiceDocSnapshot.exists()) {
                            console.log('invoice exost')

                            const invoiceData = invoiceDocSnapshot.data()
                            uniqueInvoices.set(ii, { ...invoiceData, id: ii })

                            allCronogramItems.set(`invoice_${ii}`, {
                                id: `invoice_${ii}`,
                                timestamp: moment(invoiceData?.timestamp).add(1, 'minutes').valueOf(),
                                dot: <FilePdf size={18} weight="regular" className="icon" />,
                                children: (
                                    <Tooltip
                                        title={
                                            invoiceData?.from === 'global'
                                                ? 'Se agrupo dentro de la factura global, debido a que no se contó con la información fiscal en el periodo'
                                                : ''
                                        }
                                    >
                                        <div className="d-flex flex-column">
                                            <Row>
                                                <Typography.Text className="smallparagraphbold">
                                                    Factura {invoiceData.from === 'global' ? 'global' : ''} creada
                                                    <Typography.Text
                                                        className="smallparagraphbold primary"
                                                        style={{ marginLeft: '5px' }}
                                                    >
                                                        {invoiceData?.series}-{invoiceData?.folio_number}
                                                    </Typography.Text>
                                                </Typography.Text>
                                                {downloading.includes('pdf') ? (
                                                    <MetroSpinner size={18} color="#8666FF" />
                                                ) : (
                                                    <Download
                                                        weight="regular"
                                                        className="icon clickable"
                                                        style={{ marginLeft: '5px' }}
                                                        onClick={async () => {
                                                            // return console.log(invoiceData)
                                                            setDownloading((prev: string[]) => [...prev, 'pdf'])
                                                            await DownloadInvoice({
                                                                invoice: invoiceData,
                                                                type: 'pdf',
                                                                authUser: auth.currentUser,
                                                            })
                                                            setDownloading((prev: string[]) =>
                                                                prev.filter((item) => item !== 'pdf'),
                                                            )
                                                        }}
                                                    />
                                                )}
                                            </Row>

                                            <Typography.Text className="smallparagraph">
                                                {moment(invoiceData?.timestamp + 10).format('DD MMM YY HH:mm')}
                                            </Typography.Text>
                                        </div>
                                    </Tooltip>
                                ),
                            })
                            // if ((invoiceData.emailID ?? []).length > 0) {
                            //     for (const emailsInvoice of invoiceData.emailID) {
                            //         allCronogramItems.set(`email_${emailsInvoice}`, {
                            //             id: `email_${emailsInvoice}`,
                            //             timestamp: moment(invoiceData?.lastEmail).add(1, 'minutes').valueOf(),
                            //             dot: <Envelope size={18} className="icon" />,
                            //             children: (
                            //                 <div className="d-flex flex-column">
                            //                     <Typography.Text className="smallparagraphbold">
                            //                         Factura enviada por correo
                            //                     </Typography.Text>
                            //                     <Typography.Text className="smallparagraph">
                            //                         {(invoiceData?.emails ?? []).join(', ')}
                            //                     </Typography.Text>
                            //                 </div>
                            //             ),
                            //         })
                            //     }
                            // }
                        }
                        setInvoicesRelated(Array.from(uniqueInvoices.values()))
                    }

                    // Update invoicesRelated state with unique invoices
                }
            } catch (error) {
                console.log(error)
            }

            // Convert Map to array and sort by timestamp
            const sortedCronogram = Array.from(allCronogramItems.values()).sort(
                (a: any, b: any) => b.timestamp - a.timestamp,
            )

            // Update cronogram
            setCronogram(sortedCronogram)
        }

        updateCronogram()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, emailStatus, emailsData])
    useEffect(() => {
        console.log('fetching client data')

        const fetchClientData = async () => {
            if (clientData) return
            if (data?.clientId ?? data?.clientID) {
                const clientRef = doc(fs, 'clients', data?.clientId ?? data?.clientID ?? data?.client?.id ?? '')
                const clientDocSnapshot = await getDoc(clientRef)

                if (clientDocSnapshot.exists()) {
                    setClientData(clientDocSnapshot.data())
                }
            }
        }

        fetchClientData()
    }, [clientData, data?.client?.id, data?.clientID, data?.clientId, fs])

    if (status === 'loading' || !data) {
        return <>Cargando...</>
    }

    if (status === 'error' || emailStatus === 'error' || paymentError || emailsError) {
        return (
            <>
                <Typography.Text onClick={() => navigate('payments')}>Volver</Typography.Text>
            </>
        )
    }

    const back = () => {
        if (anchorLocation) return navigate(anchorLocation)
        else return window.history.back()
    }

    const DetailsTab = () => {
        return (
            <div className="d-flex flex-column">
                <Typography.Text className="smallheader">Detalles</Typography.Text>
                <Divider />
                <Table<any>
                    columns={[
                        {
                            title: 'Producto/Servicio',
                            dataIndex: 'name',
                            key: 'name',
                            render: (text) => (
                                <Typography.Text
                                    className="smallparagraph"
                                    style={{
                                        color: '#416D60',
                                    }}
                                >
                                    {text}
                                </Typography.Text>
                            ),
                        },
                        {
                            title: 'Cantidad',
                            dataIndex: 'quantity',
                            key: 'quantity',
                            render: (text) => <Typography.Text className="smallparagraph">{text}</Typography.Text>,
                        },
                        {
                            title: 'Subtotal',
                            dataIndex: 'total',
                            key: 'total',
                            render: (text) => (
                                <Typography.Text className="smallparagraph">
                                    {returnCurrencyValue(text, data?.currency)}
                                </Typography.Text>
                            ),
                        },
                        {
                            title: 'Total',
                            dataIndex: 'total',
                            key: 'total',
                            render: (text, record) => (
                                <Typography.Text className="smallparagraph">
                                    {returnCurrencyValue(Number(text) * Number(record.quantity), data?.currency)}
                                </Typography.Text>
                            ),
                        },
                    ]}
                    bordered={false}
                    dataSource={data.items}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 3,
                    }}
                    style={{ marginBottom: '15px' }}
                />
                <Row>
                    <Col xs={12}></Col>
                    <Col xs={12}>
                        <ProductsServicesTotals incomingItems={data?.items} boxed={false} />
                    </Col>
                </Row>

                <Row style={{ margin: '25px 0px', borderTop: '1px solid #E0E0E0', paddingTop: '15px' }}>
                    <Col xs={12}>
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallheader">Cronograma</Typography.Text>

                            <Timeline
                                style={{ margin: '30px 15px' }}
                                // TODO Add more items to timeline
                                items={[...cronogram].sort((a: any, b: any) => b.timestamp - a.timestamp)}
                            />
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="" hidden>
                            <Typography.Text className="smallheader">Archivos / Enlaces</Typography.Text>
                            <DetailItem
                                icon={<FilePdf />}
                                title="Recibo en PDF"
                                value={
                                    <Typography.Text
                                        className="smallparagraph clickable"
                                        onClick={() => {
                                            if (data?.pdf) {
                                                setDownloading((prev: string[]) => [...prev, 'pdf'])
                                                DownloadFile({
                                                    url: data?.pdf,
                                                    name: `Recibo de venta "${data?.id}"`,
                                                    type: 'pdf',
                                                }).finally(() => {
                                                    setDownloading((prev: string[]) =>
                                                        prev.filter((item) => item !== 'pdf'),
                                                    )
                                                })
                                            }
                                        }}
                                    >
                                        Recibo de venta "{data?.id}"{' '}
                                        <Download weight="regular" className="icon" style={{ marginLeft: '5px' }} />
                                    </Typography.Text>
                                }
                            />
                            <DetailItem
                                icon={<CursorClick />}
                                title="Link para autofactura"
                                value={
                                    <Typography.Text
                                        className="smallparagraph clickable"
                                        onClick={() => {
                                            window.open(data?.url, '_blank')
                                        }}
                                    >
                                        {data?.url}
                                    </Typography.Text>
                                }
                            />
                            {invoicesRelated[0] && (
                                <DetailItem
                                    icon={<FilePdf />}
                                    title="Factura relacionada"
                                    value={
                                        <Typography.Text
                                            className="smallparagraph clickable"
                                            onClick={() => {
                                                setDownloading((prev: string[]) => [...prev, 'pdf'])
                                                DownloadInvoice({
                                                    invoice: invoicesRelated[0],
                                                    type: 'pdf',
                                                    downloadType: 'pdf',
                                                    authUser: auth.currentUser,
                                                    // customDownloading: (id: string) => {
                                                    //     setDownloading((prev: string[]) => [...prev, id])
                                                    // },
                                                    customFileName: `Factura "${invoicesRelated[0]?.series}-${invoicesRelated[0]?.folio_number}"`,
                                                }).finally(() => {
                                                    setDownloading((prev: string[]) =>
                                                        prev.filter((item) => item !== 'pdf'),
                                                    )
                                                })
                                            }}
                                        >
                                            Factura {invoicesRelated[0]?.series}-{invoicesRelated[0]?.folio_number}
                                            <Download weight="regular" className="icon" style={{ marginLeft: '5px' }} />
                                        </Typography.Text>
                                    }
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const RelationsTab = () => {
        return (
            <div className="d-flex flex-column">
                {/* <Typography.Text className="smallheader">
                    Relaciones{' '}
                    <span className="smallparagraph" style={{ marginLeft: '5px' }}>
                        ({receiptsRelated.length + invoicesRelated.length})
                    </span>
                </Typography.Text>
                <Divider /> */}
                <Row style={{ marginTop: '25px' }}>
                    {receiptsRelated.length > 0 && (
                        <Col xs={24}>
                            <Row justify="start" align="middle">
                                <Tooltip title="Generamos los recibos de venta, cuando no tenemos la información fiscal del cliente, de esta forma el cliente puede agregar su información fiscal para emitir la factura.">
                                    <Question size={16} className="icon" style={{ marginRight: '5px' }} />
                                </Tooltip>
                                <Typography.Text className="smallheader">
                                    Recibos (portales de auto-factura) relacionados
                                </Typography.Text>
                            </Row>
                            {receiptsRelated.length > 0 && (
                                <Table
                                    columns={[
                                        {
                                            title: 'Recibo',
                                            dataIndex: 'id',
                                            key: 'id',
                                            render: (text, payment: any) => (
                                                <Tag
                                                    className="clickable"
                                                    onClick={() => {
                                                        window.open(`receiptDetails?id=${payment?.id}`, '_blank')
                                                    }}
                                                    icon={
                                                        <CreditCard
                                                            weight="regular"
                                                            className="icon"
                                                            style={{ marginRight: '5px' }}
                                                        />
                                                    }
                                                    style={{ border: 'none' }}
                                                >
                                                    <Typography.Text className="smallparagraph">{text}</Typography.Text>
                                                </Tag>
                                            ),
                                        },
                                        {
                                            title: 'Total',
                                            dataIndex: 'total',
                                            key: 'total',
                                            render: (text, payment: any) => (
                                                <Typography.Text className="smallparagraph">
                                                    {returnCurrencyValue(text, payment?.currency)}
                                                </Typography.Text>
                                            ),
                                        },
                                        {
                                            title: 'Fecha',
                                            dataIndex: 'timestamp',
                                            key: 'timestamp',
                                            render: (text) => (
                                                <Typography.Text className="smallparagraph">
                                                    {moment(text).format('DD/MM/YY HH:mm')}
                                                </Typography.Text>
                                            ),
                                        },
                                        {
                                            title: 'Estado',
                                            dataIndex: 'status',
                                            key: 'status',
                                            render: (text, payment: any) =>
                                                ReceiptStatusTag({ record: payment, shape: 'circle', size: 15 }),
                                        },
                                        {
                                            title: 'Método de pago',
                                            dataIndex: 'payment_form',
                                            key: 'payment_form',
                                            render: (text) => (
                                                <Typography.Text className="smallparagraph">
                                                    {paymentForms.find((pm) => pm.value === text)?.label}
                                                </Typography.Text>
                                            ),
                                        },
                                        {
                                            title: '',
                                            key: 'actions',
                                            render: (text, payment: any) => <ReceiptActions receipt={payment} />,
                                        },
                                    ]}
                                    dataSource={receiptsRelated}
                                    pagination={false}
                                    bordered={false}
                                />
                            )}
                        </Col>
                    )}
                    {invoicesRelated.length > 0 && (
                        <Col xs={24} style={{ marginTop: '15px' }}>
                            <Typography.Text className="smallheader">Facturas relacionadas</Typography.Text>
                            {invoicesRelated.length > 0 && (
                                <Table
                                    columns={[
                                        {
                                            title: 'Factura',
                                            dataIndex: 'uuid',
                                            key: 'uuid',
                                            render: (text, invoice: any) => (
                                                <div className="d-flex flex-column">
                                                    <div>
                                                        <Tag
                                                            className="clickable"
                                                            icon={
                                                                <Link
                                                                    weight="regular"
                                                                    className="icon"
                                                                    style={{ marginRight: '5px' }}
                                                                />
                                                            }
                                                            style={{ border: 'none' }}
                                                            onClick={() => {
                                                                window.open(
                                                                    `invoiceDetails?id=${invoice?.id}`,
                                                                    '_blank',
                                                                )
                                                            }}
                                                        >
                                                            <Typography.Text className="smallparagraph">
                                                                {text}
                                                            </Typography.Text>
                                                        </Tag>
                                                    </div>
                                                    {invoice?.from === 'global' && (
                                                        <Typography.Text
                                                            className="smallparagraph"
                                                            style={{ marginTop: '5px' }}
                                                        >
                                                            <Info className="icon" style={{ marginRight: '5px' }} />
                                                            Factura global, agrupada con {invoice?.payments?.length -
                                                                1}{' '}
                                                            pagos más
                                                        </Typography.Text>
                                                    )}
                                                </div>
                                            ),
                                            fixed: 'left',
                                        },
                                        {
                                            title: 'Total',
                                            dataIndex: 'total',
                                            key: 'total',
                                            render: (text, invoice: any) => (
                                                <Typography.Text className="smallparagraph">
                                                    {returnCurrencyValue(text, invoice?.currency)}
                                                </Typography.Text>
                                            ),
                                        },
                                        {
                                            title: 'Fecha de emisión',
                                            dataIndex: 'timestamp',
                                            key: 'timestamp',
                                            render: (text) => (
                                                <Typography.Text className="smallparagraph">
                                                    {moment(text).format('DD/MM/YY HH:mm')}
                                                </Typography.Text>
                                            ),
                                        },
                                        {
                                            title: 'Estado',
                                            dataIndex: 'status',
                                            key: 'status',
                                            render: (text, invoice: any) =>
                                                invoice?.status === 'valid' ? <CheckCircle /> : <Prohibit />,
                                        },
                                        {
                                            title: 'Serie/Folio',
                                            dataIndex: 'series',
                                            key: 'series',
                                            render: (text, invoice: any) => (
                                                <Typography.Text className="smallparagraph">
                                                    {invoice?.series}-{invoice?.folio_number}
                                                </Typography.Text>
                                            ),
                                        },
                                        {
                                            title: 'Método de pago',
                                            dataIndex: 'payment_method',
                                            key: 'payment_method',
                                            render: (text) => (
                                                <Typography.Text className="smallparagraph">{text}</Typography.Text>
                                            ),
                                        },
                                        {
                                            title: '',
                                            key: 'actions',
                                            render: (text, invoice: any) => <InvoiceActions invoice={invoice} />,
                                        },
                                    ]}
                                    dataSource={invoicesRelated}
                                    pagination={false}
                                    bordered={false}
                                />
                            )}
                        </Col>
                    )}
                </Row>
            </div>
        )
    }

    const EmailsTab = () => {
        return (
            <div className="d-flex flex-column">
                <Typography.Text className="mediumparagraphbold">Correos</Typography.Text>
                <Divider />
                <Typography.Text className="smallparagraph">
                    Los eventos mostrados son registros del sistema de envío de correos. Si el destinatario tiene
                    configurado un bloqueador de cookies, es posible que no se registren todos los eventos.
                </Typography.Text>
                <Row style={{ marginTop: '15px' }}>
                    {emailsData.length > 0 &&
                        emailsData
                            .sort((a: any, b: any) => b.timestamp - a.timestamp)
                            .map((email: any) => (
                                <Col
                                    xs={24}
                                    key={email.id ?? generateCode(6)}
                                    style={{ marginBottom: '35px' }}
                                    onClick={() => {
                                        console.log(email)
                                    }}
                                >
                                    <Row justify="space-between" align="middle">
                                        <Row>
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#A7FF99',
                                                    color: 'black',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                <Envelope weight="regular" className="icon" />
                                            </Avatar>
                                            <div className="d-flex flex-column">
                                                <Row wrap>
                                                    {email.to?.map((to: string) => {
                                                        return (
                                                            <Tag color="#F4F4F5">
                                                                <Typography.Text
                                                                    className="smallparagraph"
                                                                    style={{ margin: 0, padding: 0 }}
                                                                >
                                                                    {to}
                                                                </Typography.Text>
                                                            </Tag>
                                                        )
                                                    })}
                                                </Row>
                                                <Typography.Text
                                                    className="smallparagraph"
                                                    style={{ marginTop: '10px' }}
                                                >
                                                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
                                                        Asunto:
                                                    </span>{' '}
                                                    {email.subject}
                                                </Typography.Text>
                                                <Typography.Text
                                                    className="smallparagraph"
                                                    style={{ marginTop: '5px' }}
                                                >
                                                    {moment(email.timestamp).format('DD/MM/YY HH:mm')}
                                                </Typography.Text>
                                            </div>
                                        </Row>
                                        <Tag>{HandleEmailStatus(email.lastEvent).message} </Tag>
                                    </Row>
                                </Col>
                            ))}
                </Row>
            </div>
        )
    }

    const EventsTab = () => {
        return (
            <div className="d-flex flex-column">
                <Typography.Text className="mediumparagraphbold">Eventos</Typography.Text>
                <Divider />
                <Row>
                    <Col xs={24}>
                        <LogsRelatedToElement id={id} v={2} />
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <Suspense>
            <MarkAsPaidModal
                data={data}
                open={completePayment}
                close={() => {
                    setCompletePayment(false)
                }}
            />
            <Row>
                <Col
                    xs={24}
                    md={8}
                    className="d-flex flex-column"
                    style={{
                        backgroundColor: '#FDFDFD',
                        padding: '20px 0px 40px 0px',
                        borderRadius: '0px',
                        borderRight: '1px solid #E0E0E0',
                        minHeight: '85vh',
                    }}
                >
                    <Row className="" style={{ marginBottom: '10px' }}>
                        <Button type="text" onClick={back}>
                            <ArrowLeft size={16} />
                            <Typography.Text className="smallparagraphbold" style={{ marginLeft: '4px' }}>
                                Atrás
                            </Typography.Text>
                        </Button>
                    </Row>
                    <Row align="middle" style={{ marginLeft: '24px' }}>
                        <Typography.Text className="smallparagrap" style={{ color: '#cecece' }}>
                            PAGO
                        </Typography.Text>
                    </Row>
                    <div className="d-flex flex-column" style={{ marginLeft: '17px', padding: '10px' }}>
                        <Row justify="space-between" align="middle">
                            <Typography.Text className="bigparagraphbold" style={{ color: 'black', fontSize: '23px' }}>
                                {returnCurrencyValue(data?.amount / 100, data?.currency?.toUpperCase())}
                            </Typography.Text>
                            {data && <PaymentsActions payment={data} />}
                        </Row>
                        <Row
                            style={{ marginTop: '20px' }}
                            align="middle"
                            className="clickable"
                            onClick={() => {
                                window.open(`clientDetails?id=${data?.clientId ?? data?.clientID}`, '_blank')
                            }}
                        >
                            <Avatar style={{ backgroundColor: '#A7FF99', color: 'black' }}>
                                {data?.client?.name?.charAt(0)}
                            </Avatar>
                            <div
                                className="d-flex flex-column"
                                style={{ marginLeft: '10px', flex: 1, paddingRight: '10px' }}
                            >
                                <Typography.Text className="smallparagraphbold" style={{ color: 'black' }}>
                                    {data?.client?.legalName ?? data?.client?.legal_name ?? data?.client?.name}
                                </Typography.Text>
                                <Typography.Text
                                    className="smallparagraph"
                                    style={{ marginTop: '5px', color: '#416D60' }}
                                >
                                    {data?.client?.email} | {data?.client?.rfc}
                                </Typography.Text>
                            </div>
                            <Eye style={{ marginLeft: 'auto', marginRight: '10px' }} />
                        </Row>
                        <Divider />
                        <div className="d-flex flex-column">
                            {data?.refunded && (
                                <Alert
                                    style={{
                                        backgroundColor: '#f1f5f9',
                                        border: '1px solid #f1f5f9',
                                        marginBottom: '15px',
                                    }}
                                    icon={<Info className="icon" />}
                                    message={
                                        <Typography.Text className="smallparagraphbold">
                                            Pago reembolsado
                                        </Typography.Text>
                                    }
                                    description={
                                        <p className="smallparagraph">
                                            Este pago fue reembolsado por la cantidad de{' '}
                                            {returnCurrencyValue(
                                                data?.amount_refunded / 100,
                                                data?.currency?.toUpperCase(),
                                            )}{' '}
                                            el día {moment(data?.refundedTimestamp).format('DD MMMM YYYY HH:mm')}.{' '}
                                            <br />
                                            {data?.invoices?.length > 0 && (
                                                <a
                                                    href={`/invoiceDetails?id=${data?.invoices[0]}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Factura relacionada: {data?.invoices[0]}
                                                </a>
                                            )}
                                            {data?.invoices?.length >= 0 && data?.receipts?.length > 0 && (
                                                <a
                                                    href={`/receiptDetails?id=${data?.receipts[0]}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Recibo relacionado: {data?.receipts[0]}
                                                </a>
                                            )}
                                        </p>
                                    }
                                />
                            )}
                            <DetailItem
                                title="ID del pago"
                                value={
                                    <Typography.Text
                                        copyable={{
                                            icon: <Copy className="icon" />,
                                        }}
                                        className="smallparagraph"
                                    >
                                        {data?.fid}
                                    </Typography.Text>
                                }
                                icon={<CalendarBlank weight="regular" className="icon" />}
                            />
                            <DetailItem
                                title="Estado"
                                value={<PaymentStatusTag shape="tag" record={paymentSet(data)} />}
                                icon={<TagChevron weight="regular" className="icon" />}
                            />

                            <DetailItem
                                title="Fecha de creación"
                                value={
                                    <Typography.Text className="smallparagraph">
                                        {moment(data?.timestamp).format('DD MMMM YYYY HH:mm')}
                                    </Typography.Text>
                                }
                                icon={<CalendarBlank weight="regular" className="icon" />}
                            />
                            {data.status === 'succeeded' && (
                                <DetailItem
                                    title="Fecha de pago"
                                    value={
                                        <Typography.Text className="smallparagraph">
                                            {moment(data?.succeededTimestamp).format('DD MMMM YYYY HH:mm')}
                                        </Typography.Text>
                                    }
                                    icon={<CalendarCheck weight="regular" className="icon" />}
                                />
                            )}

                            <DetailItem
                                title="Tipo de cambio"
                                value={
                                    <Typography.Text className="smallparagraph">
                                        1 {data?.currency} = {data?.exchange} MXN
                                    </Typography.Text>
                                }
                                icon={<HandCoins weight="regular" className="icon" />}
                            />
                            <DetailItem
                                title="Fuente"
                                value={
                                    <Tag color="#D3D9FF">
                                        <Typography.Text className="smallparagraphbold blue">
                                            {readableElementSource(data.from ?? '')}
                                        </Typography.Text>
                                    </Tag>
                                }
                                icon={<GitFork weight="regular" className="icon" />}
                            />
                        </div>
                        <Divider />
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallparagraphbold" style={{ marginBottom: '10px' }}>
                                Cliente
                            </Typography.Text>
                            <DetailItem
                                icon={<IdentificationBadge weight="regular" className="icon" />}
                                title="RFC | Tax Id"
                                value={
                                    <Typography.Text
                                        className="smallparagraph"
                                        style={{ color: 'black' }}
                                        copyable={false}
                                    >
                                        {clientData?.rfc || 'Sin información'}
                                    </Typography.Text>
                                }
                            />

                            <DetailItem
                                icon={<MapTrifold weight="regular" className="icon" />}
                                title="Código Postal"
                                value={
                                    <Typography.Text
                                        className="smallparagraph"
                                        style={{ color: 'black' }}
                                        copyable={false}
                                    >
                                        {clientData?.address?.zip ?? 'Sin información'}
                                    </Typography.Text>
                                }
                            />

                            <DetailItem
                                icon={<Flag weight="regular" className="icon" />}
                                title="País"
                                value={
                                    <Typography.Text
                                        className="smallparagraph"
                                        style={{ color: 'black' }}
                                        copyable={false}
                                    >
                                        {clientData?.address?.country ?? 'Sin información'}
                                    </Typography.Text>
                                }
                            />
                            <DetailItem
                                icon={<BookOpen weight="regular" className="icon" />}
                                title="Régimen Fiscal"
                                value={
                                    <Typography.Text
                                        className="smallparagraph"
                                        style={{ color: 'black' }}
                                        copyable={false}
                                    >
                                        {taxRegimes.find((regime) => regime.value === clientData?.tax_system)?.label ??
                                            'Sin información'}
                                    </Typography.Text>
                                }
                            />
                            <DetailItem
                                icon={<BookOpen weight="regular" className="icon" />}
                                title="Uso CFDI"
                                value={
                                    <Typography.Text
                                        className="smallparagraph"
                                        style={{ color: 'black' }}
                                        copyable={false}
                                    >
                                        {invoiceUsage.find((usage) => usage.value === clientData?.use)?.label}
                                    </Typography.Text>
                                }
                            />
                            <Divider />
                            {invoicesRelated?.length > 0 && (
                                <div
                                    className="d-flex flex-column clickable"
                                    style={{
                                        border: '1px solid #E0E0E0',
                                        borderRadius: '10px',
                                        padding: '10px',
                                    }}
                                    onClick={() => {
                                        window.open(`/invoiceDetails?id=${invoicesRelated[0]?.id}`, '_blank')
                                    }}
                                >
                                    <Row justify="space-between" align="middle">
                                        <div className="d-flex flex-column">
                                            <Typography.Text className="smallparagraphbold">
                                                Factura relacionada
                                            </Typography.Text>
                                            <Typography.Text className="smallparagraph">
                                                {invoicesRelated[0]?.uuid} | {invoicesRelated[0]?.payment_method}
                                            </Typography.Text>
                                        </div>
                                        <Row>
                                            <Typography.Text className="smallparagraphbold">
                                                {returnCurrencyValue(
                                                    invoicesRelated[0]?.total,
                                                    invoicesRelated[0]?.currency,
                                                )}
                                            </Typography.Text>
                                            <CaretRight size={16} className="icon" />
                                        </Row>
                                    </Row>
                                </div>
                            )}
                            {receiptsRelated?.filter((receipt: any) => receipt.status === 'pending').length > 0 &&
                                invoicesRelated?.length <= 0 && (
                                    <div
                                        className="d-flex flex-column clickable"
                                        style={{
                                            border: '1px solid #E0E0E0',
                                            borderRadius: '10px',
                                            padding: '10px',
                                        }}
                                        onClick={() => {
                                            window.open(`/receiptDetails?id=${receiptsRelated[0]?.id}`, '_blank')
                                        }}
                                    >
                                        <Row justify="space-between" align="middle">
                                            <div className="d-flex flex-column">
                                                <Typography.Text className="smallparagraphbold">
                                                    Portal de auto-factura
                                                </Typography.Text>
                                                <Typography.Text className="smallparagraph">
                                                    {receiptsRelated[0]?.id}
                                                </Typography.Text>
                                            </div>
                                            <Row>
                                                <Typography.Text className="smallparagraphbold">
                                                    {returnCurrencyValue(
                                                        receiptsRelated[0]?.total,
                                                        receiptsRelated[0]?.currency,
                                                    )}
                                                </Typography.Text>
                                                <CaretRight size={16} className="icon" />
                                            </Row>
                                        </Row>
                                    </div>
                                )}
                            {data?.status === 'succeeded' &&
                                moment(data?.succeededTimestamp).isBetween(
                                    moment().startOf('month'),
                                    moment().endOf('month'),
                                ) &&
                                (data?.invoices ?? []).length <= 0 &&
                                (data?.receipts ?? []).length <= 0 &&
                                (data?.automations ?? []).length <= 0 && (
                                    <div
                                        className="d-flex flex-column"
                                        style={{ border: '1px solid #E0E0E0', borderRadius: '10px', padding: '10px' }}
                                    >
                                        <Typography.Text className="smallparagraphbold">
                                            No se ha generado ninguna factura o recibo de venta
                                        </Typography.Text>
                                        <Typography.Text className="smallparagraph">
                                            Este pago no contaba con automatización, pero no te preocupes, puedes
                                            mantenerlo conciliado activando la automatización para generar una factura
                                            de venta.
                                        </Typography.Text>
                                        <Button
                                            style={{ marginTop: '10px' }}
                                            type="primary"
                                            onClick={async () => {
                                                const db = doc(collection(getFirestore(), 'payments'), data?.id)
                                                await updateDoc(db, {
                                                    automations: [
                                                        {
                                                            type: 'invoice',
                                                            ref: 'payments',
                                                            action: 'create',
                                                            from: 'payments',
                                                        },
                                                    ],
                                                    status: 'pre-succeeded',
                                                })
                                            }}
                                        >
                                            Generar automtatización para el pago
                                        </Button>
                                    </div>
                                )}
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={16} style={{ paddingLeft: '30px' }}>
                    {data ? (
                        <Tabs
                            defaultActiveKey="2"
                            // items={[Path, GitFork, Envelope, StarFour].map((Icon, i) => {
                            //     const id = String(i + 1)
                            //     return {
                            //         key: id,
                            //         label: `Tab ${id}`,
                            //         children: `Tab ${id}`,
                            //         icon: <Icon />,
                            //     }
                            // })}
                            items={[
                                {
                                    key: 'details',
                                    label: <Typography.Text className="mediumparagraphbold">Resumen</Typography.Text>,
                                    children: <DetailsTab />,
                                    icon: <Path weight="regular" className="icon" />,
                                },
                                {
                                    key: 'relations',
                                    label: (
                                        <Typography.Text className="mediumparagraphbold">Relaciones</Typography.Text>
                                    ),
                                    children: <RelationsTab />,
                                    icon: <GitFork weight="regular" className="icon" />,
                                },
                                {
                                    key: 'emails',
                                    label: <Typography.Text className="mediumparagraphbold">Correos</Typography.Text>,
                                    children: <EmailsTab />,
                                    icon: <Envelope weight="regular" className="icon" />,
                                },
                                {
                                    key: 'events',
                                    label: <Typography.Text className="mediumparagraphbold">Eventos</Typography.Text>,
                                    children: <EventsTab />,
                                    icon: <StarFour weight="regular" className="icon" />,
                                },
                            ]}
                        />
                    ) : (
                        <Skeleton active />
                    )}
                </Col>
            </Row>
        </Suspense>
    )
}

export default PaymentDetailsView
