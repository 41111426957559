import { Alert, Button, Col, Descriptions, Drawer, Form, Modal, Row, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, setElementSelected } from '../context/modalsSlice'
import { returnCurrencyValue, taxRegimes } from '../functions/helpers'
import moment from 'moment'
import { InvoiceStatusTag } from '../components/UI/ElementStatus'
import { invoiceDefSet } from '../../interfaces/invoicesDef'
import { useEffect, useState } from 'react'
import { ArrowClockwise, CaretDoubleRight } from '@phosphor-icons/react'
import { NumberInput, SelectInput, TextInput } from '../components/Forms/Inputs'
import { CFDIUses, paymentForms, paymentMethods } from '../datasets/Fiscal'
import ItemsSelection from '../components/ComponentIndividual/ItemsSelection'
import ProductsServicesTotals from '../components/ComponentIndividual/ProductsServicesTotals'
import { setData } from '../context/dataSlice'
import { SelectClientModal } from './SelectClientModal'
import { countryLabeledListWithCodeAlpha3 } from '../datasets/Countries'
import { useAuth } from 'reactfire'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { currecies } from '../datasets/Currencies'
import { GetExchangeRates } from '../context/databaseContextHelpers'
import { CancelInvoiceAlert } from '../components/UI/CancelInvoiceAlert'
import { useTranslation } from 'react-i18next'
// import { collection, getFirestore, orderBy, query, where } from 'firebase/firestore';

const SubstituteInvoiceModal = () => {
    const { exchangeRates } = useSelector((state: any) => state.data)
    const auth = useAuth()
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { t } = useTranslation()

    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { substituteInvoiceModalVisible } = useSelector((state: any) => state.modals)
    const { invoice, client, selectedServices, testmode } = useSelector((state: any) => state.data)

    const [showSelectClientModal, setShowSelectClientModal] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [items, setItems] = useState<any[]>((invoice?.internalItems || []).map((i: any) => {
    //     return {
    //         ...i,
    //         localId: generateCode(10)
    //     }
    // }))

    const invoiceToSubstituteDate = moment(invoice?.date)

    const invoiceDetailsItems = [
        {
            key: 'uuid',
            label: <Typography.Text className="smallparagraphbold">Folio Fiscal (UUID)</Typography.Text>,
            children: <Typography.Text className="smallparagraph descriptions">{invoice?.uuid}</Typography.Text>,
        },
        {
            key: '1',
            label: <Typography.Text className="smallparagraphbold">Tipo de CFDI</Typography.Text>,
            children: (
                <Typography.Text className="smallparagraph descriptions">
                    {invoice?.type || invoice?.invoiceType}
                </Typography.Text>
            ),
        },

        {
            key: 'series',
            label: <Typography.Text className="smallparagraphbold">Serie y folio</Typography.Text>,
            children: (
                <Typography.Text className="smallparagraph ">
                    {invoice?.series} - {invoice?.folio_number}
                </Typography.Text>
            ),
        },
        {
            key: 'client',
            label: <Typography.Text className="smallparagraphbold">Cliente</Typography.Text>,
            children: <Typography.Text className="smallparagraph">{invoice?.customer?.legal_name}</Typography.Text>,
        },
        {
            key: 'RFC',
            label: <Typography.Text className="smallparagraphbold">RFC</Typography.Text>,
            children: (
                <Typography.Text className="smallparagraph">
                    {invoice?.customer?.rfc || invoice?.customer?.tax_id}
                </Typography.Text>
            ),
        },
        {
            key: 'total',
            label: <Typography.Text className="smallparagraphbold">Total del CFDI</Typography.Text>,
            children: (
                <Typography.Text className="smallparagraph ">
                    {returnCurrencyValue(invoice?.total)} {invoice?.currency}{' '}
                </Typography.Text>
            ),
        },
        {
            key: 'date',
            label: <Typography.Text className="smallparagraphbold">Fecha</Typography.Text>,
            children: (
                <Typography.Text className="smallparagraph ">
                    {moment(invoice?.timestamp).format('DD MMMM YYYY HH:mm')}{' '}
                </Typography.Text>
            ),
        },
    ]

    useEffect(() => {
        if (substituteInvoiceModalVisible && invoice?.uuid) {
            form.setFieldsValue({
                ...client,
                ...invoice,
            })
            dispatch(
                setData({
                    item: 'selectedServices',
                    data: invoice?.internalItems || [],
                }),
            )
            dispatch(
                setData({
                    item: 'client',
                    data: invoice?.customer,
                }),
            )
            dispatch(
                setData({
                    item: 'invoice',
                    data: invoice,
                }),
            )
            if (Object.keys(exchangeRates).length <= 0) {
                GetExchangeRates(dispatch)
            }
        }

        // eslint-disable-next-line
    }, [substituteInvoiceModalVisible, invoice?.uuid])

    useEffect(() => {
        if (client && substituteInvoiceModalVisible) {
            form.setFieldsValue({
                legal_name: client?.legal_name,
                rfc: client?.tax_id || client?.rfc,
                tax_system: taxRegimes.find((regime) => regime.value === client?.tax_system),
                zip: client?.address?.zip,
                country: client?.address?.country || client?.country,
            })
        }

        // eslint-disable-next-line
    }, [client?.id, substituteInvoiceModalVisible])

    const handleInvoiceSubstitution = async (values: any) => {
        try {
            setLoading(true)
            const idempotencyKey = `subst-${invoice?.uuid}`

            const newInvoice = {
                ...invoice,

                payment_form: values?.paymentForm?.value || values?.payment_form || invoice?.payment_form,
                payment_method: values?.paymentMethod?.value || values?.payment_method || invoice?.payment_method,
                use: values?.use?.value || values?.use || invoice?.use,
                type: 'create_invoice',
                series: values?.series ?? invoice?.series,
                folio_number: values?.folio_number ?? invoice?.folio_number,
                client: {
                    ...client,
                    legal_name: values?.legal_name,
                    rfc: values?.rfc,
                    tax_system: values?.tax_system?.value || values?.tax_system,
                    address: {
                        ...client?.address,
                        zip: values?.zip,
                        country: values?.country,
                    },
                },
                internalItems: selectedServices,
                items: selectedServices,
                relation: invoice?.relation || null,
                idempotency_key: idempotencyKey,
                currency: values?.currency || invoice?.currency,
                exchange: values?.exchange || invoice?.exchange,
                exchange_rate: values?.exchange || invoice?.exchange,
            }

            if (invoice.isGlobal) {
                newInvoice.isGlobal = true
                newInvoice.customer = invoice.client
            }

            const data = {
                oldInvoice: { ...invoice },
                newInvoice: { ...newInvoice },
                items: selectedServices,
                test: !invoice?.livemode,
                type: 'substitute',
                billingAccountId: billingAccount?.id ?? invoice?.billingAccount,
                teamId: team?.id ?? invoice?.team,
                invoicingIntegration: 'facturapi',
                v2: true,
                idempotency_key: idempotencyKey,
            }

            // return console.log(data);

            const resp = await SignedInternalAPIRequest(data, 'invoicing', auth.currentUser)
            console.log('🚀 ~ handleInvoiceSubstitution ~ resp', resp)
            if (resp.error) {
                throw new Error(resp.error)
            }

            if (resp?.action === 'requires_manual_cancellation') {
                Modal.confirm({
                    title: 'Factura sustituida',
                    content: 'La factura sustituida requiere ser cancelada manualmente',
                    okText: 'Aceptar',
                    onOk: () => {
                        // openCancelInvoice({ ...pastInvoice, actionType: 'substitute' })
                    },
                })
            }
            message.success('Factura sustituida correctamente')
            dispatch(setData({ item: 'client', data: null }))
            dispatch(closeModal('substituteInvoiceModalVisible'))
            setLoading(false)
        } catch (error: any) {
            console.log('🚀 ~ handleInvoiceSubstitution ~ error:', error)
            setLoading(false)
            message.error(error?.message ?? error ?? 'Error al sustituir la factura')
        }
    }

    return (
        <Drawer
            style={{ marginTop: testmode ? '32px' : '0px', paddingBottom: testmode ? '32px' : '0px' }}
            title={
                <Row>
                    <Typography.Text className="mediumparagraphbold">
                        Sustituir factura con un nuevo CFDI
                    </Typography.Text>
                </Row>
            }
            open={substituteInvoiceModalVisible}
            onClose={() => {
                dispatch(closeModal('substituteInvoiceModalVisible'))
                dispatch(
                    setElementSelected({
                        element: 'invoice',
                        data: null,
                    }),
                )
            }}
            footer={
                <Row justify="end" gutter={10}>
                    <Col>
                        <Button
                            onClick={() => {
                                dispatch(closeModal('substituteInvoiceModalVisible'))
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" onClick={() => form.submit()} loading={loading}>
                            {!loading ? 'Sustituir factura' : 'Sustituyendo factura...'}
                            <CaretDoubleRight weight="regular" />
                        </Button>
                    </Col>
                </Row>
            }
            width={window.innerWidth < 700 ? '90%' : '70%'}
        >
            <Form
                layout="vertical"
                form={form}
                disabled={loading}
                initialValues={{
                    ...client,
                    zip: client?.address?.zip,
                    country: client?.address?.country || client?.country,
                    ...invoice,
                }}
                onFinish={handleInvoiceSubstitution}
            >
                <SelectClientModal
                    open={showSelectClientModal}
                    close={() => {
                        setShowSelectClientModal(false)
                        // setChangedClient(true)
                    }}
                />

                <Row justify="center">
                    <Col xs={23} style={{ marginBottom: '10px' }}>
                        {invoiceToSubstituteDate.isBefore(moment().startOf('month')) && (
                            <Alert
                                message="La factura a sustituír es de un mes anterior, considera la emisión de una Nota de Crédito."
                                type="info"
                            />
                        )}

                        {invoice.isGlobal && (
                            <Alert
                                message={
                                    <Typography.Text className="smallparagraph">
                                        La factura a sustituír es una factura global, considera la emisión de una Nota
                                        de Crédito para restar algún importe.
                                    </Typography.Text>
                                }
                                type="info"
                            />
                        )}
                        <CancelInvoiceAlert type="substitute" />
                    </Col>
                    <Col xs={23} lg={23}>
                        <div
                            style={{
                                padding: '20px',
                                borderRadius: '10px',
                                background:
                                    'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                            }}
                            className="d-flex flex-column"
                        >
                            <Descriptions
                                title={
                                    <div>
                                        <Row justify="space-between" align="middle">
                                            <Typography.Text className="mediumparagraphbold">
                                                Detalles de la factura a sustituir
                                            </Typography.Text>
                                            <InvoiceStatusTag shape="tag" record={invoiceDefSet(invoice || {})} />
                                        </Row>
                                        <Row style={{ marginTop: '10px' }}>
                                            <Typography.Text type="secondary" className="smallparagraph">
                                                Generaremos una nueva factura para sustituir el folio {invoice?.uuid}{' '}
                                                con un nuevo CFDI.
                                            </Typography.Text>
                                        </Row>
                                    </div>
                                }
                                layout="vertical"
                                size="small"
                                items={invoiceDetailsItems}
                            />
                        </div>
                    </Col>
                </Row>


                <Row align="middle" justify="center" style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <Col xs={23}>
                        <Row align="middle" justify="space-between" style={{ margin: '10px 0px' }}>
                            <Typography.Title className="bigparagraph" level={5} style={{ margin: '0px' }}>
                                1. Información del {t('cliente')}
                            </Typography.Title>
                            <Button
                                icon={<ArrowClockwise size={16} />}
                                onClick={() => {
                                    setShowSelectClientModal(true)
                                }}
                            >
                                Cambiar {t('cliente')}
                            </Button>
                        </Row>
                        <Row gutter={10}>
                            <Col xs={24} md={12}>
                                <TextInput name="legal_name" label="Nombre legal" />
                            </Col>
                            <Col xs={24} md={12}>
                                <TextInput name="rfc" label="RFC" />
                            </Col>
                            <Col xs={24} md={12}>
                                <SelectInput name="tax_system" label="Régimen fiscal" options={taxRegimes} />
                            </Col>
                            <Col xs={24} md={12}>
                                <Row gutter={10}>
                                    <Col xs={12}>
                                        <TextInput name="zip" label="Código postal" />
                                    </Col>
                                    <Col xs={12}>
                                        <SelectInput
                                            name="country"
                                            label="País"
                                            options={countryLabeledListWithCodeAlpha3.map((c) => {
                                                return {
                                                    label: `${c.code} - ${c.name}`,
                                                    value: c.code,
                                                }
                                            })}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={23}>
                        <Typography.Title className="bigparagraph" level={5}>
                            2. Información general
                        </Typography.Title>
                        <Row gutter={10}>
                            <Col xs={24} md={12}>
                                <TextInput
                                    name="series"
                                    label="Serie"
                                    rules={[{ required: true, message: 'Por favor escribe la serie de la factura' }]}
                                />
                            </Col>
                            <Col xs={12}>
                                <NumberInput
                                    name="folio_number"
                                    label="Folio"
                                    min={0}
                                    max={100000000000}
                                    step={1}
                                    rules={[{ required: true, message: 'Por favor escribe el folio de la factura' }]}
                                />
                            </Col>

                            <Col xs={24} md={12}>
                                <SelectInput
                                    name="use"
                                    label="Uso del CFDI"
                                    options={CFDIUses}
                                    rules={[{ required: true, message: 'Por favor selecciona el uso de la factura' }]}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <SelectInput
                                    name="payment_form"
                                    label="Forma de pago"
                                    placeholder="Selecciona una forma de pago"
                                    options={paymentForms}
                                    rules={[{ required: true, message: 'Por favor selecciona la forma de pago' }]}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <SelectInput
                                    name="payment_method"
                                    label="Método de pago"
                                    placeholder="Selecciona un método de pago"
                                    options={paymentMethods}
                                    rules={[{ required: true, message: 'Por favor selecciona el método de pago' }]}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <SelectInput
                                    name="currency"
                                    label="Moneda"
                                    placeholder="Selecciona una moneda"
                                    onChange={(currency: any) => {
                                        console.log(currency, exchangeRates)

                                        form.setFieldsValue({
                                            exchange: (1 / exchangeRates[currency]).toFixed(4),
                                        })
                                    }}
                                    options={currecies}
                                    rules={[{ required: true, message: 'Por favor selecciona la moneda' }]}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <NumberInput
                                    name="exchange"
                                    label="Tipo de cambio"
                                    min={0}
                                    step={0.01}
                                    max={100}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor escribe el tipo de cambio',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <Row justify="center">
                <Col xs={23}>
                    <Typography.Title className="bigparagraph" level={5}>
                        3. Productos / Servicios
                    </Typography.Title>
                    <ItemsSelection
                        hideTitle={true}
                        // incomingServices={items}
                        showServiceSelection={true}
                        changeIncoming={(newD: any) => {
                            console.log(newD)

                            // setItems(newD)

                            // dispatch(setData({
                            //     item: 'invoice',
                            //     data: {
                            //         ...invoice,
                            //         items: newD,
                            //         internalItems: newD
                            //     }
                            // }))
                        }}
                    />
                    <ProductsServicesTotals
                        boxed={false}

                        // incomingItems={items}
                    />
                </Col>
            </Row>
        </Drawer>
    )
}

export default SubstituteInvoiceModal
