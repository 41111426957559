import { Clock, Gear, GitFork } from '@phosphor-icons/react'
import { Button, Row, Tag, Typography, message } from 'antd'
import { usePermissions } from '../../customHooks/usePermissions'
import { tagsColors } from '../Forms/TagsColors'
import { UpgradePlanModal } from '../Modals/UpgradePlanModal'
import { useState } from 'react'

interface AppCardProps {
    integration: {
        name: string
        logo?: string
        completed: boolean
        description: string
        id: string
        disabled?: boolean
    }
    onClick: () => void
}

const AppCard = ({ integration, onClick }: AppCardProps) => {
    const { canInstallApps } = usePermissions()
    const { name, logo, completed, description, disabled } = integration
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)

    const activeTag = tagsColors.find((t) => t.name === 'green')
    const inactiveTag = tagsColors.find((t) => t.name === 'gray')

    const handleIntegrationClick = () => {
        if (!canInstallApps.hasPermission && !completed) {
            if (canInstallApps.restrictionType === 'PLAN' || canInstallApps.restrictionType === 'LIMIT') {
                setShowUpgradeModal(true)
                return
            }
            if (canInstallApps.restrictionType === 'USER_PERMISSION') {
                message.warning(canInstallApps.reason)
                return
            }
        }

        onClick()
    }

    return (
        <div className="custom-card" style={{ border: '1px solid #E9E9E9', borderRadius: '10px' }}>
            <div className="card-content">
                <Row align="middle" style={{ marginBottom: '10px' }}>
                    <div
                        style={{
                            height: '55px',
                            width: '55px',
                            backgroundImage: `url(${logo})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100%',
                            borderRadius: '10px',
                        }}
                    ></div>
                    <Typography.Title className="card-title" level={5} style={{ margin: '0px 12px' }}>
                        {name}
                    </Typography.Title>
                </Row>

                <div style={{ width: '90%' }}>
                    <Typography.Text type="secondary" style={{ margin: 0 }}>
                        {description}
                    </Typography.Text>
                </div>
            </div>
            <div style={{ borderTop: '1px solid #E9E9E9', padding: '18px' }}>
                <Row align="middle" justify="space-between">
                    <Tag color={completed ? activeTag?.bg : inactiveTag?.bg}>
                        <Typography.Text
                            className="smallparagraphbold"
                            style={{ color: completed ? activeTag?.text : inactiveTag?.text }}
                        >
                            {completed ? 'Activada' : 'Inactiva'}
                        </Typography.Text>
                    </Tag>

                    <Button
                        id={`card-app-${integration.id}-button`}
                        disabled={disabled}
                        onClick={handleIntegrationClick}
                        className={completed ? 'btn-secondary' : 'btn-primary'}
                        icon={
                            disabled ? (
                                <Clock weight="regular" size={18} />
                            ) : completed ? (
                                <Gear weight="regular" size={18} />
                            ) : (
                                <GitFork weight="regular" size={18} />
                            )
                        }
                    >
                        {disabled ? 'No disponible' : completed ? 'Configurar' : 'Integrar'}
                    </Button>
                </Row>
            </div>
            <UpgradePlanModal
                isOpen={showUpgradeModal}
                onClose={() => setShowUpgradeModal(false)}
                content="Para poder instalar más integraciones necesitas actualizar tu plan."
            />
        </div>
    )
}

export default AppCard
