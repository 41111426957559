import React, { useState } from 'react'
import { Dropdown, Row, Space, Typography, message } from 'antd'
import EditAction from './SingleActions/EditAction'
import { useDispatch } from 'react-redux'
import { openModal } from '../../context/modalsSlice'
import { setData, deleteData } from '../../context/dataSlice'
import DeleteAction from './SingleActions/DeteleAction'
import { deleteDoc, doc, getFirestore } from 'firebase/firestore'
import { Cardholder, DotsThree, Money } from '@phosphor-icons/react'
import { MenuProps } from 'antd/lib'
import { usePermissions } from '../../customHooks/usePermissions'
import { TeamDef } from '../../../interfaces/teamDef'
import { UpgradePlanModal } from '../Modals/UpgradePlanModal'

interface Props {
    vendor: TeamDef
    showFull?: boolean
}

const VendorActions: React.FC<Props> = ({ vendor, showFull }) => {
    const [loading, setLoading] = useState<any[]>([])
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)
    const dispatch = useDispatch()
    const { canCreateVendor, canCreatePayment, canCreateReceipt } = usePermissions()

    const handleAction = (action: () => void, permission: { hasPermission: boolean, reason?: string, restrictionType?: string }) => {
        if (!permission.hasPermission) {
            if (permission.restrictionType === 'PLAN' || permission.restrictionType === 'LIMIT') {
                setShowUpgradeModal(true)
                return
            }
            message.warning(permission.reason)
            return
        }
        action()
    }

    const thisRecordHasAnyLoading = loading.find((l) => l.id === vendor?.id) !== undefined

    const ActionsContent = () => {
        const vendorActions: MenuProps['items'] = [
            {
                onClick: () => handleAction(
                    () => {
                        dispatch(setData({ item: 'vendor', data: vendor }))
                        dispatch(openModal('paymentModalVisible'))
                    },
                    canCreatePayment
                ),
                label: (
                    <Typography.Text className="smallparagaph ">
                        <Money weight="regular" size={15} style={{ marginRight: '5px' }} /> Solicitar pago
                    </Typography.Text>
                ),
                key: 'createPayment',
            },
            {
                onClick: () => handleAction(
                    () => {
                        dispatch(setData({ item: 'vendor', data: vendor }))
                        dispatch(openModal('receiptModalVisible'))
                    },
                    canCreateReceipt
                ),
                label: (
                    <Typography.Text className="smallparagaph">
                        <Cardholder weight="regular" size={15} style={{ marginRight: '5px' }} />
                        Crear recibo
                    </Typography.Text>
                ),
                key: 'createReceipt',
            },
        ]

        return (
            <div className={showFull ? 'd-flex flex-column' : 'd-flex flex-row'}>
                <UpgradePlanModal
                    isOpen={showUpgradeModal}
                    onClose={() => setShowUpgradeModal(false)}
                    content="Para poder gestionar proveedores necesitas actualizar tu plan."
                />
                {' '}
                <Space
                    direction={showFull ? 'vertical' : 'horizontal'}
                    align="start"
                    style={{ marginBottom: showFull ? '10px' : '0px' }}
                >
                    {canCreateVendor && (
                        <EditAction
                            action={() => {
                                setLoading([...loading, { id: vendor?.id, action: `edit` }])
                                dispatch(
                                    setData({
                                        item: 'vendor',
                                        data: vendor,
                                    }),
                                )
                                dispatch(openModal('createVendorDrawerVisible'))
                                setLoading(loading.filter((l) => l.id !== vendor?.id && l.action !== `edit`))
                            }}
                            loading={loading.find((l) => l.id === vendor?.id && l.action === `edit`) !== undefined}
                            full={false}
                        />
                    )}
                    {canCreateVendor && (
                        <DeleteAction
                            action={async () => {
                                setLoading([...loading, { id: vendor?.id, action: `delete` }])
                                await deleteDoc(doc(getFirestore(), 'vendors', vendor?.id))
                                dispatch(deleteData({ item: 'vendors', id: vendor?.id }))
                                setLoading(loading.filter((l) => l.id !== vendor?.id && l.action !== `delete`))
                            }}
                            loading={false}
                        />
                    )}
                    <Dropdown disabled={thisRecordHasAnyLoading} menu={{ items: vendorActions }} trigger={['click']}>
                        <DotsThree className="icon clickable" weight="regular" size={18} />
                    </Dropdown>
                </Space>
            </div>
        )
    }

    return showFull ? (
        <Space direction="vertical" wrap={true}>
            <ActionsContent />
        </Space>
    ) : (
        <Row align="middle">
            <ActionsContent />
        </Row>
    )
}

export default VendorActions
