import React, { useState, useEffect } from 'react'
import { Alert, Button, Row, Col, Space, Typography, Divider, message, Spin, Popconfirm } from 'antd'
import { Check, Copy, Warning, Receipt, ArrowSquareOut } from '@phosphor-icons/react'
import { useSelector } from 'react-redux'
import { doc, updateDoc, getFirestore, getDoc } from 'firebase/firestore'
import { trackEvent } from '../../analytics/helpers'
import { usePostHog } from 'posthog-js/react'
import moment from 'moment'

interface PendingReceiptAlertProps {
  receiptIds: string[];
}

const PendingReceiptAlert: React.FC<PendingReceiptAlertProps> = ({ receiptIds }) => {
  const posthog = usePostHog()
  const { user } = useSelector((state: any) => state.user)
  const [loading, setLoading] = useState(true)
  const [pendingReceipts, setPendingReceipts] = useState<any[]>([])
  const [processingIds, setProcessingIds] = useState<string[]>([])
  
  useEffect(() => {
    const fetchReceiptData = async () => {
      if (!receiptIds || receiptIds.length === 0) {
        setLoading(false)
        return
      }
      
      setLoading(true)
      try {
        const db = getFirestore()
        const receiptsData = []
        
        for (const id of receiptIds) {
          try {
            const receiptDoc = await getDoc(doc(db, 'receipts', id))
            if (receiptDoc.exists()) {
              const receiptData = { id, ...receiptDoc.data() } as any
              if (receiptData.status === 'pending') {
                receiptsData.push(receiptData)
              }
            }
          } catch (error) {
            console.error(`Error fetching receipt ${id}:`, error)
          }
        }
        
        setPendingReceipts(receiptsData)
      } catch (error) {
        console.error('Error fetching receipts:', error)
      } finally {
        setLoading(false)
      }
    }
    
    fetchReceiptData()
  }, [receiptIds])
  
  const handleCompleteReceipt = async (receipt: any) => {
    try {
      setProcessingIds(prev => [...prev, receipt.id])
      
      await updateDoc(doc(getFirestore(), 'receipts', receipt.id), {
        status: 'completed',
        completedAt: moment().valueOf(),
        completedBy: {
          uid: user.uid,
          name: user.name || user.displayName,
          timestamp: moment().valueOf()
        }
      })
      
      setPendingReceipts(prev => prev.filter(r => r.id !== receipt.id))
      
      message.success('Recibo completado correctamente')
      trackEvent({ 
        name: 'receipt_completed_from_invoice', 
        metadata: { receipt_id: receipt.id }
      }, posthog)
    } catch (error) {
      console.error('Error completing receipt:', error)
      message.error('Error al completar el recibo')
    } finally {
      setProcessingIds(prev => prev.filter(id => id !== receipt.id))
    }
  }
  
  const copyReceiptUrl = (receipt: any) => {
    const url = `https://invoicing.gigstack.pro/autofactura?id=${receipt.id}`
    navigator.clipboard.writeText(url)
    message.success('URL del recibo copiada al portapapeles')
    trackEvent({ 
      name: 'receipt_url_copied', 
      metadata: { receipt_id: receipt.id }
    }, posthog)
  }
  
  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '16px', marginTop: '16px' }}>
        <Spin size="small" />
        <Typography.Text className="p-small-regular neutral-2" style={{ marginLeft: '8px' }}>
          Verificando recibos relacionados...
        </Typography.Text>
      </div>
    )
  }
  
  if (!pendingReceipts || pendingReceipts.length === 0) {
    return null
  }
  
  return (
    <Alert
      type="warning"
      icon={<></>}
      showIcon
      style={{
        marginTop: '24px',
        marginBottom: '24px',
        border: '1px solid var(--neutral-8)',
        borderRadius: '8px',
        backgroundColor: 'var(--neutral-8)'
      }}
      message={
        <div style={{ padding: '8px 0' }}>
          <Typography.Text className="p-base-bold neutral-1">
            <Warning size={16} weight="fill" className="yellow-5" style={{ marginRight: '8px' }} />
            Recibos pendientes detectados
          </Typography.Text>
          
          <Typography.Text className="p-small-regular neutral-2" style={{ display: 'block', marginTop: '8px' }}>
            El pago que estás intentando facturar, tiene relacionado un recibo de auto-factura que aún está pendiente. Si continúas, podrías generar 
            una duplicidad de facturación cuando este recibo sea completado por tu cliente o en la factura global.
          </Typography.Text>
          
          <Divider style={{ margin: '16px 0' }} />
          
          {pendingReceipts.map((receipt: any) => (
            <div key={receipt.id} style={{ marginTop: '8px' }}>
              <Row align="middle" justify="space-between" gutter={[16, 16]}>
                <Col xs={24} md={8}>
                  <Space align="center">
                    <Receipt size={20} className="neutral-2" />
                    <div>
                      <Typography.Text className="p-small-bold neutral-1">
                        Recibo # {receipt?.metadata?.orderId || receipt.id}
                      </Typography.Text>
                      <Typography.Text className="p-small-regular neutral-3" style={{ display: 'block' }}>
                        {receipt?.client?.name || receipt?.client?.legal_name || 'Sin nombre' } • ${(receipt.total || 0).toFixed(2)} {receipt.currency}
                      </Typography.Text>
                    </div>
                  </Space>
                </Col>
                <Col xs={24} md={16}>
                  <Row gutter={[8, 8]} justify="end">
                    <Col>
                      <Button
                        type="default"
                        size="small"
                        icon={<Copy size={16} />}
                        onClick={() => copyReceiptUrl(receipt)}
                      >
                        Copiar URL
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="default"
                        size="small"
                        icon={<ArrowSquareOut size={16} />}
                        onClick={() => window.open(`https://invoicing.gigstack.pro/autofactura?id=${receipt.id}`, '_blank')}
                      >
                        Facturar en portal
                      </Button>
                    </Col>
                    <Col>
                    <Popconfirm
                      title="¿Quieres cancelar este recibo?"
                      description={
                        <Typography.Text className="p-small-regular neutral-2">
                            Si continúas, el recibo se marcará como cancelado, tendrás que continuar con la generación de la factura manualmente.
                        </Typography.Text>
                      }
                      onConfirm={() => handleCompleteReceipt(receipt)}
                    >
                      <Button 
                        type="primary"
                        size="small"
                        loading={processingIds.includes(receipt.id)}
                        icon={<Check size={16} />}
                      >
                        Cancelar recibo
                        </Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      }
    />
  )
}

export default PendingReceiptAlert 