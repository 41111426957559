import React, { useState } from 'react'
import { Form, FormInstance, Row, Col, message, Button, Tag, Card, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { SelectInput, TextAreaInput, TextInput } from './Inputs'
import { setTeam } from '../../context/teamsSlice'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import SwitchHolder from './SwitchHolder'
import { CaretDown, CaretUp, PaperPlaneTilt } from '@phosphor-icons/react'

interface ElementEmailTemplateFormProps {
    incomingForm?: FormInstance // Replace 'any' with the appropriate type for incomingForm
    buttonTitle?: string
    subjectLabel?: string
    template: string
    label: string
    replacers?: string[]
    emailTitle: string
    emailHelper: string
}

export const EmailDesign = ({
    subjectInputId,
    subjectLabel,
    subjectPlaceholder,
    templateInputId,
    templateLabel,
    templatePlaceholder,
    replacers,
    helper,
    title,
}: {
    subjectInputId: string
    subjectLabel: string
    subjectPlaceholder: string
    templateInputId: string
    templateLabel: string
    templatePlaceholder: string
    replacers: string[]
    helper: string
    title?: string
}) => {
    const { team } = useSelector((state: any) => state.team)
    const [focusedField, setFocusedField] = useState<string | null>(null)
    const [isExpanded, setIsExpanded] = useState(false)
    const formInstance = Form.useFormInstance()

    // Organize variables by category
    const variableCategories: Record<string, string[]> = {
        Cliente: ['cliente', 'empresa', 'alias'],
        'Factura/Recibo': ['id', 'recibo', 'folio', 'series', 'total', 'moneda'],
        Fechas: ['fecha', 'mes', 'año', 'dia', 'hoy'],
        // Enlaces: ['linkDePago', 'linkDeFactura'],
    }

    // Find any uncategorized replacers and add to "Otros" category
    const otherVariables = replacers.filter(
        (variable) => !Object.values(variableCategories).some((category) => category.includes(variable)),
    )

    if (otherVariables.length > 0) {
        variableCategories['Otros'] = otherVariables
    }

    const insertVariable = (variable: string) => {
        if (!focusedField) return

        const currentValue = formInstance.getFieldValue(focusedField) || ''
        const variableText = `{${variable}}`

        formInstance.setFieldsValue({
            [focusedField]: currentValue + variableText,
        })

        // Try to focus the field again after insertion
        setTimeout(() => {
            formInstance.getFieldInstance(focusedField)?.focus()
        }, 0)
    }

    return (
        <Col xs={24} style={{ marginBottom: '16px' }}>
            <Card
                style={{
                    border: '1px solid #e8e8e8',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                    overflow: 'hidden',
                }}
            >
                {title && (
                    <div
                        style={{
                            backgroundColor: '#fafbfd',
                            padding: '12px 16px',
                            borderBottom: isExpanded ? '1px solid #e8e8e8' : 'none',
                            marginBottom: isExpanded ? '16px' : '0',
                            marginLeft: '-24px',
                            marginRight: '-24px',
                            marginTop: '-24px',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        <Typography.Text style={{ margin: 0 }} className="mediumparagraphbold">
                            <PaperPlaneTilt style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                            {title}
                        </Typography.Text>
                        <div style={{ fontSize: '16px', color: '#8c8c8c' }}>
                            {isExpanded ? <CaretUp /> : <CaretDown />}
                        </div>
                    </div>
                )}

                {isExpanded ? (
                    <>
                        <div style={{ padding: '0 0 16px 0' }}>
                            <Row gutter={[0, 12]}>
                                <Col xs={24} className="d-flex flex-row" style={{ gap: '5px' }}>
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            border: '1px solid #e8e8e8',
                                            borderRadius: '4px',
                                            padding: '8px 12px',
                                            flex: 1,
                                        }}
                                    >
                                        <Row align="middle" gutter={8}>
                                            <Col flex="0 0 auto">
                                                <Typography.Text type="secondary" strong>
                                                    De:
                                                </Typography.Text>
                                            </Col>
                                            <Col flex="1 1 auto">
                                                <Typography.Text type="secondary">
                                                    {team?.brand?.alias} vía GigstackPro
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            border: '1px solid #e8e8e8',
                                            borderRadius: '4px',
                                            padding: '8px 12px',
                                            flex: 1,
                                        }}
                                    >
                                        <Row align="middle" gutter={8}>
                                            <Col flex="0 0 auto">
                                                <Typography.Text type="secondary" strong>
                                                    Para:
                                                </Typography.Text>
                                            </Col>
                                            <Col flex="1 1 auto">
                                                <Typography.Text type="secondary">{'{cliente}'}</Typography.Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>

                                <Col xs={24}>
                                    <TextInput
                                        name={subjectInputId}
                                        label={null}
                                        placeholder={subjectPlaceholder}
                                        prefix={
                                            <Typography.Text type="secondary" strong style={{ marginRight: '8px' }}>
                                                Asunto:
                                            </Typography.Text>
                                        }
                                        onInput={() => setFocusedField(subjectInputId)}
                                        customStyle={{ marginBottom: '0' }}
                                    />
                                </Col>

                                <Col xs={24}>
                                    <TextAreaInput
                                        name={templateInputId}
                                        label={null}
                                        placeholder={templatePlaceholder}
                                        minRows={6}
                                        maxRows={10}
                                        onFocus={() => setFocusedField(templateInputId)}
                                        propsStyle={{ marginBottom: '0' }}
                                    />
                                </Col>
                            </Row>
                        </div>

                        {replacers?.length > 0 && (
                            <div
                                style={{
                                    padding: '12px',
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '4px',
                                    marginBottom: '12px',
                                    border: '1px solid #e8e8e8',
                                }}
                            >
                                <Typography.Text
                                    type="secondary"
                                    strong
                                    style={{ display: 'block', marginBottom: '8px' }}
                                >
                                    Variables disponibles:
                                </Typography.Text>

                                {Object.entries(variableCategories).map(([category, variables]) => {
                                    // Filter to only include variables that are in the replacers array
                                    const availableVariables =
                                        category === 'Otros'
                                            ? variables
                                            : variables.filter((v) => replacers.includes(v))

                                    if (availableVariables.length === 0) return null

                                    return (
                                        <div key={category} style={{ marginBottom: '8px' }}>
                                            <Typography.Text
                                                type="secondary"
                                                style={{ fontSize: '12px', marginBottom: '4px', display: 'block' }}
                                            >
                                                {category}:
                                            </Typography.Text>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                                                {availableVariables.map((item) => (
                                                    <Tag
                                                        key={item}
                                                        color="blue"
                                                        style={{
                                                            cursor: 'pointer',
                                                            margin: '0 0 4px 0',
                                                            padding: '2px 8px',
                                                        }}
                                                        onClick={() => insertVariable(item)}
                                                    >
                                                        {`{${item}}`}
                                                    </Tag>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}

                        {helper && (
                            <div
                                style={{
                                    borderTop: '1px dashed #e8e8e8',
                                    paddingTop: '12px',
                                    marginTop: '4px',
                                }}
                            >
                                <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                                    <span style={{ marginRight: '4px' }}>ℹ️</span>
                                    {helper}
                                </Typography.Text>
                            </div>
                        )}
                    </>
                ) : (
                    <div style={{ padding: '12px' }}>
                        <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                            {helper}
                        </Typography.Text>
                    </div>
                )}
            </Card>
        </Col>
    )
}

const ElementEmailTemplateForm: React.FC<ElementEmailTemplateFormProps> = ({
    incomingForm,
    buttonTitle,
    subjectLabel,
    template,
    label,
    replacers,
    emailTitle,
    emailHelper,
}) => {
    const { settingsDrawerConfig } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const dispatch = useDispatch()

    // Define a complete list of all available variables
    const allReplacers = [
        'alias',
        'total',
        'fecha',
        'id',
        'cliente',
        'empresa',
        'moneda',
        'recibo',
        'folio',
        'series',
        'mes',
        'año',
        'dia',
        'hoy',
        'linkDePago',
        'linkDeFactura',
        ...(replacers || []),
    ]

    // Remove duplicates
    const uniqueReplacers = [...new Set(allReplacers)]

    const [form] = Form.useForm()

    const [messageApi, contextHolder] = message.useMessage()

    const usingForm = incomingForm ?? form

    // Your component logic here

    return (
        <Form
            form={usingForm}
            layout="vertical"
            initialValues={{ ...team?.defaults }}
            onFinish={async (values: any) => {
                try {
                    messageApi.open({
                        type: 'loading',
                        content: 'Actualizando información...',
                        duration: 0,
                    })

                    // Process all template values to convert line breaks to double newlines
                    const templateValues = {
                        [template]: values[template],
                        [`${template}Subject`]: values[`${template}Subject`] ?? '',
                        receiptsRemindersTemplate:
                            values['receiptsRemindersTemplate'] ?? team?.defaults?.receiptsRemindersTemplate ?? '',
                    }

                    // Add other template fields if they exist
                    if (values['invoiceMessageComplements']) {
                        templateValues['invoiceMessageComplements'] = values['invoiceMessageComplements']
                    }

                    if (values['invoiceMessageEgress']) {
                        templateValues['invoiceMessageEgress'] = values['invoiceMessageEgress']
                    }

                    const tup = {
                        [`defaults.${template}`]: templateValues[template],
                        [`defaults.${template}Subject`]: templateValues[`${template}Subject`],
                        [`defaults.receiptsRemindersTemplate`]: templateValues.receiptsRemindersTemplate,
                    }
                    if (values['forceInvoiceBcc']) {
                        tup[`defaults.forceInvoiceBcc`] = values['forceInvoiceBcc']
                    }

                    if (values['invoiceMessageComplements']) {
                        tup[`defaults.invoiceMessageComplements`] =
                            templateValues['invoiceMessageComplements'] ??
                            team?.defaults?.invoiceMessageComplements ??
                            ''
                    }

                    if (values['invoiceMessageComplementsSubject']) {
                        tup[`defaults.invoiceMessageComplementsSubject`] =
                            values['invoiceMessageComplementsSubject'] ??
                            team?.defaults?.invoiceMessageComplementsSubject ??
                            ''
                    }

                    if (values['invoiceMessageEgress']) {
                        tup[`defaults.invoiceMessageEgress`] =
                            templateValues['invoiceMessageEgress'] ?? team?.defaults?.invoiceMessageEgress ?? ''
                    }

                    if (values['invoiceMessageEgressSubject']) {
                        tup[`defaults.invoiceMessageEgressSubject`] =
                            values['invoiceMessageEgressSubject'] ?? team?.defaults?.invoiceMessageEgressSubject ?? ''
                    }

                    if (values['receiptsRemindersSubject']) {
                        tup[`defaults.receiptsRemindersSubject`] =
                            values['receiptsRemindersSubject'] ?? team?.defaults?.receiptsRemindersSubject ?? ''
                    }

                    dispatch(setTeam({ ...team, defaults: { ...team?.defaults, ...values } }))
                    await updateDoc(doc(getFirestore(), 'teams', team?.id), tup)
                    messageApi.destroy()
                    message.success('Información actualizada correctamente')
                    dispatch(closeModal('settingsDrawerVisible'))
                } catch (error: any) {
                    message.error('Ocurrió un error al actualizar la información.')
                }
            }}
        >
            {contextHolder}
            <Row>
                <EmailDesign
                    subjectInputId={`${template}Subject`}
                    subjectLabel={subjectLabel ?? 'Asunto de la plantilla para nuevas facturas de ingreso'}
                    subjectPlaceholder="Factura/Invoice #24 vía GigstackPro"
                    templateInputId={template}
                    templateLabel={label}
                    templatePlaceholder="Estimado .... "
                    replacers={uniqueReplacers}
                    helper={emailHelper}
                    title={emailTitle}
                />

                {settingsDrawerConfig.type === 'defaults.invoiceMessage' && (
                    <EmailDesign
                        subjectInputId="invoiceMessageComplementsSubject"
                        subjectLabel="Asunto de la plantilla para complementos de pago"
                        subjectPlaceholder="Factura/Invoice #24 vía GigstackPro"
                        templateInputId="invoiceMessageComplements"
                        templateLabel="Plantilla para complementos de pago"
                        templatePlaceholder="Estimado .... "
                        replacers={uniqueReplacers}
                        helper="Este correo se envía al cliente cuando se crea un complemento de pago al cliente"
                        title="Plantilla para complementos de pago"
                    />
                )}

                {settingsDrawerConfig.type === 'defaults.invoiceMessage' && (
                    <EmailDesign
                        subjectInputId="invoiceMessageEgressSubject"
                        subjectLabel="Asunto de la plantilla para notas de crédito"
                        subjectPlaceholder="Factura/Invoice #24 vía GigstackPro"
                        templateInputId="invoiceMessageEgress"
                        templateLabel="Plantilla para notas de crédito"
                        templatePlaceholder="Estimado .... "
                        replacers={uniqueReplacers}
                        helper="Este correo se envía al cliente cuando se crea una nota de crédito"
                        title="Plantilla para notas de crédito"
                    />
                )}

                {settingsDrawerConfig?.type === 'defaults.receiptsEmailsTemplate' && (
                    <EmailDesign
                        subjectInputId="receiptsRemindersSubject"
                        subjectLabel="Asunto de la plantilla para recordatorios de recibos"
                        subjectPlaceholder="Recibo de venta - vía GigstackPro"
                        templateInputId="receiptsRemindersTemplate"
                        templateLabel="Plantilla para recordatorios de recibos"
                        templatePlaceholder="Estimado .... "
                        replacers={uniqueReplacers}
                        helper="Este correo se envía en los últimos días del mes para recordar que pueden realizar su factura"
                        title="Plantilla para recordatorios de recibos"
                    />
                )}

                {settingsDrawerConfig?.type === 'defaults.invoiceMessage' && (
                    <Col xs={24} style={{ marginTop: '25px' }}>
                        <SelectInput
                            mode="tags"
                            options={[
                                ...(team?.defaults?.forceInvoiceCc ?? []).map((item: any) => ({
                                    label: item,
                                    value: item,
                                })),
                                { label: user?.email, value: user?.email },
                            ]}
                            placeholder="Enviar copia a"
                            name="forceInvoiceBcc"
                            label="Forzar BCC en envío de correo de facturas"
                            description="Enviaremos los correos con copia a los correos seleccionados"
                        />
                    </Col>
                )}

                <Col xs={24} style={{ marginTop: '15px' }}>
                    <Row justify="end">
                        <Button type="primary" htmlType="submit">
                            {buttonTitle ? buttonTitle : 'Actualizar mensajes'}
                        </Button>
                    </Row>
                </Col>

                {settingsDrawerConfig?.type === 'defaults.receiptsEmailsTemplate' && (
                    <Col xs={24} style={{ marginTop: '25px' }}>
                        <SwitchHolder
                            switchState={team?.defaults?.avoidForeignReceiptsEmails}
                            title="Deshabilita el envío de correo de recibos a clientes extranjeros"
                            description="No enviaremos correos cuando la moneda original sea diferente a tu moneda base"
                            icon={<PaperPlaneTilt weight="regular" />}
                            changeState={async (value: boolean) => {
                                try {
                                    dispatch(
                                        setTeam({
                                            ...team,
                                            defaults: { ...team?.defaults, avoidForeignReceiptsEmails: value },
                                        }),
                                    )
                                    await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                                        'defaults.avoidForeignReceiptsEmails': value,
                                    })
                                    message.success('Configuración actualizada correctamente')
                                } catch (error: any) {
                                    message.error(error?.message ?? 'Ocurrió un error al actualizar la configuración.')
                                }
                            }}
                        />
                    </Col>
                )}

                <Col xs={24} style={{ marginTop: '5px' }}>
                    {/* <Typography.Text className="mediumparagraphbold">
                        Envío de correos electrónicos en recursos test
                    </Typography.Text> */}
                    <Col xs={24} style={{ marginTop: '15px' }}>
                        <SwitchHolder
                            switchState={team?.defaults?.avoidGlobalInvoiceEmailsOnTest}
                            title="Deshabilita el envío de correos en facturas de prueba"
                            description="No enviaremos correos de facturas de prueba"
                            icon={<PaperPlaneTilt weight="regular" />}
                            changeState={async (value: boolean) => {
                                try {
                                    dispatch(
                                        setTeam({
                                            ...team,
                                            defaults: { ...team?.defaults, avoidGlobalInvoiceEmailsOnTest: value },
                                        }),
                                    )
                                    await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                                        'defaults.avoidGlobalInvoiceEmailsOnTest': value,
                                    })
                                    message.success('Configuración actualizada correctamente')
                                } catch (error: any) {
                                    message.error(error?.message ?? 'Ocurrió un error al actualizar la configuración.')
                                }
                            }}
                        />
                    </Col>
                </Col>
            </Row>
        </Form>
    )
}

export default ElementEmailTemplateForm
