import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Popover, Button, Checkbox, Collapse, Table, Alert, Space, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentMethod, availableMethods } from '../../datasets/Payments'
import { setData } from '../../context/dataSlice'
import { getItemsAmounts } from '../../functions/helpers'
import { openModal } from '../../context/modalsSlice'
import { httpsCallable } from 'firebase/functions'
import { getFunctions } from 'firebase/functions'
import { message } from 'antd'
import stripeLogo from '../../../assets/images/stripeSquare.png'
import { doc, getDoc, getFirestore } from 'firebase/firestore'

interface PaymentsMethodsProps {
    updatePaymentMethod?: (v: any) => void
    hideTitle?: boolean
    setState?: (v: any) => void
}

const PaymentMethodInfo = ({ method, disabled }: { method: PaymentMethod; disabled: boolean }) => {
    const dispatch = useDispatch()

    return (
        <Row align="middle" justify="center" style={{ maxWidth: '300px' }}>
            <Col xs={24}>
                <Typography.Text className="smallparagraph descriptions">{method?.description}</Typography.Text>
                <Row style={{ margin: '7px 0px' }}>
                    <Typography.Text className="smallparagraphbold">Procesador: </Typography.Text>
                    <img
                        src={method.processorLogo}
                        alt=""
                        style={{ width: '20px', height: '20px', borderRadius: '4px', margin: 'auto auto auto 5px' }}
                    />
                </Row>
                <Row style={{ width: '100%', marginTop: '10px' }}>
                    {method.action && (
                        <Button
                            disabled={disabled}
                            style={{ width: '100%' }}
                            onClick={() => {
                                if (method.processor === 'spei') {
                                    dispatch(openModal('speiModalVisible'))
                                } else {
                                    dispatch(openModal('stripeBankInstructionsVisible'))
                                }
                            }}
                        >
                            {method.action}
                        </Button>
                    )}
                </Row>
            </Col>
        </Row>
    )
}

const PaymentMethodCard = ({
    type,
    selected,
    available,
    paymentMethod,
}: {
    type: string
    selected: boolean
    available: boolean
    paymentMethod: PaymentMethod
}) => {
    const dispatch = useDispatch()

    const { custom_method_types } = useSelector((state: any) => state.data)

    return (
        <Popover
            content={<PaymentMethodInfo method={paymentMethod} disabled={!available} />}
            title={paymentMethod.name}
        >
            <div
                style={{
                    cursor: available ? 'pointer' : 'not-allowed',
                    minWidth: '190px',
                    marginRight: '3px',
                    width: '230px',
                    height: '50px',
                    borderRadius: '10px',
                    border: selected ? '2px solid #8666FF' : '1px solid #e3e3e3',
                    backgroundColor: available ? '' : '#f2f2f2',
                }}
                onClick={() => {
                    if (!available) return
                    const updatedMethods = [...custom_method_types]
                    const index = updatedMethods.findIndex((p: any) => p.id === type)
                    if (index > -1) {
                        updatedMethods.splice(index, 1)
                    } else {
                        if (type === 'bank') {
                            const stripeBankIndex = updatedMethods.findIndex((p: any) => p.id === 'customer_balance')
                            if (stripeBankIndex > -1) {
                                updatedMethods.splice(stripeBankIndex, 1)
                            }
                        } else if (type === 'customer_balance') {
                            const bankIndex = updatedMethods.findIndex((p: any) => p.id === 'bank')
                            if (bankIndex > -1) {
                                updatedMethods.splice(bankIndex, 1)
                            }
                        }
                        updatedMethods.push({ id: type })
                    }
                    dispatch(
                        setData({
                            item: 'custom_method_types',
                            data: updatedMethods,
                        }),
                    )
                }}
            >
                <Row justify="space-between" align="middle" style={{ height: '100%' }}>
                    <Col xs={20}>
                        <div className="d-flex flex-row">
                            <div className="d-flex" style={{ width: '30%', marginLeft: '10px', marginRight: '10px' }}>
                                <img
                                    src={paymentMethod.logo}
                                    alt=""
                                    style={{ width: '30px', height: '30px', borderRadius: '4px' }}
                                />
                            </div>
                            <div style={{ width: '70%' }}>
                                <Typography.Text ellipsis className="smallparagraph">
                                    {paymentMethod.name}
                                </Typography.Text>
                                <p style={{ margin: 0, padding: 0, fontSize: '8px' }}>{paymentMethod.message}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={4}>
                        <Row justify="center" align="middle" style={{ height: '100%' }}>
                            <Checkbox checked={selected} disabled={!available} />
                        </Row>
                    </Col>
                </Row>
            </div>
        </Popover>
    )
}

const SimilarClientsTable = ({ visible }: { visible: boolean }) => {
    const [loading, setLoading] = useState(false)
    const [similarClients, setSimilarClients] = useState<any[]>([])
    const { client, testmode, clients } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const dispatch = useDispatch()
    const clientHandler = httpsCallable(getFunctions(), 'stripeclientsapp')

    useEffect(() => {
        const fetchSimilarClients = async () => {
            if (!client || !visible) return
            
            setLoading(true)
            try {
                const result = (await clientHandler({
                    action: 'search_similar',
                    livemode: client.livemode,
                    teamId: team.id,
                    team: team.id,
                    data: {
                        email: client.email,
                        name: client.name,
                        rfc: client.rfc
                    }
                })) as any
                
                const clients = (result?.data || []).filter((c: any) => c.gigstackId !== client.id)
                setSimilarClients(clients)
            } catch (error) {
                console.error('Error fetching similar clients:', error)
            }
            setLoading(false)
        }

        fetchSimilarClients()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, visible])

    const handleImportClient = async (stripeClient: any) => {
        try {
            setLoading(true)
            const result = (await clientHandler({
                action: 'import_customer',
                livemode: !testmode,
                teamId: team.id,
                team: team.id,
                data: stripeClient
            })) as any


            if (result?.data) {
                dispatch(setData({
                    item: 'client',
                    data: result.data.client
                }))
                
                dispatch(setData({
                    item: 'clients',
                    data: [...clients, result.data.client]
                }))
                message.success('Cliente importado correctamente')
            }
        } catch (error: any) {
            message.error(error?.message || 'Error al importar el cliente')
        } finally {
            setLoading(false)
        }
    }

    if (!visible) return null

    return (
        <div style={{ marginTop: '15px' }}>
            <Collapse>
                <Collapse.Panel 
                    header={
                        <Typography.Text strong>
                            Clientes similares encontrados ({similarClients.length})
                        </Typography.Text>
                    } 
                    key="1"
                >
                    <Table
                        loading={loading}
                        dataSource={similarClients}
                        columns={[
                            {
                                title: 'Nombre',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'Email',
                                dataIndex: 'email',
                                key: 'email',
                                render: (text: string, record: any) => (
                                    <Space align="center">
                                        <Typography.Text>{text}</Typography.Text>
                                        {record.from === 'stripe' && <Tooltip title="Cliente de Stripe">
                                            <img
                                                src={stripeLogo}
                                                alt="Stripe"
                                                style={{ 
                                                    width: '15px', 
                                                    height: '15px', 
                                                    borderRadius: '3px',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </Tooltip>}
                                    </Space>
                                )
                            },
                            {
                                title: 'Gigstack ID',
                                dataIndex: 'gigstackId',
                                key: 'gigstackId',
                            },
                            {
                                title: "Acciones",
                                key: 'actions',
                                render: (_, record: any) => (
                                    <Space>
                                        {record.gigstackId ? (
                                            <Button 
                                                className="btn-secondary"
                                                onClick={async () => {
                                                    const client = await getDoc(doc(getFirestore(), "clients", record.gigstackId))
                                                    dispatch(setData({
                                                        item: 'client',
                                                        data: client.data()
                                                    }))
                                                    message.success('Cliente seleccionado correctamente')
                                                }}
                                            >
                                                Utilizar este cliente
                                            </Button>
                                        ) : (
                                            <Button 
                                                className="btn-primary"
                                                onClick={() => handleImportClient(record)}
                                            >
                                                Importar
                                            </Button>
                                        )}
                                    </Space>
                                ),
                            }
                        ]}
                        pagination={false}
                    />
                    <Alert
                        style={{ padding: '5px 10px', marginTop: '15px' }}
                        type="warning"
                        description={
                            <Typography.Text className="smallparagraph descriptions">
                                Cada cliente en Stripe tiene una cuenta CLABE única. Por favor verifica que el cliente seleccionado sea el correcto, ya que la CLABE generada será específica para este cliente.
                            </Typography.Text>
                        }
                    />
                </Collapse.Panel>
            </Collapse>
        </div>
    )
}

const PaymentMethodsCards = () => {
    const { custom_method_types, selectedServices, client } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)

    const items = getItemsAmounts(selectedServices)
    const isBankAvailable = team.bank?.completed && team?.bank?.clabe && team?.bank?.clabe !== ' '
    const isSpeiSelected = custom_method_types?.some((p: any) => p.id === 'customer_balance')
    const clientHasStripeId = client?.metadata?.stripeId

    const handleAvailablePaymentMethodsByTeam = (type: string) => {
        if (type === 'card') {
            return team.stripe?.completed
        } else if (type === 'oxxo' && items.total < 9999) {
            return team.stripe?.completed
        } else if (type === 'bank' && isBankAvailable) {
            return team.bank?.completed
        } else if (type === 'spei') {
            return team.stripe?.completed
        } else if (type === 'customer_balance') {
            return team.stripe?.completed
        }
        return false
    }

    return (
        <>
            <div className="d-flex flex-row" style={{ justifyContent: 'space-between' }}>
                {availableMethods.map((method, index) => (
                    <PaymentMethodCard
                        key={index}
                        paymentMethod={method}
                        type={method.id}
                        selected={custom_method_types?.some((p: any) => p.id === method.id)}
                        available={handleAvailablePaymentMethodsByTeam(method.id)}
                    />
                ))}
            </div>
            {isSpeiSelected && !clientHasStripeId && client && (
                <Alert
                    style={{ marginTop: '15px' }}
                    type="info"
                    showIcon
                    message={
                        <Typography.Text className="smallparagraph">
                            El cliente seleccionado no está creado en Stripe. Lo crearemos automáticamente para asignarle una cuenta CLABE única.
                        </Typography.Text>
                    }
                />
            )}
            <SimilarClientsTable visible={isSpeiSelected} />
        </>
    )
}

const PaymentsMethods: React.FC<PaymentsMethodsProps> = ({ updatePaymentMethod, hideTitle }) => {
    const { team } = useSelector((state: any) => state.team)
    const [methods, setMethods] = useState<any[]>([])

    useEffect(() => {
        const updatedMethods = []

        if (team.stripe?.completed) {
            updatedMethods.push({
                details: 'Paga con tu tarjeta de crédito o débito',
                id: 'card',
                logo: 'https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/Cards2.svg',
                manualConfirmation: false,
                name: 'Tarjeta de crédito y débito',
                requiredKey: 'paymentIntent',
            })
        }

        if (team.stripe?.completed) {
            updatedMethods.push({
                details: 'Pago en efectivo en tiendas OXXO',
                id: 'oxxo',
                logo: 'https://js.stripe.com/v3/fingerprinted/img/oxxo-96b6ab36d23607973cb466bec56d187b.svg',
                manualConfirmation: false,
                name: 'OXXO',
                requiredKey: 'paymentIntent',
            })
        }
        if (team.bank?.completed) {
            updatedMethods.push({
                details: 'Pago con transferencia desde tu cuenta bancaria',
                id: 'bank',
                logo: 'https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/+bank.png',
                manualConfirmation: true,
                name: 'Transferencia bancaria',
            })
        }

        //remove duplicates from methods
        // const uniqueMethods = methods.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i)
        const uniqueMethods = updatedMethods.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)

        setMethods(uniqueMethods)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team.stripe?.completed, team.bank?.completed])

    return (
        <Row style={{ width: '100%', marginTop: '15px' }}>
            <Col xs={24}>
                {methods.length <= 0 ? (
                    <Typography.Text className="smallparagraph">
                        No hay métodos de pagos disponibles para esta transacción
                    </Typography.Text>
                ) : (
                    <PaymentMethodsCards />
                )}
            </Col>
        </Row>
    )
}

export default PaymentsMethods
