import {
    Button,
    Col,
    Divider,
    Row,
    Table,
    Tag,
    Typography,
    message,
    Empty,
    Card,
    Statistic,
    Skeleton,
    Tabs,
    List,
    Segmented,
    Tooltip,
    Input,
    Alert,
    Spin,
    Progress,
    Space,
} from 'antd'
import type { InputRef } from 'antd'
import {
    DocumentData,
    collection,
    doc,
    getAggregateFromServer,
    getCountFromServer,
    getFirestore,
    orderBy,
    query,
    sum,
    updateDoc,
    where,
    onSnapshot,
} from 'firebase/firestore'
import React, { useEffect, useState, useRef } from 'react'
import { ClientDef, clientSet } from '../../interfaces/clientDef'
import { useAuth, useFirestoreDocData } from 'reactfire'
import { SearchParamInURL, returnCurrencyValue } from '../functions/helpers'
import { CFDIUses, paymentForms, taxRegimes } from '../datasets/Fiscal'
import { countryLabeledListWithCodeAlpha3, mexicanStates } from '../datasets/Countries'
import {
    ArrowLeft,
    Building,
    CaretDown,
    CheckCircle,
    Clock,
    ClockCounterClockwise,
    CreditCard,
    Envelope,
    ExclamationMark,
    FileText,
    GitFork,
    Globe,
    IdentificationCard,
    Link,
    Note,
    Path,
    PencilLine,
    Phone,
    Prohibit,
    Receipt,
    StarFour,
    User,
    UserCheck,
    Plus,
    Question,
    ClockAfternoon,
    PlusCircle,
    Money,
    Cardholder,
    CurrencyDollar,
    Pencil,
    Bank,
    Gear,
    Trash,
    TagChevron,
} from '@phosphor-icons/react'
import { setData, setElementSelected } from '../context/dataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '../context/modalsSlice'
import moment from 'moment'

import { useNavigate } from 'react-router-dom'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { DetailItem } from './ReceiptsViewDetail'
import { SelectInput } from '../components/Forms/Inputs'
import { currecies } from '../datasets/Currencies'
import { ResponsiveContainer, XAxis, YAxis, Tooltip as RechartsTooltip, LineChart, Line } from 'recharts'
import { PaymentStatusTag, ReceiptStatusTag } from '../components/UI/ElementStatus'
import InvoiceActions from '../components/ElementActions/InvoiceActions'
import PaymentsActions from '../components/ElementActions/PaymentsActions'
import ReceiptActions from '../components/ElementActions/ReceiptsActions'
import { LogsRelatedToElement } from '../components/Logs/LogsRelatedToElement'
import { DownloadOutlined } from '@ant-design/icons'

import { RecurringPaymentsColumns } from '../components/Tables/Columns/RecurringPaymentsColumns'
import { usePermissions } from '../customHooks/usePermissions'
import { UploadCSFInput } from '../new-onboarding/onboarding/steps/UploadCSF'
import { useTranslation } from 'react-i18next'
import { ElementRelations } from '../components/Relations/ElementRelations'

type MonthDataItem = {
    currency: string
    type: string
    month: string
    year: number
    amount: number
}

const handleDownloadClientBalance = async ({
    client,
    loading,
    setLoading,
    teamId,
    auth,
}: {
    client: ClientDef
    loading: boolean
    setLoading: (v: boolean) => void
    teamId: string
    auth: any
}) => {
    try {
        setLoading(true)
        await SignedInternalAPIRequest(
            { clientId: client.id, teamId },
            'generateClientAccountBalance',
            auth.currentUser,
            {
                isFile: true,
                fileName: `${client.rfc ?? 'Balance'}-${moment().format('DD/MM/YYYY')}`,
                fileExtension: 'pdf',
            },
            'POST',
        )
        setLoading(false)
    } catch (error: any) {
        console.log('🚀 ~ error:', error)
        message.error(error.message || 'Error al descargar el archivo')
        setLoading(false)
    }
}

// const handleDownloadClientAccountStatement = async ({
//     clientId,
//     loading,
//     setLoading,
//     auth,
// }: {
//     clientId: string
//     loading: boolean
//     setLoading: (v: boolean) => void
//     auth: any
// }) => {
//     try {
//         setLoading(true)
//         await SignedInternalAPIRequest(
//             {
//                 clientId,
//             },
//             `exportFiles/v1/collectionPayments`,
//             auth.currentUser,
//             {
//                 isFile: true,
//                 fileName: `Estado_de_cuenta-${moment().format('DD/MM/YYYY')}`,
//                 fileExtension: 'xlsx',
//             },
//             'POST',
//         )
//         setLoading(false)
//     } catch (error: any) {
//         console.log('🚀 ~ error:', error)
//         message.error(error.message || 'Error al descargar el archivo')
//         setLoading(false)
//     }
// }

const ClientViewDetail: React.FC = () => {
    const [reviewPayments, setReviewPayments] = useState({
        loaded: false,
        visible: false,
        count: 0,
    })

    const navigate = useNavigate()
    const { canCreatePayment, canCreateInvoice, canCreateReceipt } = usePermissions()
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)

    const auth = useAuth()
    const fs = getFirestore()
    const dispatch = useDispatch()
    const id = SearchParamInURL('id')
    const { t } = useTranslation()

    const clientRef = doc(fs, 'clients', id ?? '')
    const { status, data } = useFirestoreDocData(clientRef)
    const client = clientSet(data)

    const { anchorLocation, testmode } = useSelector((state: any) => state.data)
    const [isEditingTaxSystem, setIsEditingTaxSystem] = useState(false)
    const [isEditingCountry, setIsEditingCountry] = useState(false)
    const [isEditingState, setIsEditingState] = useState(false)
    const [monthData, setMonthData] = useState<{
        loaded: boolean
        data: MonthDataItem[]
    }>({
        loaded: false,
        data: [],
    })
    // const [totalInvoiced, setTotalInvoiced] = useState(0)
    // const [totalPaid, setTotalPayments] = useState(0)
    const [showEfos, setShowEfos] = useState<boolean>(false)
    const [selectedCurrency, setSelectedCurrency] = useState<string>('ALL')
    const [selectedYear, setSelectedYear] = useState<number | 'ALL'>('ALL')

    const [paymentsRelated, setPaymentsRelated] = useState<any[]>([])
    const [invoicesRelated, setInvoicesRelated] = useState<any[]>([])
    const [receiptsRelated, setReceiptsRelated] = useState<any[]>([])
    const [recurringPayments, setRecurringPayments] = useState<any[]>([])
    const [recurringInvoices, setRecurringInvoices] = useState<any[]>([])
    const [isEditingUse, setIsEditingUse] = useState<boolean>(false)
    // const [invoicesCount, setInvoicesCount] = useState<number>(0)
    // const [paymentsCount, setPaymentsCount] = useState<number>(0)
    // const [receiptsCount, setReceiptsCount] = useState<number>(0)
    const [logs, setLogs] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const back = () => {
        if (anchorLocation) return navigate(anchorLocation)
        else return window.history.back()
    }

    const possibleChangeOfFiscalInfo = () => {
        const invoices = invoicesRelated
        const receipts = receiptsRelated
        const lastReceipt = receipts.sort((a, b) => b?.timestamp - a?.timestamp)[0]
        const lastInvoice = invoices
            .sort((a, b) => b?.timestamp - a?.timestamp)
            .filter((i) => i?.status !== 'succeeded')[0]
        if (lastReceipt?.timestamp > lastInvoice?.timestamp) {
            return {
                changed: true,
                lastReceipt,
            }
        }
        return {
            changed: false,
        }
    }

    useEffect(() => {
        if (!reviewPayments.loaded) {
            const q = query(
                collection(getFirestore(), 'payments'),
                where('team', '==', team?.id ?? ''),
                where('clientId', '==', id),
                where('status', '==', 'review_requested'),
                orderBy('timestamp', 'desc'),
            )
            getCountFromServer(q).then((count: DocumentData) => {
                setReviewPayments({
                    loaded: true,
                    visible: count.data().count > 0,
                    count: count.data().count,
                })
            })
        }
    }, [reviewPayments, team?.id, id])

    useEffect(() => {
        if (!monthData.loaded && data?.id) {
            // CHART DATA SOURCE: This is where chart data is fetched and populated
            const runAggregation = async () => {
                let dataToState: MonthDataItem[] = []

                // Calculate months since client was created
                let months = moment().diff(moment(data.timestamp), 'months')
                // Ensure we always look at least one month, including the current month
                if (months === 0) {
                    months = 1
                }

                // Important: Loop from 0 to include current month
                // Loop through recent months to gather payment and invoice data
                for (let i = 0; i <= months; i++) {
                    // Changed < to <= to include one more month
                    const currencies = currecies.map((currency) => currency.value)
                    // For each currency, create queries for payments and invoices
                    const mappedQueries = currencies.map((currency) => {
                        // For current month (i=0), use the current date as end date instead of end of month
                        // to ensure we capture all data up to now
                        const startDate = moment().subtract(i, 'month').startOf('month').valueOf()
                        const endDate =
                            i === 0
                                ? moment().valueOf() // Current time for current month
                                : moment().subtract(i, 'month').endOf('month').valueOf()

                        console.log(
                            `Querying month: ${moment().subtract(i, 'month').format('YYYY-MM')}, range: ${new Date(startDate)} to ${new Date(endDate)}`,
                        )

                        const q = query(
                            collection(getFirestore(), 'payments'),
                            where('team', '==', team?.id ?? ''),
                            where('clientId', '==', id),
                            where('timestamp', '>=', startDate),
                            where('timestamp', '<=', endDate),
                            where('currency', 'in', [currency?.toUpperCase(), currency.toLowerCase()]),
                            where('livemode', '==', !testmode),
                            orderBy('timestamp', 'desc'),
                        )
                        const invoiceq = query(
                            collection(getFirestore(), 'invoices'),
                            where('team', '==', team?.id ?? ''),
                            where('clientId', '==', id),
                            where('timestamp', '>=', startDate),
                            where('timestamp', '<=', endDate),
                            where('currency', 'in', [currency?.toUpperCase(), currency.toLowerCase()]),
                            where('livemode', '==', !testmode),
                        )
                        return {
                            invoices: invoiceq,
                            payments: q,
                            currency: currency,
                        }
                    })

                    // CHART DATA POPULATION: Process and calculate data for each month/currency combination
                    const promises = mappedQueries.map(async (query) => {
                        const countInvoicesSum = await getAggregateFromServer(query.invoices, {
                            amount: sum('total'),
                        })
                        const countPaymentSum = await getAggregateFromServer(query.payments, {
                            amount: sum('amount'),
                        })
                        const currentDate = moment().subtract(i, 'month').startOf('month')
                        return [
                            {
                                currency: query.currency,
                                type: 'payments',
                                month: currentDate.format('MM'),
                                year: currentDate.year(),
                                amount: countPaymentSum.data().amount,
                            },
                            {
                                currency: query.currency,
                                type: 'invoices',
                                month: currentDate.format('MM'),
                                year: currentDate.year(),
                                amount: countInvoicesSum.data().amount,
                            },
                        ]
                    })
                    const results = await Promise.all(promises)

                    // Flatten the nested arrays of results before adding to dataToState
                    const flatResults = results.flat()

                    // Log the raw results for debugging

                    // Filter out records with zero or undefined amounts, but log them first
                    // const zeroAmounts = flatResults.filter((item) => !item.amount || item.amount <= 0)

                    const filteredResults = flatResults.filter((item) => item.amount && item.amount > 0)

                    // Add the filtered results to dataToState
                    dataToState.push(...filteredResults)
                }

                // Log final dataset

                // CHART DATA STORAGE: Store the processed data in component state
                setMonthData({
                    loaded: true,
                    data: dataToState,
                })

                // Instead of aggregating all currencies together, we won't set these values directly
                // // They will be calculated separately by currency in the render section
                // setTotalPayments(0)
                // setTotalInvoiced(0)

                // Note: we're no longer using these queries for combined totals
                // This ensures we don't add up different currencies incorrectly
                /*
                const totalPaidQ = query(
                    collection(getFirestore(), 'payments'),
                    // where('team', '==', team?.id ?? ''),
                    where('clientId', '==', id),
                    where('livemode', '==', !testmode),
                    where('status', '==', 'succeeded'),
                )
                const invoicedQ = query(
                    collection(getFirestore(), 'invoices'),
                    where('team', '==', team?.id ?? ''),
                    where('clientId', '==', id),
                    where('livemode', '==', !testmode),
                )
                const totalPaid = await getAggregateFromServer(totalPaidQ, {
                    amount: sum('amount'),
                    count: count(),
                })
                const totalInvoiced = await getAggregateFromServer(invoicedQ, {
                    amount: sum('total'),
                    count: count(),
                })
                console.log('Total paid:', totalPaid.data().amount)
                console.log('Total invoiced:', totalInvoiced.data().amount)

                setTotalPayments(totalPaid.data().amount / 100)
                setTotalInvoiced(totalInvoiced.data().amount)
                */
            }
            runAggregation()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id])

    // useEffect(() => {
    //     if (monthData.loaded) {
    //         // Log the processed data too
    //         if (monthData.data.length > 0) {
    //             // Group data by month
    //             const groupedData = monthData.data.reduce(
    //                 (acc, item) => {
    //                     if (!acc[item.month]) {
    //                         acc[item.month] = {
    //                             month: item.month,
    //                             invoices: 0,
    //                             payments: 0,
    //                         }
    //                     }

    //                     if (item.type === 'invoices') {
    //                         acc[item.month].invoices += item.amount
    //                     } else if (item.type === 'payments') {
    //                         acc[item.month].payments += item.amount
    //                     }

    //                     return acc
    //                 },
    //                 {} as Record<string, { month: string; invoices: number; payments: number }>,
    //             )
    //             // setMonthData({
    //             //     loaded: true,
    //             //     data: Object.values(groupedData).map((item) => ({
    //             //         currency: monthData.data[0]?.currency || '',
    //             //         type: 'aggregated',
    //             //         month: item.month,
    //             //         amount: item.invoices - item.payments,
    //             //     })),
    //             // })
    //         }
    //     }
    // }, [monthData])

    useEffect(() => {
        if (data?.id) {
            console.log('ID: ', data?.id)
            // Create array to store all unsubscribe functions
            const unsubscribers: (() => void)[] = []

            // Real-time listener for payments
            const paymentsQuery = query(
                collection(getFirestore(), 'payments'),
                where('clientId', '==', id),
                where('team', '==', team?.id),
                where('livemode', '==', !testmode),
            )

            const paymentsUnsubscribe = onSnapshot(
                paymentsQuery,
                (snapshot) => {
                    setPaymentsRelated(snapshot.docs.map((doc) => doc.data()).sort((a, b) => b.timestamp - a.timestamp))
                },
                (error) => {
                    console.error('Error listening to payments:', error)
                },
            )
            unsubscribers.push(paymentsUnsubscribe)

            // Real-time listener for invoices
            const invoicesQuery = query(
                collection(getFirestore(), 'invoices'),
                where('clientId', '==', id),
                where('team', '==', team?.id),
                where('livemode', '==', !testmode),
            )

            const invoicesUnsubscribe = onSnapshot(
                invoicesQuery,
                (snapshot) => {
                    setInvoicesRelated(snapshot.docs.map((doc) => doc.data()).sort((a, b) => b.timestamp - a.timestamp))
                },
                (error) => {
                    console.error('Error listening to invoices:', error)
                },
            )
            unsubscribers.push(invoicesUnsubscribe)

            // Real-time listener for receipts
            const receiptsQuery = query(
                collection(getFirestore(), 'receipts'),
                where('clientId', '==', id),
                where('team', '==', team?.id),
                where('livemode', '==', !testmode),
            )

            const receiptsUnsubscribe = onSnapshot(
                receiptsQuery,
                (snapshot) => {
                    setReceiptsRelated(snapshot.docs.map((doc) => doc.data()).sort((a, b) => b.timestamp - a.timestamp))
                },
                (error) => {
                    console.error('Error listening to receipts:', error)
                },
            )
            unsubscribers.push(receiptsUnsubscribe)

            // Real-time listener for logs
            const logsQuery = query(
                collection(getFirestore(), 'clients', id ?? '', 'logs'),
                orderBy('timestamp', 'desc'),
            )

            const logsUnsubscribe = onSnapshot(
                logsQuery,
                (snapshot) => {
                    setLogs(snapshot.docs.map((doc) => doc.data()))
                },
                (error) => {
                    console.error('Error listening to logs:', error)
                },
            )
            unsubscribers.push(logsUnsubscribe)

            // Real-time listener for recurring payments
            const recurringPaymentsQuery = query(
                collection(getFirestore(), 'recurringEvents'),
                where('clientId', '==', id),
                where('team', '==', team?.id),
                where('elementType', 'in', ['payments', 'payment']),
                where('replicateElement.livemode', '==', !testmode),
            )

            const recurringPaymentsUnsubscribe = onSnapshot(
                recurringPaymentsQuery,
                (snapshot) => {
                    console.log(
                        'Recurring payments updated:',
                        snapshot.docs.map((doc) => doc.data()),
                    )
                    setRecurringPayments(snapshot.docs.map((doc) => doc.data()))
                },
                (error) => {
                    console.error('Error listening to recurring payments:', error)
                },
            )
            unsubscribers.push(recurringPaymentsUnsubscribe)

            // Real-time listener for recurring invoices
            const recurringInvoicesQuery = query(
                collection(getFirestore(), 'recurringEvents'),
                where('clientId', '==', id),
                where('team', '==', team?.id),
                where('elementType', 'in', ['invoices', 'invoice']),
                where('replicateElement.livemode', '==', !testmode),
            )

            const recurringInvoicesUnsubscribe = onSnapshot(
                recurringInvoicesQuery,
                (snapshot) => {
                    setRecurringInvoices(snapshot.docs.map((doc) => doc.data()))
                },
                (error) => {
                    console.error('Error listening to recurring invoices:', error)
                },
            )
            unsubscribers.push(recurringInvoicesUnsubscribe)

            // Clean up listeners when component unmounts or when data.id changes
            return () => {
                unsubscribers.forEach((unsubscribe) => unsubscribe())
                console.log('Real-time listeners cleaned up')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id])

    if (status === 'loading') return <></>

    function findChanges(before: Record<string, any>, after: Record<string, any>, path = ''): string[] {
        const changes: string[] = []

        const allKeys = new Set([...Object.keys(before), ...Object.keys(after)])

        for (const key of allKeys) {
            if (key === 'updatedLogs') continue
            const beforeValue = before[key]
            const afterValue = after[key]
            const currentPath = path ? `${path}.${key}` : key

            // Check if values are exactly the same
            if (beforeValue === afterValue) continue

            // Special handling for arrays
            if (Array.isArray(beforeValue) && Array.isArray(afterValue)) {
                // Check if both arrays have the same length and same JSON representation
                if (JSON.stringify(beforeValue) === JSON.stringify(afterValue)) {
                    continue
                }
            }

            // Special handling for empty objects
            if (
                typeof beforeValue === 'object' &&
                typeof afterValue === 'object' &&
                beforeValue !== null &&
                afterValue !== null
            ) {
                // Check if both are empty objects
                if (
                    !Array.isArray(beforeValue) &&
                    !Array.isArray(afterValue) &&
                    Object.keys(beforeValue).length === 0 &&
                    Object.keys(afterValue).length === 0
                ) {
                    continue
                }
            }

            if (beforeValue === undefined) {
                // Skip adding empty arrays or empty objects
                if (
                    (Array.isArray(afterValue) && afterValue.length === 0) ||
                    (typeof afterValue === 'object' &&
                        afterValue !== null &&
                        !Array.isArray(afterValue) &&
                        Object.keys(afterValue).length === 0)
                ) {
                    continue
                }
                changes.push(`Se añadió ${currentPath}: ${JSON.stringify(afterValue)}`)
            } else if (afterValue === undefined) {
                // Skip removing empty arrays or empty objects
                if (
                    (Array.isArray(beforeValue) && beforeValue.length === 0) ||
                    (typeof beforeValue === 'object' &&
                        beforeValue !== null &&
                        !Array.isArray(beforeValue) &&
                        Object.keys(beforeValue).length === 0)
                ) {
                    continue
                }
                changes.push(`Se eliminó ${currentPath}`)
            } else if (
                typeof beforeValue === 'object' &&
                typeof afterValue === 'object' &&
                beforeValue !== null &&
                afterValue !== null &&
                !Array.isArray(beforeValue) &&
                !Array.isArray(afterValue)
            ) {
                const nestedChanges = findChanges(beforeValue, afterValue, currentPath)
                if (nestedChanges.length > 0) {
                    changes.push(...nestedChanges)
                }
            } else {
                // Skip reporting changes where the values are effectively the same
                if (JSON.stringify(beforeValue) === JSON.stringify(afterValue)) {
                    continue
                }
                changes.push(
                    `Se actualizó ${currentPath} de ${JSON.stringify(beforeValue)} a ${JSON.stringify(afterValue)}`,
                )
            }
        }

        return changes
    }

    // CHART DATA PROCESSING: Transform raw data into the format needed for charts
    const processChartData = (data: MonthDataItem[], year?: number | 'ALL') => {
        // Filter by year if provided and not 'ALL'
        const filteredData = year && year !== 'ALL' ? data.filter((item) => item.year === year) : data

        // Group data by month, year, and currency
        const groupedByMonthAndCurrency = filteredData.reduce(
            (acc, item) => {
                // Include year in the key to separate data points from different years
                const key = `${item.month}_${item.year}_${item.currency}`
                if (!acc[key]) {
                    acc[key] = {
                        month: item.month,
                        monthYear: `${item.month}-${item.year}`, // Add combined month-year field for display
                        year: item.year,
                        currency: item.currency,
                        invoices: 0,
                        payments: 0,
                    }
                }

                if (item.type === 'invoices') {
                    acc[key].invoices += item.amount
                } else if (item.type === 'payments') {
                    // Divide payment amount by 100 since it's stored in cents
                    acc[key].payments += item.amount / 100
                }

                return acc
            },
            {} as Record<
                string,
                {
                    month: string
                    monthYear: string // Add combined month-year field
                    year: number
                    currency: string
                    invoices: number
                    payments: number
                }
            >,
        )

        // Convert to array and sort by year and month
        const result = Object.values(groupedByMonthAndCurrency).sort((a, b) => {
            // First sort by year
            if (a.year !== b.year) {
                return a.year - b.year
            }
            // Then sort by month if years are the same
            return parseInt(a.month) - parseInt(b.month)
        })

        return result
    }

    // CHART COMPONENT: Renders the chart visualization using the processed data
    const MinimalistTrendChart: React.FC<{ data: MonthDataItem[]; year: number | 'ALL' }> = ({ data, year }) => {
        if (!data || data.length === 0) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No hay datos suficientes para mostrar análisis"
                />
            )
        }

        // Get the currency from the data to use in formatting
        const currency = data.length > 0 ? data[0].currency.toUpperCase() : 'MXN'

        // Process data for the chart - don't filter if year is 'ALL'
        const filteredData = year !== 'ALL' ? data.filter((item) => item.year === year) : data
        const processedData = processChartData(filteredData, year)

        // Calculate average payment amount for reference line
        const avgPayment = processedData.reduce((sum, item) => sum + (item.payments || 0), 0) / processedData.length

        // Function to format month numbers to month names
        const formatMonth = (month: string, itemYear?: number) => {
            const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
            const monthIndex = parseInt(month) - 1
            return monthNames[monthIndex] || month
        }

        return (
            <Card
                bordered={false}
                style={{
                    borderRadius: 16,
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    marginBottom: 16,
                }}
            >
                <div style={{ height: 200, marginTop: 20 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={processedData} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
                            {/* Remove grid lines for minimalist look */}

                            {/* Hide axes for minimalist look */}
                            <XAxis
                                dataKey={year === 'ALL' ? 'monthYear' : 'month'}
                                axisLine={false}
                                tickLine={false}
                                tick={{ fill: '#aaa', fontSize: 12 }}
                                tickFormatter={(value) => {
                                    if (year === 'ALL') {
                                        // For 'ALL' years, show month-year format
                                        const [month, yearPart] = value.split('-')
                                        return `${formatMonth(month)} ${yearPart}`
                                    } else {
                                        // For specific year, just show month
                                        return formatMonth(value)
                                    }
                                }}
                            />
                            <YAxis hide={true} />

                            {/* Simple tooltip */}
                            <RechartsTooltip
                                labelFormatter={(label) => {
                                    if (year === 'ALL') {
                                        // For 'ALL' years, parse the month-year format
                                        const [month, yearPart] = label.split('-')
                                        return `${formatMonth(month)} ${yearPart}`
                                    } else {
                                        // For specific year, use the year parameter
                                        const monthName = formatMonth(label)
                                        return `${monthName} ${year}`
                                    }
                                }}
                                formatter={(value: number, name: string) => [
                                    returnCurrencyValue(value, currency),
                                    name === 'invoices' ? 'Facturas' : name === 'payments' ? 'Pagos' : name,
                                ]}
                                contentStyle={{
                                    backgroundColor: 'white',
                                    border: 'none',
                                    borderRadius: 8,
                                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                    padding: '8px 12px',
                                }}
                            />

                            {/* Reference (average) line */}
                            <Line
                                type="monotone"
                                dataKey={() => avgPayment}
                                stroke="#cccccc"
                                strokeWidth={2}
                                dot={{ r: 4, fill: '#cccccc', strokeWidth: 0 }}
                                activeDot={false}
                                name="Promedio"
                            />

                            {/* Actual data line */}
                            <Line
                                type="monotone"
                                dataKey="payments"
                                stroke="#416d60"
                                strokeWidth={2}
                                dot={{ r: 4, fill: '#416d60', strokeWidth: 0 }}
                                activeDot={{ r: 6, fill: '#416d60', strokeWidth: 0 }}
                                name="Pagos"
                            />
                            <Line
                                type="monotone"
                                dataKey="invoices"
                                stroke="#588b51"
                                strokeWidth={2}
                                dot={{ r: 4, fill: '#588b51', strokeWidth: 0 }}
                                activeDot={{ r: 6, fill: '#588b51', strokeWidth: 0 }}
                                name="Facturas"
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </Card>
        )
    }

    const InvoicesTab = () => {
        return (
            <div className="d-flex flex-column">
                <Row>
                    <Col xs={24} style={{ marginTop: '15px' }}>
                        <Row justify="space-between" align="middle" style={{ marginBottom: '10px' }}>
                            <Typography.Text className="smallheader">Facturas relacionadas</Typography.Text>
                            <Button
                                onClick={() => {
                                    dispatch(
                                        setData({
                                            item: 'client',
                                            data: client,
                                        }),
                                    )
                                    dispatch(openModal('invoiceModalVisible'))
                                }}
                            >
                                Crear factura
                            </Button>
                        </Row>
                        {invoicesRelated.length > 0 ? (
                            <Table
                                scroll={{
                                    x: true,
                                }}
                                columns={[
                                    {
                                        title: 'Factura',
                                        dataIndex: 'uuid',
                                        key: 'uuid',
                                        render: (text, invoice: any) => (
                                            <Tag
                                                className="clickable"
                                                icon={
                                                    <Link
                                                        weight="regular"
                                                        className="icon"
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                }
                                                style={{ border: 'none' }}
                                                onClick={() => {
                                                    window.open(`invoiceDetails?id=${invoice?.id}`, '_blank')
                                                }}
                                            >
                                                <Typography.Text className="smallparagraph">{text}</Typography.Text>
                                            </Tag>
                                        ),
                                        fixed: 'left',
                                    },
                                    {
                                        title: 'Total',
                                        dataIndex: 'total',
                                        key: 'total',
                                        render: (text, invoice: any) => (
                                            <Typography.Text className="smallparagraph">
                                                {returnCurrencyValue(text, invoice?.currency)}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Fecha de emisión',
                                        dataIndex: 'timestamp',
                                        key: 'timestamp',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">
                                                {moment(text).format('DD/MM/YY HH:mm')}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Estado',
                                        dataIndex: 'status',
                                        key: 'status',
                                        render: (text, invoice: any) =>
                                            invoice?.status === 'valid' ? <CheckCircle /> : <Prohibit />,
                                    },
                                    {
                                        title: 'Serie/Folio',
                                        dataIndex: 'series',
                                        key: 'series',
                                        render: (text, invoice: any) => (
                                            <Typography.Text className="smallparagraph">
                                                {invoice?.series}-{invoice?.folio_number}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Método de pago',
                                        dataIndex: 'payment_method',
                                        key: 'payment_method',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">{text}</Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Forma de pago',
                                        dataIndex: 'payment_form',
                                        key: 'payment_form',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">
                                                {paymentForms.find((pm) => pm.value === text)?.label}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Uso',
                                        dataIndex: 'use',
                                        key: 'use',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">{text}</Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Relación',
                                        dataIndex: 'relation',
                                        key: 'relation',
                                        render: (text, record) => (
                                            <ElementRelations record={record} elementType="invoices" />
                                        ),
                                    },
                                    {
                                        title: '',
                                        key: 'actions',
                                        render: (text, invoice: any) => <InvoiceActions invoice={invoice} />,
                                    },
                                ]}
                                dataSource={invoicesRelated}
                                pagination={false}
                                bordered={false}
                            />
                        ) : (
                            <div className="d-flex flex-column">
                                <Typography.Text className="smallparagraph">
                                    No hay facturas relacionadas
                                </Typography.Text>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        )
    }

    const PaymentsTab = () => {
        return (
            <div className="d-flex flex-column">
                <Row>
                    <Col xs={24}>
                        <Row justify="space-between" align="middle" style={{ marginBottom: '10px' }}>
                            <Typography.Text className="smallheader">Pagos relacionados</Typography.Text>
                            <Button
                                onClick={() => {
                                    dispatch(
                                        setData({
                                            item: 'client',
                                            data: client,
                                        }),
                                    )
                                    dispatch(openModal('paymentModalVisible'))
                                }}
                            >
                                Crear pago
                            </Button>
                        </Row>
                        {paymentsRelated.length > 0 ? (
                            <Table
                                columns={[
                                    {
                                        title: 'Pago',
                                        dataIndex: 'id',
                                        key: 'id',
                                        render: (text, payment: any) => (
                                            <Tag
                                                className="clickable"
                                                onClick={() => {
                                                    window.open(`paymentDetails?id=${payment?.id}`, '_blank')
                                                }}
                                                icon={
                                                    <CreditCard
                                                        weight="regular"
                                                        className="icon"
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                }
                                                style={{ border: 'none' }}
                                            >
                                                <Typography.Text className="smallparagraph">{text}</Typography.Text>
                                            </Tag>
                                        ),
                                    },
                                    {
                                        title: 'Total',
                                        dataIndex: 'amount',
                                        key: 'amount',
                                        render: (text, payment: any) => (
                                            <Typography.Text className="smallparagraph">
                                                {returnCurrencyValue(text / 100, payment?.currency)}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Fecha',
                                        dataIndex: 'timestamp',
                                        key: 'timestamp',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">
                                                {moment(text).format('DD/MM/YY HH:mm')}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Estado',
                                        dataIndex: 'status',
                                        key: 'status',
                                        render: (text, payment: any) => (
                                            <Typography.Text className="smallparagraph">
                                                {PaymentStatusTag({ record: payment, shape: 'circle' })}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Método de pago',
                                        dataIndex: 'payment_form',
                                        key: 'payment_form',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">
                                                {paymentForms.find((pm) => pm.value === text)?.label}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Relación',
                                        dataIndex: 'relation',
                                        key: 'relation',
                                        render: (text, record) => (
                                            <ElementRelations record={record} elementType="payments" />
                                        ),
                                    },
                                    {
                                        title: '',
                                        key: 'actions',
                                        render: (text, payment: any) => <PaymentsActions payment={payment} />,
                                    },
                                ]}
                                dataSource={paymentsRelated}
                                pagination={false}
                                bordered={false}
                            />
                        ) : (
                            <div className="d-flex flex-column">
                                <Typography.Text className="smallparagraph">No hay pagos relacionados</Typography.Text>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        )
    }

    const ReceiptsTab = () => {
        return (
            <div className="d-flex flex-column">
                <Row>
                    <Col xs={24}>
                        <Row justify="space-between" align="middle" style={{ marginBottom: '10px' }}>
                            <Typography.Text className="smallheader">Portales de auto-factura</Typography.Text>
                            <Button
                                onClick={() => {
                                    dispatch(
                                        setData({
                                            item: 'client',
                                            data: client,
                                        }),
                                    )
                                    dispatch(openModal('receiptModalVisible'))
                                }}
                            >
                                Crear portal de auto-factura
                            </Button>
                        </Row>
                        {receiptsRelated.length > 0 ? (
                            <Table
                                columns={[
                                    {
                                        title: 'Pago',
                                        dataIndex: 'id',
                                        key: 'id',
                                        render: (text, receipt: any) => (
                                            <Tag
                                                className="clickable"
                                                onClick={() => {
                                                    window.open(`receiptDetails?id=${receipt?.id}`, '_blank')
                                                }}
                                                icon={
                                                    receipt?.globalInvoiceTimestamp ? (
                                                        <Tooltip title="Factura global">
                                                            <Globe
                                                                weight="regular"
                                                                className="icon"
                                                                style={{ marginRight: '5px' }}
                                                            />
                                                        </Tooltip>
                                                    ) : (
                                                        <Note
                                                            weight="regular"
                                                            className="icon"
                                                            style={{ marginRight: '5px' }}
                                                        />
                                                    )
                                                }
                                                style={{ border: 'none' }}
                                            >
                                                <Typography.Text className="smallparagraph">{text}</Typography.Text>
                                            </Tag>
                                        ),
                                    },
                                    {
                                        title: 'Total',
                                        dataIndex: 'total',
                                        key: 'total',
                                        render: (text, receipt: any) => (
                                            <Typography.Text className="smallparagraph">
                                                {returnCurrencyValue(text, receipt?.currency)}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Fecha',
                                        dataIndex: 'timestamp',
                                        key: 'timestamp',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">
                                                {moment(text).format('DD/MM/YY HH:mm')}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Válido hasta',
                                        dataIndex: 'validUntil',
                                        key: 'validUntil',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">
                                                {moment(text).format('DD/MM/YY HH:mm')}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Estado',
                                        dataIndex: 'status',
                                        key: 'status',
                                        render: (text, payment: any) => (
                                            <Typography.Text className="smallparagraph">
                                                {ReceiptStatusTag({ record: payment, shape: 'circle' })}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Método de pago',
                                        dataIndex: 'payment_form',
                                        key: 'payment_form',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">
                                                {paymentForms.find((pm) => pm.value === text)?.label}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Desde',
                                        dataIndex: 'from',
                                        key: 'from',
                                        render: (text) => (
                                            <Tag>
                                                <Typography.Text className="smallparagraph">{text}</Typography.Text>
                                            </Tag>
                                        ),
                                    },
                                    {
                                        title: '',
                                        key: 'actions',
                                        render: (text, receipt: any) => (
                                            <ReceiptActions receipt={receipt} fromClientView={true} />
                                        ),
                                    },
                                ]}
                                dataSource={receiptsRelated}
                                pagination={false}
                                bordered={false}
                            />
                        ) : (
                            <div className="d-flex flex-column">
                                <Typography.Text className="smallparagraph">
                                    No hay portales de auto-factura relacionados
                                </Typography.Text>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        )
    }

    const LogsTab = () => {
        const columns = [
            {
                title: 'Date',
                dataIndex: 'timestamp',
                key: 'timestamp',
                width: 200,
                render: (timestamp: string) => (
                    <Typography.Text className="smallparagraph">
                        {moment(timestamp).format('DD/MM/YY HH:mm')}
                    </Typography.Text>
                ),
            },
            {
                title: 'Changes',
                key: 'changes',
                render: (_: any, record: any) => {
                    const changes = findChanges(record.before, record.after)
                    if (changes.length === 0) {
                        return null
                    }
                    return (
                        <List
                            bordered
                            dataSource={changes}
                            renderItem={(item) => (
                                <List.Item>
                                    <Typography.Text className="smallparagraph">{item}</Typography.Text>
                                </List.Item>
                            )}
                        />
                    )
                },
            },
        ]

        // Filter out logs with no changes
        const filteredLogs = logs.filter((log) => {
            const changes = findChanges(log.before, log.after)
            return changes.length > 0
        })

        return (
            <div className="d-flex flex-column">
                <Typography.Text className="smallheader">Cambios en {t('cliente')}</Typography.Text>
                <Table
                    columns={columns}
                    dataSource={filteredLogs}
                    pagination={{
                        pageSize: 3,
                        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
                        showSizeChanger: false,
                        showQuickJumper: false,
                    }}
                    bordered={false}
                    rowKey={(record, index) => index?.toString() || '0'}
                />
            </div>
        )
    }
    const EventsTab = () => {
        return (
            <div className="d-flex flex-column">
                <Typography.Text className="smallheader">Eventos</Typography.Text>
                <Divider />
                <Row>
                    <Col xs={24}>
                        <LogsRelatedToElement id={id} v={2} />
                    </Col>
                </Row>
            </div>
        )
    }

    const AnalisisComponent = () => {
        return (
            <div className="d-flex flex-column">
                <Typography.Text className="smallparagraphbold" style={{ marginBottom: '10px' }}>
                    Análisis
                </Typography.Text>

                {/* Show loading state if data is not yet loaded */}
                {!monthData.loaded ? (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <Spin tip="Cargando datos..." />
                    </div>
                ) : monthData.data.length > 0 ? (
                    <>
                        {/* DATA PRESENTATION: Filter and organize data for display based on user selections */}
                        {(() => {
                            // Get available years from the data
                            const availableYears = Array.from(new Set(monthData.data.map((item) => item.year))).sort(
                                (a, b) => b - a,
                            ) // Sort descending

                            // Extract available currencies from data
                            const availableCurrencies = Array.from(
                                new Set(monthData.data.map((item) => item.currency.toUpperCase())),
                            )

                            // Year selector options with "All years" option
                            const yearOptions = [
                                { label: 'Todos los años', value: 'ALL' as const },
                                ...availableYears.map((year) => ({
                                    label: `${year}`,
                                    value: year,
                                })),
                            ]

                            // Filter data by selected year if not 'ALL'
                            const yearFilteredData =
                                selectedYear === 'ALL'
                                    ? monthData.data
                                    : monthData.data.filter((item) => item.year === selectedYear)

                            // Group data by currency
                            const currencyGroups = yearFilteredData.reduce(
                                (acc, item) => {
                                    const currency = item.currency.toUpperCase()
                                    if (!acc[currency]) {
                                        acc[currency] = {
                                            payments: 0,
                                            invoices: 0,
                                            data: [],
                                        }
                                    }

                                    // Add to the appropriate accumulator based on type
                                    if (item.type === 'payments') {
                                        acc[currency].payments += item.amount / 100
                                    } else if (item.type === 'invoices') {
                                        acc[currency].invoices += item.amount
                                    }

                                    // Add data for the chart
                                    acc[currency].data.push(item)

                                    return acc
                                },
                                {} as Record<string, { payments: number; invoices: number; data: MonthDataItem[] }>,
                            )

                            // Create options for Segmented component
                            const currencyOptions = [
                                { label: 'Todas', value: 'ALL' },
                                ...availableCurrencies.map((currency) => ({
                                    label: currency,
                                    value: currency,
                                })),
                            ]

                            return (
                                <>
                                    <div style={{ display: 'flex', gap: '10px', marginBottom: '15px' }}>
                                        <div>
                                            <Typography.Text className="smallparagraph" style={{ marginRight: '8px' }}>
                                                Año:
                                            </Typography.Text>
                                            <Segmented
                                                value={selectedYear}
                                                onChange={(value) =>
                                                    setSelectedYear(value === 'ALL' ? 'ALL' : Number(value))
                                                }
                                                options={yearOptions}
                                                size="small"
                                            />
                                        </div>
                                        <div>
                                            <Typography.Text className="smallparagraph" style={{ marginRight: '8px' }}>
                                                Moneda:
                                            </Typography.Text>
                                            <Segmented
                                                value={selectedCurrency}
                                                onChange={(value) => setSelectedCurrency(value.toString())}
                                                options={currencyOptions}
                                                size="small"
                                            />
                                        </div>
                                    </div>

                                    {/* Show all currencies or only the selected one */}
                                    <Row gutter={[16, 16]}>
                                        {Object.entries(currencyGroups)
                                            .filter(
                                                ([currency]) =>
                                                    selectedCurrency === 'ALL' || currency === selectedCurrency,
                                            )
                                            .map(([currency, data], index, array) => {
                                                // Determine if we should show full width (only one currency)
                                                const showFullWidth = array.length === 1

                                                return (
                                                    <Col
                                                        key={currency}
                                                        xs={24}
                                                        sm={24}
                                                        md={showFullWidth ? 24 : 12}
                                                        lg={showFullWidth ? 24 : 12}
                                                        xl={showFullWidth ? 24 : 12}
                                                    >
                                                        <div style={{ marginBottom: '20px' }}>
                                                            {selectedCurrency === 'ALL' && (
                                                                <Typography.Text
                                                                    className="smallparagraphbold"
                                                                    style={{ marginBottom: '5px' }}
                                                                >
                                                                    {currency}
                                                                </Typography.Text>
                                                            )}
                                                            <Row
                                                                justify="space-evenly"
                                                                align="middle"
                                                                style={{ marginBottom: '10px' }}
                                                            >
                                                                <Statistic
                                                                    title="Pagos completos"
                                                                    value={data.payments}
                                                                    precision={2}
                                                                    formatter={(value) =>
                                                                        `${returnCurrencyValue(value as number, currency)}`
                                                                    }
                                                                />
                                                                <Statistic
                                                                    title="Facturado"
                                                                    value={data.invoices}
                                                                    precision={2}
                                                                    formatter={(value) =>
                                                                        `${returnCurrencyValue(value as number, currency)}`
                                                                    }
                                                                />
                                                            </Row>
                                                            <MinimalistTrendChart
                                                                data={data.data}
                                                                year={selectedYear}
                                                            />
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                    </Row>
                                </>
                            )
                        })()}
                    </>
                ) : (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No hay datos suficientes para mostrar análisis"
                    />
                )}
            </div>
        )
    }

    const DetailsTab = () => {
        // Calculate summary statistics
        const pendingInvoices = invoicesRelated.filter((i) => i.payment_method === 'PPD' && i.lastBalance > 0).length
        const pendingReceipts = receiptsRelated.filter((r) => r.status === 'pending').length
        const actionRequiredPayments = paymentsRelated.filter((p) => p.status === 'requires_action').length

        // Calculate total invoiced and paid amounts by currency - exclude payment complements
        const financialSummary = Object.entries(
            invoicesRelated
                .filter((invoice) => invoice.type === 'I') // Exclude invoices with payment_method (payment complements)
                .reduce(
                    (acc, invoice) => {
                        const currency = invoice.currency || 'MXN'
                        if (!acc[currency]) {
                            acc[currency] = { invoiced: 0, pending: 0 }
                        }
                        acc[currency].invoiced += invoice.total || 0
                        acc[currency].pending += invoice.lastBalance || 0
                        return acc
                    },
                    {} as Record<string, { invoiced: number; pending: number }>,
                ),
        ) as [string, { invoiced: number; pending: number }][]

        // Color palette from the provided design
        const colors = {
            green: {
                light: '#e8f9ef',
                medium: '#9bdbbd',
                dark: '#429c76',
            },
            purple: {
                light: '#f0ecff',
                medium: '#c5b6ff',
                dark: '#7c6fd3',
            },
            orange: {
                light: '#fff2e9',
                medium: '#ffcca7',
                dark: '#da8b4f',
            },
            pink: {
                light: '#ffeaf3',
                medium: '#ffb8d9',
                dark: '#d25c94',
            },
            neutral: {
                light: '#f7f7f8',
                medium: '#e6e6e9',
                dark: '#333338',
            },
        }

        return (
            <Row gutter={[0, 24]}>
                {/* Summary Dashboard */}
                <Col xs={24}>
                    <Card
                        className="shadow-sm"
                        title={
                            <Typography.Text className="smallheader" style={{ margin: 0, color: colors.neutral.dark }}>
                                Resumen del {t('Cliente')}
                            </Typography.Text>
                        }
                    >
                        <Row gutter={[16, 16]}>
                            {/* Financial summary by currency */}
                            {financialSummary.map(([currency, amounts], index) => (
                                <Col xs={24} sm={12} md={8} key={currency}>
                                    <Card
                                        bordered={false}
                                        className="inner-card"
                                        style={{
                                            background: colors.green.light,
                                            borderLeft: `3px solid ${colors.green.dark}`,
                                        }}
                                    >
                                        <Statistic
                                            title={
                                                <span
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                        color: colors.neutral.dark,
                                                    }}
                                                >
                                                    Total Facturado ({currency})
                                                </span>
                                            }
                                            value={amounts.invoiced}
                                            precision={2}
                                            valueStyle={{ color: colors.green.dark }}
                                            formatter={(value) => returnCurrencyValue(value as number, currency)}
                                        />
                                        {amounts.pending > 0 && (
                                            <div style={{ marginTop: '8px' }}>
                                                <Statistic
                                                    title={
                                                        <span
                                                            style={{
                                                                fontSize: '12px',
                                                                fontWeight: 400,
                                                                color: colors.neutral.dark,
                                                            }}
                                                        >
                                                            Pendiente de pago
                                                        </span>
                                                    }
                                                    value={amounts.pending}
                                                    precision={2}
                                                    valueStyle={{ fontSize: '14px', color: colors.orange.dark }}
                                                    formatter={(value) =>
                                                        returnCurrencyValue(value as number, currency)
                                                    }
                                                />
                                            </div>
                                        )}
                                    </Card>
                                </Col>
                            ))}

                            {/* Status counters */}
                            <Col xs={24} sm={12} md={8}>
                                <Card
                                    bordered={false}
                                    className="inner-card"
                                    style={{
                                        background: pendingInvoices > 0 ? '#FFF6F0' : colors.neutral.light,
                                        borderLeft: pendingInvoices > 0 ? `3px solid #E87E51` : 'none',
                                    }}
                                >
                                    <Statistic
                                        title={
                                            <span
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    color: colors.neutral.dark,
                                                }}
                                            >
                                                Facturas PPD Pendientes
                                            </span>
                                        }
                                        value={pendingInvoices}
                                        valueStyle={{
                                            fontSize: '28px',
                                            color: pendingInvoices > 0 ? '#E87E51' : colors.neutral.dark,
                                        }}
                                    />
                                    {pendingInvoices > 0 && (
                                        <div style={{ marginTop: '10px' }}>
                                            <Button
                                                type="link"
                                                size="small"
                                                style={{ padding: 0, color: '#044737' }}
                                                onClick={() =>
                                                    document
                                                        .getElementById('pendingInvoicesSection')
                                                        ?.scrollIntoView({ behavior: 'smooth' })
                                                }
                                            >
                                                Ver detalles
                                            </Button>
                                        </div>
                                    )}
                                </Card>
                            </Col>

                            <Col xs={24} sm={12} md={8}>
                                <Card
                                    bordered={false}
                                    className="inner-card"
                                    style={{
                                        background: pendingReceipts > 0 ? '#FFFBEB' : colors.neutral.light,
                                        borderLeft: pendingReceipts > 0 ? `3px solid #F5C155` : 'none',
                                    }}
                                >
                                    <Statistic
                                        title={
                                            <span
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    color: colors.neutral.dark,
                                                }}
                                            >
                                                Portales de Auto-factura
                                            </span>
                                        }
                                        value={pendingReceipts}
                                        valueStyle={{
                                            fontSize: '28px',
                                            color: pendingReceipts > 0 ? '#F5C155' : colors.neutral.dark,
                                        }}
                                    />
                                    {pendingReceipts > 0 && (
                                        <div style={{ marginTop: '10px' }}>
                                            <Button
                                                type="link"
                                                size="small"
                                                style={{ padding: 0, color: '#044737' }}
                                                onClick={() =>
                                                    document
                                                        .getElementById('pendingReceiptsSection')
                                                        ?.scrollIntoView({ behavior: 'smooth' })
                                                }
                                            >
                                                Ver detalles
                                            </Button>
                                        </div>
                                    )}
                                </Card>
                            </Col>

                            <Col xs={24} sm={12} md={8}>
                                <Card
                                    bordered={false}
                                    className="inner-card"
                                    style={{
                                        background: actionRequiredPayments > 0 ? '#FFF1F5' : colors.neutral.light,
                                        borderLeft: actionRequiredPayments > 0 ? `3px solid #DF5D92` : 'none',
                                    }}
                                >
                                    <Statistic
                                        title={
                                            <span
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    color: colors.neutral.dark,
                                                }}
                                            >
                                                Pagos con Acción Pendiente
                                            </span>
                                        }
                                        value={actionRequiredPayments}
                                        valueStyle={{
                                            fontSize: '28px',
                                            color: actionRequiredPayments > 0 ? '#DF5D92' : colors.neutral.dark,
                                        }}
                                    />
                                    {actionRequiredPayments > 0 && (
                                        <div style={{ marginTop: '10px' }}>
                                            <Button
                                                type="link"
                                                size="small"
                                                style={{ padding: 0, color: '#044737' }}
                                                onClick={() =>
                                                    document
                                                        .getElementById('pendingPaymentsSection')
                                                        ?.scrollIntoView({ behavior: 'smooth' })
                                                }
                                            >
                                                Ver detalles
                                            </Button>
                                        </div>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col xs={24}>
                    {possibleChangeOfFiscalInfo().changed && (
                        <Alert
                            message={
                                <Typography.Text className="smallheader">
                                    Cambio en información fiscal detectado
                                </Typography.Text>
                            }
                            description={
                                <p className="smallparagraph mb-0">
                                    Se generó el portal de auto-factura
                                    <span className="smallparagraphbold" style={{ marginLeft: '5px' }}>
                                        {possibleChangeOfFiscalInfo().lastReceipt.id}
                                    </span>
                                    , debido a :
                                    <span
                                        className="smallparagraphbold text-danger"
                                        style={{ marginLeft: '5px', marginRight: '5px' }}
                                    >
                                        {possibleChangeOfFiscalInfo().lastReceipt.invoiceErrorMessage ??
                                            'un posible cambio en la información fiscal'}
                                    </span>
                                    <br />
                                    Puedes compartir el enlace de auto-factura para corregir la información{' '}
                                    <a
                                        href={possibleChangeOfFiscalInfo().lastReceipt.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {possibleChangeOfFiscalInfo().lastReceipt.url}
                                    </a>
                                </p>
                            }
                            type="warning"
                            showIcon
                            style={{ marginBottom: '20px' }}
                            action={
                                <Button
                                    size="small"
                                    href={possibleChangeOfFiscalInfo().lastReceipt.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Abrir Portal
                                </Button>
                            }
                        />
                    )}

                    {invoicesRelated.filter((i) => i.payment_method === 'PPD' && i.lastBalance > 0).length > 0 && (
                        <Card
                            className="shadow-sm"
                            style={{ marginBottom: '24px' }}
                            id="pendingInvoicesSection"
                            title={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <FileText style={{ marginRight: '8px' }} />
                                    <Typography.Text className="smallheader" style={{ margin: 0 }}>
                                        Facturas pendientes de complemento de pago
                                    </Typography.Text>
                                </div>
                            }
                        >
                            <Row align="middle" style={{ marginBottom: '16px' }}>
                                <Question />
                                <Typography.Text className="smallparagraph" style={{ marginLeft: '8px', flex: 1 }}>
                                    Las facturas en PPD requieren de emitir complementos de pago al momento de la
                                    recepción de los ingresos, estas facturas se encuentran con saldo pendiente de
                                    confirmar.
                                </Typography.Text>
                            </Row>
                            <Table
                                dataSource={invoicesRelated.filter(
                                    (i) => i.payment_method === 'PPD' && i.lastBalance > 0,
                                )}
                                columns={[
                                    {
                                        title: 'Factura',
                                        dataIndex: 'uuid',
                                        key: 'uuid',
                                        render: (text, invoice: any) => (
                                            <div className="d-flex flex-column">
                                                <div>
                                                    <Tag
                                                        className="clickable"
                                                        icon={
                                                            <Link
                                                                weight="regular"
                                                                className="icon"
                                                                style={{ marginRight: '5px' }}
                                                            />
                                                        }
                                                        style={{ border: 'none' }}
                                                        onClick={() => {
                                                            window.open(`invoiceDetails?id=${invoice?.id}`, '_blank')
                                                        }}
                                                    >
                                                        <Typography.Text className="smallparagraphbold">
                                                            {text}
                                                        </Typography.Text>
                                                    </Tag>
                                                </div>
                                            </div>
                                        ),
                                        fixed: 'left',
                                    },
                                    {
                                        title: 'Factura pagada',
                                        dataIndex: 'lastBalance',
                                        key: 'lastBalance',
                                        render: (text, invoice: any) => (
                                            <Tooltip
                                                title={`La factura tiene pagos pendientes por ${returnCurrencyValue(invoice.lastBalance, invoice.currency)} de ${returnCurrencyValue(invoice.total, invoice.currency)}`}
                                            >
                                                <div>
                                                    <Progress
                                                        percent={100 - (invoice.lastBalance / invoice.total) * 100}
                                                        size={{ width: 120 }}
                                                        strokeColor="#52c41a"
                                                        trailColor="#f0f0f0"
                                                        format={(percent) => `${Math.floor(percent || 0)}%`}
                                                    />
                                                    <Typography.Text
                                                        type="secondary"
                                                        style={{ fontSize: '12px', display: 'block', marginTop: '4px' }}
                                                    >
                                                        {returnCurrencyValue(
                                                            invoice.total - invoice.lastBalance,
                                                            invoice.currency,
                                                        )}{' '}
                                                        de {returnCurrencyValue(invoice.total, invoice.currency)}
                                                    </Typography.Text>
                                                </div>
                                            </Tooltip>
                                        ),
                                    },
                                    {
                                        title: 'Serie/Folio',
                                        dataIndex: 'series',
                                        key: 'series',
                                        render: (text, invoice: any) => (
                                            <Typography.Text className="smallparagraph">
                                                {invoice?.series}-{invoice?.folio_number}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: '',
                                        key: 'actions',
                                        render: (text, invoice: any) => <InvoiceActions invoice={invoice} />,
                                    },
                                ]}
                                pagination={false}
                                bordered={false}
                                className="custom-table"
                                size="middle"
                            />
                        </Card>
                    )}

                    {receiptsRelated.filter((r) => r.status === 'pending').length > 0 && (
                        <Card
                            className="shadow-sm"
                            style={{ marginBottom: '24px' }}
                            id="pendingReceiptsSection"
                            title={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Receipt style={{ marginRight: '8px' }} />
                                    <Typography.Text className="smallheader" style={{ margin: 0 }}>
                                        Portales de auto-factura pendientes
                                    </Typography.Text>
                                </div>
                            }
                        >
                            <Row align="middle" style={{ marginBottom: '16px' }}>
                                <Question />
                                <Typography.Text className="smallparagraph" style={{ marginLeft: '8px', flex: 1 }}>
                                    No se contaba la información fiscal, o tuvo algún cambio reciente y se generó el
                                    portal de auto-factura de las siguientes transacciones, recuerda que el{' '}
                                    {t('cliente')}
                                    tendrá fecha límite antes de emitir al publico en general.
                                </Typography.Text>
                            </Row>
                            <Table
                                dataSource={receiptsRelated.filter((r) => r.status === 'pending')}
                                columns={[
                                    {
                                        title: 'ID',
                                        dataIndex: 'id',
                                        key: 'id',
                                        render: (id: string, record: any) => {
                                            return (
                                                <div className="d-flex flex-column">
                                                    <Typography.Text className="smallparagraphbold">
                                                        {id}
                                                    </Typography.Text>
                                                    <Typography.Text type="secondary">
                                                        {returnCurrencyValue(record.total, record.currency)}
                                                    </Typography.Text>
                                                </div>
                                            )
                                        },
                                    },
                                    {
                                        title: 'Fecha',
                                        dataIndex: 'timestamp',
                                        key: 'timestamp',
                                        render: (timestamp: number) => {
                                            return (
                                                <Typography.Text>
                                                    {moment(timestamp).format('DD/MM/YY HH:mm')}
                                                </Typography.Text>
                                            )
                                        },
                                    },
                                    {
                                        title: 'Valido hasta',
                                        dataIndex: 'validUntil',
                                        key: 'validUntil',
                                        render: (validUntil: number) => {
                                            const isExpiringSoon = moment(validUntil).diff(moment(), 'days') < 3
                                            return (
                                                <Typography.Text
                                                    style={{ color: isExpiringSoon ? '#ff4d4f' : undefined }}
                                                >
                                                    {moment(validUntil).format('DD/MM/YY HH:mm')}
                                                    {isExpiringSoon && ' (Expira pronto)'}
                                                </Typography.Text>
                                            )
                                        },
                                    },
                                    {
                                        title: '',
                                        key: 'actions',
                                        render: (record: any) => {
                                            return <ReceiptActions receipt={record} fromClientView={true} />
                                        },
                                    },
                                ]}
                                pagination={false}
                                bordered={false}
                                className="custom-table"
                                size="middle"
                            />
                        </Card>
                    )}

                    {paymentsRelated.filter((p) => p.status === 'requires_action').length > 0 && (
                        <Card
                            className="shadow-sm"
                            style={{ marginBottom: '24px' }}
                            id="pendingPaymentsSection"
                            title={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CreditCard style={{ marginRight: '8px' }} />
                                    <Typography.Text className="smallheader" style={{ margin: 0 }}>
                                        Pagos que necesitan acción del {t('cliente')}
                                    </Typography.Text>
                                </div>
                            }
                        >
                            <Row align="middle" style={{ marginBottom: '16px' }}>
                                <Question />
                                <Typography.Text className="smallparagraph" style={{ marginLeft: '8px', flex: 1 }}>
                                    Estos pagos requieren que el {t('cliente')} realice una acción adicional, como
                                    confirmar el pago o proporcionar más información, generalmente es ocasionado cuando
                                    el {t('cliente')}
                                    selecciona métodos de pago como OXXO, o transferencia interbancaria.
                                </Typography.Text>
                            </Row>
                            <Table
                                dataSource={paymentsRelated.filter((p) => p.status === 'requires_action')}
                                columns={[
                                    {
                                        title: 'ID',
                                        dataIndex: 'id',
                                        key: 'id',
                                        render: (id: string, record: any) => {
                                            return (
                                                <div className="d-flex flex-column">
                                                    <Typography.Text className="smallparagraphbold">
                                                        {id}
                                                    </Typography.Text>
                                                    <Typography.Text type="secondary">
                                                        {returnCurrencyValue(record.amount / 100, record.currency)}
                                                    </Typography.Text>
                                                </div>
                                            )
                                        },
                                    },
                                    {
                                        title: 'Estado',
                                        dataIndex: 'status',
                                        key: 'status',
                                        render: (status: string, record: any) => {
                                            return <PaymentStatusTag shape="circle" record={record} />
                                        },
                                    },
                                    {
                                        title: 'Iniciado',
                                        dataIndex: 'timestamp',
                                        key: 'timestamp',
                                        render: (timestamp: number) => {
                                            return (
                                                <Typography.Text>
                                                    {moment(timestamp).format('DD/MM/YY HH:mm')}
                                                </Typography.Text>
                                            )
                                        },
                                    },
                                    {
                                        title: 'Requiere acción desde',
                                        dataIndex: 'requires_actionTimestamp',
                                        key: 'requires_actionTimestamp',
                                        render: (requires_actionTimestamp: number) => {
                                            const daysSince = moment().diff(moment(requires_actionTimestamp), 'days')
                                            return (
                                                <div>
                                                    <Typography.Text>
                                                        {moment(requires_actionTimestamp).format('DD/MM/YY HH:mm')}
                                                    </Typography.Text>
                                                    {daysSince > 3 && (
                                                        <Tag color="red" style={{ marginLeft: '8px' }}>
                                                            {daysSince} días
                                                        </Tag>
                                                    )}
                                                </div>
                                            )
                                        },
                                    },
                                    {
                                        title: '',
                                        key: 'actions',
                                        render: (record: any) => {
                                            return <PaymentsActions payment={record} />
                                        },
                                    },
                                ]}
                                pagination={false}
                                bordered={false}
                                className="custom-table"
                                size="middle"
                            />
                        </Card>
                    )}

                    <Card
                        className="shadow-sm"
                        title={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Path style={{ marginRight: '8px' }} />
                                <Typography.Text className="smallheader" style={{ margin: 0 }}>
                                    Análisis financiero
                                </Typography.Text>
                            </div>
                        }
                    >
                        <AnalisisComponent />
                    </Card>
                </Col>
            </Row>
        )
    }

    const RecurringEventsTab: React.FC = () => {
        return (
            <Tabs
                items={[
                    {
                        key: 'payments',
                        label: 'Pagos recurrentes',
                        children: (
                            <div className="d-flex flex-column">
                                {/* <Typography.Text className="smallparagraphbold"> Pagos recurrentes</Typography.Text> */}
                                <Row justify="end" style={{ marginBottom: '16px' }}>
                                    <Button
                                        size="middle"
                                        type="primary"
                                        onClick={() => {
                                            dispatch(
                                                setData({
                                                    item: 'payment',
                                                    data: {
                                                        from: 'recurringEvent',
                                                        action: 'create',
                                                    },
                                                }),
                                            )
                                            dispatch(
                                                setData({
                                                    item: 'recurringEventData',
                                                    data: {},
                                                }),
                                            )
                                            dispatch(setData({ item: 'client', data: data }))
                                            dispatch(
                                                setData({
                                                    item: 'selectedServices',
                                                    data: [],
                                                }),
                                            )
                                            dispatch(openModal('paymentModalVisible'))
                                        }}
                                        icon={<PlusCircle className="" size={18} weight="regular" />}
                                        disabled={!canCreatePayment}
                                    >
                                        Nuevo pago recurrente
                                    </Button>
                                </Row>
                                <Table
                                    dataSource={recurringPayments}
                                    columns={[
                                        {
                                            title: 'ID',
                                            dataIndex: 'id',
                                            key: 'id',
                                            render: (id: string, record: any) => (
                                                <div className="d-flex flex-column">
                                                    <Typography.Text
                                                        className="smallparagraphbold"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        {record.temporality === 'daily'
                                                            ? `Diario a las ${record.onTime}`
                                                            : record.temporality === 'weekly'
                                                              ? `Semanal, los ${moment()
                                                                    .day(record.onWeekday ?? '01')
                                                                    .format('dddd')} ${record.onTime}`
                                                              : `Mensual los días ${record.onDay} ${record.onTime}`}{' '}
                                                    </Typography.Text>
                                                    <Typography.Text className="smallparagraph">
                                                        Finaliza el {moment(record.endDate).format('DD/MM/YY HH:mm')}
                                                    </Typography.Text>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Estado',
                                            dataIndex: 'status',
                                            key: 'status',

                                            minWidth: 100,
                                            render: (status: string, record: any) => (
                                                <RecurringPaymentsColumns columnName="statusSwitch" record={record} />
                                            ),
                                        },
                                        // {
                                        //     title: 'Último pago',
                                        //     dataIndex: 'lastPayment',
                                        //     key: 'lastPayment',
                                        //     render: (lastPayment: string, record: any) => (
                                        //         <Typography.Text className="smallparagraph">
                                        //             {moment(lastPayment).format('DD/MM/YY HH:mm')}
                                        //         </Typography.Text>
                                        //     ),
                                        // },
                                        {
                                            title: 'Próximo pago',
                                            dataIndex: 'nextRun',
                                            key: 'nextRun',
                                            minWidth: 100,
                                            render: (next: string, record: any) => (
                                                <RecurringPaymentsColumns columnName="nextRun" record={record} />
                                            ),
                                        },
                                        {
                                            title: 'Creados',
                                            dataIndex: 'related',
                                            key: 'related',
                                            render: (amount: number, record: any) => (
                                                <RecurringPaymentsColumns columnName="relations" record={record} />
                                            ),
                                        },
                                        {
                                            title: 'Acciones',
                                            key: 'actions',
                                            render: (record: any) => (
                                                <RecurringPaymentsColumns columnName="Acciones" record={record} />
                                            ),
                                        },
                                    ]}
                                    pagination={false}
                                    bordered={false}
                                    className="custom-table"
                                />
                            </div>
                        ),
                    },
                    {
                        key: 'invoices',
                        label: 'Facturas',
                        children: (
                            <div className="d-flex flex-column">
                                {/* <Typography.Text className="smallparagraphbold"> Facturas recurrentes</Typography.Text> */}
                                <Row justify="end" style={{ marginBottom: '16px' }}>
                                    <Button
                                        size="middle"
                                        type="primary"
                                        icon={<PlusCircle className="" size={18} weight="regular" />}
                                        disabled={!canCreateInvoice}
                                        onClick={() => {
                                            dispatch(setData({ item: 'recurringEventData', data: {} }))
                                            dispatch(setData({ item: 'client', data: data }))
                                            dispatch(setData({ item: 'selectedServices', data: [] }))
                                            dispatch(
                                                setData({
                                                    item: 'invoice',
                                                    data: {
                                                        from: 'recurringEvent',
                                                        id: null,
                                                    },
                                                }),
                                            )
                                            dispatch(
                                                setElementSelected({
                                                    element: 'recurringInvoice',
                                                    data: true,
                                                }),
                                            )
                                            dispatch(openModal('invoiceModalVisible'))
                                        }}
                                    >
                                        Nueva factura recurrente
                                    </Button>
                                </Row>
                                <Table
                                    dataSource={recurringInvoices}
                                    columns={[
                                        {
                                            title: 'ID',
                                            dataIndex: 'id',
                                            key: 'id',
                                            render: (id: string, record: any) => (
                                                <div className="d-flex flex-column">
                                                    <Typography.Text
                                                        className="smallparagraphbold"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        {record.temporality === 'daily'
                                                            ? `Diario a las ${record.onTime}`
                                                            : record.temporality === 'weekly'
                                                              ? `Semanal, los ${moment()
                                                                    .day(record.onWeekday ?? '01')
                                                                    .format('dddd')} ${record.onTime}`
                                                              : `Mensual los días ${record.onDay} ${record.onTime}`}{' '}
                                                    </Typography.Text>
                                                    <Typography.Text className="smallparagraph">
                                                        Finaliza el {moment(record.endDate).format('DD/MM/YY HH:mm')}
                                                    </Typography.Text>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Estado',
                                            dataIndex: 'status',
                                            key: 'status',

                                            minWidth: 100,
                                            render: (status: string, record: any) => (
                                                <RecurringPaymentsColumns columnName="statusSwitch" record={record} />
                                            ),
                                        },
                                        // {
                                        //     title: 'Último pago',
                                        //     dataIndex: 'lastPayment',
                                        //     key: 'lastPayment',
                                        //     render: (lastPayment: string, record: any) => (
                                        //         <Typography.Text className="smallparagraph">
                                        //             {moment(lastPayment).format('DD/MM/YY HH:mm')}
                                        //         </Typography.Text>
                                        //     ),
                                        // },
                                        {
                                            title: 'Próxima factura',
                                            dataIndex: 'nextRun',
                                            key: 'nextRun',
                                            minWidth: 100,
                                            render: (next: string, record: any) => (
                                                <RecurringPaymentsColumns columnName="nextRun" record={record} />
                                            ),
                                        },
                                        {
                                            title: 'Creadas',
                                            dataIndex: 'related',
                                            key: 'related',
                                            render: (amount: number, record: any) => (
                                                <RecurringPaymentsColumns columnName="relations" record={record} />
                                            ),
                                        },
                                        {
                                            title: 'Acciones',
                                            key: 'actions',
                                            render: (record: any) => (
                                                <RecurringPaymentsColumns columnName="Acciones" record={record} />
                                            ),
                                        },
                                    ]}
                                    pagination={false}
                                    bordered={false}
                                    className="custom-table"
                                />
                            </div>
                        ),
                    },
                ]}
            />
        )
    }

    // Email Tags Input Component
    const EmailTagsInput: React.FC<{
        emails: string[]
        clientRef: any
        user: any
    }> = ({ emails, clientRef, user }) => {
        const [tags, setTags] = useState<string[]>(emails)
        const [inputVisible, setInputVisible] = useState(false)
        const [inputValue, setInputValue] = useState('')
        const inputRef = useRef<InputRef>(null)

        useEffect(() => {
            if (inputVisible) {
                inputRef.current?.focus()
            }
        }, [inputVisible])

        // Email validation function
        const isValidEmail = (email: string): boolean => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return emailRegex.test(email)
        }

        const handleClose = (removedTag: string) => {
            const newTags = tags.filter((tag) => tag !== removedTag)
            setTags(newTags)

            // Update the document in Firebase
            updateDoc(clientRef, {
                bcc: newTags,
                lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (removed email: ${removedTag}) from email tag editor.`,
            })
                .then(() => {
                    message.success(`Email ${removedTag} eliminado correctamente`)
                })
                .catch((error) => {
                    message.error(`Error al eliminar el email: ${error.message}`)
                })
        }

        const showInput = () => {
            setInputVisible(true)
        }

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value)
        }

        const handleInputConfirm = () => {
            if (inputValue && isValidEmail(inputValue) && !tags.includes(inputValue)) {
                const newTags = [...tags, inputValue]
                setTags(newTags)

                // Update the document in Firebase
                updateDoc(clientRef, {
                    bcc: newTags,
                    lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (added email: ${inputValue}) from email tag editor.`,
                })
                    .then(() => {
                        message.success(`Email ${inputValue} agregado correctamente`)
                    })
                    .catch((error) => {
                        message.error(`Error al agregar el email: ${error.message}`)
                    })
            } else if (inputValue && !isValidEmail(inputValue)) {
                message.error('Por favor ingresa un correo electrónico válido')
            } else if (inputValue && tags.includes(inputValue)) {
                message.warning('Este correo ya existe en la lista')
            }
            setInputVisible(false)
            setInputValue('')
        }

        return (
            <div className="email-tags-container">
                <div style={{ marginBottom: '8px' }}>
                    {tags.map((tag) => (
                        <Tag key={tag} closable style={{ marginBottom: '4px' }} onClose={() => handleClose(tag)}>
                            {tag}
                        </Tag>
                    ))}
                </div>
                {inputVisible ? (
                    <Input
                        ref={inputRef}
                        type="text"
                        size="small"
                        style={{ width: 200 }}
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                        placeholder="correo@ejemplo.com"
                    />
                ) : (
                    <Tag onClick={showInput} style={{ borderStyle: 'dashed', cursor: 'pointer' }}>
                        <Plus size={14} weight="bold" /> Agregar correo
                    </Tag>
                )}
            </div>
        )
    }

    return (
        <Row>
            <Col
                xs={24}
                md={8}
                className="d-flex flex-column"
                style={{
                    backgroundColor: '#FDFDFD',
                    padding: '20px 0px 40px 0px',
                    borderRadius: '0px',
                    borderRight: '1px solid #E0E0E0',
                    minHeight: '80vh',
                }}
            >
                <Row className="" justify="space-between" style={{ marginBottom: '10px' }}>
                    <Button type="text" onClick={back}>
                        <ArrowLeft size={16} />
                        <Typography.Text className="smallparagraphbold" style={{ marginLeft: '4px' }}>
                            Atrás
                        </Typography.Text>
                    </Button>
                    <Space style={{ marginRight: '10px' }}>
                        <Tooltip title="Solicitar Pago">
                            <Money
                                size={18}
                                weight="regular"
                                className="icon clickable createClientPayment"
                                onClick={() => {
                                    if (!canCreatePayment) {
                                        message.warning('No tienes permisos para crear pagos')
                                        return
                                    }
                                    dispatch(
                                        setData({
                                            item: 'client',
                                            data: client,
                                        }),
                                    )
                                    dispatch(openModal('paymentModalVisible'))
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Crear Recibo">
                            <Cardholder
                                size={18}
                                weight="regular"
                                className="icon clickable createClientReceipt"
                                onClick={() => {
                                    if (!canCreateReceipt) {
                                        message.warning('No tienes permisos para crear recibos')
                                        return
                                    }
                                    dispatch(
                                        setData({
                                            item: 'client',
                                            data: client,
                                        }),
                                    )
                                    dispatch(openModal('receiptModalVisible'))
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Crear Factura">
                            <CurrencyDollar
                                size={18}
                                weight="regular"
                                className="icon clickable createClientInvoice"
                                onClick={() => {
                                    if (!canCreateInvoice) {
                                        message.warning('No tienes permisos para crear facturas')
                                        return
                                    }
                                    dispatch(
                                        setData({
                                            item: 'client',
                                            data: client,
                                        }),
                                    )
                                    dispatch(openModal('invoiceModalVisible'))
                                }}
                            />
                        </Tooltip>
                        <Divider type="vertical" />
                        <Tooltip title="Editar">
                            <Pencil
                                size={18}
                                weight="regular"
                                className="icon clickable"
                                onClick={() => {
                                    // if (!canCreateClient) {
                                    //     message.warning(
                                    //         canCreateClient.reason || 'No tienes permisos para editar clientes',
                                    //     )
                                    //     return
                                    // }
                                    dispatch(
                                        setData({
                                            item: 'client',
                                            data: client,
                                        }),
                                    )
                                    dispatch(openModal('createClientDrawerVisible'))
                                }}
                            />
                        </Tooltip>
                        {client?.metadata?.stripeId && (
                            <Tooltip title="Ver datos bancarios para la transferencia">
                                <Bank
                                    size={18}
                                    weight="regular"
                                    className="icon clickable"
                                    onClick={() => {
                                        dispatch(
                                            setData({
                                                item: 'client',
                                                data: client,
                                            }),
                                        )
                                        dispatch(openModal('stripeBankInstructionsVisible'))
                                    }}
                                />
                            </Tooltip>
                        )}
                        <Tooltip title="Valores Predeterminados">
                            <Gear
                                size={18}
                                weight="regular"
                                className="icon clickable"
                                onClick={() => {
                                    // if (!canCreateClient) {
                                    //     message.warning(
                                    //         canCreateClient.reason || 'No tienes permisos para editar clientes',
                                    //     )
                                    //     return
                                    // }
                                    dispatch(
                                        setData({
                                            item: 'client',
                                            data: client,
                                        }),
                                    )
                                    dispatch(openModal('clientDefaultsModalVisible'))
                                }}
                            />
                        </Tooltip>
                    </Space>
                </Row>
                <div className="d-flex flex-column" style={{ marginLeft: '17px', padding: '10px' }}>
                    <Row align="middle">
                        <Typography.Text className="smallparagrap" style={{ color: '#cecece' }}>
                            {t('Cliente')}
                        </Typography.Text>
                    </Row>
                    <Row justify="space-between" align="middle">
                        <Typography.Text className="bigparagraphbold" style={{ color: 'black', fontSize: '23px' }}>
                            {data?.legal_name ?? data?.name}
                        </Typography.Text>

                        {/* {data && <ReceiptActions receipt={data} />} */}
                    </Row>
                    {/* <Row style={{ marginTop: '20px' }} align="middle" className="">
                        <Avatar style={{ backgroundColor: '#A7FF99', color: 'black' }}>
                            {data?.name?.charAt(0) ??
                                data?.legalName?.charAt(0) ??
                                data?.legal_name?.charAt(0) ??
                                data?.company?.charAt(0)}
                        </Avatar>
                        <div
                            className="d-flex flex-column"
                            style={{ marginLeft: '10px', flex: 1, paddingRight: '10px' }}
                        >
                            <Typography.Text className="smallparagraphbold" style={{ color: 'black' }}>
                                {data?.legal_name ?? data?.name}
                            </Typography.Text>
                            <Typography.Text
                                className="smallparagraph"
                                onClick={() => console.log(data)}
                                style={{ marginTop: '5px', color: '#416D60' }}
                            >
                                {data?.email ?? 'Sin correo'} | {data?.rfc ?? 'Sin RFC'}
                            </Typography.Text>
                        </div>
                    </Row> */}
                    {!data?.efos?.is_valid && data?.efos && (
                        <div
                            className="d-flex flex-column"
                            style={{ backgroundColor: '#FFECEC', padding: '10px', borderRadius: '5px' }}
                        >
                            <Divider />
                            <Row justify="space-between" align="middle">
                                <div className="d-flex flex-column">
                                    <Typography.Text className="smallparagraphbold" style={{ marginBottom: '5px' }}>
                                        <ExclamationMark className="icon" size={14} weight="regular" />
                                        Contribuyente en lista negra del SAT
                                    </Typography.Text>
                                    <Typography.Text
                                        className="smallparagraph"
                                        style={{ marginBottom: '10px', marginLeft: '10px' }}
                                    >
                                        {data.efos?.data?.fechaLista}
                                    </Typography.Text>
                                </div>
                                <CaretDown
                                    onClick={() => setShowEfos(!showEfos)}
                                    className="icon"
                                    size={14}
                                    weight="regular"
                                />
                            </Row>
                            {showEfos &&
                                data.efos?.data.detalles?.map((detail: any) => {
                                    return Object.keys(detail).map((key: any) => {
                                        return (
                                            <DetailItem
                                                title={key}
                                                value={
                                                    <Typography.Text className="smallparagraph">
                                                        {detail[key]}
                                                    </Typography.Text>
                                                }
                                            />
                                        )
                                    })
                                })}
                        </div>
                    )}
                    <Divider />
                    <div className="d-flex flex-column"></div>
                    <div className="d-flex flex-column">
                        <Typography.Text className="smallparagraphbold" style={{ marginBottom: '10px' }}>
                            Información de contacto
                        </Typography.Text>
                    </div>
                    <DetailItem
                        title="Razón social"
                        icon={<User className="icon" size={14} weight="regular" />}
                        value={
                            <Typography.Text
                                className="smallparagraph"
                                editable={{
                                    icon: <PencilLine className="icon" size={14} weight="regular" />,
                                    tooltip: 'Editar razón social',
                                    onChange: async (value) => {
                                        await updateDoc(clientRef, {
                                            legal_name: value,
                                            legalName: value,
                                            lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${value}) from inline edit.`,
                                        })
                                    },
                                }}
                            >
                                {data?.legal_name}
                            </Typography.Text>
                        }
                    />
                    <DetailItem
                        title="Nombre"
                        icon={<Building className="icon" size={14} weight="regular" />}
                        value={
                            <Typography.Text
                                className="smallparagraph"
                                editable={{
                                    icon: <PencilLine className="icon" size={14} weight="regular" />,
                                    tooltip: 'Editar nombre comercial',
                                    onChange: async (value) => {
                                        await updateDoc(clientRef, {
                                            name: value,
                                            lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${value}) from inline edit.`,
                                        })
                                    },
                                }}
                            >
                                {data?.name}
                            </Typography.Text>
                        }
                    />
                    <DetailItem
                        title="Correo principal"
                        icon={<Envelope className="icon" size={14} weight="regular" />}
                        value={
                            <Typography.Text
                                className="smallparagraph"
                                editable={{
                                    icon: <PencilLine className="icon" size={14} weight="regular" />,
                                    tooltip: 'Editar correo',
                                    onChange: async (value) => {
                                        await updateDoc(clientRef, {
                                            email: value,
                                            lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} from inline edit.`,
                                        })
                                    },
                                }}
                            >
                                {data?.email}
                            </Typography.Text>
                        }
                    />
                    <DetailItem
                        title="Correos extra"
                        icon={<Envelope className="icon" size={14} weight="regular" />}
                        value={<EmailTagsInput emails={data?.bcc || []} clientRef={clientRef} user={user} />}
                    />
                    <DetailItem
                        title="Teléfono"
                        icon={<Phone className="icon" size={14} weight="regular" />}
                        value={
                            <Typography.Text
                                className="smallparagraph"
                                editable={{
                                    icon: <PencilLine className="icon" size={14} weight="regular" />,
                                    tooltip: 'Editar teléfono',
                                    onChange: async (value) => {
                                        await updateDoc(clientRef, {
                                            phone: value,
                                            lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${value}) from inline edit.`,
                                        })
                                    },
                                }}
                            >
                                {data?.phone}
                            </Typography.Text>
                        }
                    />
                    <DetailItem
                        title="RFC"
                        icon={<IdentificationCard className="icon" size={14} weight="regular" />}
                        value={
                            <Typography.Text
                                className="smallparagraph"
                                editable={{
                                    icon: <PencilLine className="icon" size={14} weight="regular" />,
                                    tooltip: 'Editar RFC',
                                    onChange: async (value) => {
                                        await updateDoc(clientRef, {
                                            rfc: value,
                                            lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${value}) from inline edit.`,
                                        })
                                    },
                                }}
                            >
                                {data?.rfc}
                            </Typography.Text>
                        }
                    />

                    <DetailItem
                        title="Compañía"
                        icon={<Building className="icon" size={14} weight="regular" />}
                        value={
                            <Typography.Text
                                className="smallparagraph"
                                editable={{
                                    icon: <PencilLine className="icon" size={14} weight="regular" />,
                                    tooltip: 'Editar compañía',
                                    onChange: async (value) => {
                                        await updateDoc(clientRef, {
                                            company: value,
                                            lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${value}) from inline edit.`,
                                        })
                                    },
                                }}
                            >
                                {data?.company}
                            </Typography.Text>
                        }
                    />

                    <DetailItem
                        title="Régimen Fiscal"
                        icon={<UserCheck className="icon" size={14} weight="regular" />}
                        value={
                            isEditingTaxSystem ? (
                                <SelectInput
                                    name="tax_system"
                                    customStyle={{ width: '100%', maxWidth: '300px' }}
                                    placeholder="Selecciona el régimen fiscal"
                                    options={taxRegimes}
                                    defaultValue={data?.tax_system}
                                    onBlur={() => setIsEditingTaxSystem(false)}
                                    autoFocus={true}
                                    onChange={async (value: any) => {
                                        try {
                                            await updateDoc(clientRef, {
                                                tax_system: value,
                                                lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${value}) from inline edit.`,
                                            })
                                            setIsEditingTaxSystem(false)
                                        } catch (error) {
                                            console.log('ERROR: ', error)
                                        }
                                    }}
                                />
                            ) : (
                                <Typography.Text className="smallparagraph" onClick={() => setIsEditingTaxSystem(true)}>
                                    {!data?.tax_system
                                        ? 'No definido'
                                        : typeof data?.tax_system === 'string'
                                          ? taxRegimes.find(
                                                (regime) =>
                                                    regime.value === data.tax_system ||
                                                    regime.value === data.tax_system,
                                            )?.label || data.tax_system
                                          : data.tax_system?.label}
                                    <PencilLine
                                        className="icon"
                                        size={14}
                                        weight="regular"
                                        style={{ marginLeft: '8px' }}
                                    />
                                </Typography.Text>
                            )
                        }
                    />
                    <DetailItem
                        title="Uso de CFDI's"
                        icon={<TagChevron className="icon" size={14} weight="regular" />}
                        value={
                            isEditingUse ? (
                                <SelectInput
                                    name="state"
                                    customStyle={{ width: '100%', maxWidth: '300px' }}
                                    placeholder="Selecciona el uso"
                                    options={CFDIUses}
                                    defaultValue={data?.use}
                                    onBlur={() => setIsEditingUse(false)}
                                    autoFocus={true}
                                    onChange={async (value: any) => {
                                        await updateDoc(clientRef, {
                                            use: value.value ?? value,
                                            lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${value.value}) from inline edit.`,
                                        })
                                        setIsEditingUse(false)
                                    }}
                                />
                            ) : (
                                <Typography.Text className="smallparagraph" onClick={() => setIsEditingUse(true)}>
                                    {!data?.use
                                        ? 'No definido'
                                        : typeof data?.use === 'string'
                                          ? CFDIUses.find(
                                                (regime) => regime.value === data.use || regime.value === data.use,
                                            )?.label || data.use
                                          : data.use?.label}
                                    <PencilLine
                                        className="icon"
                                        size={14}
                                        weight="regular"
                                        style={{ marginLeft: '8px' }}
                                    />
                                </Typography.Text>
                            )
                        }
                    />
                    <DetailItem
                        title="Fuente"
                        value={
                            <Tag color="#D3D9FF">
                                <Typography.Text className="smallparagraphbold blue">{data?.from}</Typography.Text>
                            </Tag>
                        }
                        icon={<GitFork weight="regular" className="icon" />}
                    />
                    <DetailItem
                        title="Creado el"
                        value={
                            <Typography.Text className="smallparagraph">
                                {moment(data?.timestamp).format('DD MMMM YYYY HH:mm')}
                            </Typography.Text>
                        }
                        icon={<Clock weight="regular" className="icon" />}
                    />
                    <Divider />
                    <div className="d-flex flex-column" style={{ marginBottom: '10px' }}>
                        <Typography.Text className="smallparagraphbold" style={{ marginBottom: '10px' }}>
                            Dirección de contacto
                        </Typography.Text>
                    </div>
                    <Row justify="start">
                        <DetailItem
                            style={{ flex: 1, borderRight: '1px solid #cecece' }}
                            title="Calle"
                            value={
                                <Typography.Text
                                    className="smallparagraph"
                                    editable={{
                                        icon: <PencilLine className="icon" size={14} weight="regular" />,
                                        tooltip: 'Editar calle',
                                        onChange: async (value) => {
                                            await updateDoc(clientRef, {
                                                address: {
                                                    ...data?.address,
                                                    address: value,
                                                },
                                                lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${value}) from inline edit.`,
                                            })
                                        },
                                    }}
                                >
                                    {data?.address?.address}
                                </Typography.Text>
                            }
                        />
                        <DetailItem
                            style={{ flex: 1, paddingLeft: '5px' }}
                            title="Número exterior"
                            value={
                                <Typography.Text
                                    className="smallparagraph"
                                    editable={{
                                        icon: <PencilLine className="icon" size={14} weight="regular" />,
                                        tooltip: 'Editar    ',
                                        onChange: async (value) => {
                                            await updateDoc(clientRef, {
                                                address: {
                                                    ...data?.address,
                                                    exterior: value,
                                                },
                                                lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${value}) from inline edit.`,
                                            })
                                        },
                                    }}
                                >
                                    {data?.address?.exterior}
                                </Typography.Text>
                            }
                        />
                    </Row>
                    <DetailItem
                        title="Número interior"
                        value={
                            <Typography.Text
                                className="smallparagraph"
                                editable={{
                                    icon: <PencilLine className="icon" size={14} weight="regular" />,
                                    tooltip: 'Editar número interior',
                                    onChange: async (value) => {
                                        await updateDoc(clientRef, {
                                            address: {
                                                ...data?.address,
                                                interior: value,
                                            },
                                            lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${value}) from inline edit.`,
                                        })
                                    },
                                }}
                            >
                                {data?.address?.exterior}
                            </Typography.Text>
                        }
                    />
                    <DetailItem
                        title="País"
                        value={
                            isEditingCountry ? (
                                <SelectInput
                                    name="country"
                                    customStyle={{ width: '100%', maxWidth: '400px' }}
                                    placeholder="Selecciona el país"
                                    options={countryLabeledListWithCodeAlpha3.map((country) => ({
                                        label: country.name,
                                        value: country.code,
                                    }))}
                                    defaultValue={
                                        typeof data?.address?.country === 'string'
                                            ? data?.address?.country
                                            : data?.address?.country?.code
                                    }
                                    onBlur={() => setIsEditingCountry(false)}
                                    autoFocus={true}
                                    onChange={async (value: any) => {
                                        const selectedCountry = countryLabeledListWithCodeAlpha3.find(
                                            (c) => c.code === value,
                                        )
                                        await updateDoc(clientRef, {
                                            address: {
                                                ...data?.address,
                                                country: selectedCountry?.code,
                                            },
                                            lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${selectedCountry?.code}) from inline edit.`,
                                        })
                                        setIsEditingCountry(false)
                                    }}
                                />
                            ) : (
                                <Typography.Text className="smallparagraph" onClick={() => setIsEditingCountry(true)}>
                                    {countryLabeledListWithCodeAlpha3.find((c) => c.code === data?.address?.country)
                                        ?.name ?? 'No definido'}
                                    <PencilLine
                                        className="icon"
                                        size={14}
                                        weight="regular"
                                        style={{ marginLeft: '8px' }}
                                    />
                                </Typography.Text>
                            )
                        }
                    />
                    <DetailItem
                        title="Código Postal"
                        value={
                            <Typography.Text
                                className="smallparagraph"
                                editable={{
                                    icon: <PencilLine className="icon" size={14} weight="regular" />,
                                    tooltip: 'Editar código postal',

                                    onChange: async (value) => {
                                        await updateDoc(clientRef, {
                                            address: {
                                                ...data?.address,
                                                zip: value,
                                            },
                                            lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${value}) from inline edit.`,
                                        })
                                    },
                                }}
                            >
                                {data?.address?.zip}
                            </Typography.Text>
                        }
                    />
                    <DetailItem
                        title="Estado"
                        // icon={<UserCheck className="icon" size={14} weight="regular" />}
                        value={
                            isEditingState ? (
                                <SelectInput
                                    name="state"
                                    customStyle={{ width: '100%', maxWidth: '300px' }}
                                    placeholder="Selecciona el estado"
                                    options={mexicanStates}
                                    defaultValue={data?.address?.state}
                                    onBlur={() => setIsEditingState(false)}
                                    autoFocus={true}
                                    onChange={async (value: any) => {
                                        await updateDoc(clientRef, {
                                            address: {
                                                ...data?.address,
                                                state: value,
                                            },
                                            lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${value.value}) from inline edit.`,
                                        })
                                        setIsEditingState(false)
                                    }}
                                />
                            ) : (
                                <Typography.Text className="smallparagraph" onClick={() => setIsEditingState(true)}>
                                    {!data?.address?.state
                                        ? 'No definido'
                                        : typeof data?.address?.state === 'string'
                                          ? mexicanStates.find(
                                                (regime) =>
                                                    regime.value === data.address?.state ||
                                                    regime.value === data.address?.state,
                                            )?.label || data.address?.state
                                          : data.address?.state?.label}
                                    <PencilLine
                                        className="icon"
                                        size={14}
                                        weight="regular"
                                        style={{ marginLeft: '8px' }}
                                    />
                                </Typography.Text>
                            )
                        }
                    />
                    {/*IMPLEMENT data.metadata where metadata is dynamic, the user should be able to add another key value pair*/}
                    <div style={{ marginTop: '16px', marginBottom: '16px' }}>
                        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
                            <Typography.Text className="mediumparagraphbold" style={{}}>
                                Metadata personalizada
                            </Typography.Text>
                            <Button
                                type="link"
                                icon={<PlusCircle size={16} weight="regular" />}
                                style={{ marginLeft: '8px', color: '#034737' }}
                                onClick={async () => {
                                    const newMetadata = { ...(data?.metadata || {}) }
                                    const key = `campo_${Object.keys(newMetadata).length + 1}`
                                    newMetadata[key] = ''

                                    await updateDoc(clientRef, {
                                        metadata: newMetadata,
                                        lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (añadió metadata) from inline edit.`,
                                    })
                                }}
                            >
                                Nuevo
                            </Button>
                        </Row>

                        {data?.metadata && Object.keys(data.metadata).length > 0 ? (
                            Object.entries(data.metadata).map(([key, value], index) => (
                                <div
                                    key={`metadata-${index}`}
                                    style={{ display: 'flex', marginBottom: '8px', alignItems: 'center' }}
                                >
                                    <Typography.Text
                                        style={{ width: '180px', marginRight: '8px' }}
                                        editable={
                                            key === 'stripeId'
                                                ? false
                                                : {
                                                      icon: <PencilLine className="icon" size={14} weight="regular" />,
                                                      tooltip: 'Editar clave',
                                                      onChange: async (newKey) => {
                                                          if (newKey === key) return

                                                          const newMetadata = { ...(data?.metadata || {}) }
                                                          delete newMetadata[key]
                                                          newMetadata[newKey] = value

                                                          await updateDoc(clientRef, {
                                                              metadata: newMetadata,
                                                              lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (editó metadata) from inline edit.`,
                                                          })
                                                      },
                                                  }
                                        }
                                    >
                                        {key === 'stripeId' ? (
                                            <Tooltip title="Este campo es usado por el sistema">
                                                <span style={{ fontWeight: 'bold', color: '#034737' }}>{key}</span>
                                            </Tooltip>
                                        ) : (
                                            key
                                        )}
                                    </Typography.Text>
                                    <Typography.Text
                                        style={{ flex: 1 }}
                                        editable={{
                                            icon: <PencilLine className="icon" size={14} weight="regular" />,
                                            tooltip: 'Editar valor',
                                            onChange: async (newValue) => {
                                                const newMetadata = { ...(data?.metadata || {}) }
                                                newMetadata[key] = newValue

                                                await updateDoc(clientRef, {
                                                    metadata: newMetadata,
                                                    lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (editó metadata) from inline edit.`,
                                                })
                                            },
                                        }}
                                    >
                                        {value as string}
                                    </Typography.Text>
                                    {key === 'stripeId' ? (
                                        <Tooltip title="Este campo no puede ser eliminado por ser usado por el sistema">
                                            <Button
                                                type="text"
                                                icon={<Prohibit size={16} weight="regular" />}
                                                disabled={true}
                                                style={{ color: '#999' }}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <Button
                                            type="text"
                                            danger
                                            icon={<Trash size={16} weight="regular" />}
                                            onClick={async () => {
                                                const newMetadata = { ...(data?.metadata || {}) }
                                                delete newMetadata[key]

                                                await updateDoc(clientRef, {
                                                    metadata: newMetadata,
                                                    lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (eliminó metadata) from inline edit.`,
                                                })
                                            }}
                                        />
                                    )}
                                </div>
                            ))
                        ) : (
                            <Empty
                                description="No hay metadatos personalizados"
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                style={{ margin: '16px 0' }}
                            />
                        )}
                    </div>

                    <Divider />
                    <UploadCSFInput
                        onFiscalLoaded={async (v: any) => {
                            await updateDoc(clientRef, {
                                rfc: v?.rfc,
                                legal_name: v?.name,
                                company: v?.company,
                                zip: v?.zipCode,
                                tax_system: v?.fiscalRegime,
                                address: {
                                    address: v?.street,
                                    exterior: v?.numberExt ?? null,
                                    interior: v?.numberInt ?? null,
                                    country: 'MEX',
                                    state: v?.state,
                                    zip: v?.zipCode,
                                },
                                lastEdited: `${user.uid} -> ${user?.name} ${user?.lastName} (${v?.name}) from from csf.`,
                            })
                        }}
                        customLabel="Actualizar desde constancia de situación fiscal"
                        customPath={`teams/${data?.team}/files/csf`}
                    />
                    <Row justify="space-between" style={{ marginTop: '22px' }}>
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            loading={loading}
                            onClick={() =>
                                handleDownloadClientBalance({
                                    client: data as ClientDef,
                                    loading,
                                    setLoading,
                                    teamId: data?.team || '',
                                    auth,
                                })
                            }
                        >
                            Descargar estado de cuenta
                        </Button>
                    </Row>
                </div>
            </Col>
            <Col xs={24} md={16} style={{ paddingLeft: '30px' }}>
                {data ? (
                    <Tabs
                        defaultActiveKey="2"
                        // items={[Path, GitFork, Envelope, StarFour].map((Icon, i) => {
                        //     const id = String(i + 1)
                        //     return {
                        //         key: id,
                        //         label: `Tab ${id}`,
                        //         children: `Tab ${id}`,
                        //         icon: <Icon />,
                        //     }
                        // })}
                        items={[
                            {
                                key: 'details',
                                label: <Typography.Text className="mediumparagraphbold">Resumen</Typography.Text>,
                                children: <DetailsTab />,
                                icon: <Path weight="regular" className="icon" />,
                            },

                            {
                                key: 'payments',
                                label: <Typography.Text className="mediumparagraphbold">Pagos</Typography.Text>,
                                children: <PaymentsTab />,
                                icon: <CreditCard weight="regular" className="icon" />,
                            },

                            {
                                key: 'invoices',
                                label: <Typography.Text className="mediumparagraphbold">Facturas</Typography.Text>,
                                children: <InvoicesTab />,
                                icon: <FileText weight="regular" className="icon" />,
                            },
                            {
                                key: 'recurringEvents',
                                label: (
                                    <Typography.Text className="mediumparagraphbold">
                                        Eventos recurrentes
                                    </Typography.Text>
                                ),
                                children: <RecurringEventsTab />,
                                icon: <ClockAfternoon weight="regular" className="icon" />,
                            },
                            {
                                key: 'receipts',
                                label: <Typography.Text className="mediumparagraphbold">Recibos</Typography.Text>,
                                children: <ReceiptsTab />,
                                icon: <Receipt weight="regular" className="icon" />,
                            },
                            // {
                            //     key: 'emails',
                            //     label: <Typography.Text className="mediumparagraphbold">Correos</Typography.Text>,
                            //     children: <></>,
                            //     icon: <Envelope weight="regular" className="icon" />,
                            // },
                            {
                                key: 'changes',
                                label: <Typography.Text className="mediumparagraphbold">Cambios</Typography.Text>,
                                children: <LogsTab />,
                                icon: <ClockCounterClockwise weight="regular" className="icon" />,
                            },
                            {
                                key: 'events',
                                label: <Typography.Text className="mediumparagraphbold">Eventos</Typography.Text>,
                                children: <EventsTab />,
                                icon: <StarFour weight="regular" className="icon" />,
                            },
                        ]}
                    />
                ) : (
                    <Skeleton active />
                )}
            </Col>
        </Row>
    )
}

export default ClientViewDetail
