import { Button, Col, DatePicker, Drawer, Form, Row, Switch, Typography, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { useEffect, useState } from 'react'
import { GetClients, GetExchangeRates, GetServices } from '../context/databaseContextHelpers'
import { generateCode, getItemsAmounts } from '../functions/helpers'
import moment from 'moment'
import dayjs from 'dayjs'
import { CaretDoubleRight, X } from '@phosphor-icons/react'
import { setData } from '../context/dataSlice'
import SelectClientCard from '../components/Cards/SelectClientCard'
import ItemsSelection from '../components/ComponentIndividual/ItemsSelection'
import ProductsServicesTotals from '../components/ComponentIndividual/ProductsServicesTotals'
import { CheckBoxInput, NumberInput, SelectInput } from '../components/Forms/Inputs'
import { currecies } from '../datasets/Currencies'
import { arrayUnion, doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore'
import { manageAutomations } from '../../helpers/helperFunctions'
import { addParamToUrl } from '../functions/UrlParams'
import { paymentForms } from '../datasets/Fiscal'

export const RegisterPaymentDrawer: React.FC = () => {
    const dispatch = useDispatch()
    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const { registerPaymentModalVisible } = useSelector((state: any) => state.modals)
    const { services, clients, exchangeRates, servicesLoaded, clientsLoaded, testmode } = useSelector(
        (state: any) => state.data,
    )
    const [form] = Form.useForm()
    const { payment } = useSelector((state: any) => state.data)
    const [loading, setloading] = useState(false)
    const { client, selectedServices } = useSelector((state: any) => state.data)
    const { currency } = useSelector((state: any) => state.data)
    const [, /*currencyState*/ setCurrency] = useState('')

    useEffect(() => {}, [registerPaymentModalVisible])

    useEffect(() => {
        if (registerPaymentModalVisible) {
            if (services.length === 0 && !servicesLoaded) {
                GetServices(dispatch, team.id)
            }

            if (clients.length === 0 && !clientsLoaded) {
                GetClients(dispatch, team.id, clientsLoaded)
            }

            if (Object.keys(exchangeRates).length === 0) {
                GetExchangeRates(dispatch)
            }

            addParamToUrl('open', 'registerPayment')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerPaymentModalVisible])

    const handleCurrencyChange = (value: string) => {
        const selectedCurrency = currecies.find((currency) => currency.value === value)
        if (selectedCurrency) {
            setCurrency(selectedCurrency.value) // Actualizar el estado de la moneda
            const exchangeRate = exchangeRates[selectedCurrency.value.toUpperCase()]
            form.setFieldsValue({
                exchange: exchangeRate ? (1 / exchangeRate).toFixed(4) : '',
                exchange_rate: exchangeRate ? (1 / exchangeRate).toFixed(4) : '', // Actualizar el tipo de cambio
            })
        }
    }

    const Cancel = () => {
        dispatch(closeModal('registerPaymentModalVisible'))
        form.resetFields()
        dispatch(
            setData({
                item: 'selectedServices',
                data: [],
            }),
        )
        dispatch(
            setData({
                item: 'client',
                data: null,
            }),
        )
    }

    const handleRegisterPayment = async (values: any) => {
        console.log('🚀 ~ handleRegisterPayment ~ values', values)
        const id = generateCode(10, 'payment')
        let p: any = {
            ...payment,
            amount: getItemsAmounts(selectedServices, true).total * 100,
            items: selectedServices,
            status: 'requires_payment_method',
            succeededTimestamp: moment().valueOf(),
            internalStatus: 'requires_payment_method',
            internalItems: selectedServices,
            custom_method_types: [],
            payment_form: values.paymentForm,
            client: client ?? null,
            clientId: client?.id ?? null,
            clientID: client?.id ?? null,
            payment_method: 'PUE',
            exchange: Number(values.exchange),
            currency: values.currency,
            paidId: values.paymentForm === '03' ? 'bank' : values.paymentForm,
            id: id,
            fid: id,
            v: 2,
            from: 'manual',
            owner: user?.uid,
            processor: 'manual',
            relatedTo: '',
            invoices: null,
            payments: null,
            discount: 0,
            team: team.id,
            billingAccount: team.billingAccount,
            timestamp: moment(values.timestamp?.valueOf()).valueOf(),
            created: moment().valueOf(),
            livemode: !testmode,
            binnacle: `Payment created manually by ${user?.name} ${user?.lastName}`,
        }

        // return console.log(p);

        setloading(true)
        if (values.automations) {
            p.automations = manageAutomations({ toCreate: 'invoice', from: 'payments' })
        }

        //remove undefined values
        Object.keys(p).forEach((key) => p[key] === undefined && delete p[key])

        try {
            await setDoc(doc(getFirestore(), 'payments', id), {
                ...p,
                ...{
                    status: 'succeeded',
                    internalStatus: 'succeeded',
                },
            })
            // await updateDoc(doc(getFirestore(), 'payments', id), {
            //     status: 'succeeded',
            //     internalStatus: 'succeeded',
            // })
            if ((payment?.invoices ?? []).length > 0) {
                for (const inv of payment?.invoices) {
                    await updateDoc(doc(getFirestore(), 'invoices', inv), {
                        payments: arrayUnion(id),
                    })
                }
            }
            setTimeout(() => {
                message.success('Pago registrado correctamente')
                dispatch(closeModal('registerPaymentModalVisible'))
                form.resetFields()
                dispatch(
                    setData({
                        item: 'selectedServices',
                        data: [],
                    }),
                )
                dispatch(
                    setData({
                        item: 'client',
                        data: null,
                    }),
                )
                setloading(false)
            }, 500)
        } catch (error: any) {
            console.log('🚀 ~ handleRegisterPayment ~ error:', error)
            message.error(error.message ?? 'Ocurrió un error al registrar el pago')
            setloading(false)
        }
    }

    return (
        <Drawer
            title={
                <div style={{ position: 'relative', paddingBottom: '0px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            //center the text
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        Registrar un pago
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X className="icon clickable" size={20} onClick={Cancel} />
                                <></>

                                {!payment && (
                                    <Button type="primary" onClick={() => form.submit()} disabled={loading}>
                                        Registrar
                                        <CaretDoubleRight
                                            size={15}
                                            style={{ marginLeft: '5px' }}
                                            className=""
                                            weight="regular"
                                        />
                                    </Button>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            closeIcon={<></>}
            onClose={Cancel}
            open={registerPaymentModalVisible}
            width={'100%'}
            style={{ zIndex: 8000000, marginTop: testmode ? '32px' : '0px', paddingBottom: testmode ? '32px' : '0px' }}
        >
            <Form
                form={form}
                onFinish={handleRegisterPayment}
                initialValues={{
                    test: testmode,
                    ...(payment ?? {}),
                }}
                layout="vertical"
            >
                <Row justify="center">
                    <Col xs={23} lg={17}>
                        <Typography.Title className="bigparagraph" level={5}>
                            1. Cliente
                        </Typography.Title>
                        <SelectClientCard />
                        <Col xs={24}>
                            <Typography.Title className="bigparagraph" level={5} style={{ marginTop: '80px' }}>
                                2. Productos / Servicios
                            </Typography.Title>
                            <ItemsSelection hideTitle={true} />
                            <ProductsServicesTotals boxed={false} />
                        </Col>
                        <Col xs={24}>
                            <Typography.Title className="bigparagraph" level={5} style={{ marginTop: '80px' }}>
                                3. Forma de pago
                            </Typography.Title>
                            <SelectInput
                                name="paymentForm"
                                label="Forma de pago"
                                options={paymentForms}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor selecciona la forma de pago',
                                    },
                                ]}
                            />
                        </Col>
                        <Row gutter={12}>
                            <Col xs={24} md={12}>
                                <SelectInput
                                    rules={[
                                        { required: true, message: `Por favor seleccion la moneda` }, // Agregar regla de validación requerida
                                    ]}
                                    label="Moneda"
                                    name="currency"
                                    options={currecies}
                                    placeholder="Moneda"
                                    // onChange={() => {
                                    //     form.setFieldsValue({
                                    //         exchange: (1 / exchangeRates[currency]).toFixed(4)
                                    //     })
                                    // }}
                                    onChange={handleCurrencyChange}
                                    // defaultValue={currency}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <NumberInput
                                    name={'exchange'}
                                    placeholder="Tipo de cambio"
                                    disabled={currency === 'MXN' ? true : false}
                                    min={0}
                                    max={100000}
                                    step={0.1}
                                    label="Tipo de cambio"
                                />
                            </Col>

                            <Col xs={24}>
                                <Form.Item
                                    name="timestamp"
                                    label="Fecha de pago"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor selecciona la fecha de pago',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format="DD/MM/YYYY"
                                        onChange={(date) => {
                                            if (date) {
                                                const now = dayjs()
                                                let selectedDate = dayjs(date)

                                                if (selectedDate.isSame(now, 'day')) {
                                                    selectedDate = selectedDate
                                                        .set('hour', now.hour())
                                                        .set('minute', now.minute())
                                                        .set('second', now.second())
                                                }

                                                form.setFieldsValue({
                                                    timestamp: selectedDate,
                                                })

                                                console.log('🚀 ~ handleRegisterPayment ~ selectedDate', selectedDate)
                                            }
                                        }}
                                        disabledDate={(current: any) => {
                                            return current && current > moment().endOf('day')
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Col>
                            <Typography.Title className="bigparagraph" level={5} style={{ marginTop: '80px' }}>
                                4. ¿Crear factura automática?
                            </Typography.Title>
                            <CheckBoxInput
                                disabled={!getItemsAmounts(selectedServices, true).canSend}
                                name="automations"
                                content={
                                    <>
                                        {' '}
                                        <Typography.Text
                                            type="secondary"
                                            className="strong"
                                            style={{ fontSize: '13px' }}
                                        >
                                            Crear una factura cuando el pago esté completo
                                        </Typography.Text>
                                    </>
                                }
                            />
                        </Col>
                        <Typography.Title className="bigparagraph" level={5}>
                            6. Pago en modo pruebas
                        </Typography.Title>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="¿Deseas registrar el pago en modo pruebas?"
                                valuePropName="checked"
                                name="test"
                            >
                                <Switch
                                    checked={testmode}
                                    onChange={(checked) => {
                                        dispatch(
                                            setData({
                                                item: 'testmode',
                                                data: checked,
                                            }),
                                        )
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Col>
                </Row>
                <Row justify={'end'}>
                    <Button type="primary" htmlType="submit" disabled={loading}>
                        Registrar
                        <CaretDoubleRight size={15} style={{ marginLeft: '5px' }} className="" weight="regular" />
                    </Button>
                </Row>
            </Form>
        </Drawer>
    )
}
