import { AutoComplete, Form, Typography } from 'antd'
import React, { useState } from 'react'
import { CollectionType, getSearchData } from '../../search/SearchComponent'
import { useSelector } from 'react-redux'

const formatLabel = (hit: any) => {
    if (hit.document.similar) {
        return `${hit.document.code}: ${hit.document.description} (${hit.document.similar})`
    } else {
        return `${hit.document.code}: ${hit.document.description}`
    }
}

export default function ProductKeySearch() {
    const [productKeys, setProductKeys] = useState([])
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')

    const { team } = useSelector((state: any) => state.team)

    const handleSearch = async (e: any) => {
            const searchParams = {
                collection: 'product_keys' as CollectionType,
                query: e.toString(),
                team,
                setLoading: setLoading,
            }

            const searchData: any = await getSearchData(searchParams)

            if (searchData.hits) {
                const autocompleteOptions = searchData.hits.map((hit: any) => ({
                    value: hit.document.code,
                    label: formatLabel(hit),
                }))
                setProductKeys(autocompleteOptions)
            }
    }

    const handleSelect = (e: any) => {
        setValue(e)
        setProductKeys([])
    }

    return (
        <div>
            <div>
                <Typography className="p-base-bold" style={{ marginBottom: '10px' }}>
                    Código del producto:
                </Typography>
                <div style={{ marginBottom: '7px' }}>
                    <Form.Item
                        id="product-key-search-form-item"
                        name="product_key"
                        rules={[{ required: true, message: 'Por favor selecciona un código de producto' }]}
                    >
                        <AutoComplete
                            style={{ width: '100%' }}
                            options={loading ? [{ value: 'Cargando...' }] : productKeys.length > 0 ? productKeys : []}
                            placeholder="Servicios contables, Material médico, etc..."
                            onSearch={handleSearch}
                            onChange={(value) => setValue(value)}
                            onSelect={handleSelect}
                            value={value}
                        />
                    </Form.Item>
                    <Typography className="p-small-regular neutral-3" style={{ marginLeft: '3px', padding: '0' }}>
                        Ingresa tu búsqueda.
                    </Typography>
                </div>
            </div>
        </div>
    )
}
