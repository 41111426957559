import { Alert, Space, Typography } from 'antd'
import { Warning } from '@phosphor-icons/react'

interface CancelInvoiceAlertProps {
    type?: 'cancel' | 'substitute'
}

export const CancelInvoiceAlert = ({ type = 'cancel' }: CancelInvoiceAlertProps) => {
    return (
        <Alert
            type="warning"
            showIcon
            icon={<Warning size={20} className="warning-color" />}
            message={
                <Typography.Text className="p-base-bold warning-color">
                    {type === 'cancel' ? 'Verifica antes de cancelar' : 'Verifica antes de sustituir'}
                </Typography.Text>
            }
            description={
                <Space direction="vertical" size="small">
                    <Typography.Text className="p-base-regular" style={{ margin: '0px' }}>
                        Antes de {type === 'cancel' ? 'cancelar' : 'sustituir'} la factura, verifica que:
                    </Typography.Text>
                    <ul style={{ marginLeft: '10px', marginBottom: '8px' }}>
                        <li>
                            <Typography.Text className="p-base-regular">
                                No existan complementos de pago relacionados
                            </Typography.Text>
                        </li>
                        <li>
                            <Typography.Text className="p-base-regular">
                                Si estás gestionando un reembolso, considera emitir una nota de crédito en lugar de cancelar
                            </Typography.Text>
                        </li>
                    </ul>
                    <Typography.Text className="p-base-regular warning-color">
                        La cancelación incorrecta de facturas puede tener implicaciones fiscales.
                    </Typography.Text>
                </Space>
            }
            style={{
                marginBottom: '24px',
                // backgroundColor: 'var(--warning-1)',
                // border: '1px solid var(--warning-base)',
                borderRadius: '8px'
            }}
        />
    )
}

