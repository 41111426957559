import { Checkbox, Col, Modal, Row, Switch, Typography, Select, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { X } from '@phosphor-icons/react'
import { setData } from '../context/dataSlice'
import { useState } from 'react'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { useTranslation } from 'react-i18next'
import { paymentForms } from '../datasets/Fiscal'

const ClientDefaultsModal = () => {
    const dispatch = useDispatch()
    const { clientDefaultsModalVisible } = useSelector((state: any) => state.modals)
    const { client, clients } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)
    const { t } = useTranslation()

    const [defaults, setDefaults] = useState(
        client?.defaults ?? {
            avoidNextInvoices: false,
            defaultPaymentForm: undefined,
        },
    )

    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        dispatch(closeModal('clientDefaultsModalVisible'))
    }

    const handleSave = async () => {
        setLoading(true)
        const clientUpdate = {
            ...client,
            defaults: defaults,
        }
        dispatch(
            setData({
                item: 'clients',
                data: clients.map((client: any) => {
                    if (clientUpdate.id === client.id) {
                        return clientUpdate
                    }
                    return client
                }),
            }),
        )

        await updateDoc(doc(getFirestore(), 'clients', clientUpdate.id), { defaults: defaults })
        dispatch(
            setData({
                item: 'client',
                data: clientUpdate,
            }),
        )
        setLoading(false)
        handleClose()
    }

    return (
        <Modal
            title={
                <div style={{ position: 'relative', paddingBottom: '15px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        Configuración predeterminada del {t('cliente')}
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X className="icon clickable" size={20} onClick={handleClose} />
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            closeIcon={<></>}
            width={window.innerWidth > 768 ? '50%' : '90%'}
            style={{ top: 15 }}
            open={clientDefaultsModalVisible}
            footer={
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button type="primary" onClick={handleSave} loading={loading}>
                        Guardar cambios
                    </Button>
                </div>
            }
        >
            <div
                style={{
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    padding: '0px 10px',
                }}
            >
                <Typography.Text className="cLabel">Emitir facturas automáticas para este cliente</Typography.Text>
                <div style={{ marginBottom: '5px', marginTop: '5px' }}>
                    <Switch
                        onChange={async (v) => {
                            setDefaults({ ...defaults, avoidNextInvoices: !v })
                        }}
                        defaultChecked={!defaults?.avoidNextInvoices}
                    />
                </div>
                <div className="d-flex flex-row" style={{ marginTop: '15px' }}>
                    <Checkbox
                        disabled={!defaults?.avoidNextInvoices}
                        checked={defaults?.invoiceableReceipts}
                        onChange={(v) => {
                            setDefaults({ ...defaults, invoiceableReceipts: v.target.checked })
                        }}
                    >
                        Crear recibos de venta facturables
                    </Checkbox>
                </div>
                <Typography.Text type="secondary" style={{ fontSize: '10px' }}>
                    {defaults?.avoidNextInvoices
                        ? `*Emitiremos recibos de venta que ${defaults?.invoiceableReceipts ? '' : 'NO'} podrán ser facturados`
                        : '*Emitiremos facturas o recibos de venta facturables para este cliente.'}
                </Typography.Text>
                {team?.whmcs?.completed && <div style={{ marginTop: '20px' }}>
                    <Typography.Text className="cLabel">Forma de pago predeterminada</Typography.Text>
                    <div style={{ marginBottom: '5px', marginTop: '5px' }}>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Selecciona una forma de pago"
                            value={defaults?.defaultPaymentForm}
                            onChange={(value) => {
                                setDefaults({ ...defaults, defaultPaymentForm: value })
                            }}
                            options={paymentForms}
                        />
                    </div>
                    <Typography.Text type="secondary" style={{ fontSize: '10px' }}>
                        *Esta forma de pago se aplicará automáticamente a todos los pagos de WHMCS de este cliente 
                    </Typography.Text>
                </div>}
            </div>
        </Modal>
    )
}

export default ClientDefaultsModal
