import { Button, Col, Divider, Form, Modal, Row, Typography, message, Alert, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { IntegrationOnbHeader, IntegrationOnboarding } from '../../components/Integrations/IntegrationOnboarding'
import { ArrowLeft, CurrencyDollar, Question, Robot, Swap } from '@phosphor-icons/react'
import { integrations } from '../../datasets/Integrations'
import { useEffect, useState } from 'react'
import { useForm } from 'antd/es/form/Form'
import { TextInput } from '../../components/Forms/Inputs'
import { useAuth } from 'reactfire'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { GetTeamHelper } from '../../context/databaseContextHelpers'
import SwitchHolder from '../../components/Forms/SwitchHolder'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { getStripeConnectLink } from '../../components/Integrations/stripeHelpers'
import { addParamToUrl } from '../../functions/UrlParams'
import { usePostHog } from 'posthog-js/react'
import moment from 'moment'
import { trackEvent } from '../../analytics/helpers'
import { getFunctions, httpsCallable } from 'firebase/functions'

interface PaymentWithoutAutomation {
    id: string;
}

interface PaymentsWithoutAutomationResult {
    livemodeCount: number;
    testmodeCount: number;
    payments: PaymentWithoutAutomation[];
}

const StripeApiConnection = ({ setStep }: { setStep: (s: number) => void }) => {
    const [form] = useForm()
    const dispatch = useDispatch()
    const auth = useAuth()

    const { team } = useSelector((state: any) => state.team)
    const stripeIntegration = integrations(team).find((i) => i.id === 'stripe')

    const [loading, setLoading] = useState(false)

    const handleStripeAPIConnection = async (v: any) => {
        try {
            setLoading(true)

            await SignedInternalAPIRequest(
                {
                    teamId: team?.id,
                    billingAccountId: team?.billingAccount,
                    secret: v.liveApiKey,
                    testSecret: v.testApiKey,
                    public: v.livePublicApiKey,
                    testPublic: v.testPublicApiKey,
                    accountId: v.accountId,
                },
                'stripeManualConnect',
                auth.currentUser,
                {},
            )

            message.success('Stripe conectado exitosamente')

            GetTeamHelper(team.id, dispatch)

            form.resetFields()

            setLoading(false)
            dispatch(closeModal('stripeModalVisible'))
        } catch (error: any) {
            setLoading(false)
            message.error(error?.message ?? 'Ocurrió un error al conectar con Stripe')
        }
    }

    return (
        <div style={{ padding: '20px 24px' }}>
            <IntegrationOnbHeader
                logo={stripeIntegration?.logo ?? ''}
                title="Conecta tu cuenta vía API"
                description={
                    <Typography.Text className="mediumparagraph">
                        Cónoce más sobre como conectarte a Stripe vía API {'\u00A0'}{' '}
                        <Typography.Link
                            underline
                            href="https://helpcenter.gigstack.pro/es/articles/7000584-como-conectar-tu-cuenta-de-stripe-con-gigstack-pro"
                            target="_blank"
                        >
                            aquí
                        </Typography.Link>
                    </Typography.Text>
                }
                justify="start"
            />

            <Row justify="center" style={{ marginTop: '20px' }}>
                <Col xs={22}>
                    <Form form={form} layout="vertical" disabled={loading} onFinish={handleStripeAPIConnection}>
                        <TextInput
                            label="ID de la cuenta"
                            name="accountId"
                            placeholder="acct_*****"
                            rules={[
                                { required: true, message: 'Por favor ingrese su ID de cuenta' },
                                {
                                    validator: (rule: any, value: string) => {
                                        if (!value.startsWith('acct_')) {
                                            return Promise.reject('ID de cuenta inválido, debe comenzar con acct_')
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                },
                            ]}
                        />
                        <TextInput
                            label="API Key Live"
                            name="liveApiKey"
                            placeholder="sk_live*****"
                            rules={[
                                { required: true, message: 'Por favor ingrese su API Live' },
                                {
                                    validator: (rule: any, value: string) => {
                                        if (!value.startsWith('sk_live') && !value.startsWith('rk_live')) {
                                            return Promise.reject('API Key Live inválida, debe comenzar con sk_live o rk_live')
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                },
                            ]}
                        />
                        <TextInput
                            label="API Key Test"
                            name="testApiKey"
                            placeholder="sk_test*****"
                            rules={[
                                { required: true, message: 'Por favor ingrese su API Key Test' },
                                {
                                    validator: (rule: any, value: string) => {
                                        if (!value.startsWith('sk_test') && !value.startsWith('rk_test')) {
                                            return Promise.reject('API Key Test inválida, debe comenzar con sk_test o rk_test')
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                },
                            ]}
                        />

                        <Divider />

                        <TextInput
                            label="API Key Pública Live"
                            name="livePublicApiKey"
                            placeholder="pk_live*****"
                            rules={[
                                { required: true, message: 'Por favor ingrese su API Key Test' },
                                {
                                    validator: (rule: any, value: string) => {
                                        if (!value.startsWith('pk_live')) {
                                            return Promise.reject('API Key Test inválida, debe comenzar con pk_live')
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                },
                            ]}
                        />

                        <TextInput
                            label="API Key Pública Test"
                            name="testPublicApiKey"
                            placeholder="pk_test*****"
                            rules={[
                                { required: true, message: 'Por favor ingrese su API Key Test' },
                                {
                                    validator: (rule: any, value: string) => {
                                        if (!value.startsWith('pk_test')) {
                                            return Promise.reject('API Key Test inválida, debe comenzar con pk_test')
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                },
                            ]}
                        />
                    </Form>
                </Col>
            </Row>

            <Row justify="end" align="middle" style={{ marginTop: '20px' }}>
                <Button
                    disabled={loading}
                    icon={<ArrowLeft className="icon" size={18} weight="regular" />}
                    style={{ marginRight: '5px' }}
                    onClick={() => setStep(0)}
                >
                    Atrás
                </Button>
                <Button loading={loading} type="primary" onClick={() => form.submit()}>
                    Conectar
                </Button>
            </Row>
        </div>
    )
}

const ManageStripeIntegration = () => {
    const posthog = usePostHog()
    const fs = getFirestore()
    const dispatch = useDispatch()
    const { team } = useSelector((state: any) => state.team)
    const functions = getFunctions()

    const [automaticInvoicing, setAutomaticInvoicing] = useState(team?.stripe?.automaticInvoicing ?? false)
    const [automaticInvoicingTest, setAutomaticInvoicingTest] = useState(
        team?.stripe?.onlyAutomaticInvoicingOnTestMode ?? false,
    )
    const [switchLoading, setSwitchLoading] = useState(false)
    const [paymentsWithoutAutomation, setPaymentsWithoutAutomation] = useState<PaymentsWithoutAutomationResult | null>(null)

    const [useStripeExchangeRate, setUseStripeExchangeRate] = useState(team?.defaults?.useStripeExchangeRate ?? false)
    const [convertPaymentsToCurrency, setConvertPaymentsToCurrency] = useState<string | null>(team?.defaults?.convertAllPaymentsToCurrency || null)

    const handleAutomatizePayments = async () => {
        if (!paymentsWithoutAutomation || !paymentsWithoutAutomation.payments) return
        
        setSwitchLoading(true)
        try {
            const handlePaymentsFunction = httpsCallable<any, any>(functions, 'hadlepaymentswithoutautomations')
            await handlePaymentsFunction({
                teamId: team?.id,
                paymentIds: paymentsWithoutAutomation.payments.map((p: PaymentWithoutAutomation) => p.id),
                action: 'update'
            })
            
            setPaymentsWithoutAutomation(null)
            message.success('Pagos actualizados correctamente')
        } catch (error: any) {
            message.error(error?.message ?? 'Ocurrió un error al actualizar los pagos')
        } finally {
            setSwitchLoading(false)
        }
    }

    return (
        <div style={{ padding: '20px 24px 30px 20px' }}>
            <Typography.Text className="smallheader">Configura la conexión con Stripe</Typography.Text>
            <Typography.Text className="smallparagraph descriptions">
                Usaremos esta configuración para la facturación automática con Stripe
            </Typography.Text>

            <Divider>
                <Typography.Text className="smallheader">Facturación automática</Typography.Text>
            </Divider>

            <SwitchHolder
                changeState={async () => {
                    try {
                        trackEvent({ name: 'stripe_automatic_invoicing', metadata: { active: !automaticInvoicing } }, posthog)
                        
                        if (!automaticInvoicing) {
                            setSwitchLoading(true)
                            
                            const startDate = moment().startOf('month').valueOf()
                            const endDate = moment().endOf('month').valueOf()
                            
                            const handlePaymentsFunction = httpsCallable<any, PaymentsWithoutAutomationResult>(functions, 'hadlepaymentswithoutautomations')
                            const result = await handlePaymentsFunction({
                                teamId: team?.id,
                                action: 'count',
                                startDate,
                                endDate
                            })
                            console.log('result', result)
                            if (result.data && (result.data.livemodeCount > 0 || result.data.testmodeCount > 0)) {
                                setPaymentsWithoutAutomation(result.data)
                            } else {
                                setPaymentsWithoutAutomation(null)
                            }
                        }
                        
                        await updateDoc(doc(fs, 'teams', team?.id), {
                            'stripe.automaticInvoicing': !automaticInvoicing,
                        })
                        setAutomaticInvoicing(() => !automaticInvoicing)
                        await GetTeamHelper(team?.id, dispatch)
                        message.success('Configuración actualizada')
                    } catch (error) {
                        message.error('Ocurrió un error al actualizar la configuración')
                    } finally {
                        setSwitchLoading(false)
                    }
                }}
                switchState={automaticInvoicing}
                loading={switchLoading}
                icon={<Robot className="icon clickable" weight="regular" size={16} />}
                title={'Activar facturación automática'}
                description="Intentaremos facturar automáticamente tus ventas, si no es posible, crearemos un recibo de venta para que tu cliente genere su factura"
            />

            {paymentsWithoutAutomation && (
                <div style={{ marginTop: '15px' }}>
                    <Alert
                        type="warning"
                        message={
                            <div>
                                <Space direction="vertical">
                                    <Typography.Text>
                                        Hay {paymentsWithoutAutomation.livemodeCount + paymentsWithoutAutomation.testmodeCount} pagos del mes actual sin automatización.
                                        {paymentsWithoutAutomation.livemodeCount > 0 && ` ${paymentsWithoutAutomation.livemodeCount} en modo producción.`}
                                        {paymentsWithoutAutomation.testmodeCount > 0 && ` ${paymentsWithoutAutomation.testmodeCount} en modo prueba.`}
                                    </Typography.Text>
                                    <Typography.Text>
                                        ¿Deseas automatizarlos?
                                    </Typography.Text>
                                </Space>

                                <Row justify="end" style={{ marginTop: '10px', width: '100%' }}>
                                    <Button 
                                        size="small" 
                                        type="primary" 
                                        onClick={handleAutomatizePayments}
                                        loading={switchLoading}
                                    >
                                        Automatizar pagos
                                    </Button>
                                </Row>
                            </div>
                        }
                    />
                </div>
            )}

            {automaticInvoicing && (
                <>
                    <Divider style={{ margin: '5px', border: 'none' }} />

                    <SwitchHolder
                        changeState={async () => {
                            try {
                                await updateDoc(doc(fs, 'teams', team?.id), {
                                    'stripe.onlyAutomaticInvoicingOnTestMode': !automaticInvoicingTest,
                                })

                                setAutomaticInvoicingTest(() => !automaticInvoicingTest)
                                await GetTeamHelper(team?.id, dispatch)

                                message.success('Configuración actualizada')
                            } catch (error) {
                                message.error('Ocurrió un error al actualizar la configuración')
                            }
                        }}
                        switchState={automaticInvoicingTest}
                        icon={<Robot className="icon clickable" weight="regular" size={16} />}
                        title={'Facturación automática solo en pagos de prueba'}
                        description="Solo automatzaremos los pagos que recibamos en modo test"
                    />
                </>
            )}

            <Divider style={{ marginTop: '20px' }}>
                <Typography.Text className="smallheader">Monedas y tipos de cambio</Typography.Text>
            </Divider>

            <SwitchHolder
                changeState={async (checked: boolean) => {
                    try {
                        await updateDoc(doc(fs, 'teams', team?.id), {
                            'defaults.useStripeExchangeRate': checked,
                            'defaults.convertAllPaymentsToCurrency': checked ? convertPaymentsToCurrency : null,
                        })
                        await GetTeamHelper(team?.id, dispatch)
                        console.log('convertPaymentsToCurrency', convertPaymentsToCurrency)
                        console.log('checked', checked)
                        console.log('convertPaymentsToCurrency', checked ? convertPaymentsToCurrency : null)
                        setUseStripeExchangeRate(checked)
                        setConvertPaymentsToCurrency(checked ? convertPaymentsToCurrency : null)

                        message.success('Configuración actualizada')
                    } catch (error: any) {
                        message.error(error?.message ?? 'Ocurrió un error al actualizar la configuración')
                    }
                }}
                switchState={useStripeExchangeRate}
                value={useStripeExchangeRate}
                icon={<Swap className="icon clickable" weight="regular" size={16} />}
                title={'Usar el tipo de cambio proporcionado por Stripe'}
                description="Usaremos el tipo de cambio de Stripe para cada transacción"
            />

            <Divider style={{ margin: '5px', border: 'none' }} />

            <SwitchHolder
                changeState={async (checked: boolean) => {
                    try {
                        const currency = checked ? 'mxn' : null

                        if (!useStripeExchangeRate)
                            return message.warning(
                                'Para activar esta opción en tu cuenta debes tener activo el tipo de cambio de Stripe',
                            )
                        await updateDoc(doc(fs, 'teams', team?.id), {
                            'defaults.convertAllPaymentsToCurrency': currency,
                        })
                        await GetTeamHelper(team?.id, dispatch)
                        setConvertPaymentsToCurrency(currency)

                        message.success('Configuración actualizada')
                    } catch (error: any) {
                        message.error(error?.message ?? 'Ocurrió un error al actualizar la configuración')
                    }
                }}
                switchState={convertPaymentsToCurrency === 'mxn'}
                disabled={!useStripeExchangeRate}
                value={convertPaymentsToCurrency === 'mxn'}
                icon={<CurrencyDollar className="icon clickable" weight="regular" size={16} />}
                title="Convertir pagos en otras monedas a MXN"
                description="Convertiremos automáticamente los pagos en otras monedas a pesos mexicanos usando el tipo de cambio de Stripe"
            />
        </div>
    )
}

export const StripeModal = () => {
    const posthog = usePostHog()
    const dispatch = useDispatch()
    const auth = useAuth()
    const [step, setStep] = useState(0)

    const { stripeModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)

    const stripeIntegration = integrations(team).find((i) => i.id === 'stripe')

    const handleStripeConnect = async () => {
        try {
            const link = await getStripeConnectLink({ team, auth })
            const firstTime = !team?.stripe?.completed
            
            trackEvent({
                name: firstTime ? 'stripe_started' : 'stripe_changed',
                metadata: {
                    date: moment().format('DD/MM/YYYY HH:mm'),
                }
            }, posthog)
            window.open(link?.url, '_blank')

            dispatch(closeModal('stripeModalVisible'))
        } catch (error) {
            message.error('Ocurrió un error al conectar con Stripe')
        }
    }

    useEffect(() => {
        if (stripeModalVisible) {
            addParamToUrl('open', 'stripe')
        }
    }, [stripeModalVisible])

    const toRender = () => {
        if (!stripeIntegration?.completed) {
            switch (step) {
                case 0:
                    return (
                        <IntegrationOnboarding
                            logo={stripeIntegration?.logo ?? ''}
                            title="Conecta Stripe con Gigstack"
                            description="Cobra y factura automáticamente con Stripe"
                            bullets={[
                                { type: 'positive', description: 'Facturar automáticamente tus ventas' },
                                { type: 'positive', description: 'Crear links de pago personalizados' },
                                { type: 'positive', description: 'Facturación recurrente con pagos en Stripe' },
                                { type: 'positive', description: 'Sincroniza automáticamente tus ventas' },
                            ]}
                            IntegrationFooter={
                                <Row justify="space-between" style={{ padding: '20px 24px 20px 24px' }}>
                                    <Row>
                                        {/** TODO: Agregar funcionalidad */}
                                        <Button
                                            icon={<Question className="icon clickable" weight="regular" size={16} />}
                                        >
                                            <Typography.Text className="mediumparagraphbold">
                                                ¿Cómo funciona?
                                            </Typography.Text>
                                        </Button>
                                    </Row>
                                    <Row>
                                        <Button style={{ marginRight: '10px' }} onClick={() => setStep(1)}>
                                            <Typography.Text className="mediumparagraphbold">
                                                Conectar vía API
                                            </Typography.Text>
                                        </Button>
                                        <Button type="primary" onClick={handleStripeConnect}>
                                            Conectar
                                        </Button>
                                    </Row>
                                </Row>
                            }
                        />
                    )
                case 1:
                    return <StripeApiConnection setStep={(s) => setStep(s)} />
            }
        }

        return <ManageStripeIntegration />
    }

    return (
        <Modal
            open={stripeModalVisible}
            className="integrationModal"
            onCancel={() => {
                setStep(0)
                dispatch(closeModal('stripeModalVisible'))
            }}
            title={null}
            footer={null}
        >
            {toRender()}
        </Modal>
    )
}
