import { Avatar, Button, Col, Divider, Row, Skeleton, Table, Tabs, Tag, Timeline, Typography, message } from 'antd'
import { collection, doc, getDoc, getFirestore, orderBy, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Payment, paymentSet } from '../../interfaces/paymentsDef'
import { useAuth, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire'
import ReceiptActions from '../components/ElementActions/ReceiptsActions'
import ProductsServicesTotals from '../components/ComponentIndividual/ProductsServicesTotals'
import {
    DownloadFile,
    DownloadInvoice,
    HandleEmailStatus,
    SearchParamInURL,
    generateCode,
    invoiceUsage,
    readableElementSource,
    returnCurrencyValue,
} from '../functions/helpers'
import moment from 'moment'
import { PaymentStatusTag } from '../components/UI/ElementStatus'
import {
    ArrowLeft,
    BookOpen,
    Calendar,
    CheckCircle,
    ClockCountdown,
    CreditCard,
    CursorClick,
    Download,
    Envelope,
    Eye,
    FilePdf,
    Flag,
    GitFork,
    HandCoins,
    Hash,
    IdentificationBadge,
    Link,
    MapTrifold,
    Path,
    Prohibit,
    StarFour,
} from '@phosphor-icons/react'
import { useDispatch, useSelector } from 'react-redux'
import { LogsRelatedToElement } from '../components/Logs/LogsRelatedToElement'
import { paymentForms, taxRegimes } from '../datasets/Fiscal'
import { openModal } from '../context/modalsSlice'
import { setData, setElementSelected } from '../context/dataSlice'
import { MetroSpinner } from 'react-spinners-kit'
import { usePermissions } from '../customHooks/usePermissions'
import InvoiceActions from '../components/ElementActions/InvoiceActions'
import PaymentsActions from '../components/ElementActions/PaymentsActions'

interface DetailItemProps {
    title: string
    value: React.ReactNode
    icon?: React.ReactNode
    onClick?: () => void
    style?: React.CSSProperties
}

export const DetailItem: React.FC<DetailItemProps> = ({ title, value, icon, onClick, style }) => {
    return (
        <div className="d-flex flex-row" style={{ marginBottom: '15px', justifyContent: 'start', ...style }}>
            <Typography.Text className="smallparagraph" style={{ color: '#71717A', minWidth: '35%' }}>
                {icon && <span style={{ marginRight: '8px' }}>{icon}</span>}
                {title}
            </Typography.Text>
            <div
                className="d-fle align-items-center"
                onClick={onClick}
                style={{ cursor: onClick ? 'pointer' : 'default', paddingLeft: '5px', flex: 1, color: '#0F172A' }}
            >
                {value}
            </div>
        </div>
    )
}

const ReceiptsViewDetail: React.FC = () => {
    const auth = useAuth()
    const fs = getFirestore()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { anchorLocation } = useSelector((state: any) => state.data)
    const [receiptData, setReceiptData] = useState<any>(null)
    const [, /*paymentData*/ setPaymentData] = useState<Payment | null>(null)
    const [clientData, setClientData] = useState<any>(null)
    const [downloading, setDownloading] = useState<any>([])
    const [invoiceRelated, setInvoiceRelated] = useState<any>(null)
    const [paymentsRelated, setPaymentsRelated] = useState<any>([])
    const [invoicesRelated, setInvoicesRelated] = useState<any>([])

    const id = SearchParamInURL('id')
    if (!id) {
        message.warning('No se encontró el id del Recibo')
        navigate('payments')
    }

    const receiptRef = doc(fs, 'receipts', id ?? '')
    const { data } = useFirestoreDocData(receiptRef)

    const { status: emailStatus, data: emailsData } = useFirestoreCollectionData(
        query(collection(fs, 'emails'), where('related', '==', `receipts/${id}`), orderBy('timestamp', 'desc')),
    )
    const [cronogram, setCronogram] = useState<any>([
        {
            id: 'emails_loading',
            timestamp: moment().valueOf(),
            dot: <MetroSpinner size={18} color="#8666FF" />,
            children: (
                <div className="d-flex flex-column">
                    <Typography.Text className="smallparagraphbold">
                        Correos
                        <Typography.Text className="smallparagraphbold primary">enviados</Typography.Text>
                    </Typography.Text>
                    <Typography.Text className="smallparagraph">
                        {moment(data?.timestamp).format('DD MMM YY HH:mm')}
                    </Typography.Text>
                </div>
            ),
        },
    ])

    //const { team } = useSelector((state: any) => state.team)
    //const { user } = useSelector((state: any) => state.user)
    const { canCreateReceipt } = usePermissions()

    useEffect(() => {
        if (emailStatus === 'success' && emailsData) {
            console.log(emailsData)

            const newCronogram = [
                {
                    id: 'creation',
                    timestamp: data?.timestamp,
                    dot: <HandCoins size={18} weight="regular" className="icon" />,
                    children: (
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallparagraphbold">
                                Recibo creado
                                <Typography.Text className="smallparagraphbold primary">
                                    {'\u00A0' + readableElementSource(data?.from ?? 'manual')}
                                </Typography.Text>
                            </Typography.Text>
                            <Typography.Text className="smallparagraph">
                                {moment(data?.timestamp).format('DD MMM YY HH:mm')}
                            </Typography.Text>
                        </div>
                    ),
                },
                ...(data?.status === 'completed' && data?.completedAt
                    ? [
                          {
                              id: 'completion',
                              timestamp: data.completedAt,
                              dot: <Flag size={18} weight="regular" className="icon" />,
                              children: (
                                  <div className="d-flex flex-column">
                                      <Typography.Text className="smallparagraphbold">
                                          Recibo completado
                                          <Typography.Text className="smallparagraphbold primary">
                                              {'\u00A0'}exitosamente
                                          </Typography.Text>
                                      </Typography.Text>
                                      <Typography.Text className="smallparagraph">
                                          {moment(data.completedAt).format('DD MMM YY HH:mm')}
                                      </Typography.Text>
                                  </div>
                              ),
                          },
                      ]
                    : []),
                ...emailsData.map((email) => ({
                    id: email.id,
                    timestamp: email.timestamp,
                    dot: <Envelope size={18} className="icon" />,
                    children: (
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallparagraphbold">
                                Enviado a
                                <Typography.Text className="smallparagraphbold primary">
                                    {'\u00A0' + email.to.join(', ')}
                                </Typography.Text>
                            </Typography.Text>
                            <Typography.Text className="smallparagraph">
                                {moment(email.timestamp).format('DD MMM YY HH:mm')}
                            </Typography.Text>
                        </div>
                    ),
                })),
            ].sort((a: any, b: any) => b.timestamp - a.timestamp)

            setCronogram(newCronogram)
        }
    }, [emailStatus, emailsData, data?.timestamp, data?.from, data?.status, data?.completedAt])

    const back = () => {
        if (anchorLocation) return navigate(anchorLocation)
        else return window.history.back()
    }

    useEffect(() => {
        const fetchReceiptData = async () => {
            try {
                const receiptDocRef = doc(fs, 'receipts', id as string)
                const receiptDocSnapshot = await getDoc(receiptDocRef)
                if (receiptDocSnapshot.exists()) {
                    const receiptDetails = receiptDocSnapshot.data() // Cambio aquí

                    setReceiptData(receiptDetails as any)
                } else {
                    message.error('No se encontró el recibo')
                }
            } catch (error) {
                message.error('Error al obtener los detalles del recibo')
            }
        }

        const fetchPaymentData = async () => {
            try {
                for (const pi of receiptData?.payments ?? []) {
                    const paymentDocRef = doc(fs, 'payments', pi as string)
                    const paymentDocSnapshot = await getDoc(paymentDocRef)
                    if (paymentDocSnapshot.exists()) {
                        const paymentDetails = paymentDocSnapshot.data()
                        setPaymentData(paymentSet(paymentDetails ?? {}))
                    } else {
                        message.error('No se encontró el pago')
                    }
                }
            } catch (error) {}
        }

        fetchPaymentData()
        fetchReceiptData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, fs])

    useEffect(() => {
        console.log('fetching client data')

        const fetchClientData = async () => {
            if (clientData) return
            if (data?.clientId ?? data?.clientID) {
                const clientRef = doc(fs, 'clients', data?.clientId ?? data?.clientID ?? data?.client?.id ?? '')
                const clientDocSnapshot = await getDoc(clientRef)

                if (clientDocSnapshot.exists()) {
                    setClientData(clientDocSnapshot.data())
                }
            }
        }

        fetchClientData()
    }, [clientData, data?.client?.id, data?.clientID, data?.clientId, fs])

    useEffect(() => {
        const fetchInvoiceRelated = async () => {
            if (data?.status !== 'completed' || !data?.invoice || invoiceRelated) return
            const invoiceRef = doc(fs, 'invoices', data?.invoice ?? '')
            const invoiceDocSnapshot = await getDoc(invoiceRef)
            if (invoiceDocSnapshot.exists()) {
                setInvoiceRelated(invoiceDocSnapshot.data())
            }
        }

        fetchInvoiceRelated()
    }, [data?.status, data?.invoice, fs, invoiceRelated?.timestam, invoiceRelated])

    useEffect(() => {
        const bringRelations = async () => {
            if (
                receiptData?.payments &&
                receiptData?.payments.length > 0 &&
                paymentsRelated.length !== receiptData?.payments.length
            ) {
                console.log(receiptData.payments)

                for (const pi of receiptData.payments) {
                    console.log(pi)

                    const paymentRef = doc(fs, 'payments', pi as string)
                    const paymentDocSnapshot = await getDoc(paymentRef)
                    console.log(paymentDocSnapshot.data())

                    if (paymentDocSnapshot.exists()) {
                        setPaymentsRelated((prev: any) => [...prev, paymentDocSnapshot.data()])
                        console.log('cronograma')

                        setCronogram((prev: any) => [
                            ...prev,
                            {
                                id: `payment_${pi}`,
                                timestamp: paymentDocSnapshot.data()?.timestamp,
                                dot: <CreditCard size={18} weight="regular" className="icon" />,
                                children: (
                                    <div className="d-flex flex-column">
                                        <Typography.Text className="smallparagraphbold">
                                            Pago relacionado
                                            <Typography.Text
                                                className="smallparagraphbold primary"
                                                style={{ marginLeft: '5px' }}
                                            >
                                                {returnCurrencyValue(
                                                    paymentDocSnapshot.data()?.amount / 100,
                                                    paymentDocSnapshot.data()?.currency,
                                                )}
                                            </Typography.Text>
                                        </Typography.Text>
                                        <Typography.Text className="smallparagraph">
                                            {moment(paymentDocSnapshot.data()?.timestamp).format('DD MMM YY HH:mm')}
                                        </Typography.Text>
                                    </div>
                                ),
                            },
                        ])
                    }
                }
            }
            if (
                receiptData?.invoices &&
                receiptData?.invoices.length > 0 &&
                invoicesRelated.length !== receiptData?.invoices.length
            ) {
                for (const ii of receiptData.invoices) {
                    const invoiceRef = doc(fs, 'invoices', ii as string)
                    const invoiceDocSnapshot = await getDoc(invoiceRef)
                    if (invoiceDocSnapshot.exists()) {
                        setInvoicesRelated((prev: any) => [...prev, invoiceDocSnapshot.data()])
                        setCronogram((prev: any) => [
                            ...prev,
                            {
                                id: `invoice_${ii}`,
                                timestamp: invoiceDocSnapshot.data()?.timestamp,
                                dot: <FilePdf size={18} weight="regular" className="icon" />,
                                children: (
                                    <div className="d-flex flex-column">
                                        <Typography.Text className="smallparagraphbold">
                                            Factura relacionada
                                            <Typography.Text
                                                className="smallparagraphbold primary"
                                                style={{ marginLeft: '5px' }}
                                            >
                                                {invoiceDocSnapshot.data()?.series}-
                                                {invoiceDocSnapshot.data()?.folio_number}
                                            </Typography.Text>
                                        </Typography.Text>
                                        <Typography.Text className="smallparagraph">
                                            {moment(invoiceDocSnapshot.data()?.timestamp).format('DD MMM YY HH:mm')}
                                        </Typography.Text>
                                    </div>
                                ),
                            },
                        ])
                    }
                }
            }
        }
        bringRelations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receiptData?.id])

    const DetailsTab = () => {
        return (
            <div className="d-flex flex-column">
                <Typography.Text className="smallheader">Detalles</Typography.Text>
                <Divider />
                <Table<any>
                    columns={[
                        {
                            title: 'Producto/Servicio',
                            dataIndex: 'name',
                            key: 'name',
                            render: (text) => (
                                <Typography.Text
                                    className="smallparagraph"
                                    style={{
                                        color: '#416D60',
                                    }}
                                >
                                    {text}
                                </Typography.Text>
                            ),
                        },
                        {
                            title: 'Cantidad',
                            dataIndex: 'quantity',
                            key: 'quantity',
                            render: (text) => <Typography.Text className="smallparagraph">{text}</Typography.Text>,
                        },
                        {
                            title: 'Subtotal',
                            dataIndex: 'total',
                            key: 'total',
                            render: (text) => (
                                <Typography.Text className="smallparagraph">
                                    {returnCurrencyValue(text, data?.currency)}
                                </Typography.Text>
                            ),
                        },
                        {
                            title: 'Total',
                            dataIndex: 'total',
                            key: 'total',
                            render: (text, record) => (
                                <Typography.Text className="smallparagraph">
                                    {returnCurrencyValue(Number(text) * Number(record.quantity), data?.currency)}
                                </Typography.Text>
                            ),
                        },
                    ]}
                    bordered={false}
                    dataSource={data.items}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 3,
                    }}
                    style={{ marginBottom: '15px' }}
                />
                <Row>
                    <Col xs={12}></Col>
                    <Col xs={12}>
                        <ProductsServicesTotals incomingItems={data?.items} boxed={false} />
                    </Col>
                </Row>

                <Row style={{ margin: '25px 0px', borderTop: '1px solid #E0E0E0', paddingTop: '15px' }}>
                    <Col xs={12}>
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallheader">Cronograma</Typography.Text>

                            <Timeline
                                style={{ margin: '30px 15px' }}
                                // TODO Add more items to timeline
                                items={[...cronogram].sort((a: any, b: any) => b.timestamp - a.timestamp)}
                            />
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallheader">Archivos / Enlaces</Typography.Text>
                            <DetailItem
                                icon={<FilePdf />}
                                title="Recibo en PDF"
                                value={
                                    <Typography.Text
                                        className="smallparagraph clickable"
                                        onClick={() => {
                                            if (data?.pdf) {
                                                setDownloading((prev: string[]) => [...prev, 'pdf'])
                                                DownloadFile({
                                                    url: data?.pdf,
                                                    name: `Recibo de venta "${data?.id}"`,
                                                    type: 'pdf',
                                                }).finally(() => {
                                                    setDownloading((prev: string[]) =>
                                                        prev.filter((item) => item !== 'pdf'),
                                                    )
                                                })
                                            }
                                        }}
                                    >
                                        Recibo de venta "{data?.id}"{' '}
                                        <Download weight="regular" className="icon" style={{ marginLeft: '5px' }} />
                                    </Typography.Text>
                                }
                            />
                            <DetailItem
                                icon={<CursorClick />}
                                title="Link para autofactura"
                                value={
                                    <Typography.Text
                                        className="smallparagraph clickable"
                                        onClick={() => {
                                            window.open(data?.url, '_blank')
                                        }}
                                    >
                                        {data?.url}
                                    </Typography.Text>
                                }
                            />
                            {invoiceRelated && (
                                <DetailItem
                                    icon={<FilePdf />}
                                    title="Factura relacionada"
                                    value={
                                        <Typography.Text
                                            className="smallparagraph clickable"
                                            onClick={() => {
                                                if (invoiceRelated?.pdf) {
                                                    setDownloading((prev: string[]) => [...prev, 'pdf'])
                                                    DownloadInvoice({
                                                        invoice: invoiceRelated,
                                                        type: 'pdf',
                                                        downloadType: 'pdf',
                                                        authUser: auth.currentUser,
                                                        // customDownloading: (id: string) => {
                                                        //     setDownloading((prev: string[]) => [...prev, id])
                                                        // },
                                                        customFileName: `Factura "${invoiceRelated?.series}-${invoiceRelated?.folio_number}"`,
                                                    }).finally(() => {})
                                                }
                                            }}
                                        >
                                            Factura {invoiceRelated?.series}-{invoiceRelated?.folio_number}
                                            <Download weight="regular" className="icon" style={{ marginLeft: '5px' }} />
                                        </Typography.Text>
                                    }
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const RelationsTab = () => {
        return (
            <div className="d-flex flex-column">
                <Typography.Text className="smallheader">
                    Relaciones{' '}
                    <span className="smallparagraph" style={{ marginLeft: '5px' }}>
                        ({paymentsRelated.length + invoicesRelated.length})
                    </span>
                </Typography.Text>
                <Divider />
                <Row>
                    <Col xs={24}>
                        <Typography.Text className="smallheader">Pagos relacionados</Typography.Text>
                        {paymentsRelated.length > 0 && (
                            <Table
                                columns={[
                                    {
                                        title: 'Pago',
                                        dataIndex: 'id',
                                        key: 'id',
                                        render: (text, payment: any) => (
                                            <Tag
                                                className="clickable"
                                                onClick={() => {
                                                    window.open(`paymentDetails?id=${payment?.id}`, '_blank')
                                                }}
                                                icon={
                                                    <CreditCard
                                                        weight="regular"
                                                        className="icon"
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                }
                                                style={{ border: 'none' }}
                                            >
                                                <Typography.Text className="smallparagraph">{text}</Typography.Text>
                                            </Tag>
                                        ),
                                    },
                                    {
                                        title: 'Total',
                                        dataIndex: 'amount',
                                        key: 'amount',
                                        render: (text, payment: any) => (
                                            <Typography.Text className="smallparagraph">
                                                {returnCurrencyValue(text / 100, payment?.currency)}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Fecha',
                                        dataIndex: 'timestamp',
                                        key: 'timestamp',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">
                                                {moment(text).format('DD/MM/YY HH:mm')}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Estado',
                                        dataIndex: 'status',
                                        key: 'status',
                                        render: (text, payment: any) => (
                                            <Typography.Text className="smallparagraph">
                                                {PaymentStatusTag({ record: payment, shape: 'circle' })}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Método de pago',
                                        dataIndex: 'payment_form',
                                        key: 'payment_form',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">
                                                {paymentForms.find((pm) => pm.value === text)?.label}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: '',
                                        key: 'actions',
                                        render: (text, payment: any) => <PaymentsActions payment={payment} />,
                                    },
                                ]}
                                dataSource={paymentsRelated}
                                pagination={false}
                                bordered={false}
                            />
                        )}
                    </Col>
                    <Col xs={24} style={{ marginTop: '15px' }}>
                        <Typography.Text className="smallheader">Facturas relacionadas</Typography.Text>
                        {invoicesRelated.length > 0 && (
                            <Table
                                columns={[
                                    {
                                        title: 'Factura',
                                        dataIndex: 'uuid',
                                        key: 'uuid',
                                        render: (text, invoice: any) => (
                                            <Tag
                                                className="clickable"
                                                icon={
                                                    <Link
                                                        weight="regular"
                                                        className="icon"
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                }
                                                style={{ border: 'none' }}
                                                onClick={() => {
                                                    window.open(`invoiceDetails?id=${invoice?.id}`, '_blank')
                                                }}
                                            >
                                                <Typography.Text className="smallparagraph">{text}</Typography.Text>
                                            </Tag>
                                        ),
                                        fixed: 'left',
                                    },
                                    {
                                        title: 'Total',
                                        dataIndex: 'total',
                                        key: 'total',
                                        render: (text, invoice: any) => (
                                            <Typography.Text className="smallparagraph">
                                                {returnCurrencyValue(text, invoice?.currency)}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Fecha de emisión',
                                        dataIndex: 'timestamp',
                                        key: 'timestamp',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">
                                                {moment(text).format('DD/MM/YY HH:mm')}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Estado',
                                        dataIndex: 'status',
                                        key: 'status',
                                        render: (text, invoice: any) =>
                                            invoice?.status === 'valid' ? <CheckCircle /> : <Prohibit />,
                                    },
                                    {
                                        title: 'Serie/Folio',
                                        dataIndex: 'series',
                                        key: 'series',
                                        render: (text, invoice: any) => (
                                            <Typography.Text className="smallparagraph">
                                                {invoice?.series}-{invoice?.folio_number}
                                            </Typography.Text>
                                        ),
                                    },
                                    {
                                        title: 'Método de pago',
                                        dataIndex: 'payment_method',
                                        key: 'payment_method',
                                        render: (text) => (
                                            <Typography.Text className="smallparagraph">{text}</Typography.Text>
                                        ),
                                    },
                                    {
                                        title: '',
                                        key: 'actions',
                                        render: (text, invoice: any) => <InvoiceActions invoice={invoice} />,
                                    },
                                ]}
                                dataSource={invoicesRelated}
                                pagination={false}
                                bordered={false}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        )
    }

    const EmailsTab = () => {
        return (
            <div className="d-flex flex-column">
                <Typography.Text className="mediumparagraphbold">Correos</Typography.Text>
                <Divider />
                <Typography.Text className="smallparagraph">
                    Los eventos mostrados son registros del sistema de envío de correos. Si el destinatario tiene
                    configurado un bloqueador de cookies, es posible que no se registren todos los eventos.
                </Typography.Text>
                <Row style={{ marginTop: '15px' }}>
                    {emailsData.length > 0 &&
                        emailsData.map((email: any) => (
                            <Col
                                xs={24}
                                key={email.id ?? generateCode(6)}
                                style={{ marginBottom: '35px' }}
                                onClick={() => {
                                    console.log(email)
                                }}
                            >
                                <Row justify="space-between" align="middle">
                                    <Row>
                                        <Avatar
                                            style={{ backgroundColor: '#A7FF99', color: 'black', marginRight: '10px' }}
                                        >
                                            <Envelope weight="regular" className="icon" />
                                        </Avatar>
                                        <div className="d-flex flex-column">
                                            <Row wrap>
                                                {email.to?.map((to: string) => {
                                                    return (
                                                        <Tag color="#F4F4F5">
                                                            <Typography.Text
                                                                className="smallparagraph"
                                                                style={{ margin: 0, padding: 0 }}
                                                            >
                                                                {to}
                                                            </Typography.Text>
                                                        </Tag>
                                                    )
                                                })}
                                            </Row>
                                            <Typography.Text className="smallparagraph" style={{ marginTop: '10px' }}>
                                                <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Asunto:</span>{' '}
                                                {email.subject}
                                            </Typography.Text>
                                            <Typography.Text className="smallparagraph" style={{ marginTop: '5px' }}>
                                                {moment(email.timestamp).format('DD/MM/YY HH:mm')}
                                            </Typography.Text>
                                        </div>
                                    </Row>
                                    <Tag>{HandleEmailStatus(email.lastEvent).message} </Tag>
                                </Row>
                            </Col>
                        ))}
                </Row>
            </div>
        )
    }

    const EventsTab = () => {
        return (
            <div className="d-flex flex-column">
                <Typography.Text className="mediumparagraphbold">Eventos</Typography.Text>
                <Divider />
                <Row>
                    <Col xs={24}>
                        <LogsRelatedToElement id={id} v={2} />
                    </Col>
                </Row>
            </div>
        )
    }
    return (
        <Row>
            <Col
                xs={24}
                md={8}
                className="d-flex flex-column"
                style={{
                    backgroundColor: '#FDFDFD',
                    padding: '20px 0px 40px 0px',
                    borderRadius: '0px',
                    borderRight: '1px solid #E0E0E0',
                }}
            >
                <Row className="" style={{ marginBottom: '10px' }}>
                    <Button type="text" onClick={back}>
                        <ArrowLeft size={16} />
                        <Typography.Text className="smallparagraphbold" style={{ marginLeft: '4px' }}>
                            Atrás
                        </Typography.Text>
                    </Button>
                </Row>
                <div className="d-flex flex-column" style={{ marginLeft: '17px', padding: '10px' }}>
                    <Row justify="space-between" align="middle">
                        <Typography.Text className="bigparagraphbold" style={{ color: 'black', fontSize: '23px' }}>
                            {returnCurrencyValue(data?.total, data?.currency?.toUpperCase())}
                        </Typography.Text>
                        {data && <ReceiptActions receipt={data} />}
                    </Row>
                    <Row
                        style={{ marginTop: '20px' }}
                        align="middle"
                        className="clickable"
                        onClick={() => {
                            window.open(`clientDetails?id=${data?.clientId ?? data?.clientID}`, '_blank')
                        }}
                    >
                        <Avatar style={{ backgroundColor: '#A7FF99', color: 'black' }}>
                            {data?.client?.name?.charAt(0)}
                        </Avatar>
                        <div
                            className="d-flex flex-column"
                            style={{ marginLeft: '10px', flex: 1, paddingRight: '10px' }}
                        >
                            <Typography.Text className="smallparagraphbold" style={{ color: 'black' }}>
                                {data?.client?.legalName ?? data?.client?.legal_name ?? data?.client?.name}
                            </Typography.Text>
                            <Typography.Text className="smallparagraph" style={{ marginTop: '5px', color: '#416D60' }}>
                                {data?.client?.email} | {data?.client?.rfc}
                            </Typography.Text>
                        </div>
                        <Eye style={{ marginLeft: 'auto', marginRight: '10px' }} />
                    </Row>
                    <Divider />
                    <div className="d-flex flex-column">
                        <DetailItem
                            icon={<Hash weight="regular" className="icon" />}
                            title="ID del recibo"
                            value={
                                <Typography.Text
                                    className="smallparagraphbold"
                                    style={{ color: 'black' }}
                                    copyable={false}
                                >
                                    {data?.id}
                                </Typography.Text>
                            }
                        />

                        <DetailItem
                            icon={<ClockCountdown weight="regular" className="icon" />}
                            title="Estado"
                            value={
                                <Typography.Text className="smallparagraph" style={{ color: 'black' }} copyable={false}>
                                    {data?.status === 'pending' ? 'Pendiente' : 'Completado'}
                                </Typography.Text>
                            }
                        />

                        <DetailItem
                            icon={<Link weight="regular" className="icon" />}
                            title="Link de autofactura"
                            value={
                                <Typography.Text className="smallparagraph" style={{ color: 'black' }} copyable={true}>
                                    {data?.url}
                                </Typography.Text>
                            }
                        />

                        <DetailItem
                            icon={<HandCoins weight="regular" className="icon" />}
                            title="Método de Pago"
                            value={
                                <Typography.Text className="smallparagraph" style={{ color: 'black' }} copyable={false}>
                                    {paymentForms.find((form) => form.value === data?.payment_form)?.label}
                                </Typography.Text>
                            }
                        />

                        <DetailItem
                            icon={<Calendar weight="regular" className="icon" />}
                            title="Válido Hasta"
                            value={
                                <Typography.Text className="smallparagraph" style={{ color: 'black' }} copyable={false}>
                                    {moment(data?.validUntil).format('DD/MM/YY hh:mm A')}
                                </Typography.Text>
                            }
                        />

                        <DetailItem
                            title="Fuente"
                            value={
                                <Tag color="#D3D9FF">
                                    <Typography.Text className="smallparagraphbold blue">
                                        {readableElementSource(data?.from ?? '')}
                                    </Typography.Text>
                                </Tag>
                            }
                            icon={<GitFork weight="regular" className="icon" />}
                        />
                    </div>
                    <Divider />
                    <div className="d-flex flex-column">
                        <Typography.Text className="smallparagraphbold" style={{ marginBottom: '10px' }}>
                            Cliente
                        </Typography.Text>
                        <DetailItem
                            icon={<IdentificationBadge weight="regular" className="icon" />}
                            title="RFC | Tax Id"
                            value={
                                <Typography.Text className="smallparagraph" style={{ color: 'black' }} copyable={false}>
                                    {clientData?.rfc || 'Sin información'}
                                </Typography.Text>
                            }
                        />

                        <DetailItem
                            icon={<MapTrifold weight="regular" className="icon" />}
                            title="Código Postal"
                            value={
                                <Typography.Text className="smallparagraph" style={{ color: 'black' }} copyable={false}>
                                    {clientData?.address?.zip ?? 'Sin información'}
                                </Typography.Text>
                            }
                        />

                        <DetailItem
                            icon={<Flag weight="regular" className="icon" />}
                            title="País"
                            value={
                                <Typography.Text className="smallparagraph" style={{ color: 'black' }} copyable={false}>
                                    {clientData?.address?.country ?? 'Sin información'}
                                </Typography.Text>
                            }
                        />
                        <DetailItem
                            icon={<BookOpen weight="regular" className="icon" />}
                            title="Régimen Fiscal"
                            value={
                                <Typography.Text className="smallparagraph" style={{ color: 'black' }} copyable={false}>
                                    {taxRegimes.find((regime) => regime.value === clientData?.tax_system)?.label ??
                                        'Sin información'}
                                </Typography.Text>
                            }
                        />
                        <DetailItem
                            icon={<BookOpen weight="regular" className="icon" />}
                            title="Uso CFDI"
                            value={
                                <Typography.Text className="smallparagraph" style={{ color: 'black' }} copyable={false}>
                                    {invoiceUsage.find((usage) => usage.value === clientData?.use)?.label}
                                </Typography.Text>
                            }
                        />
                    </div>
                    <Row justify="end" align="middle" style={{ marginTop: '15px', gap: '5px' }}>
                        {data?.status === 'pending' && (
                            <Button
                                onClick={() => {
                                    dispatch(openModal('createInvoiceFromReceiptModalVisible'))
                                    dispatch(
                                        setData({
                                            item: 'receipt',
                                            data: data,
                                        }),
                                    )
                                }}
                            >
                                Completar recibo de venta
                            </Button>
                        )}
                        <Button
                            onClick={() => {
                                if (!canCreateReceipt.hasPermission)
                                    return message.warning(
                                        canCreateReceipt.reason ?? 'No tienes permisos para enviar recibos',
                                    )
                                const receiptClient = clientData
                                dispatch(openModal('sendByEmailModalVisible'))
                                dispatch(
                                    setElementSelected({
                                        element: 'toEmail',
                                        data: { ...data, elementType: 'receipts' },
                                    }),
                                )
                                dispatch(
                                    setData({
                                        item: 'client',
                                        data: receiptClient,
                                    }),
                                )
                            }}
                            icon={<Envelope weight="regular" className="icon" />}
                        />
                        <Button
                            disabled={(downloading ?? [])?.find(
                                (l: any) => l.id === data?.id && l.action === `download`,
                            )}
                            onClick={async () => {
                                console.log('downloading', downloading)

                                DownloadFile({
                                    type: 'pdf',
                                    url: data?.pdf,
                                    name: 'Recibo-de-venta',
                                    setdownloading: (r: any) => {
                                        setDownloading((past: any) => [
                                            ...past,
                                            {
                                                id: data?.id,
                                                action: `download`,
                                            },
                                        ])
                                    },
                                })
                                await new Promise((resolve) => setTimeout(resolve, 1000))
                                setDownloading(
                                    downloading.filter((l: any) => l.id !== data?.id && l.action !== `download`),
                                )
                            }}
                            icon={
                                downloading.find((l: any) => l.id === data?.id && l.action === `download`) ? (
                                    <MetroSpinner size={18} color="#8666FF" />
                                ) : (
                                    <Download weight="regular" className="icon" />
                                )
                            }
                        />
                    </Row>
                </div>
            </Col>
            <Col xs={24} md={16} style={{ paddingLeft: '30px' }}>
                {data ? (
                    <Tabs
                        defaultActiveKey="2"
                        // items={[Path, GitFork, Envelope, StarFour].map((Icon, i) => {
                        //     const id = String(i + 1)
                        //     return {
                        //         key: id,
                        //         label: `Tab ${id}`,
                        //         children: `Tab ${id}`,
                        //         icon: <Icon />,
                        //     }
                        // })}
                        items={[
                            {
                                key: 'details',
                                label: <Typography.Text className="mediumparagraphbold">Resumen</Typography.Text>,
                                children: <DetailsTab />,
                                icon: <Path weight="regular" className="icon" />,
                            },
                            {
                                key: 'relations',
                                label: <Typography.Text className="mediumparagraphbold">Relaciones</Typography.Text>,
                                children: <RelationsTab />,
                                icon: <GitFork weight="regular" className="icon" />,
                            },
                            {
                                key: 'emails',
                                label: <Typography.Text className="mediumparagraphbold">Correos</Typography.Text>,
                                children: <EmailsTab />,
                                icon: <Envelope weight="regular" className="icon" />,
                            },
                            {
                                key: 'events',
                                label: <Typography.Text className="mediumparagraphbold">Eventos</Typography.Text>,
                                children: <EventsTab />,
                                icon: <StarFour weight="regular" className="icon" />,
                            },
                        ]}
                    />
                ) : (
                    <Skeleton active />
                )}
            </Col>
        </Row>
    )

    /*
    const ActionsHeader = () => {
        return (
            <Col xs={24}>
                <div
                    style={{
                        borderRadius: '10px',
                        backgroundColor: '#F9FAFC',
                        padding: '10px',
                    }}
                >
                    <Row justify="space-between" align="middle">
                        <Typography.Text className="smallparagraphbold">Acciones</Typography.Text>
                        <ReceiptActions
                            receipt={{ ...data, emails: typeof data?.emails === 'object' ? [] : data?.emails }}
                        />
                    </Row>
                </div>
            </Col>
        )
    }
    const ProductAndServices = () => {
        return (
            <Col xs={24} lg={24} style={{ marginTop: '25px' }}>
                <div className="d-flex flex-column">
                    <Row align="middle">
                        <Typography.Text className="smallparagraph descriptions">Productos / Servicios</Typography.Text>
                        <div
                            style={{ height: '1px', borderBottom: '1px solid #D8DAE5', flex: 1, marginLeft: '15px' }}
                        ></div>
                    </Row>
                    <ItemsSelection incomingServices={data?.items} disabled={true} hideTitle={true} />
                    <Row justify="end">
                        <ProductsServicesTotals incomingItems={data?.items} boxed={false} />
                    </Row>
                </div>
            </Col>
        )
    }

    const ReceiptDetailsGeneral = () => {
        let details = [
            {
                key: 'id',
                label: <Typography.Text className="smallparagraphbold">ID del recibo</Typography.Text>,
                children: <Typography.Text className="smallparagraph ">{data?.id}</Typography.Text>,
            },

            {
                key: 'currency',
                label: <Typography.Text className="smallparagraphbold">Moneda</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">{data?.currency?.toUpperCase()}</Typography.Text>
                ),
            },
            {
                key: 'exchange_rate',
                label: <Typography.Text className="smallparagraphbold">Tipo de cambio</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        1 {data?.currency} = {data?.exchange} MXN
                    </Typography.Text>
                ),
            },
            {
                key: 'total',
                label: <Typography.Text className="smallparagraphbold">Total</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {returnCurrencyValue(data?.total)} {data?.currency}{' '}
                    </Typography.Text>
                ),
            },

            {
                key: 'source',
                label: <Typography.Text className="smallparagraphbold">Fuente</Typography.Text>,
                children: (
                    <Tag color="#D3D9FF">
                        <Typography.Text className="smallparagraphbold blue">
                            {readableElementSource(data?.from ?? '')}
                        </Typography.Text>
                    </Tag>
                ),
            },

            {
                key: 'date',
                label: <Typography.Text className="smallparagraphbold">Fecha</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {moment(data?.timestamp).format('DD MMMM YYYY HH:mm')}{' '}
                    </Typography.Text>
                ),
            },
            {
                key: 'URL',
                label: <Typography.Text className="smallparagraphbold">URL de AutoFactura</Typography.Text>,
                children: (
                    <Typography.Text
                        className="smallparagraph "
                        copyable={{ icon: <Copy weight="regular" className="icon" /> }}
                    >
                        {data?.url}
                    </Typography.Text>
                ),
            },
        ]

        if (data?.global)
            details.push({
                key: 'global',
                label: <Typography.Text className="smallparagraphbold">Factura global</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">
                        {data?.global?.periodicity === 'month' ? 'Mensual' : data?.global?.periodicity}{' '}
                        {moment(data?.global?.months, 'MM').format('MMMM')} {data?.global?.year}{' '}
                    </Typography.Text>
                ),
            })
        if (data?.hilosResponse?.conversation) {
            details.push({
                key: 'hilos',
                label: <Typography.Text className="smallparagraphbold">Conversación en Hilos</Typography.Text>,
                children: (
                    <Typography.Text className="smallparagraph ">{data?.hilosResponse?.conversation} </Typography.Text>
                ),
            })
        }

        if (data?.from === 'manual' || !data?.from) {
            details.push({
                ...createdByObject({ resource: data, team, user }),
            })
        }

        return (
            <Col xs={24} lg={24}>
                <div
                    style={{
                        width: '100%',
                        padding: '20px',
                        borderRadius: '10px',
                        background:
                            'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                    }}
                    className="d-flex flex-column"
                >
                    <Descriptions
                        column={{ xs: 1, sm: 1, md: 1, lg: 3, xl: 3, xxl: 3 }}
                        title={
                            <Row justify="space-between" align="middle">
                                <Typography.Text className="mediumparagraphbold">
                                    Información general del recibo de venta
                                </Typography.Text>
                                <ReceiptStatusTag shape="tag" record={receiptDefSet(data ?? {})} />
                            </Row>
                        }
                        layout="horizontal"
                        size="small"
                        items={details}
                    />
                </div>
            </Col>
        )
    }

    const EmailsRelated = () => {
        return (
            <Col xs={24}>
                <EmailsTable
                    customHeader={
                        <Row align="middle" style={{ marginBottom: '15px' }}>
                            <Typography.Text className="smallparagraph descriptions">Correos enviados</Typography.Text>
                            <div
                                style={{
                                    height: '1px',
                                    borderBottom: '1px solid #D8DAE5',
                                    flex: 1,
                                    marginLeft: '15px',
                                }}
                            ></div>
                        </Row>
                    }
                    emailsData={emailsData}
                    emailStatus={emailStatus}
                />
            </Col>
        )
    }
    return (
        <div>
            <HeaderForViewDetail data={data} title={`Recibo de venta por ${returnCurrencyValue(data?.total)}`} />

            <Row gutter={[16, 16]} style={{ padding: '20px 20px' }}>
                <Col xs={24}>
                    <Row>
                        <Avatar
                            style={{
                                verticalAlign: 'middle',
                                marginRight: '10px',
                                background:
                                    'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                            }}
                            size={40}
                        >
                            <Typography.Text className="smallparagraphbold">
                                {(data?.client?.legal_name ?? data?.client?.name ?? '?')[0]}
                            </Typography.Text>
                        </Avatar>
                        <div className="d-flex flex-column">
                            <Typography.Text className="smallheader">
                                {data?.client?.name ?? data?.client?.legal_name ?? ''}
                            </Typography.Text>
                            <Typography.Text className="smallparagraph descriptions">
                                {data?.client?.rfc} - {data?.client?.email}
                            </Typography.Text>
                            <Typography.Text className="smallparagraph descriptions">
                                {typeof data?.client?.address?.country === 'string'
                                    ? data?.client?.address?.country
                                    : (data?.client?.address?.country?.label ?? '')}{' '}
                                - C.P. {data?.client?.address?.zip}
                            </Typography.Text>
                        </div>
                    </Row>
                </Col>
                <ActionsHeader />
                <ReceiptDetailsGeneral />
                <ProductAndServices />
                <Col xs={24} lg={24}>
                    <Row align="middle" style={{ marginBottom: '15px' }}>
                        <Typography.Text className="smallparagraph descriptions">Recibos relacionados</Typography.Text>
                        <div
                            style={{ height: '1px', borderBottom: '1px solid #D8DAE5', flex: 1, marginLeft: '15px' }}
                        ></div>
                    </Row>
                    <RelationsHandler
                        title="Recibos relacionados"
                        viewType="table"
                        type="payments"
                        collection="receipts"
                        subcollection="payments"
                        item={optimizedPaymentDefSet(data ?? {})}
                        itemId={data?.id}
                    />
                </Col>
                <Col xs={24} lg={24}>
                    <Row align="middle" style={{ marginBottom: '15px' }}>
                        <Typography.Text className="smallparagraph descriptions">Pagos relacionados</Typography.Text>
                        <div
                            style={{ height: '1px', borderBottom: '1px solid #D8DAE5', flex: 1, marginLeft: '15px' }}
                        ></div>
                    </Row>
                    <RelationsHandler
                        title="Facturas relacionados"
                        viewType="table"
                        type="payments"
                        collection="invoices"
                        subcollection="payments"
                        item={optimizedPaymentDefSet(data ?? {})}
                        itemId={data?.id}
                    />
                </Col>

                <EmailsRelated />
                <Col xs={24} lg={24}>
                    <Row align="middle" style={{ marginBottom: '15px' }} className="clickable">
                        <Typography.Text className="smallparagraph descriptions">Eventos enviados</Typography.Text>
                        <div
                            style={{
                                height: '1px',
                                borderBottom: '1px solid #D8DAE5',
                                flex: 1,
                                marginLeft: '15px',
                            }}
                        ></div>
                    </Row>
                    <div
                        style={{
                            marginTop: '5px',
                            backgroundColor: '#fafbfd',
                            padding: '10px',
                            borderRadius: '15px',
                        }}
                    >
                        <LogsRelatedToElement id={id} />
                    </div>
                </Col>
            </Row>
        </div>
    )
    */
}

export default ReceiptsViewDetail
