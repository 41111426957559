import React, { useState } from 'react'
import { Space, Tooltip, message } from 'antd'
import EditAction from './SingleActions/EditAction'
import { useDispatch } from 'react-redux'
import { openModal } from '../../context/modalsSlice'
import { pushNewData, setData } from '../../context/dataSlice'
import { InternalItem, internalItemSet } from '../../../interfaces/internalItemDef'
import { Copy } from '@phosphor-icons/react'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import { generateCode } from '../../functions/helpers'
import { MetroSpinner } from 'react-spinners-kit'
import { usePermissions } from '../../customHooks/usePermissions'

const ServiceActions = ({ service }: { service?: InternalItem }) => {
    const dispatch = useDispatch()
    const { canCreateService } = usePermissions()
    const [loading, setLoading] = useState<string[]>([])
    return (
        <Space>
            {/* Add your components here */}
            {canCreateService.hasPermission && (
                <EditAction
                    action={function (): void {
                        if (!service) return
                        setLoading([...loading, service?.id ?? 'edit'])
                        dispatch(
                            setData({
                                item: 'service',
                                data: service,
                            }),
                        )
                        dispatch(
                            setData({
                                item: 'taxes',
                                data: service.taxes,
                            }),
                        )
                        dispatch(openModal('createServiceVisible'))
                        setLoading(loading.filter((l) => l !== (service?.id ?? 'edit')))
                    }}
                    loading={loading.includes(service?.id ?? 'edit')}
                />
            )}

            <Tooltip title="Duplicar">
                {loading.includes(`${service?.id ?? 'duplicate'}-duplicate`) ? (
                    <MetroSpinner size={16} color="#686769" />
                ) : (
                    <Copy
                        className="icon clickable"
                        size={19}
                        onClick={async () => {
                            try {
                                const newDoc = doc(getFirestore(), 'services', generateCode(10, 'service'))
                                setLoading([...loading, `${service?.id ?? 'duplicate'}-duplicate`])
                                const newService = internalItemSet({
                                    id: newDoc.id,
                                    ...service,
                                    name: `${service?.name ?? 'Nuevo servicio'} (copia)`,
                                })
                                dispatch(
                                    pushNewData({
                                        item: 'services',
                                        data: newService,
                                    }),
                                )
                                await setDoc(newDoc, newService)
                                await new Promise((resolve) => setTimeout(resolve, 500))
                                message.success('Servicio duplicado correctamente')
                                setLoading(loading.filter((l) => l !== `${service?.id ?? 'duplicate'}-duplicate`))
                            } catch (error: any) {
                                message.error(error?.message ?? 'Error al duplicar el servicio')
                            }
                        }}
                    />
                )}
            </Tooltip>
        </Space>
    )
}

export default ServiceActions
