import {
    ArrowUUpLeft,
    Bank,
    ClipboardText,
    CreditCard,
    DotsThree,
    File,
    Link,
    PaperPlaneTilt,
    PlusCircle,
    Prohibit,
    TextColumns,
    Trash,
    WhatsappLogo,
} from '@phosphor-icons/react'
import { Button, Dropdown, Popconfirm, Row, message } from 'antd'
import { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingDefinition } from '../../../interfaces/loadingDef'
import EmailActions from './SingleActions/EmailActions'
import CopyAction from './SingleActions/CopyAction'
import { closeModal, openModal, setElementSelected } from '../../context/modalsSlice'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useAuth } from 'reactfire'
import { ShareViaWhatsapp } from '../../functions/helpers'
import { usePermissions } from '../../customHooks/usePermissions'
import { setData } from '../../context/dataSlice'
import { MenuProps } from 'antd/lib'
import Typography from 'antd/es/typography/Typography'
import { clientDataHelper } from '../../../helpers/helperFunctions'
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { MarkAsPaidModal } from '../../pages/PaymentDetailsViewOld'

interface PaymentActionsProps {
    payment: any
    enabled?: boolean | null
    hide?: boolean | null
    from?: string
}

export const PAYMENT_ACTIONS_DEFINITION = {
    SEND_EMAIL: {
        action: async ({ payment, dispatch }: { payment: any; dispatch: any }) => {
            const paymentClient = await clientDataHelper(payment.clientId)
            dispatch(openModal('sendByEmailModalVisible'))
            dispatch(
                setElementSelected({
                    element: 'toEmail',
                    data: { ...payment, elementType: 'payments' },
                }),
            )
            dispatch(
                setData({
                    item: 'client',
                    data: paymentClient,
                }),
            )
        },
        icon: <PaperPlaneTilt size={18} />,
        label: 'Enviar por correo',
    },
    COPY: {
        action: ({ paymentId, from }: { paymentId: string; from: string }) => {
            const toCopy =
                from === 'paymentLink'
                    ? `https://pay.gigstack.pro/paymentLinks/${paymentId}`
                    : `https://gigstack.xyz/${paymentId}`

            navigator.clipboard.writeText(toCopy)
            message.success('Copiado al portapapeles')
        },
        icon: <ClipboardText size={18} />,
        label: 'Copiar enlace de pago',
    },
}

const PaymentsActions: FunctionComponent<PaymentActionsProps> = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { canCreatePayment, canCreateInvoice } = usePermissions()
    const { clients } = useSelector((state: any) => state.data)

    const [loading, setLoading] = useState<LoadingDefinition[]>([])
    const [, /*actionOnPaymen*/ setactionOnPayment] = useState(null)
    const [completePayment, setCompletePayment] = useState(false)
    const auth = useAuth()
    const { payment } = props

    if (props.hide) return <></>

    const thisPaymentHasAnyLoading = loading.find((l) => l.id === payment?.id) !== undefined

    // const ShareForWhatsApp = () => {
    //     return (
    //         <div
    //             style={{
    //                 cursor: 'pointer',
    //                 marginLeft: '5px',
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //             }}
    //         >
    //             <WhatsappLogo
    //                 size={18}
    //                 weight="regular"
    //                 className="icon"
    //                 onClick={() =>
    //                     ShareViaWhatsapp(
    //                         `Puedes realizar el pago por ${payment.amount} ${payment.currency} en el siguiente enlace! 😉 : ${payment.shortURL}`,
    //                     )
    //                 }
    //             />
    //         </div>
    //     )
    // }

    // const IssueAnInvoice = () => {
    //     return (
    //         <div
    //             style={{
    //                 cursor: 'pointer',
    //                 marginLeft: '5px',
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //             }}
    //         >
    //             <Tooltip title="Emitir factura">
    //                 <PlusCircle
    //                     size={18}
    //                     weight="regular"
    //                     className="icon"
    //                     onClick={() => {
    //                         const ctxClient = clients.find(
    //                             (c: any) =>
    //                                 c.id === payment?.client?.id ||
    //                                 c.id === payment?.clientId ||
    //                                 c.id === payment?.clientID,
    //                         )

    //                         dispatch(
    //                             setData({
    //                                 item: 'client',
    //                                 data: ctxClient || payment?.client,
    //                             }),
    //                         )
    //                         dispatch(
    //                             setData({
    //                                 item: 'selectedServices',
    //                                 data: payment?.items,
    //                             }),
    //                         )
    //                         dispatch(
    //                             setData({
    //                                 item: 'invoice',
    //                                 data: {
    //                                     payments: [payment?.id],
    //                                     paymentForm: payment?.payment_form || payment.paymentForm,
    //                                     paymentMethod: payment?.payment_method || payment.paymentMethod,
    //                                     currency: payment?.currency,
    //                                     exchange_rate: payment?.exchange_rate || payment.exchangeRate || 1,
    //                                 },
    //                             }),
    //                         )

    //                         dispatch(openModal('invoiceModalVisible'))
    //                     }}
    //                 />
    //             </Tooltip>
    //         </div>
    //     )
    // }

    const CancelAction = () => {
        if (payment?.status !== 'canceled' && payment?.cancellation_status !== 'pending' && canCreatePayment.hasPermission) {
            return (
                <div
                    style={{
                        cursor: 'pointer',
                        marginLeft: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Popconfirm
                        disabled={thisPaymentHasAnyLoading}
                        title="Cancelar pago"
                        description="Este recibo no se podrá pagar"
                        okText="Cancelar"
                        cancelText="Volver"
                        okButtonProps={{ danger: true }}
                        cancelButtonProps={{ type: 'default' }}
                        onConfirm={async () => {
                            setactionOnPayment(payment.id)
                            message.loading('Cancelando pago...')
                            try {
                                const resp = await SignedInternalAPIRequest(
                                    {
                                        type: 'payment_request_cancel',
                                        id: payment.paymentIntent,
                                        paymentID: payment.id,
                                        fid: payment.fid,
                                    },
                                    'paymentsHandler',
                                    auth.currentUser,
                                )

                                if (resp.error) {
                                    message.error('Error al cancelar pago')
                                    return setactionOnPayment(null)
                                }
                                message.success('Pago cancelado')
                                setactionOnPayment(null)
                            } catch (error) {
                                message.error('Error al cancelar pago')
                                setactionOnPayment(null)
                            }
                            await new Promise((resolve) => setTimeout(resolve, 1000))
                            setLoading(loading.filter((l) => l.id !== payment?.id && l.action !== `cancel`))
                        }}
                    >
                        <Typography> Cancelar pago</Typography>
                    </Popconfirm>
                </div>
            )
        } else return <></>
    }

    const MarkAsPaid = () => {
        setCompletePayment(true)
    }

    // const ToReview = () => {
    //     if (payment?.status === 'review_requested') {
    //         return (
    //             <>
    //                 <Tooltip title="Revisar pago">
    //                     <File
    //                         size={18}
    //                         weight="regular"
    //                         style={{ marginLeft: '5px' }}
    //                         onClick={() => {
    //                             dispatch(
    //                                 setData({
    //                                     item: 'payment',
    //                                     data: payment,
    //                                 }),
    //                             )
    //                             dispatch(openModal('approveModalVisible'))
    //                         }}
    //                         className="icon clickable"
    //                     />
    //                 </Tooltip>
    //             </>
    //         )
    //     } else return <></>
    // }

    const PaymentSucceededToPending = () => {
        const title = 'Recursos Relacionados'

        dispatch(
            setData({
                item: 'relationInfo',
                data: {
                    title,
                    sourceType: 'payments',
                    item: payment,
                },
            }),
        )

        dispatch(openModal('cancelResourcesModalVisible'))
    }

    // const DisplayBankTransferInfo = () => {
    //     return (
    //         <Tooltip title="Ver instrucciones de transferencia">
    //             <Bank
    //                 size={18}
    //                 weight="regular"
    //                 style={{ marginLeft: '5px' }}
    //                 onClick={() => {
    //                     dispatch(
    //                         setData({
    //                             item: 'payment',
    //                             data: payment,
    //                         }),
    //                     )
    //                     dispatch(openModal('stripeBankInstructionsVisible'))
    //                 }}
    //                 className="icon clickable"
    //             />
    //         </Tooltip>
    //     )
    // }
    const handleAction = (
        action: () => void,
        permission: { hasPermission: boolean; reason?: string; restrictionType?: string },
    ) => {
        if (!permission.hasPermission) {
            message.warning(permission.reason)
            return
        }
        action()
    }

    const paymentActionsMenu: MenuProps['items'] = [
        payment?.status === 'succeeded' && {
            key: 'change-status-to-pending',
            label: 'Cambiar estado a "Pendiente"',
            onClick: PaymentSucceededToPending,
            icon: <ArrowUUpLeft size={18} />,
        },
        payment?.status === 'succeeded' && { type: 'divider' },
        !payment?.livemode && {
            key: 'copy-payment-id',
            label: (
                <CopyAction
                    toCopy={payment?.fid}
                    loading={thisPaymentHasAnyLoading}
                    isMenu
                    menuTitle="Copiar ID de pago"
                />
            ),
            icon: <ClipboardText size={18} />,
        },
        (payment?.shortURL || payment?.shortUrl) &&
            payment?.status !== 'succeeded' &&
            payment?.status !== 'review_requested' && {
                key: 'copy-short-url',
                label: (
                    <CopyAction
                        toCopy={payment?.shortURL || payment?.shortUrl}
                        loading={thisPaymentHasAnyLoading}
                        isMenu
                        menuTitle="Copiar liga de pago corta"
                    />
                ),
                icon: <Link size={18} />,
            },
        payment?.fid?.startsWith('plink') && {
            key: 'copy-long-url',
            label: (
                <CopyAction
                    toCopy={`https://beta.gigstack.pro/paymentLinks/${payment?.fid}`}
                    loading={thisPaymentHasAnyLoading}
                    isMenu
                    menuTitle="Copiar liga de pago larga"
                />
            ),
            icon: <Link size={18} />,
        },
        payment?.status !== 'succeeded' &&
            payment?.status !== 'paid' &&
            canCreatePayment.hasPermission && {
                key: 'send-by-email',
                label: (
                    <EmailActions
                        action={() => {}}
                        loading={loading.find((l) => l.id === payment?.id && l.action === `email`) !== undefined}
                        isMenu
                    />
                ),
                onClick: () => PAYMENT_ACTIONS_DEFINITION.SEND_EMAIL.action({ payment, dispatch }),
                icon: PAYMENT_ACTIONS_DEFINITION.SEND_EMAIL.icon,
            },
        payment?.shortURL &&
            payment?.status !== 'succeeded' &&
            payment?.status !== 'canceled' && {
                key: 'share-for-whatsapp',
                label: 'Compartir por WhatsApp',
                onClick: () =>
                    ShareViaWhatsapp(
                        `Puedes realizar el pago por ${payment?.amount} ${payment?.currency} en el siguiente enlace! 😉 : ${payment?.shortURL}`,
                    ),
                icon: <WhatsappLogo size={18} />,
            },
        { type: 'divider' },
        {
            key: 'issue-an-invoice',
            label: 'Emitir Factura',
            onClick: () =>
                handleAction(async () => {
                    const clientId = payment?.client?.id || payment?.clientId || payment?.clientID
                    const ctxClient = clients.find((c: any) => c.id === clientId)

                    message.loading('Cargando información...')

                    let clientFromDb
                    try {
                        clientFromDb = await getDoc(doc(getFirestore(), 'clients', clientId))
                        if (!clientFromDb.exists()) {
                            clientFromDb = await getDoc(doc(getFirestore(), 'clients', payment?.clientId))
                        }
                    } catch (error) {
                        clientFromDb = null
                    }

                    dispatch(
                        setData({
                            item: 'client',
                            data: clientFromDb?.data() || ctxClient || payment?.client,
                        }),
                    )
                    dispatch(
                        setData({
                            item: 'selectedServices',
                            data: payment?.items,
                        }),
                    )
                    dispatch(
                        setData({
                            item: 'invoice',
                            data: {
                                receipts: payment?.receipts || [],
                                payments: [payment?.id],
                                paymentForm: payment?.payment_form || payment?.paymentForm,
                                paymentMethod: payment?.payment_method || payment?.paymentMethod,
                                currency: payment?.currency,
                                exchange_rate: payment?.exchange_rate || payment?.exchangeRate || 1,
                            },
                        }),
                    )

                    dispatch(openModal('invoiceModalVisible'))
                }, canCreateInvoice),
            icon: <PlusCircle size={18} />,
        },
        {
            key: 'to-review',
            label: 'Revisar Pago',
            onClick: () => {
                if (!canCreatePayment.hasPermission) {
                    message.warning(canCreatePayment.reason || 'No tienes permisos para revisar pagos')
                    return
                }
                dispatch(
                    setData({
                        item: 'payment',
                        data: payment,
                    }),
                )
                dispatch(openModal('approveModalVisible'))
            },
            icon: <File size={18} />
        },
        payment?.status !== 'succeeded' && {
            key: 'mark-as-paid',
            label: 'Marcar como pagado',
            onClick: MarkAsPaid,
            icon: <CreditCard size={18} />,
        },
        payment?.next_action?.display_bank_transfer_instructions &&
            payment?.status !== 'succeeded' && {
                key: 'display-bank-transfer-info',
                label: 'Ver instrucciones de transferencia',
                onClick: () => {
                    dispatch(
                        setData({
                            item: 'payment',
                            data: payment,
                        }),
                    )
                    dispatch(openModal('stripeBankInstructionsVisible'))
                },
                icon: <Bank size={18} />,
            },
        { type: 'divider' },
        ['requires_payment_method', 'requires_action', 'requires_confirmation'].includes(payment?.status) && {
            key: 'cancel-payment',
            label: <CancelAction />,
            icon: <Prohibit size={18} />,
            disabled: !canCreatePayment.hasPermission,
        },
        ['requires_payment_method', 'requires_action', 'requires_confirmation'].includes(payment?.status) &&
            !payment?.invoices?.length &&
            !payment?.receipts?.length && {
                key: 'delete-payment',
                label: (
                    <Popconfirm
                        title="¿Estás seguro de eliminar este pago?"
                        okText="Eliminar"
                        cancelText="Cancelar"
                        onConfirm={async () => {
                            await updateDoc(doc(getFirestore(), 'payments', payment?.fid), {
                                status: 'deleted',
                                team: `old_${payment.team}`,
                            })
                            message.success('Pago eliminado')
                        }}
                    >
                        <Typography>Eliminar pago</Typography>
                    </Popconfirm>
                ),
                icon: <Trash size={18} />,
            },
        payment.status === 'active' &&
            payment?.id?.startsWith('plink') && {
                key: 'delete-payment-link',
                label: (
                    <Popconfirm
                        title="¿Estás seguro de eliminar este link de pago?"
                        okText="Eliminar"
                        cancelText="Cancelar"
                        onConfirm={async () => {
                            await updateDoc(doc(getFirestore(), 'paymentLinks', payment?.fid), {
                                status: 'deleted',
                                team: `old_${payment.team}`,
                            })
                            message.success('Link de pago eliminado')
                        }}
                    >
                        <Typography>Eliminar link de pago</Typography>
                    </Popconfirm>
                ),
                icon: <Trash size={18} />,
            },
    ].filter(Boolean) // Filter out any false or null entries

    if (props.from === 'successView') {
        return (
            <Row>
                <Button
                    className="btn-secondary"
                    onClick={() => {
                        console.log('PAYMENT: ', payment)
                        PAYMENT_ACTIONS_DEFINITION.COPY.action({
                            paymentId: payment?.id || payment?.fid,
                            from: payment?.from,
                        })
                    }}
                    style={{ margin: '5px' }}
                >
                    {PAYMENT_ACTIONS_DEFINITION.COPY.icon} {PAYMENT_ACTIONS_DEFINITION.COPY.label}
                </Button>
                <Button
                    className="btn-secondary"
                    onClick={() => PAYMENT_ACTIONS_DEFINITION.SEND_EMAIL.action({ payment, dispatch })}
                    style={{ margin: '5px' }}
                >
                    {PAYMENT_ACTIONS_DEFINITION.SEND_EMAIL.icon} {PAYMENT_ACTIONS_DEFINITION.SEND_EMAIL.label}
                </Button>
                <Button
                    className="btn-secondary"
                    onClick={() => {
                        dispatch(closeModal('paymentModalVisible'))
                        navigate(`/paymentDetails?id=${payment.fid}`)
                    }}
                    style={{ margin: '5px' }}
                >
                    <TextColumns size={18} /> Ver detalles
                </Button>
            </Row>
        )
    }

    return (
        <Row>
            <MarkAsPaidModal
                data={payment}
                open={completePayment}
                close={() => {
                    setCompletePayment(false)
                }}
            />
            <Dropdown menu={{ items: paymentActionsMenu }}>
                <DotsThree size={24} style={{ cursor: 'pointer' }} />
            </Dropdown>
        </Row>
    )
}

export default PaymentsActions
