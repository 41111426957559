import { Button, Col, Empty, Form, Row, Table, Tag, Tooltip, Typography } from 'antd'
import { ClientDef } from '../../../interfaces/clientDef'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { GetClients } from '../../context/databaseContextHelpers'
import { colors } from '../../designSystem/colors'
import { CaretRight } from '@phosphor-icons/react'
import { setData } from '../../context/dataSlice'
import { ClientsToMergeForm } from '../../modals/MergeClientsModal'

const MergeClientsCard = ({ onSuccess }: { onSuccess: (v: any) => void }) => {
    const { clientsToMerge } = useSelector((state: any) => state.data)
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)

    const clientName =
        clientsToMerge[0]?.name || clientsToMerge[0]?.legal_name || clientsToMerge[0]?.email || 'Cliente sin nombre'

    return (
        <div className="clients-jtbd--card">
            <Row justify="space-between" style={{ borderBottom: '1px solid #E2E8F0' }}>
                <Row align="middle" style={{ padding: '8px 20px' }}>
                    <Typography.Text className="mediumparagraph" style={{ marginRight: '10px' }}>
                        {clientName}
                    </Typography.Text>
                    <Tag color={colors.background_darker}>
                        <Typography.Text className="smallparagraphbold" style={{ color: colors.text_muted }}>
                            {clientsToMerge.length} clientes duplicados
                        </Typography.Text>
                    </Tag>
                </Row>
                <Row style={{ padding: '8px 20px' }}>
                    <Button
                        type="text"
                        onClick={() => {
                            onSuccess(clientsToMerge)
                        }}
                    >
                        Saltar tarea
                        <CaretRight size={16} />
                    </Button>
                </Row>
            </Row>
            {clientsToMerge.length > 0 && (
                <>
                    <Col style={{ padding: '0px 20px' }}>
                        <ClientsToMergeForm
                            form={form}
                            loading={loading}
                            setLoading={(v) => setLoading(v)}
                            onSuccess={(v: ClientDef[]) => {
                                onSuccess(v)
                                form.resetFields()
                            }}
                        />
                    </Col>
                    <Row justify="end">
                        <Button type="primary" loading={loading} onClick={() => form.submit()}>
                            Unificar clientes
                        </Button>
                    </Row>
                </>
            )}

            {clientsToMerge.length === 0 && (
                <Row justify="center" align="middle" style={{ padding: '20px' }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No hay clientes para unificar" />
                </Row>
            )}
        </div>
    )
}

export const ClientsJTDContainer = () => {
    const dispatch = useDispatch()
    const { team } = useSelector((state: any) => state.team)
    const { clients } = useSelector((state: any) => state.data)
    const [clientsToUnify, setClientsToUnify] = useState<ClientDef[][]>([])

    useEffect(() => {
        if (clients.length === 0) {
            GetClients(dispatch, team.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (clients.length > 0) {
            const validClients = clients.filter((c: any) => 
                !(c.ignoreSuggestions || []).includes('merge_clients') &&
                c?.email && 
                typeof c?.email === 'string' && 
                c?.email?.trim() !== ''
            )

            const groupClients = _.groupBy(validClients, 'email')

            const clientsToUnify = Object.keys(groupClients)
                .map((key) => {
                    if (groupClients[key].length > 1) {
                        return groupClients[key]
                    }
                    return null
                })
                .filter((c) => c) as ClientDef[][]
            
            setClientsToUnify(clientsToUnify)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clients])

    const onSuccess = (v: ClientDef[]) => {
        dispatch(
            setData({
                item: 'clients',
                data: clients.map((c: any) => {
                    if (v.map((c) => c.id).includes(c.id)) {
                        return {
                            ...c,
                            ignoreSuggestions: [...c.ignoreSuggestions, 'merge_clients'],
                        }
                    }
                    return c
                }),
            }),
        )
        setClientsToUnify(clientsToUnify.filter((c) => c[0].id !== v[0].id))
        try {
            try {
                dispatch(setData({ item: 'clientsToMerge', data: [] }))
            } catch (error) {
                console.log('error', error)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    return (
        <Col xs={24}>
            <Row>{/* <Tag color="blue">Clients to Unify</Tag> */}</Row>
            <Row gutter={12}>
                <Col xs={10}>
                    <Table
                        columns={[
                            {
                                title: 'Tarea',
                                dataIndex: 'task',
                                key: 'task',
                                render: (text: string, option: ClientDef[]) => {
                                    return (
                                        <Tag color={colors.background_darker}>
                                            <Typography.Text
                                                className="smallparagraphbold"
                                                style={{ color: colors.text_muted }}
                                            >
                                                Contacto duplicado
                                            </Typography.Text>
                                        </Tag>
                                    )
                                },
                            },
                            {
                                title: 'Cliente',
                                dataIndex: 'client',
                                key: 'client',
                                render: (text: string, option: ClientDef[]) => {
                                    const client = option[0]
                                    const name =
                                        client?.name || client?.legal_name || client?.email || 'Cliente sin nombre'
                                    return (
                                        <Row
                                            justify="space-between"
                                            className="clickable"
                                            onClick={() => {
                                                console.log('SELECTED CLIENTS: ', option)
                                                dispatch(
                                                    setData({
                                                        item: 'clientsToMerge',
                                                        data: (option || []) as ClientDef[],
                                                    }),
                                                )
                                            }}
                                        >
                                            <Row justify="space-between" style={{ width: '90%' }}>
                                                <Typography.Text
                                                    className="mediumparagraph"
                                                    ellipsis
                                                    style={{ maxWidth: '80%' }}
                                                >
                                                    {name}
                                                </Typography.Text>
                                                <Tooltip title={`Unificar ${option.length} clientes repetidos`}>
                                                    <Tag color={colors.background_darker}>
                                                        <Typography.Text
                                                            className="smallparagraphbold"
                                                            style={{ color: colors.text_muted }}
                                                        >
                                                            {option.length}
                                                        </Typography.Text>
                                                    </Tag>
                                                </Tooltip>
                                            </Row>
                                            <Row justify="end" style={{ width: '10%' }}>
                                                <CaretRight size={16} className="clickable" />
                                            </Row>
                                        </Row>
                                    )
                                },
                            },
                        ]}
                        dataSource={clientsToUnify}
                    />
                </Col>
                <Col xs={14}>
                    <MergeClientsCard onSuccess={(v: ClientDef[]) => onSuccess(v)} />
                </Col>
            </Row>
        </Col>
    )
}
