import { LinkBreak } from '@phosphor-icons/react'
import { Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../context/dataSlice'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { handleRelationsTitle } from './ElementRelations'

interface DeleteRelationButtonProps {
    recordId: string
    recordType: string
}

export const DeleteRelationButton = ({ recordId, recordType }: DeleteRelationButtonProps) => {
    const dispatch = useDispatch()
    const { relationInfo } = useSelector((state: any) => state.data)
    const { sourceType, item } = relationInfo || {}

    const handleDelete = async () => {
        if (!sourceType || !item?.id) return

        const title = handleRelationsTitle(sourceType)

        const callableVendorsFunction = httpsCallable(getFunctions(), 'deletedocumentrelations')
        const response: any = await callableVendorsFunction({
            collection: sourceType,
            documentId: item.id,
            relationsToDelete: [recordId],
            fieldToFilter: recordType,
        })

        // Then set the new data in the next tick
        setTimeout(() => {
            dispatch(
                setData({
                    item: 'relationInfo',
                    data: {
                        title,
                        sourceType,
                        item: response.data,
                    },
                }),
            )
        }, 0)
    }

    return (
        <Tooltip title="Eliminar relación">
            <LinkBreak
                size={20}
                weight="regular"
                style={{ display: 'flex', padding: 0 }}
                onClick={handleDelete}
            />
        </Tooltip>
    )
} 