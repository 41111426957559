import { ArrowCounterClockwise } from '@phosphor-icons/react'
import { message, Row, Space, Table, Tag, Tooltip, Typography } from 'antd'
import { collection, doc, getFirestore, orderBy, query, updateDoc, where } from 'firebase/firestore'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { MetroSpinner } from 'react-spinners-kit'
import { useFirestoreCollectionData } from 'reactfire'

export const LogsRelatedToElement = ({ id, v }: { id: string | null; v?: number }) => {
    const { team } = useSelector((state: any) => state.team)

    const q = query(
        collection(getFirestore(), 'logs'),
        where('data.payload.data.id', '==', id),
        where('eventType', '==', 'webhook.sent'),
        where('team', '==', team?.id),
        orderBy('timestamp', 'desc'),
    )
    const { data } = useFirestoreCollectionData(q, {
        idField: 'id',
    })

    const columns = [
        {
            title: 'Evento',
            dataIndex: 'event',
            key: 'event',
            render: (action: string, record: any) => {
                const resCode = record.resCode || null
                const message = record?.response?.message || 'No hay mensaje disponible'

                return (
                    <Row>
                        {resCode && (
                            <Tooltip title={message}>
                                <Tag color={resCode < 400 ? 'var(--green-3)' : 'var(--red-3)'}>
                                    <Typography.Text
                                        className="smallparagraphbold"
                                        color={resCode < 400 ? 'var(--green-5)' : 'var(--red-5)'}
                                    >
                                        {resCode}
                                    </Typography.Text>
                                </Tag>
                            </Tooltip>
                        )}
                        <Tag color="var(--teal-1)">
                            <Typography.Text className="smallparagraphbold" color="var(--teal-5)">
                                {record.data?.payload?.event}
                            </Typography.Text>
                        </Tag>
                    </Row>
                )
            },
        },
        {
            title: 'Fecha de envío',
            dataIndex: 'timestamp',
            render: (id: string, record: any) => (
                <Typography.Text className="p-base-regular">
                    {moment(record.timestamp).format('DD/MM/YYYY HH:mm')}
                </Typography.Text>
            ),
        },
        {
            title: 'URL',
            dataIndex: 'url',
            render: (data: string, record: any) => {
                const url = record?.message?.split('{{/url/')[1]?.split('}}')[0] || 'No disponible'
                return <Typography.Text className="smallparagraph">{url}</Typography.Text>
            },
        },
        {
            title: 'Eventos enviados',
            dataIndex: 'resendCounter',
            render: (data: string, record: any) => {
                const count = (record?.resendCounter || 0) + 1
                return <Typography.Text className="smallparagraph">{count}</Typography.Text>
            },
        },
        {
            title: 'Acciones',
            dataIndex: 'actions',
            render: (status: string, record: any) => (
                <Space size="middle">
                    {record.resend ? (
                        <MetroSpinner size={16} />
                    ) : (
                        <Tooltip title="Reenviar">
                            <ArrowCounterClockwise
                                className="clickable"
                                size={16}
                                onClick={async () => {
                                    try {
                                        await updateDoc(doc(getFirestore(), 'logs', record.id), {
                                            resend: true,
                                        })
                                        message.success('Webhook reenviado correctamente')
                                    } catch (error: any) {
                                        message.error(
                                            error.message || 'Ocurrió un error al intentar reenviar el webhook',
                                        )
                                    }
                                }}
                            />
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ]

    if (v === 2) {
        return (
            <Table
                columns={[
                    {
                        title: 'Evento',
                        dataIndex: 'event',
                        key: 'event',
                        render: (action: string, record: any) => {
                            const resCode = record.resCode || null
                            return (
                                <Row>
                                    <Tag color={resCode < 400 ? 'var(--green-3)' : 'var(--red-3)'}>
                                        <Tooltip title={record.response?.message}>
                                            <Typography.Text className="smallparagraph">
                                                {record.data?.payload?.event}
                                            </Typography.Text>
                                        </Tooltip>
                                    </Tag>
                                    <Typography.Text className="smallparagraph">
                                        {record.data?.payload?.event}
                                    </Typography.Text>
                                </Row>
                            )
                        },
                    },
                    {
                        title: 'Fecha de envío',
                        dataIndex: 'timestamp',
                        render: (timestamp: any) => (
                            <Typography.Text className="p-base-regular">
                                {moment(timestamp).format('DD/MM/YYYY HH:mm')}
                            </Typography.Text>
                        ),
                    },
                    {
                        title: 'Estado',
                        dataIndex: 'url',
                        render: (data: string, record: any) => {
                            const url = record?.message?.split('{{/url/')[1]?.split('}}')[0] || 'No disponible'
                            return <Typography.Text className="smallparagraph">{url}</Typography.Text>
                        },
                    },
                    {
                        title: 'Eventos',
                        dataIndex: 'resendCounter',
                        render: (data: string, record: any) => {
                            const count = (record?.resendCounter || 0) + 1
                            return (
                                <Space>
                                    <Typography.Text className="smallparagraph">{count}</Typography.Text>
                                    <ArrowCounterClockwise
                                        className="clickable"
                                        size={16}
                                        onClick={async () => {
                                            try {
                                                await updateDoc(doc(getFirestore(), 'logs', record.id), {
                                                    resend: true,
                                                })
                                                message.success('Webhook reenviado correctamente')
                                            } catch (error: any) {
                                                message.error(
                                                    error.message || 'Ocurrió un error al intentar reenviar el webhook',
                                                )
                                            }
                                        }}
                                    />
                                </Space>
                            )
                        },
                    },
                ]}
                dataSource={data}
                pagination={false}
            />
        )
    }

    return <Table columns={columns} dataSource={data} />
}
