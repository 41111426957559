import {
    CalendarBlank,
    Cardholder,
    CopySimple,
    CurrencyDollar,
    Link,
    Money,
    Plus,
    Question,
    Rocket,
    TestTube,
} from '@phosphor-icons/react'
import { Avatar, Button, Col, DatePicker, Dropdown, Form, Image, Row, Tag, Typography, message, Tour } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
import { useAuth } from 'reactfire'
import { setTeam } from '../context/teamsSlice'
import { openModal } from '../context/modalsSlice'
import moment from 'moment'

import { getItemsAmounts } from '../functions/helpers'
import dayjs from 'dayjs'
import ClientDataWrapper from '../datawrappers/clientDataWrapper'
import { query, collection, where, getFirestore, limit, orderBy } from 'firebase/firestore'
import {
    invoiceStatusColorText,
    paymentStatusColorText,
    readableInvoiceStatus,
    readablePaymentStatus,
    readableReceiptStatus,
    receiptStatusColorText,
} from '../../helpers/statusHelpers'
import { optimizedPaymentDefSet } from '../../interfaces/paymentsDef'
import { optimizedInvoiceDefSet } from '../../interfaces/invoicesDef'
import { receiptDefSet } from '../../interfaces/receiptsDef'
import { useNavigate } from 'react-router-dom'
import { setData } from '../context/dataSlice'
import PendingInvitesElement from '../components/ComponentIndividual/PendingInvitesElement'
import { usePostHog } from 'posthog-js/react'
import { getBillingData } from '../functions/Billing'
import InsightSpaceWrapper from '../components/Insights/InsightsSpace'
import { integrations } from '../datasets/Integrations'
import { teamDefSet } from '../../interfaces/teamDef'
import type { TourProps } from 'antd'

import { FullChartPayments, PaymentsCard, PeriodGrowthCard, RefundsCard } from '../components/Insights/PaymentsChart'
import { FullChartInvoices, InvoiceSourcesChart, InvoiceTypesCharts } from '../components/Insights/InvoiceChart'
import { ClientsAmount } from '../components/Insights/ClientsInsights'
import NoticeBoard from '../components/HomePageComponents/NoticeBoard'
import { trackEvent } from '../analytics/helpers'

const HomePage: React.FC = () => {
    const posthog = usePostHog()
    const navigate = useNavigate()
    const auth = useAuth()
    const dispatch = useDispatch()
    const { user } = useSelector((state: any) => state.user)
    const { team } = useSelector((state: any) => state.team)
    const { testmode, period, billing } = useSelector((state: any) => state.data)
    const [invitesInfo, setInvitesInfo] = useState({
        loaded: false,
        invites: [],
    })

    const [reload, setReload] = useState(false)
    const [tour, startTour] = useState(false)

    const daysref = useRef(null)
    const completedpayments = useRef(null)
    const periodGrowth = useRef(null)
    const paymentsGraph = useRef(null)
    const invoicesGraph = useRef(null)
    const receiptsInfo = useRef(null)
    const datepicker = useRef(null)

    useEffect(() => {
        getBillingData({ team: team, user: user, dispatch })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () => {
            setReload(true)
            new Promise((resolve) => setTimeout(resolve, 100)).then(() => {
                setReload(false)
            })
        }
    }, [testmode])

    const [teamMembersState, setTeamMembersState] = useState({
        loaded: false,
        members: [],
        teamId: team?.id,
        failed: false,
        error: null,
    })

    const getMembers = async () => {
        try {
            if (!team?.id) return
            setTeamMembersState({
                loaded: false,
                members: [],
                teamId: team?.id,
                failed: false,
                error: null,
            })
            const rawMembers = await SignedInternalAPIRequest(
                {},
                `handleSeats/v1/teams/${team?.id}/members`,
                auth.currentUser,
                {},
                'GET',
            )

            dispatch(
                setTeam({
                    ...team,
                    rawMembers: rawMembers,
                }),
            )
            setTeamMembersState({
                loaded: true,
                members: rawMembers,
                teamId: team?.id,
                failed: false,
                error: null,
            })
        } catch (error: any) {
            setTeamMembersState({
                loaded: true,
                members: [],
                teamId: team?.id,
                failed: true,
                error: error?.message ?? '',
            })
        }
    }

    useEffect(() => {
        var config = {
            selector: '#newFeats',
            account: 'xaVPV7',
        }
        window?.Headway?.init(config)
    }, [])
    useEffect(() => {
        if ((!teamMembersState?.loaded && !team?.rawMembers) || teamMembersState?.teamId !== team?.id) {
            getMembers()
        } else if (!teamMembersState.loaded && team.rawMembers) {
            setTeamMembersState({
                loaded: true,
                members: team.rawMembers,
                teamId: team?.id,
                failed: false,
                error: null,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team?.id])

    const Header = () => {
        return (
            <Col xs={23} lg={23}>
                <div
                    className=""
                    style={{
                        backgroundColor: '#F8F8F8',
                        padding: '20px',
                        borderRadius: '10px',
                    }}
                >
                    <Row justify="space-between" align="middle" wrap>
                        <div className="d-flex flex-column">
                            <div id="newFeats">
                                <Typography.Text className="mediumheader" id="HW_badge_cont HW_badge helloUser">
                                    Hola {user?.firstName ?? user?.name?.split(' ')[0]}!
                                </Typography.Text>
                            </div>
                            <Typography.Text className="smallparagraph descriptions">
                                {moment().format('dddd D MMMM')}.
                            </Typography.Text>
                        </div>
                        <Row align="middle">
                            <Form
                                style={{ flex: 1 }}
                                initialValues={{
                                    dates: [dayjs(period.start), dayjs(period.end)],
                                }}
                            >
                                <div ref={datepicker}>
                                    <Form.Item
                                        name="dates"
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            marginLeft: '5px',
                                            backgroundColor: '#fafbfd',
                                            overflow: 'hidden',
                                            borderRadius: '15px',
                                        }}
                                    >
                                        <DatePicker.RangePicker
                                            style={{
                                                maxWidth: '250px',
                                                backgroundColor: '#fafbfd',
                                                borderRadius: '15px',
                                            }}
                                            suffixIcon={<CalendarBlank size={17} weight="regular" className="icon" />}
                                            format="DD MMM YY"
                                            onChange={(a: any) => {
                                                if (!a) return
                                                dispatch(
                                                    setData({
                                                        item: 'period',
                                                        data: {
                                                            start: a[0].valueOf(),
                                                            end: a[1].valueOf(),
                                                        },
                                                    }),
                                                )
                                            }}
                                            presets={[
                                                {
                                                    label: `${moment().startOf('month').format('D')} al ${moment().endOf('month').format('D MMMM YYYY')}`,
                                                    value: [dayjs().startOf('month'), dayjs().endOf('month')],
                                                },
                                                {
                                                    label: `${moment().add(-1, 'month').startOf('month').format('D')} al ${moment().add(-1, 'month').endOf('month').format('D MMMM YYYY')}`,
                                                    value: [
                                                        dayjs().add(-1, 'month').startOf('month'),
                                                        dayjs().add(-1, 'month').endOf('month'),
                                                    ],
                                                },
                                                {
                                                    label: '60 días',
                                                    value: [
                                                        dayjs().add(-60, 'days').startOf('day'),
                                                        dayjs().endOf('day'),
                                                    ],
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </div>
                            </Form>
                            <Dropdown
                                menu={{
                                    items: [
                                        {
                                            onClick: async () => {
                                                dispatch(openModal('paymentModalVisible'))
                                            },
                                            label: (
                                                <Typography.Text className="smallparagaph ">
                                                    <Money weight="regular" size={15} style={{ marginRight: '5px' }} />{' '}
                                                    Solicitar pago
                                                </Typography.Text>
                                            ),
                                            key: 'createPayment',
                                        },
                                        {
                                            onClick: async () => {
                                                dispatch(openModal('invoiceModalVisible'))
                                            },
                                            label: (
                                                <Typography.Text className="smallparagaph ">
                                                    <CurrencyDollar
                                                        weight="regular"
                                                        size={15}
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                    Crear factura
                                                </Typography.Text>
                                            ),
                                            key: 'createInvoice',
                                        },
                                        {
                                            onClick: async () => {
                                                dispatch(openModal('receiptModalVisible'))
                                            },
                                            label: (
                                                <Typography.Text className="smallparagaph ">
                                                    <Cardholder
                                                        weight="regular"
                                                        size={15}
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                    Crear recibo
                                                </Typography.Text>
                                            ),
                                            key: 'createReceipt',
                                        },

                                        //
                                    ],
                                }}
                                trigger={['click']}
                            >
                                <Button
                                    size="middle"
                                    shape="circle"
                                    icon={<Plus className="icon" />}
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => {}}
                                ></Button>
                            </Dropdown>
                        </Row>
                    </Row>
                </div>
            </Col>
        )
    }

    const LastResourceCard = ({
        title,
        client,
        amount,
        since,
        tag,
        type,
        detailsPath,
        id,
    }: {
        title: string
        client: string
        amount: string
        since: string
        tag: ReactNode
        type: string
        detailsPath?: string | null
        id: string
    }) => {
        return (
            <Row key={id} justify="space-between">
                <div className="d-flex flex-column">
                    <Typography.Text
                        className={(detailsPath ? 'clickable' : '') + ' smallparagraphbold'}
                        onClick={() => {
                            if (!detailsPath) return
                            navigate(`/${detailsPath}?id=` + id)
                        }}
                    >
                        {client?.toUpperCase()}
                    </Typography.Text>
                    <Typography.Text className="smallparagraph">
                        {amount} {since}
                    </Typography.Text>
                </div>
                <div>{tag}</div>
            </Row>
        )
    }

    const LastResources = () => {
        return (
            <Col xs={24} lg={23} style={{ marginTop: '25px' }}>
                <label>Últimos recursos creados</label>
                <Row gutter={12}>
                    <Col xs={24} lg={8}>
                        <div
                            style={{
                                padding: '15px',
                                borderRadius: '10px',
                                backgroundColor: '#F8F8F8',
                                marginBottom: '10px',
                            }}
                        >
                            <Typography.Text
                                className="smallparagraph descriptions"
                                onClick={() => {
                                    navigate('/payments')
                                }}
                            >
                                Pago
                            </Typography.Text>
                            <div style={{ marginBottom: '5px' }}></div>
                            {!reload && (
                                <ClientDataWrapper
                                    clientId=""
                                    query={query(
                                        collection(getFirestore(), 'payments'),
                                        where('team', '==', team?.id),
                                        where('livemode', '==', !testmode),
                                        orderBy('timestamp', 'desc'),
                                        limit(1),
                                    )}
                                    renderData={(d) => {
                                        return d.map((payment: any) => {
                                            return (
                                                <LastResourceCard
                                                    title={''}
                                                    client={payment?.client?.name}
                                                    amount={
                                                        getItemsAmounts(payment?.items, payment?.paidIn === 'bank')
                                                            .totalString
                                                    }
                                                    since={moment(payment.timestamp).fromNow()}
                                                    tag={
                                                        <Tag
                                                            color={paymentStatusColorText(
                                                                optimizedPaymentDefSet(payment),
                                                            )}
                                                        >
                                                            {readablePaymentStatus(optimizedPaymentDefSet(payment))}
                                                        </Tag>
                                                    }
                                                    type={'payments'}
                                                    id={payment?.fid}
                                                    detailsPath={'paymentDetails'}
                                                    key={payment?.fid}
                                                />
                                            )
                                        })
                                    }}
                                />
                            )}
                        </div>
                    </Col>

                    <Col xs={24} lg={8}>
                        <div
                            style={{
                                padding: '15px',
                                borderRadius: '10px',
                                backgroundColor: '#F8F8F8',
                                marginBottom: '10px',
                            }}
                        >
                            <Typography.Text
                                className="smallparagraph descriptions"
                                onClick={() => {
                                    navigate('/receipts')
                                }}
                            >
                                Recibo de venta
                            </Typography.Text>
                            <div style={{ marginBottom: '5px' }}></div>
                            {!reload && (
                                <ClientDataWrapper
                                    clientId=""
                                    query={query(
                                        collection(getFirestore(), 'receipts'),
                                        where('team', '==', team?.id),
                                        where('livemode', '==', !testmode),
                                        orderBy('timestamp', 'desc'),
                                        limit(1),
                                    )}
                                    renderData={(d) => {
                                        return d.map((receipt: any) => {
                                            return (
                                                <LastResourceCard
                                                    title={''}
                                                    client={receipt?.client?.name}
                                                    amount={
                                                        getItemsAmounts(receipt?.items, receipt?.paidIn === 'bank')
                                                            .totalString
                                                    }
                                                    since={moment(receipt.timestamp).fromNow()}
                                                    tag={
                                                        <Tag color={receiptStatusColorText(receiptDefSet(receipt))}>
                                                            {readableReceiptStatus(receiptDefSet(receipt))}
                                                        </Tag>
                                                    }
                                                    type={'receipts'}
                                                    id={receipt?.id}
                                                    detailsPath={null}
                                                    key={receipt?.id}
                                                />
                                            )
                                        })
                                    }}
                                />
                            )}
                        </div>
                    </Col>
                    <Col xs={24} lg={8}>
                        <div
                            style={{
                                padding: '15px',
                                borderRadius: '10px',
                                backgroundColor: '#F8F8F8',
                                marginBottom: '10px',
                            }}
                        >
                            <Typography.Text
                                className="smallparagraph descriptions"
                                onClick={() => {
                                    navigate('/invoices')
                                }}
                            >
                                Factura
                            </Typography.Text>
                            <div style={{ marginBottom: '5px' }}></div>
                            {!reload && (
                                <ClientDataWrapper
                                    clientId=""
                                    query={query(
                                        collection(getFirestore(), 'invoices'),
                                        where('team', '==', team?.id),
                                        where('livemode', '==', !testmode),
                                        orderBy('timestamp', 'desc'),
                                        limit(1),
                                    )}
                                    renderData={(d) => {
                                        return d.map((invoice: any) => {
                                            return (
                                                <LastResourceCard
                                                    title={''}
                                                    client={invoice?.client?.name}
                                                    amount={
                                                        getItemsAmounts(
                                                            invoice?.internalItems,
                                                            invoice?.paidIn === 'bank',
                                                        ).totalString
                                                    }
                                                    since={moment(invoice.timestamp).fromNow()}
                                                    tag={
                                                        <Tag
                                                            color={invoiceStatusColorText(
                                                                optimizedInvoiceDefSet(invoice),
                                                            )}
                                                        >
                                                            {readableInvoiceStatus(optimizedInvoiceDefSet(invoice))}
                                                        </Tag>
                                                    }
                                                    type={'receipts'}
                                                    id={invoice?.id}
                                                    detailsPath={'invoiceDetails'}
                                                    key={invoice?.id}
                                                />
                                            )
                                        })
                                    }}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Col>
        )
    }

    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const CalendarCard = () => {
        return (
            <Col xs={12} lg={8}>
                <div
                    ref={daysref}
                    style={{
                        backgroundColor: '#F8F8F8',
                        padding: '20px',
                        borderRadius: '8px',
                        minHeight: '160px',
                        marginBottom: '10px',
                    }}
                    className="d-flex flex-column"
                >
                    <Avatar
                        style={{ backgroundColor: 'white' }}
                        icon={<CalendarBlank weight="regular" color="#334055" />}
                    />
                    <Typography.Text className="smallheader" style={{ marginTop: '4px' }}>
                        {moment().endOf('month').endOf('day').fromNow(true)}
                    </Typography.Text>
                    <Typography.Text className="smallparagraph descriptions">Fin de mes</Typography.Text>
                    <Row wrap style={{ marginTop: '8px' }}>
                        {Array.from({ length: moment().endOf('month').startOf('day').date() }, (_, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{
                                        width: '5px',
                                        height: '5px',
                                        borderRadius: '50%',
                                        backgroundColor: i < moment().date() ? '#8666FF' : '#E8E8E8',
                                        margin: '2px',
                                    }}
                                ></div>
                            )
                        })}
                    </Row>
                </div>
            </Col>
        )
    }

    const LastGeneratedReport = () => {
        return (
            <Col xs={24} lg={14}>
                <div
                    className="d-flex flex-row"
                    style={{ backgroundColor: '#F8F8F8', padding: '5px', borderRadius: '8px', marginBottom: '10px' }}
                >
                    <Avatar
                        style={{ backgroundColor: 'white', marginRight: '10px' }}
                        icon={<Question weight="regular" color="#334055" />}
                    />
                    <Typography.Text className="smallparagraph">
                        Reporte generado hasta el {moment().add(-1, 'days').endOf('day').format('DD MMM YYYY HH:mm')}
                    </Typography.Text>
                </div>
            </Col>
        )
    }

    const MembershipCard = ({ xs = 24, lg = 12, xl = 8 }: { xs?: number; lg?: number; xl?: number }) => {
        if (billing?.subscription?.status === 'active' || billing?.plan?.id !== 'free') return <></>

        return (
            <Col xs={xs} lg={lg} xl={xl}>
                <div
                    style={{
                        padding: '15px',
                        borderRadius: '10px',
                        backgroundColor: '#F8F8F8',
                        // border: '1px solid #E8E8E8',
                    }}
                    className="d-flex flex-column"
                >
                    <Row>
                        <Avatar
                            style={{ backgroundColor: 'white', marginRight: '10px' }}
                            icon={<Rocket weight="regular" color="#334055" />}
                        />
                        <div className="d-flex flex-column" style={{ flex: 1 }}>
                            <Typography.Text className="smallheader">Suscríbete a un plan</Typography.Text>
                            <Typography.Text className="smallparagraph descriptions">
                                Libera todo el potencial de gigstack pro y olvídate de los procesos manuales
                            </Typography.Text>
                            <div style={{ marginTop: '15px' }}>
                                {' '}
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        navigate('/memberships')
                                    }}
                                >
                                    Contratar plan
                                </Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </Col>
        )
    }

    const IntegrationStatus = ({ active, image }: { active: boolean; image: string }) => {
        return (
            <Col xs={12} lg={3}>
                <div
                    className="d-flex flex-column"
                    style={{
                        backgroundColor: '#F8F8F8',
                        padding: '15px',
                        borderRadius: '8px',
                        marginBottom: '10px',
                        alignItems: 'center',
                    }}
                >
                    <Row justify="end" style={{ width: '100%' }}>
                        <div
                            style={{
                                backgroundColor: active ? '#00FF00' : '#FF0000',
                                borderRadius: '50%',
                                width: '5px',
                                height: '5px',
                            }}
                        ></div>
                    </Row>
                    <Image src={image} width={25} height={25} />
                </div>
            </Col>
        )
    }

    const QuickAccessCards = ({ xs = 24, lg = 12, xl = 8 }: { xs?: number; lg?: number; xl?: number }) => {
        return (
            <Row>
                <Col xs={23} style={{ marginTop: '25px' }}>
                    <label>Accesos rápidos</label>
                </Col>
                <Col xs={24} lg={23} style={{ marginTop: '15px' }}>
                    <Row gutter={12}>
                        {team?.onboardingUrl && !team?.facturapi?.completed && (
                            <Col xs={24} lg={8} style={{ marginTop: '5px' }}>
                                <div
                                    style={{
                                        padding: '15px',
                                        borderRadius: '10px',
                                        background: 'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                                        border: '1px solid transparent',
                                    }}
                                >
                                    <Typography.Text className="smallparagraphbold">
                                        Enlace para subir sellos
                                    </Typography.Text>
                                    <div style={{ marginBottom: '1px' }}></div>
                                    <Typography.Text className="smallparagraph descriptions">
                                        Comparte este enlace con tu contador para que pueda subir los sellos del SAT por ti
                                    </Typography.Text>
                                    <div style={{ marginBottom: '10px' }}></div>
                                    <Button
                                        onClick={() => {
                                            navigator.clipboard.writeText(team.onboardingUrl)
                                            message.success('Enlace copiado al portapapeles')
                                        }}
                                        icon={<CopySimple />}
                                        type="dashed"
                                        size="small"
                                    >
                                        Copiar enlace
                                    </Button>
                                </div>
                            </Col>
                        )}
                        {billingAccount?.price_id === 'free' && (
                            <Col xs={24} lg={8} style={{ marginTop: '5px' }}>
                                <div
                                    style={{
                                        padding: '15px',
                                        borderRadius: '10px',
                                        background:
                                            'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                                        border: '1px solid transparent',
                                    }}
                                >
                                    <Typography.Text className="smallparagraphbold">
                                        Suscríbete a un plan
                                    </Typography.Text>
                                    <div style={{ marginBottom: '1px' }}></div>
                                    <Typography.Text className="smallparagraph descriptions">
                                        Libera todo el potencial de gigstack pro y olvídate de los procesos manuales
                                    </Typography.Text>
                                    <div style={{ marginBottom: '10px' }}></div>
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={() => {
                                            navigate('/memberships')
                                        }}
                                    >
                                        Contratar plan
                                    </Button>
                                </div>
                            </Col>
                        )}
                        <Col xs={24} lg={8} style={{ marginTop: '5px' }}>
                            <div
                                style={{
                                    padding: '15px',
                                    borderRadius: '10px',
                                    background:
                                        'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                                    border: '1px solid transparent',
                                }}
                            >
                                <Typography.Text className="smallparagraphbold">Mi portal de clientes</Typography.Text>
                                <div style={{ marginBottom: '1px' }}></div>
                                <Typography.Text className="smallparagraph descriptions">
                                    Comparte este link con tus clientes para que puedan ver sus facturas y pagos.
                                </Typography.Text>
                                <div style={{ marginBottom: '10px' }}></div>
                                {team?.customerPortalId ? (
                                    <Button
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                `https://portal.gigstack.pro/${team?.customerPortalId}`,
                                            )
                                            message.success('Link copiado al portapapeles')
                                        }}
                                        icon={<CopySimple />}
                                        type="dashed"
                                        size="small"
                                    >
                                        /{team?.customerPortalId}
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        disabled={billingAccount?.price_id === 'free'}
                                        size="small"
                                        onClick={() => {
                                            dispatch(
                                                setData({
                                                    item: 'settingsDrawerConfig',
                                                    data: {
                                                        type: 'customerPortal',
                                                        title: 'Portal de clientes',
                                                        description: 'Edita el acceso al portal de cliente.',
                                                    },
                                                }),
                                            )
                                            dispatch(openModal('settingsDrawerVisible'))
                                        }}
                                    >
                                        Configurar portal
                                    </Button>
                                )}
                            </div>
                        </Col>
                        <Col xs={24} lg={8} style={{ marginTop: '5px' }}>
                            <div
                                style={{
                                    padding: '15px',
                                    borderRadius: '10px',
                                    background:
                                        'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                                    border: '1px solid transparent',
                                }}
                            >
                                <Typography.Text className="smallparagraphbold">Portal de ayuda</Typography.Text>
                                <div style={{ marginBottom: '1px' }}></div>
                                <Typography.Text className="smallparagraph descriptions">
                                    Encuentra artículos, tips y ayuda de como utilizar gigstack en nuestro helpcenter.
                                </Typography.Text>
                                <div style={{ marginBottom: '10px' }}></div>
                                <Button
                                    onClick={() => {
                                        window.open('https://helpcenter.gigstack.pro', '_blank')
                                    }}
                                    icon={<Link />}
                                    type="dashed"
                                    size="small"
                                >
                                    acceder
                                </Button>
                            </div>
                        </Col>
                        <Col xs={24} lg={8} style={{ marginTop: '5px' }}>
                            <div
                                style={{
                                    padding: '15px',
                                    borderRadius: '10px',
                                    background:
                                        'linear-gradient(180deg, rgba(186, 216, 239, 0.34) 0%, rgba(134, 102, 255, 0.06) 100%)',
                                    border: '1px solid transparent',
                                }}
                                className={posthog.isFeatureEnabled('featureRequestsCards') ? 'shake' : ''}
                            >
                                <Typography.Text className="smallparagraphbold">Feature Requests</Typography.Text>
                                <div style={{ marginBottom: '1px' }}></div>
                                <Typography.Text className="smallparagraph descriptions">
                                    Accede a la cajita de deseos y vota por las funcionalidades que más te gustaría
                                    tener
                                </Typography.Text>
                                <div style={{ marginBottom: '10px' }}></div>
                                <Button
                                    type="default"
                                    size="small"
                                    onClick={() => {
                                        dispatch(openModal('showFeedback'))
                                        trackEvent(
                                            {
                                                name: 'feedback_opened',
                                                metadata: {
                                                    from: 'card',
                                                },
                                            },
                                            posthog,
                                        )
                                    }}
                                >
                                    Acceder
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} lg={23} style={{ marginTop: '25px' }}>
                    {(!invitesInfo.loaded || invitesInfo?.invites?.length > 0) && (
                        <PendingInvitesElement
                            customHeader={
                                <Col xs={24}>
                                    <label>Invitaciones pendientes</label>
                                </Col>
                            }
                            setInvitesInfo={(v: any) => setInvitesInfo(v)}
                            invitesInfo={invitesInfo}
                        />
                    )}
                </Col>

                <Col xs={0} lg={0}>
                    <label>Recepción de ingresos vía</label>
                    <Row gutter={12} style={{ marginTop: '15px' }}>
                        {integrations(teamDefSet(team ?? {}))
                            .filter((i: any) => i.clasification === 'payments' || i.clasification === 'invoice')
                            .sort((a: any, b: any) => (a.completed ? -1 : 1))
                            .map((intg) => {
                                return <IntegrationStatus active={intg.completed} image={intg.logo} />
                            })}

                        {/* <IntegrationStatus /> */}
                    </Row>
                </Col>

                <Col xs={24} lg={23} style={{ marginTop: '24px' }}>
                    <div
                        className="d-flex flex-column"
                        style={{
                            backgroundColor: '#F8F8F8',
                            padding: '15px',
                            borderRadius: '8px',
                            marginBottom: '10px',
                        }}
                    >
                        <Row justify="space-between" align="middle">
                            <Row>
                                <Avatar
                                    style={{ backgroundColor: 'white', marginRight: '10px' }}
                                    icon={<Rocket weight="regular" color="#334055" />}
                                />
                                <div className="d-flex flex-column">
                                    <Typography.Text className="smallparagraphbold">
                                        Tu feedback es lo más importante
                                    </Typography.Text>
                                    <Typography.Text className="smallparagraph descriptions">
                                        Ayúdanos a mejorar gigstack con tus comentarios y sugerencias
                                    </Typography.Text>
                                </div>
                            </Row>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    window.open('https://calendly.com/yidem-o5y', '_blank')
                                    trackEvent(
                                        {
                                            name: 'feedback_opened',
                                            metadata: {
                                                from: 'card',
                                            },
                                        },
                                        posthog,
                                    )
                                }}
                            >
                                Agendar una llamada
                            </Button>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }

    const TestModeInsightsNotice = () => {
        return (
            <Col xs={23} lg={8}>
                <Row gutter={10}>
                    <Col xs={24}>
                        <div
                            style={{
                                padding: '15px',
                                borderRadius: '10px',
                                backgroundColor: '#F8F8F8',
                            }}
                            className="d-flex flex-row"
                        >
                            <Avatar
                                style={{ backgroundColor: 'white', marginRight: '10px' }}
                                icon={<TestTube weight="regular" color="#334055" />}
                            />
                            <Typography.Text className="smallparagraph">
                                Insights disponibles unicamente en modo live.
                            </Typography.Text>
                        </div>
                    </Col>
                </Row>
            </Col>
        )
    }

    const steps: TourProps['steps'] = [
        {
            title: <Typography.Text className="smallheader">Selecciona el tiempo del reporte</Typography.Text>,
            description: (
                <Typography.Text className="smallparagraph">
                    Puedes seleccionar un tiempo para visualizar los datos registrados, utilizaremos la cantidad de días
                    para el periodo anterior, por ejemplo si seleccionas del día 1 al 15, el periodo anterior será del
                    16 al 30 del mes anterior.
                </Typography.Text>
            ),
            // cover: (
            //     <img
            //         alt="tour.png"
            //         src="https://user-images.githubusercontent.com/5378891/197385811-55df8480-7ff4-44bd-9d43-a7dade598d70.png"
            //     />
            // ),
            target: () => datepicker.current,
        },
        {
            title: <Typography.Text className="smallheader">Tiempo para fin de mes</Typography.Text>,
            description: (
                <Typography.Text className="smallparagraph">
                    Fin de mes es un momento importante a nivel fiscal, ya que es necesario realizar cierres y
                    asegurarnos de que todo esté en orden.
                </Typography.Text>
            ),
            target: () => daysref.current,
        },
        {
            title: <Typography.Text className="smallheader">Pagos completos</Typography.Text>,
            description: (
                <Typography.Text className="smallparagraph">
                    Cantidad de pagos en estado exitoso realizados en el periodo seleccionado
                </Typography.Text>
            ),
            target: () => completedpayments.current,
        },
        {
            title: <Typography.Text className="smallheader">Crecimiento del periodo</Typography.Text>,
            description: (
                <Typography.Text className="smallparagraph">
                    Utilizamos la formula para calculcar el revenue growth sobre el periodo anterior seleccionado.
                </Typography.Text>
            ),
            target: () => periodGrowth.current,
        },
        {
            title: <Typography.Text className="smallheader">Gráfica de pagos</Typography.Text>,
            description: (
                <Typography.Text className="smallparagraph">
                    Gráfica de pagos realizados en el periodo seleccionado.
                </Typography.Text>
            ),
            target: () => paymentsGraph.current,
        },
        {
            title: <Typography.Text className="smallheader">Gráfica de facturas</Typography.Text>,
            description: (
                <Typography.Text className="smallparagraph">
                    Gráfica de facturas realizadas en el periodo seleccionado.
                </Typography.Text>
            ),
            target: () => invoicesGraph.current,
        },
        {
            title: <Typography.Text className="smallheader">Conciliación de recibos</Typography.Text>,
            description: (
                <Typography.Text className="smallparagraph">
                    Este valor es muy importante, ya que en caso de tenerlo activo, mostrará la cantidad de recibos
                    pendientes por facturar al final de mes.
                </Typography.Text>
            ),
            target: () => receiptsInfo.current,
        },
    ]

    return (
        <>
            <Row justify="center">
                <NoticeBoard />
                <Header />
                {team?.id && billingAccount?.id && user?.uid ? (
                    <InsightSpaceWrapper
                        fallback={
                            <Col xs={23} style={{ marginTop: '15px' }}>
                                <Row justify="start">
                                    <Col xs={23} lg={16}>
                                        <Row>
                                            <MembershipCard lg={6} />
                                            <LastResources />
                                            <QuickAccessCards />
                                        </Row>
                                    </Col>
                                    {testmode ? (
                                        <TestModeInsightsNotice />
                                    ) : (
                                        <Col xs={23} lg={8}>
                                            <Row gutter={10}>
                                                <CalendarCard />
                                                <ClientsAmount />
                                                <div
                                                    style={{
                                                        padding: '15px',
                                                        borderRadius: '10px',
                                                        backgroundColor: '#F8F8F8',
                                                    }}
                                                    onClick={() => startTour(true)}
                                                >
                                                    <Avatar
                                                        style={{ backgroundColor: 'white' }}
                                                        icon={<Question weight="regular" color="#334055" />}
                                                    />
                                                </div>
                                            </Row>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        }
                        children={({ data, pastPeriod, invoices, payments, receipts, pastPeriodDates }) => {
                            return (
                                <Col xs={23} style={{ marginTop: '15px' }}>
                                    <Row justify="center">
                                        <Col xs={23} lg={16}>
                                            <Row>
                                                <MembershipCard />
                                                <LastResources />
                                                <QuickAccessCards />
                                            </Row>
                                        </Col>
                                        {testmode ? (
                                            <TestModeInsightsNotice />
                                        ) : (
                                            <Col xs={23} lg={8}>
                                                <Row gutter={10}>
                                                    <LastGeneratedReport />
                                                    <ClientsAmount />
                                                    <Col xs={24} lg={3}>
                                                        <div
                                                            className="d-flex flex-row clickable"
                                                            style={{
                                                                backgroundColor: '#F8F8F8',
                                                                padding: '5px',
                                                                borderRadius: '8px',
                                                                marginBottom: '10px',
                                                                alignItems: 'center',
                                                                justifyItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                            onClick={() => startTour(true)}
                                                        >
                                                            <Avatar
                                                                style={{ backgroundColor: 'white' }}
                                                                icon={<Question weight="regular" color="#334055" />}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <CalendarCard />

                                                    <PaymentsCard
                                                        payments={payments}
                                                        refElement={completedpayments}
                                                        data={data}
                                                    />
                                                    <PeriodGrowthCard
                                                        data={data}
                                                        refElement={periodGrowth}
                                                        payments={payments}
                                                    />
                                                    <RefundsCard payments={payments} />
                                                    <FullChartPayments
                                                        pastPeriod={pastPeriod}
                                                        data={data}
                                                        payments={payments}
                                                        refElement={paymentsGraph}
                                                    />
                                                    <FullChartInvoices
                                                        data={data}
                                                        pastChartData={pastPeriod}
                                                        refElement={invoicesGraph}
                                                        refElement2={receiptsInfo}
                                                        receipts={receipts}
                                                        invoices={invoices}
                                                    />
                                                    <InvoiceSourcesChart data={data} invoices={invoices} />
                                                    <InvoiceTypesCharts
                                                        pastPeriod={pastPeriod}
                                                        data={data}
                                                        invoices={invoices}
                                                        receipts={receipts}
                                                    />
                                                </Row>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            )
                        }}
                    />
                ) : (
                    <Typography.Text>No tienes un equipo seleccionado</Typography.Text>
                )}
            </Row>
            <Tour open={tour} onClose={() => startTour(false)} steps={steps} />
        </>
    )
}
export default HomePage
